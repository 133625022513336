/* global gon */
import { post } from './service'

export const createCollectionAssets = (productIds) => {
  const params = {
    authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
    product_ids: productIds,
  }

  return post(gon.create_collection_url, params)
}
