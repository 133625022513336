import { connect } from 'react-redux'
import StemcellCertificationForm from '../../../components/publisher/ProductSpecs/StemcellCertificationForm'
import * as formSelectors from '../../../products/react_components/selectors/formSelectors'

import {
  updateCertificationFromFeatures,
  updateCertification,
  cancelStemcellSubmissionStart,
} from '../../../products/react_components/actions/form_slice_actions'

import { toggleInfo } from '../../../products/react_components/actions/publisher_ui_slice_actions'

const mapStateToProps = (state) => ({
  certifications: formSelectors.selectCertifications(state),
  showInfo: state.uiState.certificationPanel.showInfo,
  mediaType: state.formState.turbosquid_product_form.values.mediaType,
  formActionInProgress: state.formState.formActionInProgress,
  features: state.featureState.features,
  draftId: formSelectors.selectDraftId(state),
  collectionFeature: state.featureState.collectionFeature,
  pendingCancelStemcellSubmission: formSelectors.selectCancelStemcellSubmissionStatus(state),
  cancelStemcellSubmissionSuccess: formSelectors.selectCancelStemcellSubmissionSuccess(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateCertification: (certifications) => dispatch(updateCertification({ certifications })),
  updateCertificationFromFeatures: () => dispatch(updateCertificationFromFeatures()),
  toggleInfo: (value) => dispatch(toggleInfo({ panel: 'certificationPanel', value })),
  cancelSubmission: () => dispatch(cancelStemcellSubmissionStart()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StemcellCertificationForm)
