import React, { useContext } from 'react'
import { REGULAR, THUMBNAIL, TURNTABLE, UV, WIREFRAME } from '../../products/constants'
import { ProductContext } from '../../../contexts/ProductContext'
import { FileContext } from '../../../contexts/FileContext'
import useFileForm from '../../../hooks/useFileForm'
import { imageDimension } from './helpers'
import Badge from '../../../products/react_components/badge'
import useThumbnail from '../../../hooks/useThumbnail'
import Checkbox from './Checkbox'
import ContentLoader from './ContentLoader'

const i18nOptions = {
  scope: 'turbosquid.products.product_images_panel',
}

export default function PreviewFile({ file, selected, onSelect, signaturePreview }) {
  const { type, id, attributes: { thumbnailType: initialThumbnailType} } = file
  const { draftId } = useContext(ProductContext)
  const { clearFile, deleteFiles, pendingDelete } = useContext(FileContext)
  const {
    name,
    sourceWidth,
    sourceHeight,
    url200,
    meetsWireframeRequirements,
    meetsUvRequirements,
  } = useThumbnail(draftId, file)
  const { register, fileFormValues } = useFileForm(file, { thumbnailType: initialThumbnailType })
  const thumbnailType = fileFormValues?.thumbnailType
  const turntablePending = type === TURNTABLE && !url200
  const isPendingDelete = pendingDelete.includes(id)
  const isReady = !!draftId

  const handleDeleteFile = () => {
    if (turntablePending) {
      clearFile(id)
    } else {
      deleteFiles({ files: [file] })
    }
  }

  const disabled = !isReady || isPendingDelete

  return (
    <div
      data-id={id /* required by SortableJS toArray */}
      className={`
        col-sm-6 col-md-4 col-lg-3 image file-content
        ${!signaturePreview ? 'reorder-enabled' : ''}
        ${signaturePreview ? ' reorder-disabled preview-aspect-ratio-square search-image-placeholder' : ''}
      `}
    >
      {signaturePreview && (
        <p className="signature">
          { I18n.t('search_image', i18nOptions) }
        </p>
      )}
      <div className="file-container thumbnail">
        <button
          type="button"
          className="btn-link btnCancelUpload"
          onClick={() => handleDeleteFile()}
          disabled={disabled}
        >
          {I18n.t('remove', i18nOptions)}
        </button>

        <div className="preview-data">
          <div className="preview-wrapper thumbnail" data-testid="image-wrapper">
            {url200 ? (
              <img
                draggable={false}
                src={url200}
                className="preview"
                alt={name}
                style={imageDimension(sourceWidth, sourceHeight)}
              />
            ) : (
              <ContentLoader />
            )}
            <div className="preview-right-top-corner">
              <Badge
                type={type}
                imageType={thumbnailType}
              />
            </div>
            <div className={`preview-left-top-corner${selected ? '-checked' : ''}`}>
              <Checkbox id={id} selected={selected} onSelect={onSelect} />
            </div>
            {type === THUMBNAIL && !signaturePreview && (
              <form className="form-group radio_buttons optional product-panel__image-type-selector">
                <span className="radio inline image-type-wrapper">
                  <input
                    {...register(`${id}.thumbnailType`, { value: initialThumbnailType })}
                    type="radio"
                    id={`field-${REGULAR}-${id}`}
                    value={REGULAR}
                    className="radio_buttons optional"
                    disabled={disabled}
                  />
                  <label htmlFor={`field-${REGULAR}-${id}`}>{I18n.t('image', i18nOptions)}</label>
                </span>
                <span className="radio inline image-type-wrapper">
                  <input
                    {...register(`${id}.thumbnailType`, { value: initialThumbnailType })}
                    type="radio"
                    id={`field-${WIREFRAME}-${id}`}
                    value={WIREFRAME}
                    className="radio_buttons optional"
                    disabled={disabled || !meetsWireframeRequirements}
                  />
                  <label htmlFor={`field-${WIREFRAME}-${id}`}>{I18n.t('wireframe', i18nOptions)}</label>
                </span>
                <span className="radio inline image-type-wrapper">
                  <input
                    {...register(`${id}.thumbnailType`, { value: initialThumbnailType })}
                    type="radio"
                    id={`field-${UV}-${id}`}
                    value={UV}
                    className="radio_buttons optional"
                    disabled={disabled || !meetsUvRequirements}
                  />
                  <label htmlFor={`field-${UV}-${id}`}>{I18n.t('uv', i18nOptions)}</label>
                </span>
              </form>
            )}
          </div>
          <p className="file-name">{name}</p>
        </div>
      </div>
    </div>
  )
}
