import React from 'react'
import PropTypes from 'prop-types'

const ProductDraftID = ({ draftId }) => (
  <div id="js-turbosquid-product-draft-id" className="form-group hidden turbosquid_product_form_draft_id">
    <input
      className="hidden form-control"
      id="turbosquid_product_form_draft_id"
      name="turbosquid_product_form[draft_id]"
      type="hidden"
      value={draftId}
    />

    <span className="text-muted" />
  </div>
)

export default ProductDraftID

ProductDraftID.propTypes = {
  draftId: PropTypes.string,
}

ProductDraftID.defaultProps = {
  draftId: null,
}
