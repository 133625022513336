/* global I18n */
import { connect } from 'react-redux'
import PublisherNotifications from '../../../components/publisher/EditHeader/PublisherNotifications'
import {
  selectProductFormErrors,
  selectProductStatus,
} from '../../../products/react_components/selectors/formSelectors'
import { changeTab } from '../../../products/react_components/actions/shared_actions'

const mapStateToProps = (state) => ({
  formErrors: selectProductFormErrors(state),
  activeTab: state.uiState.activeTab,
  status: selectProductStatus(state),
  displayAlerts: state.formState.displayAlerts,
})

const mapDispatchToProps = (dispatch) => ({
  changeTab: (tab) => dispatch(changeTab({ tab })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublisherNotifications)
