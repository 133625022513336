/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import MoneyUtility from './common/money_utility';
import ChartUtility from './common/chart_utility';
import PaymentUtility from './common/payment_utility';

const PaymentEstimated = createReactClass({
  displayName: 'PaymentEstimated',
  mixins: [MoneyUtility, ChartUtility, PaymentUtility],
  render() {
    let footer_i18n_key; let header_i18n_key; let learn_more_url; let money; let
      money_class
    const underThreshold = this.props.estimated < this.props.threshold || this.props.estimated <= 0
    const forceUnpaidRoyalties = this.props.threshold < this.props.estimated && this.afterPaymentDate(this.props.last_payment_date)

    if (!gon.payment_hold && (gon.payment_preference.hold_payment || gon.payment_issues || underThreshold || gon.payment_preference.multiple_accounts || forceUnpaidRoyalties)) {
      header_i18n_key = 'estimated_unpaid_royalties'
      money_class = 'text-warning'
      money = this.cents_to_currency_parts(this.props.pending)
      learn_more_url = gon.tax_and_documentation_url
      footer_i18n_key = 'does_not_include_taxes'
    } else {
      header_i18n_key = 'estimated_unpaid_royalties'
      money_class = 'text-success'
      money = this.cents_to_currency_parts(this.props.estimated)
      learn_more_url = gon.tax_and_documentation_url
      footer_i18n_key = 'does_not_include_taxes'
    }

    return (
      <div>
        <div className="row text-center">
          <h4>
            {I18n.t(`dashboard.payments.${header_i18n_key}`)}
            {'\
           \
'}
            {(() => {
              if (gon.payment_hold) {
                return <span className="text-muted text-hold-payment">{I18n.t('dashboard.payments.on_hold')}</span>
              } if (!gon.payment_issues && !underThreshold && !gon.payment_preference.multiple_accounts) {
                return <span className="text-muted">{this.verboseDate(this.nextPaymentDate(this.props.last_payment_date))}</span>
              }
            })()}
          </h4>
          {gon.payment_hold ? (
            <span className="text-hold-payment-msg">
              {I18n.t('dashboard.payments.note')}
              :
              {' '}
              {gon.hold_payment_message}
            </span>
          ) : undefined}
        </div>
        <div className="row text-center">
          <h1 className={money_class}>
            {money.main_unit}
            <span className="cents">
              .
              {money.fractional_unit}
            </span>
          </h1>
        </div>
        <div className="row text-center">
          <div className="col-xs-12 panel-footnote">
            <span className="text-muted">
              {I18n.t(`dashboard.payments.${footer_i18n_key}`)}
              {'\
               \
'}
              <a href={learn_more_url}>{I18n.t('dashboard.payments.learn_more')}</a>
              <br />
              {I18n.t('dashboard.payments.learn_more_time_info', { time: gon.new_orleans_local_time })}
            </span>
          </div>
        </div>
      </div>
    )
  },
})

export default PaymentEstimated;
