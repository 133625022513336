import {
  CERTIFIED_PREVIEWS,
  STEMCELL_CERTIFICATION,
  CUSTOMER,
  MIN_NATIVE_COUNT,
  MIN_SEARCH_PREVIEW_COUNT,
  MIN_THUMBNAIL_COUNT,
  MIN_TURNTABLE_COUNT,
  MIN_UV_COUNT,
  MIN_WIREFRAME_COUNT,
  NATIVE,
  NONE,
  PROMOTIONAL,
  TEXTURE,
  THUMBNAIL,
  TURNTABLE,
  UV,
  VIEWER
} from '../components/products/constants'
import { invert } from 'lodash'

export const mapGon = (gon) => ({
  product: mapProduct(gon.product, gon.features),
  validations: gon.product_validations ? mapGonProductValidations(gon.product_validations) : {},
  endpoints: mapGonEndpoints(gon),
  tips: mapGonTips(gon),
  collectionTaxonomyId: gon.collection_taxonomy_id,
  previewOverrideTaxonomyIds: gon.green_lantern_preview_override_taxonomy_ids,
  legacyProductDate: new Date(gon.legacy_product_date),
  checkmateEnabled: gon.checkmate_enabled,
  checkMateDisabledLink: gon.checkmate_disabled_link,
})

export const mapProduct = (product, features) => ({
  id: product.id,
  draftId: product.draft_id ? parseInt(product.draft_id) : undefined,
  files: mapFiles(product.files),
  previews: mapPreviews(product.previews),
  certifications: mapCertifications(product.certifications),
  createdAt: product.product_created_at ? new Date(product.product_created_at) : new Date(),
  updatedAt: product.updated_at ? new Date(product.updated_at) : new Date(),
  name: product.name,
  uvMapped: product.uv_mapped,
  unwrappedUVs: product.unwrapped_u_vs,
  textures: product.textures,
  status: product.status,
  features,
  price: product.price,
  description: product.description,
  collectionProducts: product.bundle_asset_list
})

const mapCertifications = (certifications) => {
  return certifications.reduce((acc, cert) => {
    const id = mapApiToCertId(cert.id)
    const certification = {
      id,
      queueId: cert.queue_id,
      queueStatus: cert.queue_status,
      status: cert.status,
      type: cert.type,
    }
    return {
      ...acc,
      [id]: certification
    }
  }, {})
}

const certIdToApi = {
  [CERTIFIED_PREVIEWS]: 'certified_previews',
  [STEMCELL_CERTIFICATION]: 'stemcell_v2',
  [NONE]: 'none',
}
const apiToCertId = invert(certIdToApi)
export const mapApiToCertId = (type) => apiToCertId[type]
export const mapCertIdToApi = (type) => certIdToApi[type]

export const mapFile = (file) => {
  const type = mapApiToFileType(file.type)
  return {
    type,
    id: file.id,
    attributes: {
      name: file.attributes.name,
      size: file.attributes.size,
      ...mapFileAttributes(type, file.attributes)
    }
  }
}

const mapFiles = (files) => files.map(mapFile)

const mapFileAttributes = (type, attributes) => {
  switch (type) {
    case NATIVE:
      return {
        format: attributes.file_format,
        formatVersion: attributes.format_version,
        renderer: attributes.renderer,
        rendererVersion: attributes.renderer_version,
        isNative: !!attributes.is_native,
      }
    case THUMBNAIL:
    case TURNTABLE:
      return {}
    case TEXTURE:
    case PROMOTIONAL:
    case VIEWER:
    case CUSTOMER:
      return {
        fileType: type,
        description: attributes.description,
      }
    default:
      throw new Error('invalid file type')
  }
}

export const mapPreviews = (previews) => previews.map(mapPreview)

export const mapPreview = (preview) => {
  const turntableAttributes = preview.type === TURNTABLE
    ? { frameCount: preview.frame_count }
    : {}
  return {
    type: preview.type,
    id: preview.id,
    attributes: {
      name: preview.filename,
      thumbnailType: mapThumbnailType(preview.thumbnail_type),
      sourceHeight: preview.source_height,
      sourceWidth: preview.source_width,
      validSearchBackground: preview.search_background,
      url64: preview.url_64,
      url90: preview.url_90,
      url128: preview.url_128,
      url200: preview.url_200,
      ...turntableAttributes
    }
  }
}

const mapThumbnailType = (type) => {
  switch (type) {
    case 'regular':
    case 'wireframe':
      return type
    case 'uv_map':
      return UV
    default:
      return ''
  }
}

const fileTypeToApi = {
  [NATIVE]: 'product_file',
  [TEXTURE]: 'texture_file',
  [VIEWER]: 'viewer_file',
  [PROMOTIONAL]: 'promotional_file',
  [CUSTOMER]: 'customer_file',
  [THUMBNAIL]: 'thumbnail'
}

const apiToFileType = invert(fileTypeToApi)

export const mapApiToFileType = (type) => apiToFileType[type]
export const mapFileTypeToApi = (type) => fileTypeToApi[type]

const mapGonProductValidations = (validations) => ({
  nameMaxLength: validations.name_length_max,
  priceMin: validations.price_gte,
  versionMaxLength: validations.version_length_max,
  descriptionMaxLength: validations.description_length_max,
  minPreviewCount: MIN_THUMBNAIL_COUNT,
  minWireframeCount: MIN_WIREFRAME_COUNT,
  minUvCount: MIN_UV_COUNT,
  minTurntableCount: MIN_TURNTABLE_COUNT,
  minNativeCount: MIN_NATIVE_COUNT,
  minSearchImageCount: MIN_SEARCH_PREVIEW_COUNT
})

const mapGonEndpoints = (gon) => ({
  uploads: gon.send_key_url,
  stemcellResourcesUrl: gon.stemcell_resources_url
})

const mapGonTips = (gon) => ({
  regular: {
    url: gon.cm_image_icon_tips.beauty_shots.learn_more_url
  },
  uv: {
    url: gon.cm_image_icon_tips.uv_map.learn_more_url
  },
  wireframe: {
    url: gon.cm_image_icon_tips.wireframe.learn_more_url
  },
  turntable: {
    url: gon.cm_image_icon_tips.turntable.learn_more_url
  },
  search: {
    url: gon.cm_image_icon_tips.search_image.learn_more_url
  },
  naming: {
    turntables: gon.naming_turntables
  }
})
