import { formSlice } from '../reducers/form_slice'

export const {
  syncDraft,
  syncFiles,
  displayAlerts,
  startSubmit,
  changeInput,
  updateTags,
  updateMediaType,
  updateName,
  updateDescription,
  updatePrice,
  updateConstraints,
  updateFeatures,
  updateCertificationFromFeatures,
  updateCertification,
  updateLicense,
  requestFailed,
  requestSucceeded,
  constraintsFetchFailed,
  setFileSaveInProgress,
  startFileProgress,
  dropFiles,
  updateFileProgress,
  completeFileProgress,
  fileDisplayProcessed,
  fileProcessFailed,
  cancelFileProgress,
  saveImportedFileId,
  setFileFailedFalse,
  associatedFileUpdate,
  associatedFileUpdateEnd,
  associatedFileUpdatePendingReset,
  mainFileUpdateFields,
  mainFileUpdateEnd,
  mainFileUpdatePendingReset,
  clearOtherProgressFile,
  saveDraftId,
  saveDraftDone,
  publishProductInvalid,
  associatedFileDelete,
  mainFileDelete,
  fileDeletePending,
  fileDeletePendingFailed,
  fileDeletePendingFailedClear,
  saveValidationErrors,
  turntableCandidate,
  saveTurntableCandidates,
  notificationTurntableFail,
  appendNewTurntable,
  removeFailedTurntableFromPolling,
  deleteTurntableCandidate,
  filesUploaded,
  appendNewTurntableToPoll,
  setTurntablesSaveInProgress,
  previewDelete,
  previewTypeUpdateStart,
  previewUpdateOrder,
  previewUpdateSort,
  enableDragAnimation,
  disableDragAnimation,
  previewDeleteStart,
  sortState,
  previewUpdateFields,
  discardDraft,
  saveOldCertifiedPreviewsCertification,
  saveCertifiedPreviewsCertification,
  saveUploadedFile,
  deleteFileNotification,
  undoDeleteFilePending,
  associatedFileUndoDelete,
  mainFileUndoDelete,
  previewUndoDelete,
  disableCertifiedPreviewChange,
  fetchConstraints,
  fetchInitialConstraints,
  startFileProcess,
  getFileFormats,
  pollFilesDone,
  pollTurntablesDone,
  fileUploadSuccess,
  previewDraft,
  updateLastActionDraftSaving,
  updateFormActionInProgress,
  mainFileUpdateStart,
  disableDraftPendingChanges,
  undoDeletedFile,
  previewUpdateOrderStart,
  associatedFileDeleteStart,
  mainFileDeleteStart,
  retryFile,
  saveFeatureGraph,
  fetchFileCredentials,
  addThumbnailPolling,
  startThumbnailPolling,
  stopThumbnailPolling,
  setThumbnailPollingActive,
  setThumbnailPollingInactive,
  removeThumbnailPolling: removeThumbnailPollingActive,
  setTurntableFilesResolution,
  turntableSaveDone,
  updateTurntablesToPoll,
  setCollectionFeature,
  updateBrandFeatures,
  setDataValidationState,
  saveDraft,
  savePreviewOrderDone,
  setInvalidPreviewCount,
  setSkipDiffPreviews,
  setCreatingDraft,
  setDisableDragSort,
  clearPendingUndoDeletedFiles,
  setDraftSavePendingChanges,
  addAsyncQueue,
  removeAsyncQueueItem,
  setDisableLeavePagePrompt,
  setStemcell,
  cancelStemcellSubmission,
  cancelStemcellSubmissionStart,
} = formSlice.actions
