/* gon */
import { post } from './service'
import { mapProcess } from '../lib/squid-uploads-sdk/TurbosquidArtistAdaptor'
import { CUSTOMER, NATIVE, PROMOTIONAL, REGULAR, TEXTURE, THUMBNAIL, VIEWER } from '../components/products/constants'
import { invert } from 'lodash'
import { recognizeFormat, stripName } from '../utils/ProductFiles'

const processEndpoint = gon.check_processing_url

export const getProcess = ({ ids, csrfToken }) =>
  post(processEndpoint, {
    authenticity_token: csrfToken,
    ids,
  })
    .then(processes => processes.map(mapProcess))

const typeToApiResource = {
  [NATIVE]: 'product_files',
  [TEXTURE]: 'texture_files',
  [VIEWER]: 'viewer_files',
  [PROMOTIONAL]: 'promotional_files',
  [CUSTOMER]: 'customer_files',
  [THUMBNAIL]: 'thumbnails'
}

const apiResourceToType = invert(typeToApiResource)

export const mapTypeToApiResource = (type) => typeToApiResource[type]
export const mapApiResourceToType = (type) => apiResourceToType[type]

export const defaultNativeFileAttributes = {
  formatVersion: '',
  renderer: '',
  rendererVersion: '',
  isNative: false,
}

export const mapNativeFileUpload = (file, draft, formats) => {
  const fileFormat = recognizeFormat(file.name, formats)
  return {
    type: NATIVE,
    file,
    attributes: {
      draftId: draft.id,
      name: stripName(file.name),
      size: file.size,
      fileFormat: fileFormat === -1 ? 'other' : fileFormat,
      ...defaultNativeFileAttributes,
    }
  }
}

export const mapPreviewFileUpload = ({ file, draft, thumbnailType, watermarked }) => {
  return {
    type: THUMBNAIL,
    file,
    attributes: {
      draftId: draft.id,
      name: file.name,
      size: file.size,
      thumbnailType,
      watermarked
    }
  }
}

export const mapTurntableFileUpload = ({ file, draft, turntableId }) => {
  return {
    type: THUMBNAIL,
    file,
    attributes: {
      draftId: draft.id,
      name: file.name,
      size: file.size,
      turntableId,
      thumbnailType: REGULAR,
      watermarked: false
    }
  }
}

export const mapTextureFileUpload = ({ file, draft }) => {
  return {
    type: TEXTURE,
    file,
    attributes: {
      draftId: draft.id,
      name: file.name,
      size: file.size,
    }
  }
}
