import React from 'react';

export default class QuickPreviewPopup extends React.Component {
  constructor(props) {
    super(props)
    this.quickPreviewPopupWidth = props.width
    this.quickPreviewPopupGutter = props.gutter
    this.$quickPreviewPopup = $('#js-quick-preview-popup')
  }

  leftOffset({offset, width, documentWidth}) {
    let fitsOnLeft; let fitsOnRight; let
      yMax
    yMax = offset.left + width + this.quickPreviewPopupGutter + this.quickPreviewPopupWidth
    fitsOnRight = documentWidth > yMax
    fitsOnLeft = offset.left - this.quickPreviewPopupGutter - this.quickPreviewPopupWidth > 0
    if (fitsOnRight || !fitsOnLeft) {
      return offset.left + width + this.quickPreviewPopupGutter;
    }
    return offset.left - this.quickPreviewPopupGutter - this.quickPreviewPopupWidth;
  }

  topOffset({offset, height, documentHeight}) {
    let fitsVerticalAlignCenter; let verticalAlignCenterTopOffset; let
      verticalAlignCenterXMax
    verticalAlignCenterTopOffset = offset.top - (this.$quickPreviewPopup.height() - height) / 2
    verticalAlignCenterXMax = verticalAlignCenterTopOffset + this.quickPreviewPopupWidth
    fitsVerticalAlignCenter = documentHeight > verticalAlignCenterXMax
    if (fitsVerticalAlignCenter) {
      return verticalAlignCenterTopOffset
    }
    return offset.top + height - this.$quickPreviewPopup.height();
  }

  offset(options) {
    return {
      top: this.topOffset(options),
      left: this.leftOffset(options),
    }
  }

  show(options) {
    let popupContent
    this.$quickPreviewPopup.show()
    popupContent = options.$itemPopupContent.clone()
    this.$quickPreviewPopup.html(popupContent)
    popupContent.show().removeClass('hidden')
    this.$quickPreviewPopup.offset(this.offset(options))
    return this
  }

  hide() {
    this.$quickPreviewPopup.hide()
    return this
  }
}
