/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string'

const hiddenField = (key, object) => {
  if (object[key]) {
    return <input type="hidden" key={key} value={object[key]} name={key} />
  }
  return null
}

const hiddenFields = (search) => {
  const names = [
    'checkmate_status',
    'price_range',
    'file_format',
    'status',
    'size',
    'page',
  ]
  const searchQuery = queryString.parse(search)
  return <div>{names.map((name) => hiddenField(name, searchQuery))}</div>
}

const SearchForm = (props) => {
  const { location, term, handleChange } = props
  return (
    <>
      <form
        className="form-inline search-form"
        action="/turbosquid/products"
        method="get"
      >
        <div className="form-group">
          <input
            type="text"
            value={term}
            onChange={handleChange}
            className="form-control input-text search-box"
            placeholder={I18n.t('turbosquid_products.search_placeholder')}
            name="term"
          />
          <button type="submit" className="btn btn-default search-submit">
            <i className="fa fa-search" />
          </button>
          {hiddenFields(location.search)}
        </div>
      </form>
    </>
  )
}

export default SearchForm

SearchForm.propTypes = {
  location: PropTypes.object,
  term: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
}

SearchForm.defaultProps = {
  location: {},
  term: '',
}
