import { connect } from 'react-redux'
import ProductCategories from '../../../components/publisher/ProductSpecs/ProductCategoriesV2'
import { filterNotifications } from '../../../products/react_components/helpers'
import { toggleInfo } from '../../../products/react_components/actions/publisher_ui_slice_actions'
import {
  fetchConstraints,
  setCollectionFeature,
  updateFeatures,
  updateLicense,
} from '../../../products/react_components/actions/form_slice_actions'

const highlightPanel = (alerts) => (
  alerts.find((a) => a.alert_panels.find((p) => p === 'categories'))
)

const mapStateToProps = (state) => ({
  features: state.featureState.features,
  notifications: filterNotifications(state.featureState.alerts, 'categories'),
  highlight_panel: highlightPanel(state.featureState.alerts),
  mediaType: state.formState.turbosquid_product_form.values.mediaType,
  showInfo: state.uiState.categoriesPanel.showInfo,
  errors: state.formState.turbosquid_product_form.formValidationErrors,
  formActionInProgress: state.formState.formActionInProgress,
  name: state.formState.turbosquid_product_form.values.name,
  description: state.formState.turbosquid_product_form.values.description,
  tags: state.formState.turbosquid_product_form.values.tags,
  collectionFeature: state.featureState.collectionFeature,
})

const mapDispatchToProps = (dispatch) => ({
  updateFeaturesWithConstraints: (features, errors) => dispatch(fetchConstraints({ features, errors })),
  updateNameWithConstraints: (name, errors) => dispatch(fetchConstraints({ name, errors })),
  updateDescriptionWithConstraints: (description, errors) => dispatch(fetchConstraints({ description, errors })),
  updateTagsWithConstraints: (tags, errors) => dispatch(fetchConstraints({ tags, errors })),
  updateFeatures: (features) => dispatch(updateFeatures({ features, constraintsPending: true })),
  toggleInfo: (value) => dispatch(toggleInfo({ panel: 'categoriesPanel', value })),
  updateCollection: (value) => dispatch(setCollectionFeature(value)),
  updateLicense: (license, focusInput = true) => dispatch(updateLicense({ license, focusInput })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCategories)
