import React from 'react'
import GenericModal from "../../shared/GenericModal";
import PropTypes from 'prop-types'
import ReactSpinner from '../../products/react-spinner';

const i18nScope = {
  scope: 'turbosquid.products.stemcell_cancelation_modal',
}

export const StemCellCancelationModal = ({
  show,
  setShowModal,
  cancelStemcellSubmission,
  pendingCancelStemcellSubmission,
}) => {
  const handleCancelSubmission = () => {
    cancelStemcellSubmission()
  }

  return show
  ? (
    <GenericModal
      title={''}
      className="bulk stem-cell-cancelation-modal"
      show={true}
      hideModal={() => setShowModal(false)}
    >
      <div className="center">
        <h2>{I18n.t('title', i18nScope)}</h2>
        <p>{I18n.t('model_online', i18nScope)}</p>
        <p>{I18n.t('removed_from_queue', i18nScope)}</p>
        <p>{I18n.t('need_help', i18nScope)}</p>
        <hr></hr>
      </div>
      <div className="center buttons">
        <button onClick={() => setShowModal(false)} disabled={pendingCancelStemcellSubmission}>{I18n.t('return_to_editing', i18nScope)}</button>
        <button onClick={() => handleCancelSubmission()} className={pendingCancelStemcellSubmission ? "disabled" : "active"} disabled={pendingCancelStemcellSubmission}>
          <span>
            {pendingCancelStemcellSubmission 
            ? <div className="spinner-container">
                <ReactSpinner />
              </div>
            : I18n.t('confirm_cancel_button', i18nScope)}
          </span>
        </button>
      </div>
      <div className="gradient"></div>
    </GenericModal>
  ) : (
    <></>
    )
}

StemCellCancelationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  cancelStemcellSubmission: PropTypes.func.isRequired,
  pendingCancelStemcellSubmission: PropTypes.bool.isRequired,
}
