import QuickPreview from './quick-preview.js'

$(() => {
  if ($('#my-products').length || $('#my-pixelsquid-products').length ) {
    window.quickPreview = new QuickPreview()
    $(document).on('event.showQuickPreview.quickPreview', () => $("[rel='tooltip']").tooltip())
  }
  $('.library-download').click(function () {
    return ga('send', 'event', 'downloads', $(this).data('group-name'), $(this).data('product-text'))
  })
  $('#js-close-library-notification').click(() => $.cookie('dismissed_library_notification', true, {
    expires: 365,
  }))
})
