/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import MoneyUtility from './common/money_utility';
import ChartUtility from './common/chart_utility';
import PaymentAccountIssueWarning from './payment_account_issue_warning';
import PaymentEstimateWarning from './payment_estimate_warning';

const PaymentWarnings = createReactClass({
  displayName: 'PaymentWarnings',
  mixins: [MoneyUtility, ChartUtility],
  render() {
    return <div><div className="alert alert-warning-light">{[gon.payment_issues && <PaymentAccountIssueWarning key={gon.payment_issues} />, this.props.estimated > 0 && this.props.estimated < this.props.threshold && <PaymentEstimateWarning estimated={this.props.estimated} threshold={this.props.threshold} key={this.props.estimated} />]}</div></div>
  },
})

export default PaymentWarnings;
