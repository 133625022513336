/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import DashboardClient from './common/dashboard_client';
import CheckMateLeadersItem from './checkmate_leaders_item';

window.CheckMateLeaders = createReactClass({
  displayName: 'CheckMateLeaders',
  mixins: [DashboardClient],
  endpoint() {
    return '/turbosquid/dashboard_widgets/checkmate_leaders.json'
  },
  render() {
    if (this.reportsDisabled() || __guard__(this.state.data != null ? this.state.data.aggregations : undefined, ({error}) => error)) {
      return <ReportError />
    }
    return (
      <div className="panel-overflow">
        <table className="table table-light checkmate-queue">
          <thead>
            <tr>
              <th>{I18n.t('dashboard.checkmate.rank')}</th>
              <th>{I18n.t('dashboard.checkmate.artist')}</th>
              <th className="text-center">{I18n.t('dashboard.checkmate.pro')}</th>
              <th className="text-center">{I18n.t('dashboard.checkmate.lite')}</th>
              <th className="text-center">
                <div data-toggle="tooltip" data-placement="bottom" title={I18n.t('dashboard.checkmate.score_explanation')}>
                  {I18n.t('dashboard.checkmate.score')}
                  <i className="fa fa-question-circle" tabIndex="-1"/>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>{this.state.data != null ? this.state.data.checkmate_leaders.map((leader) => <CheckMateLeadersItem key={leader.turbosquid_id} leader={leader} />) : undefined}</tbody>
        </table>
      </div>
    )
  },
})

export default window.CheckMateLeaders;

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined
}
