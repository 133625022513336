import React, { useContext } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { ProductContext } from '../../contexts/ProductContext'
import { BundlesContext } from './BundlesProvider'
import RichTextArea from '../../products/react_components/form_components/RichTextArea'
import DOMPurify from 'dompurify'
import { containsHTML, normalizeTopLevelNodes, removeFormatting } from '../../utils/RichTextUtils'
import { deserialize } from '../../utils/slate/deserializer'
import FormError from '../../components/publisher/ProductFiles/FormError'
import { filesI18nOptions } from '../../components/products/constants'

const i18nOptions = {
  scope: 'turbosquid.products.product_description_panel',
}

const getSlateValue = (description) => {
  const sanitizedDescription = DOMPurify.sanitize(description)

  if (containsHTML(description)) {
    const document = new DOMParser().parseFromString(removeFormatting(sanitizedDescription), 'text/html')
    return normalizeTopLevelNodes(deserialize(document.body))
  }

  return [{
    type: 'paragraph',
    children: [{ text: sanitizedDescription }],
  }]
}

export const errorMessage = (translation) => {
  return (
    <>
      <a onClick={() => {}} className="field-link">
        {`${I18n.t('description', i18nOptions)}: `}
      </a>
      <span className="error-messages">{I18n.t(translation, filesI18nOptions)}</span>
    </>
  )
}

export default function ProductDescription() {
  const { control, formState: { errors } } = useFormContext()
  const { validations, product } = useContext(ProductContext)
  const inputName = 'product-description'

  const {
    metadataForm
} = useContext(BundlesContext)

const {
    bundleDescription,
    setBundleDescription
} = metadataForm

  return (
    <div className="form-group turbosquid_product_form_description ">
      <label className="control-label">
        {I18n.t('description', i18nOptions)}
      </label>
      <Controller
        id={inputName}
        name={inputName}
        defaultValue={getSlateValue(product.description)}
        rules={{
          validate: (value) => {
            if (value === '<p></p>') {
              return errorMessage('required')
            }
          },
          maxLength: {
            value: validations.descriptionMaxLength,
          }
        }}
        control={control}
        className="form-control"
        render={({ field: { onChange, onBlur, value, disabled }}) => (
           <RichTextArea
              data-testid='bundles-description-input'
              disabled={disabled}
              onChange={(e) => setBundleDescription(e)}
              onBlur={onBlur}
              value={bundleDescription || value}
            />
        )}
      />
      {errors[inputName] && <FormError error={errors[inputName]} />}
    </div>
  )
}