/* global I18n */
import React from 'react'
import { flattenArray } from '../products/helpers'
import PendingDraftsService from '../../services/PendingDraftsService'
import { BATCH_TTL, BATCH_RECHECK_DELAY } from '../products/constants'

const validBatch = (batch) => Date.now() - batch.createdAt <= BATCH_TTL

export default function withPendingUpdates(WrappedComponent) {
  return class MyDraftsWithPendingUpdates extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        alert: {},
        pendingDraftIds: [],
        updatedDrafts: {},
        pendingSubmits: [],
      }
    }

    componentDidMount() {
      this.fetchBatchesFromLocalStorage()
      window.addEventListener('beforeunload', this.checkForPendingSubmits)
    }

    componentWillUnmount() {
      window.removeEventListener('beforeunload', this.checkForPendingSubmits)
    }

    resetAlert = () => {
      this.setState({ alert: {} })
      return null
    }

    checkForPendingUpdates = (event) => {
      const { pendingDraftIds } = this.state
      if (pendingDraftIds.length > 0) {
        const message = I18n.t('turbosquid_products.messages.pending_updates')
        event.returnValue = message // eslint-disable-line
        return message
      }
      return null
    }

    checkForPendingSubmits = (event) => {
      const { pendingSubmits } = this.state
      if (pendingSubmits.length > 0) {
        const message = I18n.t('turbosquid_products.messages.pending_updates')
        // eslint-disable-next-line no-param-reassign
        event.returnValue = message
        return message
      }
      return null
    }

    fetchBatchesFromLocalStorage = () => {
      const batchesString = window.localStorage.getItem('batches') || '[]'
      const pendingBatches = JSON.parse(batchesString).filter(validBatch)
      const newBatchesString = JSON.stringify(pendingBatches)

      if (batchesString !== newBatchesString) {
        window.localStorage.setItem('batches', newBatchesString)
      }

      pendingBatches.forEach((batch) => {
        setTimeout(() => { this.getBulkUpdateStatus(batch.id) }, BATCH_RECHECK_DELAY)
      })

      const pendingDraftIds = flattenArray(pendingBatches.map((batch) => batch.draftIds))
      this.setState({ pendingDraftIds })
    }

    handleBulkUpdateSubmit = (data) => {
      const { batchID, draftIds, error } = data

      if (error) {
        this.setState({ alert: { type: 'danger', message: error.message, timestamp: Date.now(), timeout: BATCH_TTL } })
      } else {
        const newBatch = { id: batchID, draftIds, createdAt: Date.now() }
        const batches = JSON.parse(window.localStorage.getItem('batches') || '[]')
        const pendingBatches = batches.filter(validBatch)
        pendingBatches.push(newBatch)

        const pendingDraftIds = flattenArray(pendingBatches.map((batch) => batch.draftIds))
        const message = I18n.t('turbosquid_products.bulk_price_update.update_in_progress')
        this.setState({ pendingDraftIds, alert: { message, type: 'warning', timestamp: Date.now(), timeout: BATCH_TTL } })
        window.scrollTo(0, 0)

        window.localStorage.setItem('batches', JSON.stringify(pendingBatches))
        setTimeout(() => { this.getBulkUpdateStatus(batchID) }, BATCH_RECHECK_DELAY)
      }
    }

    getBulkUpdateStatus = (batchID) => {
      let message = ''
      PendingDraftsService.getBulkUpdateStatus(batchID)
        .then((response) => {
          const { status } = response.attributes
          switch (status) {
            case 'completed':
            case 'failed':
              this.handleFinishedBulkUpdate(response)
              break
            case 'pending':
              this.handlePendingBulkUpdate(response)
              break
            default:
              message = I18n.t('turbosquid_products.messages.update_failed')
              this.setError(message)
          }
        })
        .catch((error) => this.setError(error.message))
    }

    setError = (message) => {
      this.setState({ alert: { type: 'danger', message, timestamp: Date.now(), timeout: BATCH_TTL } })
    }

    handleFinishedBulkUpdate = (response) => {
      const batchID = response.id
      const batches = JSON.parse(window.localStorage.getItem('batches') || '[]')
      const pendingBatches = batches.filter((batch) => batch.id !== batchID)
      window.localStorage.setItem('batches', JSON.stringify(pendingBatches))

      this.setState({
        updatedDrafts: response,
        pendingDraftIds: flattenArray(pendingBatches.map((batch) => batch.draftIds)),
      })
    }

    handlePendingBulkUpdate = (response) => {
      const batchesString = window.localStorage.getItem('batches') || '[]'
      const pendingBatches = JSON.parse(batchesString).filter(validBatch)
      const newBatchesString = JSON.stringify(pendingBatches)

      if (batchesString !== newBatchesString) {
        window.localStorage.setItem('batches', newBatchesString)

        const pendingDraftIds = flattenArray(pendingBatches.map((batch) => batch.draftIds))
        this.setState({ pendingDraftIds })
      }

      const batchID = response.id
      const currentBatch = pendingBatches.filter((batch) => batch.id === batchID)[0]

      if (currentBatch) {
        setTimeout(() => { this.getBulkUpdateStatus(batchID) }, BATCH_RECHECK_DELAY)
      }
    }

    handlePendingSubmitsChanges = (submitId) => {
      const { pendingSubmits } = this.state
      const submitIdIdx = pendingSubmits.indexOf(submitId)
      if (submitIdIdx !== -1) {
        pendingSubmits.splice(submitIdIdx, 1)
      } else {
        pendingSubmits.push(submitId)
      }

      this.setState({ pendingSubmits })
    }

    render() {
      const { pendingDraftIds, updatedDrafts, alert } = this.state
      return (
        <WrappedComponent
          {...this.props} // eslint-disable-line
          alert={alert}
          pendingDraftIds={pendingDraftIds}
          updatedDrafts={updatedDrafts}
          handleBulkUpdateSubmit={this.handleBulkUpdateSubmit}
          handlePendingSubmitsChanges={this.handlePendingSubmitsChanges}
          resetParentAlert={this.resetAlert}
        />
      )
    }
  }
}
