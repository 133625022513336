import React from 'react'
import PropTypes from 'prop-types'

const SideBar = ({ title, icon, children, toggle, toggleClass }) => (
  <div id="sidebar" className={toggleClass}>
    <div className="sidebar-header">
      <span className="title">{title}</span>
      <span className="icon" onClick={toggle}><i className={icon} /></span>
    </div>
    <div className="sidebar-content">
      {children}
    </div>
  </div>
)

export default SideBar

SideBar.propTypes = {
  toggleClass: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  icon: PropTypes.string,
}

SideBar.defaultProps = {
  children: null,
  icon: '',
}
