import React from 'react'
import { Provider } from 'react-redux'
import { publisherStore } from './react_components/publisher_store'
import PublisherFormConnected from './react_components/connected/publisher_form_connected'
import BundlesEdit from './bundles/BundlesEdit'

const BundlesPublisherApplication = () => (
  <Provider store={publisherStore()}>
		<div>
			<BundlesEdit />
		</div>
 	</Provider>
)

export default BundlesPublisherApplication
