/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'

const FilterBulkActions = (
  {
    updatePriceModalVisibility,
    updateStatusModalVisibility,
    updateDownloadModalVisibility,
    updateRemovalModalVisibility,
    duplicateProduct,
    allowDuplicate,
    allowDownload,
    createCollection,
    disabled,
  }
) => (
  <div className="in-page-action-button-group bulk-actions-group">
    <div
      className="btn-group"
      id="bulk-actions-group"
      role="group"
    >
      <button
        type="button"
        className="btn btn-default dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        disabled={disabled}
      >
        {I18n.t('turbosquid_products.bulk_actions.title')}
      </button>
      <ul className="dropdown-menu">
        <li>
          <button
            type="button"
            id="bulk-price-update-link"
            onClick={() => updatePriceModalVisibility(true)}
          >
            {I18n.t('turbosquid_products.bulk_actions.price')}
          </button>
        </li>
        <li>
          <button
            type="button"
            id="bulk-status-update-link"
            onClick={() => updateStatusModalVisibility(true)}
          >
            {I18n.t('turbosquid_products.bulk_actions.status')}
          </button>
        </li>
        {allowDuplicate
        && (
          <li>
            <button
              type="button"
              id="bulk-duplicate-product-link"
              onClick={() => duplicateProduct()}
            >
              {I18n.t('turbosquid_products.bulk_actions.duplicate')}
            </button>
          </li>
        )}
        {allowDownload
        && (
          <li>
            <button
              type="button"
              id="bulk-download-product-link"
              onClick={() => updateDownloadModalVisibility(true)}
            >
              {I18n.t('turbosquid_products.bulk_actions.download')}
            </button>
          </li>
        )}
        <li>
          <button
            type="button"
            id="bulk-remove-link"
            onClick={() => updateRemovalModalVisibility(true)}
          >
            {I18n.t('turbosquid_products.bulk_actions.removal')}
          </button>
        </li>
        <li>
          <button
            type="button"
            id="bulk-create-collection-link"
            onClick={() => createCollection()}
          >
            {I18n.t('turbosquid_products.bulk_actions.create_collection')}
          </button>
        </li>
      </ul>
    </div>
  </div>
)

export default FilterBulkActions

FilterBulkActions.propTypes = {
  updatePriceModalVisibility: PropTypes.func.isRequired,
  updateStatusModalVisibility: PropTypes.func.isRequired,
  updateRemovalModalVisibility: PropTypes.func.isRequired,
  updateDownloadModalVisibility: PropTypes.func.isRequired,
  duplicateProduct: PropTypes.func.isRequired,
  allowDuplicate: PropTypes.bool.isRequired,
  allowDownload: PropTypes.bool,
  createCollection: PropTypes.func.isRequired,
}

FilterBulkActions.defaultProps = {
  allowDownload: false,
}
