export const selectFileFormats = (state, filterParams) => {
  const { uiState } = state
  const { fileFormats } = uiState
  if (!filterParams) {
    return fileFormats
  }
  const { id, mediaType } = filterParams
  if (id) {
    return fileFormats.find((format) => format.id === id)
  }
  if (mediaType) {
    return fileFormats.filter((fileFormat) => fileFormat
      .media_types.find((type) => type.id === mediaType))
  }
  return []
}

export const selectFlash = (state) => state.uiState.flash
export const selectNotifications = (state) => state.uiState.notifications
export const selectTurntableAddedFiles = (state) => state.uiState.turntableAddedFiles
export const selectDuplicatePendingUndoFile = (state) => state.uiState.duplicatePendingUndoFile
