/* global I18n, gon */
import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import ProductsStatusPreview from './ProductsStatusPreview'
import PendingAssetsService from '../../../services/PendingAssetsService'
import { statusList } from '../constants'
import { reactSelectStyle } from '../../../utils/Styles'

const i18nOptions = {
  scope: 'turbosquid_products.bulk_status_update',
}

const validStatusList = statusList
const getStatusOptions = () => ([
  { value: 'online', label: I18n.t('options.online', i18nOptions) },
  { value: 'private', label: I18n.t('options.private', i18nOptions) },
])

const productCanBeUpdated = (product) => (
  statusList.includes(product.status)
)

const formatProductParams = (product) => ({
  id: product.turbosquid_id,
  type: 'product',
  attributes: {
    status: product.newStatus,
  },
})

const buildUpdateParams = (products) => (
  products.filter(productCanBeUpdated).map(formatProductParams)
)

class StatusUpdateForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      previewableProducts: [],
      status: '',
      inProgress: false,
    }
  }

  handleStatusChange = (newOption) => {
    const status = newOption.value
    const previewableProducts = this.previewProductsChanges(status)
    const newState = { status, previewableProducts }

    this.setState(newState)
  }

  previewProductsChanges = (status) => {
    const { products } = this.props
    if (status.length > 0) {
      return this.bulkStatusUpdate(products, status)
    }
    return []
  }

  bulkStatusUpdate = (products = [], status = '') => products.map((product) => {
    const newStatus = validStatusList.includes(product.status) ? status : product.status
    return { ...product, newStatus }
  })

  handleSubmit = (e) => {
    e.preventDefault()
    const { handleBulkUpdateSubmit, handlePendingSubmitsChanges } = this.props
    const { previewableProducts } = this.state
    this.setState({ inProgress: true })

    const submitId = new Date().getTime()
    handlePendingSubmitsChanges(submitId)

    const params = {
      authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
      products: buildUpdateParams(previewableProducts) }

    PendingAssetsService.saveAssetChanges(e.target.action, params)
      .then((response) => {
        const productIds = previewableProducts.map((product) => product.turbosquid_id)
        const data = { batchID: response.id, productIds }
        this.setState({ inProgress: false })
        handlePendingSubmitsChanges(submitId)
        handleBulkUpdateSubmit(data)
      })
      .catch((error) => {
        this.setState({ inProgress: false })
        handlePendingSubmitsChanges(submitId)
        handleBulkUpdateSubmit({ error })
      })
  }

  render() {
    const { status, previewableProducts, inProgress } = this.state
    const updateableProducts = previewableProducts.filter(productCanBeUpdated)
    return (
      <form className="form-inline" method="post" action={gon.turbosquid_product_batches_path} onSubmit={this.handleSubmit}>
        <div className="bulk-status-form-fields">
          <p>{I18n.t('new_status', i18nOptions)}</p>

          <div className="form-group bulk-operator">
            <Select
              name="status"
              className="select-status"
              placeholder={I18n.t('status', i18nOptions)}
              searchable={false}
              clearable={false}
              value={getStatusOptions().filter(({ value }) => value === status)}
              onChange={this.handleStatusChange}
              options={getStatusOptions()}
              styles={reactSelectStyle}
            />
          </div>
        </div>
        <ProductsStatusPreview products={previewableProducts} />

        <div className="btn-wrapper">
          <button type="submit" disabled={inProgress || !updateableProducts.length} className="btn btn-info btn-bulk-submit">
            {I18n.t('turbosquid_products.save_bulk_changes')}
          </button>

          <div className="loader" style={{ display: inProgress ? 'inline-block' : 'none' }} />
        </div>
      </form>
    )
  }
}

export default StatusUpdateForm

StatusUpdateForm.propTypes = {
  products: PropTypes.array.isRequired,
  handleBulkUpdateSubmit: PropTypes.func.isRequired,
  handlePendingSubmitsChanges: PropTypes.func.isRequired,
}
