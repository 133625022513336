import React from 'react'
import PropTypes from 'prop-types'
import checkEmptyCell from './checkEmptyCell'
import {
  productEditCategoryUrl,
} from '../helpers'

function FeatureCell(props) {
  const {
    feature_assignments: featureAssignments,
    media_type: mediaType,
    turbosquid_id: turbosquidId,
    pendingProductIds } = props
  const features = featureAssignments || []
  const listItemStyles = { border: 0, backgroundColor: 'inherit' }
  const addCategoriesLink = features.length ? (
    ''
  ) : (
    <li style={listItemStyles} className="list-group-item add-categories">
      <i className="fa fa-exclamation-circle" />
      Add Categories
    </li>
  )
  if (mediaType !== '3d_model') {
    return <span className="category-na">N/A</span>
  }

  const featureHtml = (
    <div className="container">
      <ul className="list-group" style={{ margin: 0 }}>
        {features.map((feature, index) => {
          if (features.length < 4 && index < 3) {
            return (
              <li
                style={listItemStyles}
                key={`${feature.external_id}-${turbosquidId}-${index}`} // eslint-disable-line
                className="list-group-item"
              >
                { feature.name }
              </li>
            )
          }
          if (features.length >= 3 && index === 3) {
            return (
              <li
                style={listItemStyles}
                key={`${feature.external_id}-${turbosquidId}-${index}`} // eslint-disable-line
                className="list-group-item"
              >
                Multiple
              </li>
            )
          }
          return ''
        })}
        { addCategoriesLink }
      </ul>
    </div>
  )

  // eslint-disable-next-line max-len
  return (pendingProductIds && pendingProductIds.includes(turbosquidId)) ? (<span>{featureHtml}</span>) : (
    <a
      href={productEditCategoryUrl(turbosquidId)}
      title={features.map((feature) => ` ${feature.name}`)}
    >
      {featureHtml}
    </a>
  )
}

FeatureCell.propTypes = {
  feature_assignments: PropTypes.array.isRequired,
  media_type: PropTypes.string.isRequired,
  turbosquid_id: PropTypes.string.isRequired,
  pendingProductIds: PropTypes.array,
}

FeatureCell.defaultProps = {
  pendingProductIds: [],
}

export default checkEmptyCell(FeatureCell)
