import React from 'react'
import FadeAlert from './FadeAlert'
import { ERROR } from '../../products/constants'

export default function Notification({ notification, clear }) {
  const { type, message, link } = notification
  return (
    <FadeAlert
      onTimeout={() => clear(message)}
      className={`alert-${type === ERROR ? 'danger' : 'warning'}`}
    >
      {message}
      {link && (
        <a target="_blank" href={link.url}>{link.text}</a>
      )}
    </FadeAlert>
  )
}
