const formActionMergeDefaults = ({ method, params }) => {
  const defaults = {
    method,
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }

  return {
    ...defaults,
    ...params,
    headers: { ...defaults.headers, ...params.headers },
  }
}

const getMergeDefault = (params) => {
  const defaults = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }

  return {
    ...defaults,
    ...params,
    headers: { ...defaults.headers, ...params.headers },
  }
}

const putMergeDefault = (params) => formActionMergeDefaults({ method: 'PUT', params })
const postMergeDefault = (params) => formActionMergeDefaults({ method: 'POST', params })
const patchMergeDefault = (params) => formActionMergeDefaults({ method: 'PATCH', params })
const deleteMergeDefault = (params) => formActionMergeDefaults({ method: 'DELETE', params })

const fetchWrapper = async (input, init) => (
  fetch(input, init).then((response) => {
    // The response will contain error message text if there is an error.
    if (!response.ok) {
      return response.text().then((error) => {
        throw new Error(error)
      })
    }
    return response
  })
)

export const fetchJson = async (input, init) => {
  try {
    const response = await fetchWrapper(input, init)
    if (response.status === 204) {
      return null
    }

    return response.json()
  } catch (error) {
    return null
  }
}

export const fetchNoResponseBody = async (input, init) => (
  fetchWrapper(input, init)
)

export const get = (path, params = {}) => {
  const merged = getMergeDefault(params)
  return fetchJson(path, merged)
}

export const patch = (path, data = null, params = {}) => {
  const body = data ? JSON.stringify(data) : null
  const merged = patchMergeDefault(params)

  return fetchJson(path, { ...merged, body })
}

export const post = (path, data = null, params = {}) => {
  const body = data ? JSON.stringify(data) : null
  const merged = postMergeDefault(params)

  return fetchJson(path, { ...merged, body })
}

export const put = (path, data = null, params = {}) => {
  const body = data ? JSON.stringify(data) : null
  const merged = putMergeDefault(params)

  return fetchJson(path, { ...merged, body })
}

export const del = (path, data = null, params = {}) => {
  const body = data ? JSON.stringify(data) : null
  const merged = deleteMergeDefault(params)

  return fetchNoResponseBody(path, { ...merged, body })
}
