/* eslint-disable no-param-reassign */
/* global gon */
import { createSlice } from '@reduxjs/toolkit'
import { dismissNotification, changeTab } from '../actions/shared_actions'
import { formSlice } from './form_slice'
import { tabMapper } from '../../file_upload_lookups'

const { actions: formActions } = formSlice

const allowNotificationsToBeDisplayed = (state = {}) => {
  Object.keys(state.notifications).forEach((key) => {
    if (state.notifications[key].dismissed) {
      state.notifications[key].dismissed = false
    }
  })
}

const processStateChanges = (state = {}) => {
  Object.keys(state).forEach((key) => {
    if (state[key] && state[key].showInfo) {
      state[key].showInfo = false
    }
  })
  return state
}

export const publisherUIState = {
  isLoading: false,
  flash: {
    showInfo: false,
  },
  categoriesPanel: {
    showInfo: false,
  },
  certificationPanel: {
    showInfo: false,
  },
  descriptionPanel: {
    showInfo: false,
  },
  filesPanel: {
    showInfo: false,
    collapsed: false,
  },
  imagesPanel: {
    showInfo: false,
    collapsed: false,
  },
  licensePanel: {
    showInfo: false,
  },
  namePanel: {
    showInfo: false,
  },
  specificationsPanel: {
    showInfo: false,
  },
  tagsPanel: {
    showInfo: false,
  },
  toggledPanels: {
    uploadsProgress: true,
  },
  notifications: {
    publish: { dismissed: true },
    saveDraft: { dismissed: true },
    draftAlert: {},
    flash: { dismissed: true },
  },
  termConstraintNotifications: [],
  fileName: {
    modelFiles: '',
    previewImages: '',
    otherFiles: '',
  },
  activeTab: 1,
  turntableNotValid: false,
  turntableAddedFiles: [],
  disableTabs: false,
  fileFormats: [],
  turntableCapReached: false,
  duplicateFile: false,
  invalidTurntableName: false,
  invalidFileSize: false,
  showOverlay: false,
  fileUploaderError: {
    [tabMapper.mainFiles]: {},
    [tabMapper.previews]: {},
    [tabMapper.associatedFiles]: {},
  },
  duplicatePendingUndoFile: { id: [] },
  showDuplicatePendingUndoFile: false,
  duplicatePendingUndoFileAlertUpdateNow: false,
}

export const publisherUISlice = createSlice({
  name: 'publisherUI',
  initialState: {},
  reducers: {
    syncDraft: (state, { payload: { isSaving }}) => {
      state.isLoading = isSaving
    },
    publishProduct: (state, _) => {
      state.isLoading = true
    },
    toggleInfo: (state, { payload: { panel, value } }) => {
      state[panel].showInfo = value
    },
    saveFileFormats: (state, { payload }) => {
      state.fileFormats = payload
    },
    resetNotificationsOnPublishAttempt: (state, _) => {
      allowNotificationsToBeDisplayed(state)
    },
    fileValidate: (state, { payload: { tab, data } }) => {
      state.fileName[tab] = data.name
    },
    togglePanel: (state, { payload: { panel } }) => {
      state.toggledPanels[panel] = !state.toggledPanels[panel]
    },
    changeTab: (state, { payload: { tab } }) => {
      state.activeTab = tab
      state.fileName.modelFiles = ''
      state.fileName.previewImages = ''
      state.fileName.otherFiles = ''
      state.duplicateFile = false
      state.fileUploaderError = {
        [tabMapper.mainFiles]: {},
        [tabMapper.previews]: {},
        [tabMapper.associatedFiles]: {},
      }
    },
    notificationTurntableNotValid: (state, { payload: { error } }) => {
      state.turntableNotValid = error
    },
    clearNotificationTurntableNotValid: (state, _) => {
      state.turntableNotValid = false
    },
    saveTurntableFiles: (state, { payload: { files } }) => {
      state.turntableAddedFiles = files
      state.turntableCapReached = false
    },
    removeTurntableFromUploader: (state, { payload: { files } }) => {
      state.turntableAddedFiles.filter((e) => !files.includes(e))
    },
    clearUploadFilename: (state, _) => {
      state.fileName.modelFiles = ''
      state.fileName.previewImages = ''
      state.fileName.otherFiles = ''
    },
    turntableCapReached: (state, { payload: { files } }) => {
      if (files.length > 56) {
        state.turntableCapReached = true
      }
    },
    clearDuplicateUploadFile: (state) => {
      state.duplicateFile = false
    },
    duplicateUploadFile: (state) => {
      state.duplicateFile = true
    },
    invalidTurntableName: (state, { payload: { status } }) => {
      state.invalidTurntableName = status
    },
    invalidFileSize: (state, { payload: { status } }) => {
      state.invalidFileSize = status
    },
    resetLoading: (state, _) => {
      state.isLoading = false
    },
    toggleOverlay: (state, { payload }) => {
      state.showOverlay = payload
    },
    setFileUploaderError: (state, { payload: { tab, field, error } }) => {
      state.fileUploaderError[tab][field] = error
    },
    removeFileUploaderError: (state, { payload: { tab, field } }) => {
      state.fileUploaderError[tab][field] = null
    },
    hideDuplicateUploadPendingUndoFile: (state, _) => {
      state.showDuplicatePendingUndoFile = false
    },
    clearDuplicateUploadPendingUndoFile: (state, _) => {
      state.showDuplicatePendingUndoFile = false
      state.duplicatePendingUndoFile = { id: [] }
    },
    duplicateUploadPendingUndoFile: (state, { payload: { file } }) => {
      state.showDuplicatePendingUndoFile = true
      if (Object.keys(state.duplicatePendingUndoFile).length > 1) {
        state.duplicatePendingUndoFile.id.push(file.id)
      } else {
        state.duplicatePendingUndoFile = { ...file, id: [file.id] }
      }
    },
    resetDuplicatePendingUndoFileAlertUpdateNow: (state, _) => {
      state.duplicatePendingUndoFileAlertUpdateNow = false
    },
  },
  extraReducers: {
    [formActions.startSubmit]: (state, _) => {
      state.isLoading = true
    },
    [formActions.requestSucceeded]: (state, { payload: { response } }) => {
      state.isLoading = false
      state.flash = response
    },
    [formActions.requestFailed]: (state, _) => {
      state.isLoading = false
    },
    [formActions.publishProductInvalid]: (state, _) => {
      state.isLoading = false
    },
    [formActions.startFileProgress]: (state, _) => {
      if (state.toggledPanels.uploadsProgress === undefined) {
        state.toggledPanels.uploadsProgress = true
      }
    },
    [formActions.turntableCandidate]: (state, _) => { state.turntableNotValid = false },
    [formActions.discardDraft]: (state, _) => {
      state.disableTabs = true
      state.showOverlay = true
    },
    [formActions.completeFileProgress]: (state, _) => { state.isLoading = false },
    [formActions.saveDraftDone]: (state, _) => {
      state.isLoading = false
      processStateChanges(state)
    },
    [formActions.saveDraft]: (state, _) => {
      state.isLoading = true
    },
    [dismissNotification]: (state, { payload: { id } }) => {
      state.notifications[id] = { dismissed: true }
      if (id === 'turntableCapErrorNotifications') {
        state.turntableCapReached = false
      }
    },
    [formActions.setDataValidationState]: (state, { payload: { status } }) => {
      if (!status) allowNotificationsToBeDisplayed(state)
    },
    [changeTab]: (state, { payload: { tab } }) => {
      state.activeTab = tab
      state.fileName.modelFiles = ''
      state.fileName.previewImages = ''
      state.fileName.otherFiles = ''
      state.duplicateFile = false
      state.showDuplicatePendingUndoFile = false
      state.duplicatePendingUndoFile = { id: [] }
    },
    [formActions.clearPendingUndoDeletedFiles]: (state, _) => {
      state.duplicatePendingUndoFileAlertUpdateNow = true
    },
  },
})
