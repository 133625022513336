/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'
import PendingAssetsService from '../../../services/PendingAssetsService'

const i18nOptions = {
  scope: 'turbosquid_products.bulk_removal',
}

const checkmateNotesHTML = () => (
  I18n.t('checkmate_notes_html', {
    ...i18nOptions,
    checkmate_certification_policy_url: gon.checkmate_certification_policy_url,
  })
)

const formatProductParams = (product) => ({
  id: product.turbosquid_id,
  type: 'product',
  attributes: {
    status: 'deleted',
  },
})

const buildUpdateParams = (products) => (
  products.map(formatProductParams)
)

class RemovalForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { inProgress: false }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { products, handleBulkUpdateSubmit, handlePendingSubmitsChanges } = this.props
    this.setState({ inProgress: true })

    const removableProducts = buildUpdateParams(products)
    const submitId = new Date().getTime()
    handlePendingSubmitsChanges(submitId)

    const params = {
      authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
      products: removableProducts }

    PendingAssetsService.saveAssetChanges(e.target.action, params)
      .then((response) => {
        const productIds = products.map((product) => product.turbosquid_id)
        const data = { batchID: response.id, productIds }
        this.setState({ inProgress: false })
        handlePendingSubmitsChanges(submitId)
        handleBulkUpdateSubmit(data)
      })
      .catch((error) => {
        this.setState({ inProgress: false })
        handlePendingSubmitsChanges(submitId)
        handleBulkUpdateSubmit({ error })
      })
  }

  render() {
    const { inProgress } = this.state

    return (
      <form className="form-inline" method="post" action={gon.turbosquid_product_batches_path} onSubmit={this.handleSubmit}>
        <div className="bulk-status-form-fields">
          <p>{I18n.t('confirm_removal_selection', i18nOptions)}</p>
        </div>

        <div className="btn-wrapper">
          <button type="submit" disabled={inProgress} className="btn btn-info btn-bulk-submit">
            {I18n.t('turbosquid_products.save_bulk_changes')}
          </button>

          <div className="loader" style={{ display: inProgress ? 'inline-block' : 'none' }} />
        </div>
      </form>
    )
  }
}

export default RemovalForm

RemovalForm.propTypes = {
  products: PropTypes.array.isRequired,
  handleBulkUpdateSubmit: PropTypes.func.isRequired,
  handlePendingSubmitsChanges: PropTypes.func.isRequired,
}
