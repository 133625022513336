import React, { useCallback, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { ProductContext } from '../../contexts/ProductContext'
import { BundlesContext } from '../../products/bundles/BundlesProvider'
import { MAX_NAME_LENGTH } from '../products/constants'
import FormError from './ProductFiles/FormError'
import {
  clearTitleAndTagsForbiddenWords,
  FORBIDDEN_WORDS,
  stripHTMLFromString, validate
} from '../../products/react_components/helpers'

const i18nOptions = {
  scope: 'turbosquid.products.product_name_panel',
}

const forbiddenWords = (value) => {
  const badWords = FORBIDDEN_WORDS.filter((word) => value.toLowerCase().includes(word))
  return badWords.length
    ? <>
        {`${I18n.t('the_word_was_included', { ...i18nOptions, words: badWords })} `}
        <a target="_blank" rel="noopener noreferrer" href={I18n.t('terms_agreement_url', { ...i18nOptions })}> </a>
      </>
    : true
}

const forbiddenCharacters = (value) => {
  const characters = value.match(/[^a-zA-Z0-9-\s]/g)
  return characters
    ? <>
        {`${I18n.t('the_character_was_included', { ...i18nOptions, characters })} `}
        <a target="_blank" rel="noopener noreferrer" href={I18n.t('terms_agreement_url', { ...i18nOptions })}> </a>
      </>
    : true
}

export const errorMessage = (translation, options = {}) => {
  return (
    <>
      <a onClick={() => {}} className="field-link">
        {`${I18n.t('title', i18nOptions)}: `}
      </a>
      <span className="error-messages">{I18n.t(translation, { ...i18nOptions, ...options })}</span>
    </>
  )
}

export default function ProductTitle() {
  const { register, formState: { errors } } = useFormContext()
  const { validations, product } = useContext(ProductContext)
  const initialValue = useCallback(() => product.name, [])
  const inputName = 'product-title'

  const {
		metadataForm
	} = useContext(BundlesContext)

	const {
		bundleTitle,
		setBundleTitle
	} = metadataForm
  
  const sanitizeValue = (value) => {
    return clearTitleAndTagsForbiddenWords(
      stripHTMLFromString(setBundleTitle(value))
    ).trim()
  }
  
  const handleValidation = (value) => {
    return validate(value, [
      forbiddenWords,
      forbiddenCharacters,
    ])
  }
    
  return (
    <div className="form-group turbosquid_product_form_name" style={{width: '100%'}}>
      <label className="control-label" htmlFor={inputName}>
        {I18n.t('title', i18nOptions)}
      </label>
      <input
        type="text"
        id={inputName}
        name={inputName}
        value={bundleTitle}
        onChange={(e) => setBundleTitle(e.target.value)}
        {...register(inputName, {
          defaultValue: bundleTitle,
          value: bundleTitle,
          maxLength: {
            value: validations.nameMaxLength,
            message: errorMessage('name_validation_max_length', { upper_bound: MAX_NAME_LENGTH })
          },
          required: {
            value: true,
            short: 'Required',
            message: errorMessage('name_required'),
          },
          validate: handleValidation,
          setValueAs: sanitizeValue,
        })}
        className="form-control"
      />
      {errors[inputName] && <FormError error={errors[inputName]} />}
    </div>
  )
}
