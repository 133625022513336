import { buffers, channel as sagaChannel } from 'redux-saga'
import { fork, put, delay, take } from 'redux-saga/effects'

function* processRateLimitQueue({ channel, rateLimit }) {
  while (true) {
    const { meta: { saga, args }, ...action } = yield take(channel)
    if (action !== undefined) {
      yield fork(saga, ...args, action)
      yield delay(rateLimit)
    }
  }
}


export function createRateLimitQueue({ rateLimit }) {
  const channel = sagaChannel(buffers.expanding())
  return {
    channel,
    rateLimit,
    enqueue: function* enqueueRateLimitQueue(saga, ...args) {
      const action = args.pop()
      yield put(channel, { ...action, meta: { saga, args } })
    },
    watch: function* watchRateLimitQueue() {
      yield fork(processRateLimitQueue, { channel, rateLimit })
    },
  }
}
