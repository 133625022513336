import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import MyProducts from './containers/products/MyProducts'
import withPendingUpdates from './components/products/withPendingUpdates'

const MyProductsWithPendingUpdates = withPendingUpdates(MyProducts)

const MyProductsApp = () => (
  <BrowserRouter>
    <Route path="/turbosquid/products" component={MyProductsWithPendingUpdates} />
  </BrowserRouter>
)

export default MyProductsApp
