let dpr

window.hdpi = function () {
  dpr = window.devicePixelRatio || (window.screen.deviceXDPI / window.screen.logicalXDPI) || 1
  return !!(dpr > 1)
}

$(window).ready(() => {
  if (window.hdpi) {
    return $('img[data-src2x]').each((index, item) => {
      let element
      element = $(item)
      return element.attr('src', element.attr('data-src2x'))
    })
  }
})
