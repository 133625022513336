import { useState } from 'react'

export default function useSyncLegacyFields({ product, collectionTaxonomyId }) {
  const { uvMapped, unwrappedUVs, features } = product
  const collectionFeature = features.find(f => f.id === collectionTaxonomyId)
  const [fields, setFields] = useState({
    uvMapped,
    unwrappedUVs,
    features,
  })
  const syncOnChange = (field) => {
    setFields(prevFields => {
      return {
        ...prevFields,
        [field.name]: field.value
      }
    })
  }

  return {
    syncOnChange,
    collectionFeature,
    fields
  }
}
