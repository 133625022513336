/* global I18n, gon */
import React from 'react'
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import FeatureSelectOption from './FeatureSelectOption'
import { IsActionPublish } from '../../../products/react_components/helpers'
import DisableableField from '../../../products/react_components/form_components/DisableableField'
import { reactSelectStyle } from '../../../utils/Styles'
import Icon from '../../../products/react_components/icon'
import { licenseValueMapper } from '../../../utils/SaveValidations'

export default class FeatureSelectInput extends React.Component {
  constructor(props) {
    super(props)
    this.inputBoxRef = ''
    this.isHandleShowInput = false
    const isInline = typeof props.inline !== 'undefined' ? props.inline : false
    if (isInline) {
      this.loadOptions('', this.setSpecificCategoryNumbers)
    }
    this.state = {
      value: '',
      showSearchInput: isInline ? false : props.assignedFeatureIds.length <= 0,
      specificCategoryNumbers: '',
    }
    this.autoFocus = false
  }

  componentDidMount() {
    const { autoFocus } = this.props
    if (!this.isInline() && autoFocus) {
      this.asyncSelect.select.focus()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showSearchInput } = this.state
    const { assignedFeatureIds, menuIsOpen, autoFocus } = this.props
    const nextShowSearchInput = assignedFeatureIds.length <= 0
    if (!this.isInline()
      && prevProps.assignedFeatureIds.length !== assignedFeatureIds.length
      && showSearchInput !== nextShowSearchInput) {
      this.updateSearchInputAppear(nextShowSearchInput)
    } else if (!this.isInline() && autoFocus) {
      if (!showSearchInput) this.updateSearchInputAppear(true)
      // fix the autoFocus change to false after update state
      this.autoFocus = true
    }
    if (
      ((this.isInline() && menuIsOpen) || this.isHandleShowInput)
      && this.asyncSelect
      && prevState.showSearchInput !== showSearchInput) {
      this.asyncSelect.select.onMenuOpen()
      this.asyncSelect.select.focus()
      this.isHandleShowInput = false
    } else if (this.asyncSelect && !this.isInline() && this.autoFocus) {
      this.asyncSelect.select.focus()
      this.autoFocus = false
    }
  }

  updateSearchInputAppear = (isShow) => {
    this.setState({
      showSearchInput: isShow,
    })
  }

  handleOnEnter = (e) => {
    const options = this.asyncSelect.state.defaultOptions ? this.asyncSelect.state.defaultOptions : this.asyncSelect.state.loadedOptions
    if (e.key === 'Enter' && !options.length) {
      e.preventDefault()
    }
  }

  loadOptionsData = (term) => {
    const { ancestry } = this.props
    return ({
      fields: { tags_and_synonyms: term },
      assignable: true,
      assignable_restricted: false,
      ancestry,
      limit: this.isInline() ? 15 : 25,
    })
  }

  isInline = () => {
    const { inline } = this.props
    return typeof inline !== 'undefined' ? inline : false
  }

  minimumInputLength = () => {
    const { minimumInputLength } = this.props
    return minimumInputLength || 2
  }

  addFeatureCallback = (option) => {
    const {
      addFeatureCallback,
      closeMenuOnSelect,
    } = this.props

    if (this.asyncSelect && closeMenuOnSelect) {
      this.asyncSelect.select.onMenuClose()
    }
    addFeatureCallback(option)
  }

  parseFeatureSuggestions = (data, term) => {
    const {
      assignedFeatureIds,
      ancestry,
    } = this.props
    const features = data.filter((node) => assignedFeatureIds.indexOf(node.id) === -1)

    const processedFeatures = features.map((feature) => {
      const { visible_ancestor_tags: visibleAncestorTags } = feature
      const newFeature = feature
      const [parent] = visibleAncestorTags
      newFeature.parent = parent
      const occurrences = features.filter((node) => newFeature.text !== node.text)
      occurrences.forEach((duplicate) => {
        // eslint-disable-next-line no-param-reassign,max-len
        duplicate.parent = duplicate.visible_ancestor_tags[duplicate.visible_ancestor_tags.length - 1]
      })
      newFeature.term = term
      newFeature.ancestry_ids = newFeature.ancestry_id_path.split('/')
      newFeature.assignment_type = 'S' // for editors, all assignments are loaded as S

      if (this.isInline()) {
        const ancestryIds = ancestry.split('/')
        if (typeof newFeature.parentId === 'undefined') {
          newFeature.parentId = ''
        }
        newFeature.parentId += ancestryIds[ancestryIds.length - 1]
      }

      return newFeature
    })

    return processedFeatures
  }

  loadOptions = (term, callback) => {
    if (
      this.isInline()
      || (term && term.length >= this.minimumInputLength())
      || this.minimumInputLength() === 0
    ) {
      this.fetchOptions(term, callback)
    } else {
      callback(null, [])
    }
  }

  fetchOptions = (term, callback) => {
    const suggestionsUrl = new URL(gon.feature_suggestions_url)
    const optionsData = this.loadOptionsData(term)
    Object.keys(optionsData).forEach((key) => {
      const optionsValue = optionsData[key]
      if (typeof optionsValue !== 'object') {
        suggestionsUrl.searchParams.append(key, optionsValue)
      } else {
        Object.keys(optionsValue).forEach((valueKey) => {
          suggestionsUrl.searchParams.append(`${key}[${valueKey}]`, optionsValue[valueKey])
        })
      }
    })

    fetch(suggestionsUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      term,
    })
      .then((response) => response.json())
      .then((response) => {
        const options = this.parseFeatureSuggestions(response, term)
        callback(options)
      })
      .catch(() => {
        callback([])
      })
  }

  removeOptions = () => {
    this.setState({ value: '' })
  }

  onKeyUp = (e) => {
    if (e.keyCode==13) {
      e.stopPropagation();
      const { value } = this.asyncSelect.select.state
      if (value) {
        value.addFeatureCallback(value);
      }
    }
  }

  inputChange = (input, { action }) => {
    const { value } = this.state
    const notChangeAction = ['input-blur', 'menu-close']
    if (!notChangeAction.includes(action)) {
      this.setState({ value: input })
      return input
    } return value
  }

  featureSelectDropdownIndicator = (props) => {
    const { showChevron } = this.props
    const { menuIsOpen } = props.selectProps
    const chevronIcon = showChevron ? 'chevron-down' : ''
    const iconType = (menuIsOpen && props.options.length > 0) ? 'times' : chevronIcon
    return (
      <components.DropdownIndicator
        clearValue={props.clearValue}
        cx={props.cx}
        getStyles={props.getStyles}
        getValue={props.getValue}
        hasValue={props.hasValue}
        innerProps={props.innerProps}
        isDisabled={props.isDisabled}
        isFocused={props.isFocused}
        isMulti={props.isMulti}
        isRtl={props.isRtl}
        options={props.options}
        selectOption={props.selectOption}
        selectProps={props.selectProps}
        setValue={props.setValue}
        theme={props.theme}
      >
        <Icon type={iconType} />
      </components.DropdownIndicator>
    )
  }

  featureSelectInput = (props) => {
    const { value } = this.state
    return (
      <components.Input
        {...props}
        isHidden={props.isHidden && value.length <= 0}
        onKeyUp={this.onKeyUp}
      />
    )
  }

  getCategoriesPlaceholder = () => {
    const { featureType } = this.props
    return featureType === 'brand'
      ? I18n.t('feature_assignment_widget.add_brand_category') : I18n.t('feature_assignment_widget.add_object_category')
  }

  getAddAnotherLinkText = () => {
    const { featureType } = this.props
    return featureType === 'brand'
      ? I18n.t('feature_assignment_widget.add_another_brand_category') : I18n.t('feature_assignment_widget.add_another_object_category')
  }

  setSpecificCategoryNumbers = (options) => {
    this.setState({
      specificCategoryNumbers: options.length || 0,
    })
  }

  getLinkText = (number) => {
    const count = Number(number)
    if (count === 0 || Number.isNaN(count)) {
      return 'View more specific categories'
    }
    if (count > 1) {
      return `View ${count} more specific categories`
    } return `View ${count} more specific category`
  }

  handleSearchInputAppear = () => {
    const { showSearchInput } = this.state
    this.isHandleShowInput = true
    this.setState({
      showSearchInput: !showSearchInput,
    })
  }

  handleAddBrandCategory = () => {
    const { updateLicense } = this.props
    updateLicense(licenseValueMapper.royalty_free_editorial_uses_only, true)
  }

  featureSelectOption = ({ data, isFocused, innerProps, innerRef }) => (
    <FeatureSelectOption
      key={`category_option_${data.id}`}
      option={data}
      innerRef={innerRef}
      innerProps={innerProps}
      isFocused={isFocused}
    />
  )

  addFeature = (option) => {
    this.addFeatureCallback(option)
    this.removeOptions()
  }

  render() {
    const { featureType, assignedFeatureIds, categoryPage } = this.props
    const { showSearchInput, value, specificCategoryNumbers } = this.state
    const placeholder = this.isInline() ? I18n.t('feature_assignment_widget.navigate_deeper') : this.getCategoriesPlaceholder()
    const linkText = this.isInline()
      ? this.getLinkText(specificCategoryNumbers) : this.getAddAnotherLinkText()

    return (
      categoryPage
        ? (
          <>
            {
              (!this.isInline() && featureType === 'object' && assignedFeatureIds.length >= 1)
              && (
                <p>
                  {I18n.t('feature_assignment_widget.object_category_help_tips_2')}
                </p>
              )
            }
            {
              showSearchInput ? (
                <AsyncSelect
                  defaultOptions={this.isInline()}
                  loadOptions={this.loadOptions}
                  components={{
                    Option: (optionProps) => this.featureSelectOption(optionProps),
                    DropdownIndicator: this.featureSelectDropdownIndicator,
                    Input: this.featureSelectInput,
                  }}
                  openMenuOnFocus
                  placeholder={placeholder}
                  noOptionsMessage={() => null}
                  onInputChange={this.inputChange}
                  onKeyDown={(e) => this.handleOnEnter(e)}
                  inputValue={value}
                  styles={reactSelectStyle}
                  ref={(select) => { this.asyncSelect = select }}
                  onChange={this.addFeature}
                  closeMenuOnSelect={false}
                  controlShouldRenderValue={false}
                />
              ) : (
                <button className="btn-add-other-category" type="button" onClick={this.handleSearchInputAppear} tabIndex="-1">{linkText}</button>
              )
            }
            {
              (!this.isInline() && featureType === 'object')
              && (
                <p style={{ marginTop: '5px' }}>
                  <span style={{ marginRight: '2px' }}>{I18n.t('feature_assignment_widget.looking_for_brand')}</span>
                  <button className="btn-add-other-category" type="button" onClick={this.handleAddBrandCategory} tabIndex="-1">
                    {I18n.t('feature_assignment_widget.add_brand_below')}
                  </button>
                </p>
              )
            }
          </>
        )
        : (
          <DisableableField
            defaultDisabled={IsActionPublish(this.props.formActionInProgress) /* eslint-disable-line */}
          >
            {(disabled) => (
              <>
                {
                  (!this.isInline() && featureType === 'object' && assignedFeatureIds.length >= 1)
                  && (
                    <p>
                      {I18n.t('feature_assignment_widget.object_category_help_tips_2')}
                    </p>
                  )
                }
                {
                  showSearchInput ? (
                    <AsyncSelect
                      defaultOptions={this.isInline()}
                      loadOptions={this.loadOptions}
                      components={{
                        Option: (optionProps) => this.featureSelectOption(optionProps),
                        DropdownIndicator: this.featureSelectDropdownIndicator,
                        Input: this.featureSelectInput,
                      }}
                      openMenuOnFocus
                      placeholder={placeholder}
                      noOptionsMessage={() => null}
                      isDisabled={disabled}
                      onKeyDown={(e) => this.handleOnEnter(e)}
                      onInputChange={this.inputChange}
                      inputValue={value}
                      styles={reactSelectStyle}
                      ref={(select) => { this.asyncSelect = select }}
                      onChange={this.addFeature}
                      closeMenuOnSelect={false}
                      controlShouldRenderValue={false}
                    />
                  ) : (
                    <button className="btn-add-other-category" type="button" onClick={this.handleSearchInputAppear} tabIndex="-1">{linkText}</button>
                  )
                }
              </>
            )}
          </DisableableField>
        )

    )
  }
}

FeatureSelectInput.propTypes = {
  assignedFeatureIds: PropTypes.array,
  ancestry: PropTypes.string,
  addFeatureCallback: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  minimumInputLength: PropTypes.number,
  formActionInProgress: PropTypes.string,
  menuIsOpen: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  categoryPage: PropTypes.bool,
  featureType: PropTypes.string,
  showChevron: PropTypes.bool,
  autoFocus: PropTypes.bool,
  updateLicense: PropTypes.func,
}

FeatureSelectInput.defaultProps = {
  assignedFeatureIds: [],
  ancestry: '',
  inline: false,
  minimumInputLength: 2,
  formActionInProgress: '',
  menuIsOpen: false,
  closeMenuOnSelect: false,
  categoryPage: false,
  featureType: 'object',
  showChevron: true,
  autoFocus: false,
  updateLicense: null,
}
