/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { productEditPageUrl } from '../helpers'

const IdCell = (props) => {
  const { turbosquid_id: turbosquidId, pendingProductIds, bundle: isBundle } = props
  const editURL = isBundle ? `${gon.turbosquid_products_path}/${turbosquidId}/edit_bundle_product` : productEditPageUrl(turbosquidId)
  return (pendingProductIds && pendingProductIds.includes(turbosquidId))
    ? (<span>{turbosquidId}</span>)
    : (<a href={editURL} rel="noopener noreferrer" title={I18n.t('turbosquid.products.edit_product')}>{turbosquidId}</a>)
}

IdCell.propTypes = {
  turbosquid_id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  pendingProductIds: PropTypes.array,
}

IdCell.defaultProps = {
  pendingProductIds: [],
}

export default IdCell
