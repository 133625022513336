/* global gon, I18n */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StemCellCancelationModal } from '../StemCellCancelationModal'
import { stemcellStatusList } from '../../products/constants'

const i18nOptions = {
  scope: 'turbosquid.products.stemcell_certification_panel.stemcell_widget',
}
const jobUrl = (jobId) =>  gon.freshwater_job_url + jobId


const StemcellQueueStatus = ({
  certification,
  pendingCancelStemcellSubmission,
  cancelSubmission,
}) => {
  const { status, queue_id: queueId, queue_status: queueStatus } = certification
  const [showCancelationModal, setShowModal] = useState(false)
   
  const handleShowModal = (show) => {
    setShowModal(show)
  }

  const handleCancelSubmission = () => {
    cancelSubmission()
  }

  const isCertified = ({ queue_status, status }) => {
    return [queue_status, status].includes(stemcellStatusList.CERTIFIED)
  }

  const freshwaterJobWrapper = (queue_id, queue_status) => {
    return (
      <a href={jobUrl(queue_id)} className="stemcell-check-status" target="_blank" rel="noopener noreferrer">
        <button type="button" className="btn btn-default stemcell-button" id="stemcell-button-status">
          {queue_status && I18n.t(queue_status, i18nOptions)}
          <span>{I18n.t('see_details', i18nOptions)}</span>
        </button>      
      </a>
    )
  }

  const defaultStatus = (queue_status, status) => {
    if (isCertified({ queue_status, status })) {
      return (
        <button type="button" className="btn btn-default stemcell-button" id="stemcell-button-status-disabled">
        {I18n.t(stemcellStatusList.CERTIFIED, i18nOptions)}
      </button>  
      )
    }

    return (
      <button type="button" className="btn btn-default stemcell-button" id="stemcell-button-status-disabled">
        {I18n.t(queue_status ? queue_status : 'not_available', i18nOptions)}
      </button>  
    )
  }

  const statusWidget = ({ queue_status, queue_id, status }) => {
    if (queue_id && isCertified({ queue_status, status })) {
      return freshwaterJobWrapper(queue_id, stemcellStatusList.CERTIFIED)
    } else if (queue_id && !queue_status) {
      return freshwaterJobWrapper(queue_id, null)
    } else if (queue_id) {
      return freshwaterJobWrapper(queue_id, queue_status)
    }

    return defaultStatus(queue_status, status)
  }

  return (
    <div className="stemcell-status-wrapper">
        <p id="status-heading"><b>{I18n.t('status', i18nOptions)}</b></p>
        <div className="stemcell-status-wrapper-row">  
          <div className="form-group optional form-group-checkbox form-group-stemcell-validation">
            <input
              className="boolean optional"
              id={"stemcell"}
              name={"stemcell"}
              type="checkbox"
              value={true}
              checked={true}
              disabled={true}
            />
            <label htmlFor={"stemcell"} className="control-label" id="stemcell-label">
              {I18n.t('stemcell', i18nOptions)}         
            </label>
          </div>

          {statusWidget(certification)}       
          
          <button type="button" className="btn btn-default stemcell-button" id="stemcell-button-cancel-submission" onClick={() => handleShowModal(true)}>
            {I18n.t('cancel_certification', i18nOptions)}  
          </button>   
          
        </div>
        <StemCellCancelationModal
          show={showCancelationModal}
          setShowModal={handleShowModal}
          cancelStemcellSubmission={handleCancelSubmission}
          pendingCancelStemcellSubmission={pendingCancelStemcellSubmission}
        />
    </div>
  )
}

StemcellQueueStatus.propTypes = {
  certification: PropTypes.object,
  pendingCancelStemcellSubmission: PropTypes.bool,
  cancelSubmission: PropTypes.func.isRequired,
}

StemcellQueueStatus.defaultProps = {
  certification: {},
  pendingCancelStemcellSubmission: false,
}

export default StemcellQueueStatus
