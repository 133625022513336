/* global I18n, gon */
import React, { useContext, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Overlay, Tooltip } from 'react-bootstrap'
import Icon from '../../../products/react_components/icon'
import { mediaTypeDropdownData } from '../../../products/products_lookups'
import { infoIconClasses } from '../../../products/react_components/helpers'
import { debounce } from 'lodash'
import { ProductContext } from '../../../contexts/ProductContext'
import information from '../../../../../assets/images/information.png'

const i18nOptions = {
  scope: 'turbosquid.products.product_certification_panel',
}

const panelPopoverHTML = () => (
  I18n.t('panel_popover.html', {
    ...i18nOptions,
    checkmate_lite_url: gon.checkmate_lite_url,
    checkmate_pro_url: gon.checkmate_pro_url,
    checkmate_specifications_url: gon.checkmate_specifications_url,
  })
)

const tooltip = (handleTooltipsShow) => (
  <Tooltip id="create-collection-tooltip" onMouseOver={handleTooltipsShow} onMouseLeave={handleTooltipsShow} onFocus={() => false}>
    <a href={gon.support_ticket_url} target="_blank" rel="noreferrer">{I18n.t('create_support_ticket', i18nOptions)}</a>
    <span>
      &nbsp;
      {I18n.t('submit_collection_for_certification', i18nOptions)}
    </span>
  </Tooltip>
)

const ProductCertification = (props) => {
  const {
    mediaType,
    showInfo,
    toggleInfo,
    certifications,
    collectionFeature,
  } = props

  const certificationPanelRef = useRef(null)
  const [showTooltips, setShowTooltips] = useState(false)
  const { checkMateDisabledLink } = useContext(ProductContext)
  const checkmateCertification = certifications && certifications.find(certification => ['checkmate_pro', 'checkmate_lite'].includes(certification.id));

  if (mediaType === mediaTypeDropdownData[0].id) {

    const debounceHandleTooltipsShow = debounce((e) => {
      if (collectionFeature) {
        setShowTooltips(e.type === 'mouseover')
      } else if (showTooltips) {
        setShowTooltips(false)
      }
    }, 100)
    const handleTooltipsShow = (e) => {
      // Prevents React from resetting its properties
      e.persist()
      debounceHandleTooltipsShow(e)
    }

    if (checkmateCertification) {
      return (
        (
          <div onFocus={() => false} onMouseOver={handleTooltipsShow} onMouseLeave={handleTooltipsShow} ref={certificationPanelRef} className="panel panel-default certification-panel" id="js-turbosquid-product-certification-panel">
            <div className="panel-heading">
              <button
                type="button"
                data-toggle="collapse"
                data-target="#certification-panel-instructions"
                onClick={(_) => toggleInfo(!showInfo)}
                tabIndex="-1"
              >
                {I18n.t('certification_level', i18nOptions)}
                <Icon type={infoIconClasses(showInfo)} tabIndex="-1"/>
                <span className="certification-title">
                  <img src={information} />
                  <div>
                    <h4>{I18n.t('stemcell_info.title', i18nOptions)}</h4>
                    <p>{I18n.t('stemcell_info.body', i18nOptions)}</p>
                    <a href={checkMateDisabledLink} target="_blank" rel="noopener noreferrer" tabIndex="-1">{I18n.t('stemcell_info.button', i18nOptions)}</a>
                  </div>
                </span>
              </button>
            </div>
            <div className="panel-body panel-collapse collapse in" id="certification-panel-body">
              <div className="panel-body-container">
                <div
                  id="certification-panel-instructions"
                  className={props.showInfo ? 'panel-instructions collapse in' : 'panel-instructions collapse'}
                  dangerouslySetInnerHTML={{ __html: panelPopoverHTML() }}
                  tabIndex="-1"
                />
                  <p>{I18n.t(checkmateCertification.id, { scope: 'turbosquid.products.product_certification_panel' })}</p>
              </div>
            </div>
            <Overlay
              placement="left"
              target={() => ReactDOM.findDOMNode(certificationPanelRef.current)}
              show={showTooltips}
            >
              {tooltip(handleTooltipsShow)}
            </Overlay>
          </div>
        )
      );
    }
  }
  return null
}

ProductCertification.propTypes = {
  mediaType: PropTypes.number.isRequired,
  showInfo: PropTypes.bool,
  toggleInfo: PropTypes.func.isRequired,
  certifications: PropTypes.array,
  collectionFeature: PropTypes.number,
}

ProductCertification.defaultProps = {
  showInfo: false,
  certifications: [],
  pendingCancelCMSubmission: false,
  cancelCMSubmissionSuccess: false,
  collectionFeature: 0,
}

export default ProductCertification
