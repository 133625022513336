/* global gon */
import Immutable from 'seamless-immutable'

const IrrelevantTerms = gon.irrelevant_terms || {}

export const filterTokens = (tokens = []) => (
  tokens.filter((token) => !IrrelevantTerms[token] && Number.isNaN(Number(token)) && !token.match(/\d+\s*x\s*\d+/))
)

export const arrayToList = (array) => {
  const newArray = Immutable.asMutable(array)
  if (newArray.length > 1) {
    const list = newArray.splice(0, newArray.length - 1).map((t) => `"${t}"`).join(', ')
    return `${list} and "${newArray[newArray.length - 1]}"`
  }

  return `"${newArray[0]}"`
}

export const tokenize = (terms) => (
  terms.replace(/[./#!$%^&*;:{}=_`~()\s]+/g, ',')
    .replace(String.fromCharCode(10), ',')
    .replace(String.fromCharCode(13), ',')
    .toLowerCase()
    .split(',')
    .map((term) => term.replace(/^\W+|\W+$/, ''))
    .filter((term) => term.trim().length > 1)
)
