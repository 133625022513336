import { createSelector } from 'reselect'
import { filter } from 'lodash'

export const selectDraftId = (state) => {
  const { formState } = state
  const { draftId } = formState
  return draftId
}
// eslint-disable-next-line max-len
export const selectSpecs = ({ formState }) => formState.turbosquid_product_form
export const selectProductFormErrors = ({ formState }) => formState.turbosquid_product_form.formValidationErrors
export const selectLastActionDraftSaving = ({ formState }) => formState.lastActionDraftSaving
export const selectFeatureIds = ({ formState }) => formState.featureIds
export const selectFeatures = (state) => state.featureState.features
export const selectBrandFeatures = (state) => state.featureState.brandFeatures
export const selectLicense = ({ formState }) => formState.license
export const selectStemcell = ({ formState }) => formState.turbosquid_product_form.values.stemcell
// eslint-disable-next-line max-len
export const selectCertifications = ({ formState }) => formState.certifications
export const selectUVMapped = ({ formState }) => formState.turbosquid_product_form.values.uvMapped
export const selectUnwrappedUvs = ({ formState }) => formState.turbosquid_product_form.values.unwrappedUVs

export const isCertOfType = (cert, certType) => {
  if (!cert) return false

  return cert.id === certType
}

export const selectAllAlerts = (state) => state.featureState.alerts

export const selectFormActionInProgress = (state) => state.formState.formActionInProgress
export const selectPendingDeleteFiles = (state) => state.formState.pendingDeleteFiles
export const selectDraftIsSaving = (state) => state.formState.turbosquid_product_form.draftSaving

export const selectConstraints = (state) => state.formState.constraints

// eslint-disable-next-line max-len
export const selectProhibitedWordsError = (state) => state.formState.turbosquid_product_form.formValidationErrors.saveDraft
// eslint-disable-next-line max-len
export const selectCertifiedPreviews = ({ formState }) => formState.turbosquid_product_form.values.certifiedPreviews

// eslint-disable-next-line max-len
export const selectOldCertifiedPreviews = ({ formState }) => formState.turbosquid_product_form.values.oldCertifiedPreviews

// eslint-disable-next-line max-len
export const selectDisableCertifiedPreviewChange = ({ formState }) => formState.disableCertifiedPreviewChange

export const selectCancelCMSubmissionStatus = ({ formState }) => formState.pendingCancelCMSubmission
export const selectCancelCMSubmissionSuccess = ({ formState }) => (
  formState.cancelCMSubmissionSuccess
)

export const selectCancelStemcellSubmissionStatus = ({ formState }) => formState.pendingCancelStemcellSubmission
export const selectCancelStemcellSubmissionSuccess = ({ formState }) => formState.cancelStemcellSubmissionSuccess


export const selectDraft = (state) => state.formState.draftId !== null
export const selectDataValidationState = (state) => {
  const { formState } = state
  const { dataValidationPassed } = formState
  return dataValidationPassed || { status: true }
}

export const selectDraftSavePendingSort = ({ formState }) => (
  formState.draftSavePendingSort
)
export const selectProductStatus = ({ formState }) => formState.status

export const selectInvalidPreviewCount = ({ formState }) => formState.invalidPreviewCount

export const selectPublishValidationTriggered = ({ formState }) => (
  formState.publishValidationTriggered
)
export const selectCreatingDraft = ({ formState }) => formState.creatingDraft

export const selectAllFeatureIds = ({ featureState }) => {
  const { features: objectFeatures, brandFeatures, collectionFeature } = featureState
  let featureIds = objectFeatures.concat(brandFeatures || []).map(
    (feature) => feature.id
  )
  if (collectionFeature) {
    featureIds = featureIds.concat([collectionFeature])
  }

  return featureIds
}

export const selectCollectionFeature = (state) => state.featureState.collectionFeature

export const selectAsyncQueue = (state, pattern) => state.formState.asyncQueue[pattern]
