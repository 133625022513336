/* global gon, I18n */
import React, { useContext, useState } from 'react'
import { isEmpty } from 'lodash'
import DismissableNotification from './DismissableNotification'
import Icon from '../../products/react_components/icon'
import { useFormContext } from 'react-hook-form'
import { arrayFrom } from '../../utils/ProductFiles'
import { UIContext } from '../../contexts/UIContext'

const i18nOptions = {
  scope: 'turbosquid.products.product_header',
}

const showMoreOrLess = (showMoreNotification, setShowMoreNotification) => (
  <div className="text-center">
    {
      showMoreNotification
        ? (
          <button className="btn-show-more" type="button" onClick={() => { setShowMoreNotification(false) }}>
            {I18n.t('turbosquid.products.product_header.show_less')}
            <Icon className="fa fa-angle-up angle-icon" type="angle-up" />
          </button>
        )
        : (
          <button className="btn-show-more" type="button" onClick={() => { setShowMoreNotification(true) }}>
            {I18n.t('turbosquid.products.product_header.show_more')}
            <Icon className="fa fa-angle-down angle-icon" type="angle-down" />
          </button>
        )
    }
  </div>
)

const PublisherAlerts = () => {
  const { formState: { errors } } = useFormContext()
  const { displayAlerts } = useContext(UIContext)
  const uniqueFileErrors = Object.keys(errors)
    .reduce((acc, key) => {
      return {
        ...acc,
        [key]: errors[key]
      }
    }, {})
  const [showMoreNotification, setShowMoreNotification] = useState(false)
  let mergedErrors = uniqueFileErrors

  return (
    <div className="col-xs-12">
      {!isEmpty(mergedErrors) && displayAlerts &&
        <DismissableNotification
          extraClasses={showMoreNotification ? "expanded-notification" : ''}
          notificationLevel='danger'
          notificationId="ValidationErrorNotifications"
        >
          <div id="ValidationErrorNotifications">
            <p className="notification-message">{I18n.t('errors_present_on_publish', i18nOptions)}</p>
            <ul className="error-summary">
              {arrayFrom(uniqueFileErrors).map(e => <li key={e.id}>{e.message}</li>)}
            </ul>
          </div>

          {
            Object.keys(mergedErrors).length > 2 &&
            showMoreOrLess(
              showMoreNotification,
              setShowMoreNotification
            )
          }

        </DismissableNotification>
      }
    </div>
  )
}
export default PublisherAlerts