/* global gon */

export const legacyProductDate = new Date(gon.legacy_product_date)
export const imagesAllowedExtensions = ['jpeg', 'jpg', 'png', 'gif', 'webp', 'avif', 'tiff', 'svg']
export const imagesAllowedRegex = new RegExp(`.(${imagesAllowedExtensions.join('|')})$`)
export const acceptImageExtensions = imagesAllowedExtensions.map(e => `.${e}`).join(',')
export const mainFileNotAllowedExtensions = ['jpg', 'jpeg', 'png', 'tiff', 'psd', 'gif', 'tga', 'bmp', 'pdf', 'exe']
export const mainFileAllowedExtensions = '3ds_max,maya,cinema_4d,lightwave,blender'
export const wireframeHeight = 1080
export const wireframeWidth = 1920
export const maxAllowedFileSize = 5 * 1024 * 1024 * 1024
export const unwrappedUvsNotRequired = ['no', 'unknown']

export const validateMainFilesExtension = (file) => {
  const lowerCaseFile = file.toLowerCase()
  const regexFromMyArray = new RegExp(`.(${mainFileNotAllowedExtensions.join('|')})$`)
  return (regexFromMyArray).test(lowerCaseFile)
}

export const validFileExtensionImages = (file) => {
  const lowerCaseFile = file.toLowerCase()
  return (imagesAllowedRegex).test(lowerCaseFile)
}
