/* global gon */
export const getDeleteProductDraftUrl = (draftId = null) => {
  if (gon.product.id
    && Number(gon.product.id) <= 0
    && gon.product.draft_id
    && Number(gon.product.draft_id) <= 0
    && gon.delete_product_draft_url.indexOf('/delete_draft') >= 0) {
    // New product and no draft created
    return gon.delete_product_draft_url.replace(/\?.*/g, `?draft_id=${draftId}`)
  }
  return gon.delete_product_draft_url
}
