/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import ChartUtility from './common/chart_utility';
import StemcellProductQueueItem from './stemcell_product_queue_item';
import StemcellTurboSquidQueueItem from './stemcell_turbosquid_queue_item';

const StemcellQueueItem = createReactClass({
  displayName: 'StemcellQueueItem',
  mixins: [ChartUtility],

  render() {
    const stemcellItem = this.props.queue_item
    const type = stemcellItem.attributes.metadata.hasOwnProperty('stemcell') ? 'product' : 'turbosquid'

    if (type === 'turbosquid') {
      return (
        <StemcellTurboSquidQueueItem
          queue_item={stemcellItem}
        />
      )
    }

    return (
      <StemcellProductQueueItem
        queue_item={stemcellItem}
      />
    )
  },
})

export default StemcellQueueItem;
