import { useEffect, useRef } from 'react'

export function useIsReady() {
  const isReady = useRef(false)

  useEffect(() => {
    isReady.current = true
  })

  return isReady.current
}
