/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import MoneyUtility from './common/money_utility';

const PaymentSentItem = createReactClass({
  displayName: 'PaymentSentItem',
  mixins: [MoneyUtility],
  currencyParts() {
    return this.cents_to_currency_parts(this.props.amount)
  },
  render() {
    let fractional_unit; let main_unit; let
      muted_class
    if (this.props.type !== 'type') {
      const parts = this.currencyParts();
      ({ main_unit } = parts)
      fractional_unit = `.${parts.fractional_unit}`
      muted_class = 'cents'
    } else {
      main_unit = ''
      fractional_unit = this.props.amount
      muted_class = 'type'
    }
    return (
      <div className={`col-sm-${this.props.item_count} payment-sent-item`}>
        <h5 className="text-muted">{I18n.t(`dashboard.payments.${this.props.type}`)}</h5>
        <h3>
          {main_unit}
          <span className={`${muted_class} text-muted`}>{fractional_unit}</span>
        </h3>
      </div>
    )
  },
})

export default PaymentSentItem;
