import { hasUniqueName } from './validators'
import {
  ERROR,
  filesI18nOptions,
  imagesI18nOptions,
  MAX_UPLOADABLE_FILES,
  MAX_UPLOADABLE_TUNTABLE_FILES,
  WARNING
} from '../../products/constants'
import { getFileInfo } from '../../../utils/TurntableUtils'
import { getTurntablesFromCandidates } from '../../../hooks/useTurntableCandidates'

export const uniqueName = (existingFiles) => {
  return ({ files, warnings: w }) => {
    const uniqueFiles = existingFiles ? files.filter(f => hasUniqueName(f, existingFiles)) : files
    const warnings = files.length !== uniqueFiles.length
      ? [...w, { type: WARNING, message: I18n.t('duplicate_upload', filesI18nOptions) }]
      : w
    return {
      files: uniqueFiles,
      warnings
    }
  }
}

export const reduceFilter = (acc, filter) => {
  const { files, warnings } = filter(acc)
  return { files, warnings: [...acc.warnings, ...warnings] }
}

export const previewBatchSize = (existingFileCount) => {
  return ({ files, warnings: w }) => {
    const allowedFiles = existingFileCount + files.length > MAX_UPLOADABLE_FILES
      ? files.slice(0, MAX_UPLOADABLE_FILES - existingFileCount)
      : files
    const warnings = allowedFiles.length !== files.length
      ? [...w, { type: WARNING, message: I18n.t('previews_limit', imagesI18nOptions) }]
      : w
    return {
      files: allowedFiles,
      warnings
    }
  }
}

const mapCandidate = (candidate) => {
  const info = getFileInfo(candidate.name)
  return {
    attributes: {
      name: candidate.name,
      turntableId: info.id
    }
  }
}

const mapCandidateToFile = (files) => {
  return (candidate) => files.find(f => f.name === candidate.attributes.name)
}

export const turntableBatchSize = (turntableCandidates, existingFileCount) => {
  return ({ files, warnings: w }) => {
    const candidates = files.map(mapCandidate)
    const turntables = getTurntablesFromCandidates(candidates)

    // Don't allow any files to be uploaded if a new turntable
    // would exceed the total preview limit.

    if (turntables.length + existingFileCount > MAX_UPLOADABLE_FILES) {
      return {
        files: [],
        warnings: [...w, { type: ERROR, message: I18n.t('previews_limit', imagesI18nOptions) }]
      }
    }

    // For each turntable, allow only the amount of files
    // that doesn't exceed the individual turntable limit.
    // Warn once if any turntable limits have been reached.

    let limitExceeded = false
    let allowedFiles = []

    for (let i = 0; i < turntables.length; i++) {
      const prevTurntable = turntableCandidates.find(({ id }) => id === turntables[i].id)
      const prevTurntableFileCount = prevTurntable?.attributes?.files?.length ?? 0
      const newTurntableFiles = candidates.filter(c => c.attributes.turntableId === turntables[i].id)
      if (prevTurntableFileCount + newTurntableFiles.length > MAX_UPLOADABLE_TUNTABLE_FILES) {
        limitExceeded = true
        allowedFiles = [
          ...allowedFiles,
          ...newTurntableFiles
            .slice(0, MAX_UPLOADABLE_TUNTABLE_FILES - prevTurntableFileCount)
            .map(mapCandidateToFile(files))
        ]
      } else {
        allowedFiles = [
          ...allowedFiles,
          ...newTurntableFiles.map(mapCandidateToFile(files))
        ]
      }
    }

    return {
      files: allowedFiles,
      warnings: limitExceeded
        ? [...w, { type: WARNING, message: `Maximum number of turntable frames is ${MAX_UPLOADABLE_TUNTABLE_FILES}` }]
        : []
    }
  }
}
