/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'seamless-immutable'
import Icon from '../../../products/react_components/icon'
import { infoIconClasses, IsActionPublish ,getErrors } from '../../../products/react_components/helpers'
import FieldErrors from '../../../products/react_components/form_components/field_errors'
import FeatureWidget from '../FeatureAssignmentWidget/FeatureWidget'
import BrandModal from './BrandModal'
import { licenseValueMapper } from '../../../utils/SaveValidations'
import information from '../../../../../assets/images/information.png'
import DisableableField from '../../../products/react_components/form_components/DisableableField'

const i18nOptions = {
  scope: 'turbosquid.products.product_license_panel',
}

const panelPopoverHTML = () => (
  I18n.t('panel_popover.html', {
    ...i18nOptions,
    using_the_royalty_free_license_url: gon.using_the_royalty_free_license_url,
    all_extended_uses_url: gon.all_extended_uses_url,
    editorial_uses_only_url: gon.editorial_uses_only_url,
    custom_url: gon.custom_url,
  })
)

class ProductLicense extends React.Component {
  constructor(props) {
    super(props)

    const selectedLicense = props.license
    this.state = {
      license: props.license,
      showBrandModal: false,
    }
    this.isHandleAutoFocusInput = false
    this.selectedLicense = props.license
  }

  shouldComponentUpdate(nextProps) {
    const { license } = this.props
    const { focusBrandInput, license: nextLicense, updateLicense } = nextProps
    if (focusBrandInput) {
      this.selectedLicense = licenseValueMapper.royalty_free_editorial_uses_only
      this.isHandleAutoFocusInput = true
      updateLicense(this.selectedLicense)
    } else if (license === nextLicense) {
      this.isHandleAutoFocusInput = false
    }
    return true
  }

  componentDidUpdate(prevProps, prevState) {
    const { license, updateLicense } = this.props
    if (license !== this.selectedLicense) {
      updateLicense(this.selectedLicense)
    }
    if (prevProps.license !== this.selectedLicense && this.selectedLicense === 'royalty_free_editorial_uses_only') {
      this.divFeature.scrollIntoView({ behavior: 'smooth' })
    }
  }

  panelClasses = () => {
    const { highlight_panel: highlightPanel } = this.props
    if (highlightPanel) {
      return 'panel panel-warning license-panel'
    }
    return 'panel panel-default license-panel'
  }

  isAllUses = () => {
    const { prohibit_extended_uses: prohibitExtendedUses } = this.props
    if (!prohibitExtendedUses) {
      return this.selectedLicense === 'royalty_free_all_extended_uses'
    }
    return false
  }

  isEditorialUses = () => {
    const { prohibit_editorial_uses: prohibitEditorialUses } = this.props
    if (!prohibitEditorialUses) {
      return this.selectedLicense === 'royalty_free_editorial_uses_only'
    }
    return false
  }

  isCustomUses = () => {
    const { prohibit_custom_uses: prohibitCustomUses } = this.props
    if (!prohibitCustomUses) {
      return this.selectedLicense === 'custom'
    }
    return false
  }

  textMuted = (element) => {
    const {
      prohibit_editorial_uses: prohibitEditorialUses,
      prohibit_extended_uses: prohibitExtendedUses,
      prohibit_custom_uses: prohibitCustomUses,
    } = this.props
    if ((element === 'extended_use' && prohibitExtendedUses)
      || (element === 'editorial_only' && prohibitEditorialUses)
      || (element === 'custom' && prohibitCustomUses)) {
      return 'text-muted'
    }
    return ''
  }

  selectLicense = (e) => {
    const { updateLicense } = this.props
    this.isHandleAutoFocusInput = true
    this.selectedLicense = e.target.value
    return updateLicense(e.target.value)
  }

  renderAlertInfo = (notification, index) => (
    <div className="alert alert-info" key={`notification-${index}`}>
      {notification}
    </div>
  )

  toggleLicenseInfo = () => {
    const { showInfo, toggleInfo } = this.props
    toggleInfo(!showInfo)
  }

  handleDefaultChecked = (license, blockPublish, checkedLicense) => {
    if (license.name === 'royalty_free_all_extended_uses' && !blockPublish) {
      return license.defaultChecked || (checkedLicense === 'royalty_free_all_extended_uses')
    } return license.defaultChecked
  }

  displayBrandModal = () => {
    this.setState({
      showBrandModal: true,
    })
  }

  hideBrandModal = () => {
    this.setState({
      showBrandModal: false,
    })
  }

  render = () => {
    const {
      showInfo,
      prohibit_extended_uses: prohibitExtendedUses,
      prohibit_editorial_uses: prohibitEditorialUses,
      errors,
      formActionInProgress,
      highlight_panel: highlightPanel,
      updateBrandFeatures,
      brandFeatures,
      categoryPage,
    } = this.props
    const {
      showBrandModal,
    } = this.state
    const checkedLicense = this.selectedLicense
    const enableExtendedUsesLicense = (checkedLicense === 'royalty_free_all_extended_uses' || checkedLicense === null)
      && (highlightPanel === null) ? false : prohibitExtendedUses
    const editorialOnlyMuted = this.textMuted('editorial_only')
    const extendedUseMuted = this.textMuted('extended_use')
    const blockPublish = (highlightPanel !== null) && highlightPanel.type === 'publish_not_allowed'
    const extendedUseLicenseMutedClassName = enableExtendedUsesLicense ? extendedUseMuted : ''
    const licenses = [
      {
        name: 'no',
        className: 'license_brand_no',
        defaultChecked: blockPublish ? false : this.isAllUses(),
        disabled: blockPublish ? true : enableExtendedUsesLicense,
        mutedClassName: blockPublish ? 'text-muted' : extendedUseLicenseMutedClassName,
        content: I18n.t('options_no_content', i18nOptions),
        value: licenseValueMapper.royalty_free_all_extended_uses,
      },
      {
        name: 'yes',
        className: 'license_brand_yes',
        defaultChecked: this.isEditorialUses(),
        disabled: prohibitEditorialUses,
        mutedClassName: editorialOnlyMuted,
        content: I18n.t('options_yes_content', i18nOptions),
        value: licenseValueMapper.royalty_free_editorial_uses_only,
      },
    ]
    const mutableFeatures = Immutable.asMutable(brandFeatures)
    return (
      <div className={this.panelClasses()}>
        <div className="panel-heading">
          <button type="button" data-toggle="collapse" className="panel-title" data-target="#license-panel-instructions" tabIndex="-1" onClick={this.toggleLicenseInfo}>
            {I18n.t('3d_model_license_for_customers', i18nOptions)}
            <Icon type={infoIconClasses(showInfo)} tabIndex="-1"/>
          </button>
          <a className="external-link" href={gon.using_the_3d_model_license_url} target="_blank" rel="noopener noreferrer" tabIndex="-1">
            <img src={information} alt={I18n.t('view_license', i18nOptions)} />
          </a>
        </div>

        <div className="panel-body panel-collapse collapse in" id="license-panel-body">
          <div className="panel-body-container">
            <div
              id="license-panel-instructions"
              className={showInfo ? 'panel-instructions collapse in' : 'panel-instructions collapse'}
              dangerouslySetInnerHTML={{ __html: panelPopoverHTML() }/* eslint-disable-line */}
            />

            <div className="license-help-tips">
              <p>{I18n.t('prohibited_brands', i18nOptions)}</p>
              <p><a href={gon.prohibited_brands_url} target="_blank" rel="noopener noreferrer" tabIndex="-1">{I18n.t('check_here', i18nOptions)}</a></p>
              <p>
                {I18n.t('brand_category_help_tips_1', i18nOptions)}
                <a className="external-link" href={gon.associated_brands_information_url} target="_blank" rel="noopener noreferrer" tabIndex="-1">
                  {I18n.t('view_examples', i18nOptions)}
                </a>
              </p>
            </div>

            <div className="licenses-wrapper" id="turbosquid_product_form_license">
              <div className="license-brand-selection">
                {
                  licenses
                  && licenses.map((license) => (
                    <div className={`license-wrapper ${license.className}`} key={license.className}>
                      <DisableableField
                        defaultDisabled={IsActionPublish(formActionInProgress)}
                      >
                        {(disabled) => (
                          <input
                            className="radio_buttons optional"
                            checked={
                              this.handleDefaultChecked(license, blockPublish, checkedLicense)
                            }
                            disabled={!categoryPage && disabled}
                            id={`turbosquid_product_form_${license.className}`}
                            key={`license-${license.defaultChecked}`}
                            onChange={this.selectLicense}
                            type="radio"
                            value={license.value}
                            tabIndex="-1"
                          />
                        )}
                      </DisableableField>

                      <label htmlFor={`turbosquid_product_form_${license.className}`} className={`license-option-label ${license.mutedClassName}`} tabIndex="0">
                        <span className={`subtitle ${license.mutedClassName}`}>
                          {I18n.t(`option_${license.name}`, i18nOptions)}
                        </span>
                      </label>
                      <p className="license-option-content">{license.content}</p>
                    </div>
                  ))
                }
              </div>
            </div>
            {
              checkedLicense === 'royalty_free_editorial_uses_only'
              && (
                <>
                  <div ref={(element) => { this.divFeature = element }}>
                    <FeatureWidget
                      features={mutableFeatures}
                      updateFeatures={updateBrandFeatures}
                      formActionInProgress={formActionInProgress}
                      featureType="brand"
                      errors={errors}
                      autoFocus={this.isHandleAutoFocusInput}
                      categoryPage={categoryPage}
                    />
                  </div>
                  <div className="add-missing-brand-container">
                    <span>{I18n.t('can_not_find_brand', i18nOptions)}</span>
                    <button className="btn-add-missing-brand" type="button" onClick={this.displayBrandModal}>
                      {I18n.t('report_missing_brand', i18nOptions)}
                    </button>
                  </div>
                </>
              )
            }
          </div>

          {
            getErrors(errors).license
            && <FieldErrors errors={getErrors(errors).license} classNames="text-error align-left-text-red" />
          }
        </div>
        <BrandModal
          show={showBrandModal}
          hideModal={this.hideBrandModal}
        />
      </div>
    )
  }
}

ProductLicense.propTypes = {
  highlight_panel: PropTypes.object,
  license: PropTypes.string,
  notifications: PropTypes.array,
  prohibit_editorial_uses: PropTypes.bool,
  prohibit_extended_uses: PropTypes.bool,
  prohibit_custom_uses: PropTypes.bool,
  showInfo: PropTypes.bool,
  toggleInfo: PropTypes.func.isRequired,
  updateLicense: PropTypes.func.isRequired,
  errors: PropTypes.object,
  formActionInProgress: PropTypes.string,
  updateBrandFeatures: PropTypes.func.isRequired,
  brandFeatures: PropTypes.array,
  categoryPage: PropTypes.bool,
  focusBrandInput: PropTypes.bool,
}

ProductLicense.defaultProps = {
  highlight_panel: null,
  license: '',
  notifications: [],
  prohibit_editorial_uses: false,
  prohibit_extended_uses: false,
  prohibit_custom_uses: false,
  showInfo: false,
  errors: {},
  formActionInProgress: '',
  brandFeatures: [],
  categoryPage: false,
  focusBrandInput: false,
}

export default ProductLicense
