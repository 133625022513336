import { connect } from 'react-redux'
import PublisherForm from '../Publisher'

import {
  fetchInitialConstraints,
  getFileFormats,
} from '../../react_components/actions/form_slice_actions'

const mapStateToProps = (state) => ({
  isLoading: state.uiState.isLoading,
  showOverlay: state.uiState.showOverlay
})

const mapDispatchToProps = (dispatch) => ({
  getInitialTermsConstraints: () => dispatch(fetchInitialConstraints()),
  getFileFormats: () => dispatch(getFileFormats())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublisherForm)
