import React, { useContext } from 'react'
import { FileContext } from '../../../contexts/FileContext'
import { count } from '../../../utils/ProductFiles'
import ReactSpinner from '../../../products/react-spinner'

const i18nOptions = {
  scope: 'turbosquid.products.product_files_panel',
}

export default function UndoDeleteNotification() {
  const { deletedFiles, undoDeleteFiles, pendingUndoDelete } = useContext(FileContext)
  const deletedFilesCount = count(deletedFiles)

  if (deletedFilesCount === 0) return null

  return (
    <div aria-label="Deleted files notifications" className="fade-notification">
      {I18n.t(
        `removed_file${deletedFilesCount === 1 ? '_one' : '_other'}`,
        { ...i18nOptions, count: deletedFilesCount }
      )}
      {
        !pendingUndoDelete
        && (
          <button
            onClick={() => undoDeleteFiles()}
            className="browse-files-link"
            style={{background: 'transparent'}}
          >
            {I18n.t('undo', i18nOptions)}
          </button>
        )
      }
      { pendingUndoDelete && <span className="delete-file-spinner-container browse-files-link"><ReactSpinner /></span> }
    </div>
  )
}
