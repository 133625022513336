import React, { useContext } from 'react'
import { BundlesContext } from './BundlesProvider'
import PropTypes from 'prop-types'

import ReactTable from 'react-table'
import Trashcan from '../../../../assets/images/bundles/trash-var-solid25.png'

const ProductSelectionTable = ReactTable

export default function BundlesProductSelection({ data }) {
  const {
    searchSelect,  
  } = useContext(BundlesContext)

  const {
    handleSelectCheckbox,
  } = searchSelect

  const NameCell = ({ name }) => (
    <span className='bundles-table-cell' >{name}</span>
  )

  NameCell.propTypes = {
    status: PropTypes.string,
  }

  NameCell.defaultProps = {
    status: '',
  }

  const renderNameCell = (cellInfo) => {
    /* eslint-disable */
    return (
      <NameCell
        name={cellInfo.original.name}
      />
    )
    /* eslint-enable */
  }

  const renderPreviewCell = (cellInfo) => {
    /* eslint-disable */
    return (
      <div className="image-container bundles-image-container">
        <img className="bundles-image" src={cellInfo.original.thumbnail} alt={cellInfo.original.name} />
      </div>
    )
    /* eslint-enable */
  }

  const renderPriceCell = (cellInfo) => {
    /* eslint-disable */
    return (
      <span className='bundles-table-cell'>
        {cellInfo.original.price}
      </span>
    )
    /* eslint-enable */
  }

  const renderIdCell = (cellInfo) => {
    /* eslint-disable */
    return (
      <span className='bundles-table-cell'>
        {cellInfo.original.id}
      </span>
    )
    /* eslint-enable */
  }

  const renderRemoveCell = (cellInfo) => {
    /* eslint-disable */
    return (
      <span className="remove-container bundles-table-cell" data-testid="remove-bundle" onClick={(e) => {handleSelectCheckbox(cellInfo.original.id, 'remove')}}>
        <img className='remove-image' src={Trashcan} alt={'remove'}/>
      </span>
    )
    /* eslint-enable */
  }

  const defaultColumns = [
    {
      Header: 'Preview',
      maxWidth: 80,
      minWidth: 80,
      id: 'preview',
      accessor: 'thumbnail',
      Cell: renderPreviewCell,
    },
    {
      Header: 'Name',
      accessor: 'name',
      id: 'name',
      sortable: false,
      // minWidth: 80,
      Cell: renderNameCell
    },
    {
      Header: 'Price',
      accessor: 'price',
      id: 'price',
      sortable: false,
      // minWidth: 80,
      Cell: renderPriceCell
    },
    {
      Header: 'ID',
      accessor: 'id',
      id: 'id',
      sortable: false,
      // minWidth: 80,
      Cell: renderIdCell
    },
    {
      Header: '',
      accessor: 'id',
      id: 'delete_id',
      sortable: false,
      // minWidth: 80,
      Cell: renderRemoveCell,
    },
  ]

  return (
    <>
      <div>
      <ProductSelectionTable
				manual
				data={data}
				columns={defaultColumns}
				keyField="name"
				NoDataComponent={() => null}
				showPagination={false}
				minRows={0}
      />
      </div>
    </>
  )
}