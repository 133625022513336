import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas/publisher_saga'
import { flashSlice, flashInitialState } from './reducers/flash_slice'
import { formSlice, getFormInitialState } from './reducers/form_slice'
import { publisherUISlice, publisherUIState } from './reducers/publisher_ui_slice'
import { publisherFeatureSlice, getFeatureInitialState } from './reducers/publisher_feature_slice'

const devMode = process.env.NODE_ENV === 'development'
const sagaMiddleware = createSagaMiddleware()
// TODO: ideally we should re-enable immutable and serializable checks at some point.
// We are dispatching File and Date objects in redux actions and that's not OK.
// However, fixing all the places causing this is too heavy for this initial migration to RTK.
const defaultMiddleware = getDefaultMiddleware({
  thunk: false,
  immutableCheck: false,
  serializableCheck: false,
})
const middleware = [...defaultMiddleware, sagaMiddleware]

let store = null

// TODO: move initial state into slices?
const getInitialState = () => ({
  featureState: getFeatureInitialState(),
  uiState: publisherUIState,
  flashState: flashInitialState,
  formState: getFormInitialState('turbosquid_product_form'),
})

export const publisherStore = () => {
  if (!store) {
    store = configureStore({
      devTools: devMode,
      reducer: {
        featureState: publisherFeatureSlice.reducer,
        uiState: publisherUISlice.reducer,
        flashState: flashSlice.reducer,
        formState: formSlice.reducer,
      },
      middleware,
      preloadedState: getInitialState()
    })

    sagaMiddleware.run(rootSaga)
  }
  return store
}
