export const mediaTypeDropdownData = [
  { id: 2, name: '3D Models' },
  { id: 3, name: 'Textures' },
  { id: 5, name: 'Motion Capture' },
]

export const colorDepthDropdownData = [
  { id: 0, name: 'Other' },
  { id: 1, name: '8 Bit' },
  { id: 2, name: '8 Bit Indexed' },
  { id: 3, name: '16 Bit' },
  { id: 4, name: '24 Bit' },
  { id: 5, name: '32 Bit' },
  { id: 6, name: 'Grayscale' },
]

export const frameRateDropdownData = [
  { id: 0, name: 'Other' },
  { id: 1, name: 'Film 24fps' },
  { id: 2, name: 'PAL 25fps' },
  { id: 3, name: 'NTSC 30fps' },
  { id: 4, name: 'below 24fps' },
  { id: 5, name: 'above 30fps' },
]

export const associatedFileTypes = [
  {
    name: 'promotional_file',
    label: 'Promotional Download',
  },
  {
    name: 'customer_file',
    label: 'Customer Only Download',
  },
  {
    name: 'texture_file',
    label: 'Textures',
  },
  {
    name: 'viewer_file',
    label: 'Marmoset Viewer Files',
  },
]
