/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const d3 = require('d3-time-format')

const ChartUtility = {
  verboseDate(date) {
    if (date != null) {
      return this.formattedDate(date)
    }
    return ''
  },
  formattedDate(date) {
    const format = ('%B %d, %Y')
    const formatMonth = d3.timeFormat(format)
    const newDate = formatMonth(new Date (date))
    return newDate
  },
}

export default ChartUtility;
