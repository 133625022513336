$(() => {
  $('.pixelsquid-products-suggestions').select2({
    allowClear: true,
    minimumInputLength: 2,
    placeholder: "Select a state",
    ajax: {
      url: function (_) {
        return $(this).data('url');
      },
      dataType: 'json',
      delay: 300,
      cache: true,
      data: function (params) {
        return {
          fields: { tags_and_synonyms: params.term },
          ancestor_code: $(this).data('type'),
          assignable: $(this).data('assignable')
        }
      },
      processResults: function (data) {
        let options = data.map(option => {
          let term = option.text;
          let category = option.visible_ancestor_tags[0] || '';
          let text = category ? `${term} in ${category}` : term;

          return {
            id: option.id,
            text: text.toLowerCase()
          }
        })

        return { results: options }
      }
    }
  });

  $('.pixelsquid-products-tags').select2({
    tags: true,
    minimumInputLength: 2,
    ajax: {
      url: function (_) {
        return $(this).data('url');
      },
      dataType: 'json',
      delay: 300,
      cache: true,
      data: function (params) {
        return {
          fields: { tags: params.term }
        }
      },
      processResults: function (data) {
        let results = data.map(({ text }) => {
          return {
            id: text,
            text: text
          }
        });
        return { results: results }
      }
    }
  });

  const searchField = document.querySelector('.pixelsquid-products-tags + .select2-container .select2-search__field')

  if (searchField) {
    searchField.addEventListener('paste', (e) => {
      e.preventDefault()
      e.stopPropagation()
      const text = e.clipboardData.getData('text')
      const splitRegex = text.match(',') ? /,/ : /\s/
      const select = $('.pixelsquid-products-tags')

      text.split(splitRegex).forEach((tag) => {
        tag = tag.trim()
        const option = new Option(tag, tag, true, true)
        select.append(option).trigger('change')
      })
    });
  }
})
