/* global I18n */
import React from 'react'
import CubeGrid from './CubeGrid'
import { imagesI18nOptions, INSUFFICIENT_FRAMES, MISSING_FRAMES } from '../../products/constants'

export default function TurntableCandidate({ turntable, errors, clearCandidates }) {
  const { attributes } = turntable
  const { isWaiting, name, frameCount } = attributes
  const missingFramesError = errors.find(e => [INSUFFICIENT_FRAMES, MISSING_FRAMES].includes(e.type))
  return (
    <div className="col-sm-6 col-md-4 col-lg-3 file-content turntable ">
      <div className="file-container turntable">
        {!isWaiting && (
          <button
            type="button"
            className="btn-link btnCancelUploadTurntable"
            onClick={clearCandidates}
          >
            {I18n.t('remove_turntable', imagesI18nOptions)}
          </button>
        )}
        <div className="preview-data">
          <div className="preview-wrapper turntable background-image-turntable">
            {!!missingFramesError && (
              <div className="badge pull-left incomplete">
                    <span className="incomplete-warning">
                      { I18n.t('incomplete', imagesI18nOptions) }
                      {' '}
                    </span>
                360&deg;
              </div>
            )}
            {!isWaiting && !errors.length && (
              <span className="processing-label-container">
                    { I18n.t('turbosquid.products.product_files_panel.processing') }
                  </span>
            )}
            <CubeGrid />
          </div>
          <p className="file-name">
            { `${name} (${frameCount ?? 0} ${I18n.t('frames', imagesI18nOptions)})`}
          </p>
          {!!missingFramesError && (
            <div className="missing-turntable-images">
                  <span>
                    {`${I18n.t('missing_turntable_positions', imagesI18nOptions)}: ${missingFramesError.data.join(', ')}.`}
                  </span>
              <span>
                    {' '}
                {I18n.t('complete_the_turntable', imagesI18nOptions)}
                  </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
