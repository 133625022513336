import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { syncFiles } from '../products/react_components/actions/form_slice_actions'

export default function useSyncLegacyFiles(
  files = {
    preview: {},
    native: {},
    associated: {},
  }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(syncFiles({ ...files }))
  }, [files])
}
