export const formCertifications = (product) => {
  const certifications = product.certifications.map((certification) => {
    const {
      attributes,
    } = certification

    const certificationCopy = { ...certification }
    delete certificationCopy.attributes
    return { ...certificationCopy, ...attributes }
  })

  return { ...product, certifications }
}
