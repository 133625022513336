import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { FileContext } from '../contexts/FileContext'
import { ProductContext } from '../contexts/ProductContext'
import { isEqual } from 'lodash'
import { fieldSuffix } from '../utils/ProductFiles'
import useConfirmLeave from './useConfirmLeave'

export default function useFileForm(file, initialValues) {
  const { attributes, index } = file
  const { draftId } = useContext(ProductContext)
  const { updateFiles, patchFile, isPatching } = useContext(FileContext)
  const form = useFormContext()
  const { watch } = form
  const currentValues = useRef(initialValues)

  useConfirmLeave(isPatching)

  // Initialize form
  useEffect(() => {
    Object.keys(initialValues).forEach(key => {
      form.setValue(
        `${file.id}.${key}`, initialValues[key],
        {
          shouldDirty: false,
          shouldTouch: false,
          shouldValidate: false
        }
      )
    })
  }, [])

  // If form values change, automatically patch
  // file on the server, updating local files optimistically
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      // Only watch this file's form values
      if (!new RegExp(`${file.id}.*`).test(name)) return

      const fieldName = fieldSuffix(name)
      const fieldValue = (values[file.id] ?? {})[fieldName]
      const hasBeenInitialized = fieldValue !== undefined
      const hasChanged = !isEqual(currentValues.current[fieldName], fieldValue)

      if (hasBeenInitialized && hasChanged) {
        const updatedValues = {
          ...currentValues.current,
          [fieldName]: fieldValue
        }
        const updatedAttributes = {
          ...attributes,
          ...updatedValues
        }
        currentValues.current = updatedValues
        updateFiles([{
          id: file.id,
          type: updatedAttributes.fileType ?? file.type,
          attributes: updatedAttributes,
          index
        }])
        debouncedPatchFile(updatedAttributes)
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, index]);

  const debouncedPatchFile = useCallback(
    debounce((attributes) => patchFile({ ...file, attributes }), 1000), []
  )

  return {
    ...form,
    fileFormValues: currentValues.current,
    isReady: !!draftId,
  }
}
