/* global gon, I18n */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import DismissableNotificationContainer from '../../../containers/publisher/EditHeader/DismissableNotificationContainer'
import {
  idFromErrorKey,
  translationFromErrorKey,
} from '../../../utils/NotificationsHelpers'
import Icon from '../../../products/react_components/icon'
import { useFormContext } from 'react-hook-form'
import { arrayFrom } from '../../../utils/ProductFiles'

const i18nOptions = {
  scope: 'turbosquid.products.product_header',
}

const showMoreOrLess = (showMoreNotification, setShowMoreNotification) => (
  <div className="text-center">
    {
      showMoreNotification
        ? (
          <button className="btn-show-more" type="button" onClick={() => { setShowMoreNotification(false) }}>
            {I18n.t('turbosquid.products.product_header.show_less')}
            <Icon className="fa fa-angle-up angle-icon" type="angle-up" />
          </button>
        )
        : (
          <button className="btn-show-more" type="button" onClick={() => { setShowMoreNotification(true) }}>
            {I18n.t('turbosquid.products.product_header.show_more')}
            <Icon className="fa fa-angle-down angle-icon" type="angle-down" />
          </button>
        )
    }
  </div>
)

const PublisherAlerts = ({
  formErrors,
  displayAlerts
}) => {
  const { formState: { errors } } = useFormContext()
  const uniqueFileErrors = Object.keys(errors)
    .reduce((acc, type) => {
      return {
        ...acc,
        ...errors[type]
      }
    }, {})
  const [showMoreNotification, setShowMoreNotification] = useState(false)
  let mergedErrors = {
    ...formErrors,
    ...uniqueFileErrors
  }

  return (
    <div className="col-xs-12">
      {!isEmpty(mergedErrors) && displayAlerts &&
        <DismissableNotificationContainer
          extraClasses={showMoreNotification ? "expanded-notification" : ''}
          notificationLevel='danger'
          notificationId="ValidationErrorNotifications"
        >
          <div id="ValidationErrorNotifications">
            <p className="notification-message">{I18n.t('errors_present_on_publish', i18nOptions)}</p>
            <ul className="error-summary">
              {
                Object.keys(formErrors || {}).map((key) => (
                  <li key={key}>
                    <a href={`#${idFromErrorKey(key)}`} className="field-link"><u>{translationFromErrorKey(key)}</u></a>
                    {' '}
                    {
                      formErrors[key].map((error, index) =>
                        <span key={index} className="error-messages">{error.messageForNotif}</span>)
                    }
                  </li>
                ))
              }

              {arrayFrom(uniqueFileErrors).map(e => <li key={e.id}>{e.message}</li>)}
            </ul>
          </div>

          {
            Object.keys(mergedErrors).length > 2 &&
            showMoreOrLess(
              showMoreNotification,
              setShowMoreNotification
            )
          }

        </DismissableNotificationContainer>
      }
    </div>
  )
}

PublisherAlerts.propTypes = {
  formErrors: PropTypes.object,
  activeTab: PropTypes.number,
  displayAlerts: PropTypes.bool,
}

PublisherAlerts.defaultProps = {
  formErrors: {},
  activeTab: 1,
  displayAlerts: false,
}

export default PublisherAlerts
