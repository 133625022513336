import React from 'react';
import { timeFormat } from 'd3-time-format'
import Chart from './common/chart';
import ChartUtility from './common/chart_utility';
import NumberUtility from './common/number_utility';

const PublishingChart = createReactClass({
  displayName: 'PublishingChart',
  mixins: [ChartUtility, NumberUtility],
  buildSeries(name, months) {
    const result = months.map(function(month) {
        const key = name + '_cumulative_count';
        if (month[key] != null) {
          return month[key].value;
        } else {
          return 0;
        }
    });

    result.unshift(I18n.t('dashboard.publishing.'+name));
    return result;
  },
  valueArray(objects) {
    const array = (() => {
      const result = [];
      for (let key in objects) {
        var value;
        result.push(value = objects[key]);
      }
      return result;
    })();
    array.shift();
    return array;
  },
  zeroSeries(value) {
    return value === 0;
  },
  chartData() {
    const publish_chart_x = ['x'];
    const response = this.props.aggregations;
    const {
      months
    } = response;

    const turbosquid_series = this.buildSeries('turbosquid', months);
    const checkmate_series  = this.buildSeries('checkmate', months);
    const pixelsquid_series = this.buildSeries('partner_sites', months);
    const formatMonth = timeFormat("%Y-%m-%d")
    response.months.map(month => {
      const date = new Date(month.from_as_string)
      return publish_chart_x.push(formatMonth(date))
    });

    const chart = [publish_chart_x];
    if (!this.valueArray(turbosquid_series).every(this.zeroSeries)) { chart.push(turbosquid_series); }
    if (!this.valueArray(checkmate_series).every(this.zeroSeries)) { chart.push(checkmate_series); }
    if (!this.valueArray(pixelsquid_series).every(this.zeroSeries)) { chart.push(pixelsquid_series); }
    return chart;
  },
  colors() {
    let colors;
    return colors = { turbosquid: '#75caeb', checkmate: '#ff4136', pixelsquid: '#28b62c' };
  },
  render() {
    if (this.props.aggregations != null) {
      return <div><Chart colors={this.colors()} data={this.chartData()} y_ticks_integers show_legend={false} /></div>
    }
    return <PanelSpinner hideText="true" />
  },
})

export default PublishingChart;
