import { useQuery } from 'react-query'
import * as DraftService from '../services/draft-service'
import {
  MIN_UV_HEIGHT,
  MIN_UV_WIDTH,
  MIN_WIREFRAME_HEIGHT,
  MIN_WIREFRAME_WIDTH
} from '../components/products/constants'

export default function useThumbnail(draftId, file) {
  const { id, attributes } = file
  const { url200, sourceWidth, sourceHeight, validSearchBackground } = attributes
  const {
    data: thumbnails,
  } = useQuery(
    'getPreviews',
    () => DraftService.getPreviews({ draftId }),
    { enabled: !url200 }
  )

  const thumbnail = (() => {
    if (url200) {
      return {
        url200,
        sourceWidth,
        sourceHeight,
        validSearchBackground,
      }
    } else if (!url200 && thumbnails) {
      const t = thumbnails.find(t => t.id === id)
      return t?.attributes?.url200
        ? {
          url200: t.attributes.url200,
          sourceWidth: t.attributes.sourceWidth,
          sourceHeight: t.attributes.sourceHeight,
          validSearchBackground: t.attributes.validSearchBackground,
        }
        : {}
    } else {
      return {}
    }
  })()

  return {
    ...attributes,
    ...thumbnail,
    meetsWireframeRequirements: sourceWidth >= MIN_WIREFRAME_WIDTH && sourceHeight >= MIN_WIREFRAME_HEIGHT,
    meetsUvRequirements: sourceWidth >= MIN_UV_WIDTH && sourceHeight >= MIN_UV_HEIGHT
  }
}
