/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'

const CreateNewProduct = (props) => {
  const { alignClass } = props
  const newProductURL = gon.turbosquid_new_product_draft_url
  return (
    <a
      href={newProductURL}
      id="create-new-product"
      className={`btn new-product-btn my-products__new-product-btn ${alignClass}`}
    >
      {I18n.t('turbosquid_products.create_new_product')}
    </a>
  )
}

export default CreateNewProduct

CreateNewProduct.propTypes = {
  alignClass: PropTypes.string,
}

CreateNewProduct.defaultProps = {
  alignClass: '',
}
