/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const i18nOptions = {
  scope: 'turbosquid.products.product_alerts.draft_warning',
}

const DiscardModal = ({ show, hideModal, discardDraft }) => (
  <Modal show={show} className="discard-modal-container" onHide={hideModal}>
    <Modal.Dialog>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('are_you_sure', i18nOptions)}</Modal.Title>
      </Modal.Header>
      <div className="separator" />
      <Modal.Footer>
        <a variant="secondary" type="button" onClick={hideModal}>
          {' '}
          {I18n.t('cancel', i18nOptions)}
        </a>
        <a variant="primary" id="discard" className="delete" type="button" onClick={discardDraft}>
          {' '}
          {I18n.t('ok', i18nOptions)}
        </a>
      </Modal.Footer>
    </Modal.Dialog>
  </Modal>
)

DiscardModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  discardDraft: PropTypes.func.isRequired,
}

export default DiscardModal
