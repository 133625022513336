/* global I18n, gon */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PublisherButtonsTopContainer from '../../../containers/publisher/EditHeader/PublisherButtonsTopContainer'
import { productStatusLink } from './StatusDescription'
import { formattedActionTime } from '../../../utils/time_helpers'

const i18nOptions = {
  scope: 'turbosquid.products.product_header',
}

const ProductHeaderEditForm = ({
  name,
  status,
  hasDraft,
  updatedAt,
  isLoading,
  draftSaving
}) => {

  const [updatePresent, setUpdatePresent] = useState(updatedAt)

  useEffect(() => {
    setUpdatePresent(getDraftUpdates(draftSaving, updatedAt))
  }, [updatedAt, draftSaving, name, isLoading])


  const getDraftUpdates = (draftSaving, updatedAt) => {   
    if (draftSaving) {
      if (updatedAt) {
        return `${I18n.t('draft_saving', i18nOptions)} Last Saved at ${formattedActionTime(new Date(updatedAt))}`
      } else {
        return `${I18n.t('draft_saving', i18nOptions)}`
      }
    } 
    if (updatedAt) {
      return `${I18n.t('draft_saved_at', i18nOptions)} ${formattedActionTime(new Date(updatedAt))}`
    } 
  }

  return (
    <div className="product-header-action-wrapper">
      <div className="product-info-wrapper">
        <h1 className="product-title">{name || I18n.t('new_product', i18nOptions)}</h1>
        <div className="product-status">
          {productStatusLink(status)}
        </div>
        <div className="product-info">
          <span className="product-info-type">
            {`${I18n.t('type', i18nOptions)}:`}
          </span>
          {(hasDraft || (gon.product.id && Number(gon.product.id) <= 0) || updatePresent)
            ? (
              <div>
                <span className="product-info-draft">
                  {I18n.t('draft', i18nOptions)}
                </span>
                <span className="product-info-draft-info">
                  {updatePresent}
                </span>
              </div>
            )
            : <span className="product-info-draft">{I18n.t('published_product', i18nOptions)}</span>}
        </div>
      </div>
      <PublisherButtonsTopContainer />
    </div>
  )
}
  
ProductHeaderEditForm.propTypes = {
  name: PropTypes.string,
  status: PropTypes.string.isRequired,
  hasDraft: PropTypes.bool,
  updatedAt: PropTypes.string,
  isLoading: PropTypes.bool,
  draftSaving: PropTypes.bool,
}

ProductHeaderEditForm.defaultProps = {
  hasDraft: false,
  updatedAt: '',
  isLoading: false,
}

export default ProductHeaderEditForm
