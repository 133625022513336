import React from 'react'
import PropTypes from 'prop-types'
import { getDownloadUrl, getFileExtension } from '../helpers'

function DownloadAssetFiles(props) {
  const { assetFiles, productId } = props
  const display = assetFiles.length ? 'block' : 'none'

  return (
    <>
      <table id="bulk-download-asset-files" className="table table-striped table-fixed" style={{ display }}>
        <tbody>
          {
            assetFiles.map((file) => (
              <tr id={`download-${file.id}`} key={file.id}>
                <td className="col-sm-8 left-column file-name">
                  <a href={getDownloadUrl({ productId, file })} target="_blank" rel="noopener noreferrer" title={file.name}>
                    {file.name}
                  </a>
                </td>
                <td className="col-sm-2 file-extension">{getFileExtension(file.name)}</td>
                <td className="col-sm-2 right-column file-download-link">
                  <a href={getDownloadUrl({ productId, file })} target="_blank" rel="noopener noreferrer">
                    <div>
                      <i className="fa fa-arrow-circle-o-down" />
                    </div>
                  </a>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  )
}

export default DownloadAssetFiles

DownloadAssetFiles.propTypes = {
  assetFiles: PropTypes.array.isRequired,
  productId: PropTypes.string.isRequired,
}
