/* global I18n, gon */
import React from 'react'

const i18nOptions = {
  scope: 'turbosquid.products.product_images_panel',
}

export default function CertifiedPreviews({ certifiedPreviewsActive }) {
  const html = I18n.t('certified_previews_tip.html', {
    ...i18nOptions,
    certified_previews_url: gon.cm_image_icon_tips.beauty_shots.learn_more_url,
  })
  return (
    <div className="row file-list-wrapper image-files-wrapper">
      <div className="preview-image-standard">
        <p dangerouslySetInnerHTML={{ __html: html } /* eslint-disable-line */ } />
        <div className={`turbosquid_product_form_certifiedPreviews ${certifiedPreviewsActive ? 'enabled' : ''}`}>
          <i className={`image-icon-hint certified-previews fa ${certifiedPreviewsActive ? 'fa-check-circle' : 'fa-exclamation-circle'}`} />
          <label>{I18n.t('certified_previews_label', i18nOptions)}</label>
        </div>
      </div>
    </div>
  )
}
