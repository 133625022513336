/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import DashboardClient from './common/dashboard_client';
import PaymentUtility from './common/payment_utility';
import ChartUtility from './common/chart_utility';
import PanelSpinner from './common/panel_spinner';
import PaymentWarnings from './payment_warnings';
import PaymentEstimated from './payments_estimated';
import PaymentPrevious from './payments_previous';
import PaymentSent from './payments_sent';

const Payments = createReactClass({
  displayName: 'Payments',
  mixins: [DashboardClient, PaymentUtility, ChartUtility],
  endpoint() {
    return '/turbosquid/dashboard_widgets/payments.json'
  },
  lastPaymentDate() {
    if (this.state.data.aggregations.max_payment_date.value != null) {
      return this.state.data.aggregations.max_payment_date.value
    }
  },
  payments() {
    return this.state.data.payments
  },
  previous() {
    return this.state.data.payments.slice(0, 3).reverse()
  },
  pending() {
    return this.state.data.aggregations.total_pending.value
  },
  estimated() {
    return this.state.data.aggregations.total_estimated.value
  },
  threshold() {
    return this.state.data.aggregations.payment_threshold.value
  },
  recent_payment_amount() {
    if (this.state.data.aggregations.recent_payment_amount.value != null) {
      return this.state.data.aggregations.recent_payment_amount.value
    }
    return 0
  },
  recent_payment_held() {
    return this.state.data.aggregations.recent_payment_withheld.value
  },
  recent_payment_date() {
    return this.state.data.aggregations.recent_payment_date.value
  },
  recent_payment_fee() {
    return this.state.data.aggregations.recent_payment_fee.value
  },
  recent_payment_type() {
    return this.state.data.aggregations.recent_payment_type.value.replace('Pay by ', '')
  },
  details_royalty_rate() {
    return this.state.data.aggregations.payment_details_royalty_rate.value
  },
  details_tax_status() {
    return this.state.data.aggregations.payment_details_tax_status.value
  },
  details_threshold() {
    if (gon.payment_preference.hold_payment != null) {
      return I18n.t('dashboard.payments.hold_payments')
    }
    return `${I18n.t('dashboard.payments.at_least')} $${this.state.data.aggregations.payment_details_threshold.value / 100}`
  },
  details_withholding_rate() {
    return this.state.data.aggregations.payment_details_withholding_rate.value
  },

  render() {
    return (
      <div className="panel-body text-left">
        <div className="panel-heading-text row">
          <div className="col-xs-12">
            <a className="pull-right toggle-collapse js-toggle-collapse" data-toggle="collapse" data-target=".payments-panel-collapse" href="#"><i className={this.panelChevronClass('payments-container')} /></a>
            <ul className="nav nav-tabs">
              <li className="active"><a href="#payments" data-toggle="tab" className="panel-heading-label">{I18n.t('dashboard.payments.payments')}</a></li>
              <li><a href="#details" data-toggle="tab" className="panel-heading-label">{I18n.t('dashboard.payments.payments_details')}</a></li>
              <li className="panel-info-link"><a href={gon.payment_report_url}><i className="fa fa-table" /></a></li>
            </ul>
          </div>
        </div>
        <div className={`panel-divider payments-panel-collapse ${this.panelCollapseClass('payments-container')}`} />
        <div className={`payments-panel-collapse ${this.panelCollapseClass('payments-container')}`}>
          {(() => {
            if (this.reportsDisabled() || __guard__(this.state.data != null ? this.state.data.aggregations : undefined, ({error}) => error)) {
              return <ReportError />
            } if (this.state.data != null) {
              const estimated = this.estimated()
              const previous = this.previous()
              const threshold = this.threshold()
              const payments = this.payments()
              const warn = !gon.payment_hold && (gon.payment_issues || this.afterPaymentDate(this.lastPaymentDate) && estimated < threshold && !gon.payment_hold && estimated > 0)
              const recent_payment = this.recent_payment_amount() > 0
              return (
                <div className="tab-content" id="paymentsTab">
                  <div id="payments" className="tab-pane fade active in" role="tabpanel"><div className="row text-center">{[recent_payment && <PaymentSent key="payment-sent" payment_date={this.recent_payment_date()} amount={this.recent_payment_amount()} withholdings={this.recent_payment_held()} type={this.recent_payment_type()} fee={this.recent_payment_fee()} />, warn && <PaymentWarnings key="payment-warnings" threshold={threshold} estimated={estimated} />, <PaymentEstimated key="payment-estimated" estimated={estimated} pending={this.pending()} threshold={threshold} last_payment_date={this.lastPaymentDate()} />, previous.length >= 3 && [<div key="panel-divider" className="panel-divider" />, <PaymentPrevious key="payment-previous" payments={previous} />]]}</div></div>
                  <div id="details" className="tab-pane fade" role="tabpanel">
                    <div className="row text-center">
  <div className="col-sm-6 payment-details-item">
<h3 className="text-muted">{I18n.t('dashboard.payments.royalty_rate')}</h3><div className="royalty-rate">
{this.details_royalty_rate()}<span className="cents">%</span></div></div>
<div className="col-sm-6 payment-details-item">
<h3 className="text-muted">{I18n.t('dashboard.payments.tax_info')}</h3><div>
<h5>{I18n.t('dashboard.payments.withholding_rate')}: </h5><span>
{this.details_withholding_rate()}%</span></div>
<div><h5>{I18n.t('dashboard.payments.tax_status')}: </h5><span>{this.details_tax_status()}</span></div>
</div>
  <div className="col-sm-6 payment-details-item bottom_item">
<h3 className="text-muted">{I18n.t('dashboard.payments.threshold')}</h3><div className="threshold-amount">
<h5>{I18n.t('dashboard.payments.threshold_amount')}: </h5><span>{this.details_threshold()}</span></div>
<div className="text-muted">{I18n.t('dashboard.payments.threshold_note')}</div>
</div>
<div className="col-sm-6 payment-details-item bottom_item">
<h3 className="text-muted">{I18n.t('dashboard.payments.more')}</h3><div>
<a href={gon.turbosquid_seller_url}>{I18n.t('dashboard.payments.update_information')}</a><a href={gon.turbosquid_payment_requirements_url} target="_blank">{I18n.t('dashboard.payments.learn_more_payment')}</a></div></div>
</div>
                  </div>
                </div>
              )
            }
            return <PanelSpinner />
          })()}
        </div>
      </div>
    );
  },
})

export default Payments;

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined
}
