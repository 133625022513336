import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import MyDrafts from './containers/products/MyDrafts'
import withPendingUpdates from './components/drafts/withPendingUpdates'

const MyDraftsWithPendingUpdates = withPendingUpdates(MyDrafts)

const MyDraftsApp = () => (
  <BrowserRouter>
    <Route path="/turbosquid/drafts" component={MyDraftsWithPendingUpdates} />
  </BrowserRouter>
)

export default MyDraftsApp
