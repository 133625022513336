import { Text } from 'slate'

export const serialize = (node) => {
  if (Text.isText(node)) {
    return node.text
  }

  const children = node.children.map((n) => serialize(n)).join('').replace(/\n{3,}/g, '\n\n')

  switch (node.type) {
    case 'br':
      return '\n'
    case 'productLink':
      return children
    case 'block-quote':
    case 'bulleted-list':
    case 'heading-one':
    case 'heading-two':
    case 'numbered-list':
    case 'paragraph':
      return `${children}\n`
    case 'list-item':
      return `* ${children}\n`
    default:
      return children
  }
}
