const buildPattern = (extensions) => {
  let pattern = '[ ._-]+?'
  const joinedExtensions = extensions.join('|')
  if (extensions.length > 0) {
    pattern += extensions.length === 1 ? extensions[0] : `(${joinedExtensions})`
  }
  pattern += '([ ._0-9-]|$)+?'
  return pattern
}

export const recognizeExtension = (filename, fileExtensions = [], fileTypeGroup = '3d_models') => {
  let lastOccurence = 0
  let lastExtensionId = -1

  let filteredFileExtensions = fileExtensions
  if (fileTypeGroup !== undefined) {
    filteredFileExtensions = fileExtensions.filter((fileFormat) => {
      const { media_types: mediaTypes } = fileFormat
      return mediaTypes.find((type) => type.id === fileTypeGroup)
    })
  }
  filteredFileExtensions.forEach((extension) => {
    if (lastOccurence <= filename.length - 1) {
      const { extensions } = extension
      if (extensions.length > 0) {
        let currentOccurence = -1
        const pattern = buildPattern(extensions)
        const regexp = new RegExp(pattern, 'gi')
        let match = regexp.exec(filename)
        while (match !== null) {
          currentOccurence = match.index
          match = regexp.exec(filename)
        }
        if (currentOccurence > lastOccurence) {
          lastOccurence = regexp.lastIndex
          lastExtensionId = extension.id
        }
      }
    }
  })
  return lastExtensionId
}
