import { useMutation } from 'react-query'
import * as FileService from '../services/file-service'
import { mapTypeToApiResource } from '../services/file-service'


export default function usePatchFile({draftId, productId, csrfToken }) {
  const {
    mutateAsync: patchFile,
    isLoading: isPatching
  } = useMutation(
    'patchFile',
    async (file) => FileService.patchFile({
      resource: mapTypeToApiResource(file.type),
      file,
      productId,
      draftId,
      csrfToken,
    })
  )

  return {
    isPatching,
    patchFile
  }
}
