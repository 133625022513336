$(function() {
  $('.js-stemcell-create-new-button').on('click', function(e) {
    e.preventDefault();
    $('#stem_cell_product_job_type').val($(this).attr('id'));
    $('.js-stemcell-create-new-row').hide();
    $('.js-stemcell-start-job-row').show();
  });

  $('.js-stemcell-cancel-new-button').on('click', function(e) {
    e.preventDefault();

    //clear any error messages
    $('.js-stemcell-start-job-error').html('');
    $('.js-stemcell-start-job-error').hide();

    $('#stem_cell_product_product_name').val('');
    $("#new_stem_product_submit_button").prop('disabled',false);

    $('.js-stemcell-start-job-row').hide();
    $('.js-stemcell-create-new-row').show();

    //reset product_type value
    $('#stem_cell_product_job_type').val('');
  });

  $('#new_stem_cell_product').submit(function() {
    $("#new_stem_product_submit_button").prop('disabled',true);
  });

  $('.stemcell-product').on('click', '.js-cancel-start-turbosquid-job', function(e) {
    e.preventDefault();

    var stemcell_id = $(this).data('stemcell_id');
    var placeholder = $("#turbosquid_job_" + stemcell_id + "_placeholder").html();
    var block = $("#turbosquid_job_" + stemcell_id);
    block.html(placeholder);

    var spinner = block.parent('.stemcell-product').find('.js-product-spinner');
    spinner.hide();
  });

  $('.stemcell-product').on('click', '.js-create-turbosquid-job', function(e) {
    var spinner = $(this).parent().siblings('.js-product-spinner');
    spinner.show();
  });

  $('.stemcell-product').on('click', '.js-confirm-start-turbosquid-job', function(e) {
    var spinner = $(this).closest('.stemcell-product').find('.js-product-spinner');    
    spinner.show();
  });

  $('.stemcell-product').on('click', '.js-edit-name', function(e) {
    var product_id = $(this).data('product_id');

    $('.product-' + product_id).find('.js-static-name').hide();
    $('.product-' + product_id).find('.js-editable-name').show();
  });

  $('.stemcell-product').on('click', '.js-edit-name-cancel', function(e) {
    e.preventDefault();

    var product_id = $(this).data('product_id');

    $('.product-' + product_id).find('.js-static-name').show();
    $('.product-' + product_id).find('.js-editable-name').hide();

    var old_name = $('.js-product-name-text-' + product_id).html().trim();
    $('.js-name-value-' + product_id).val(old_name);
  });
});
