/* global gon */
import Decimal from 'decimal.js'

import { LOWER_PRICE_BOUND, HIGHER_PRICE_BOUND } from '../../../products/react_components/helpers'

/**
 * Compute new price for a product
 *
 * @param  {Number} price Old price
 * @param  {String} operator Operator can be one of +, -, =
 * @param  {Number} amount The amount to increase/decrease/set to
 * @return {Number} Returns new price
 */
const computeNewPrice = (price, operator = '+', amount = 0) => {
  switch (operator) {
    case '+':
      return price.add(amount).ceil()
    case '-':
      return (price.greaterThan(amount) ? price.minus(amount).ceil() : new Decimal(0))
    case '=':
      return new Decimal(amount).ceil()
    default:
      return price
  }
}

/**
 * Round up price for a non-checkmate product
 *
 * @param price
 */
const roundUpPrice = (price) => {
  let newPrice = price
  if (price < 0) {
    newPrice = 0
  } else if (price > HIGHER_PRICE_BOUND) {
    newPrice = HIGHER_PRICE_BOUND
  }

  return new Decimal(newPrice)
}

/**
 * Compute new prices for an array of products.
 * New prices are computed using rules described by the user
 *
 * @param  {Array} products Array of products
 * @param  {Hash}  options  Hash of options that includes amount, operator and unit
 * @return {Array} Returns an array of hashes containing updated product data
 */
export const bulkPriceUpdate = (products = [], options = {}) => products.map((product) => {
  const price = new Decimal(product.price.replace(/[^\d.]/g, ''))
  const { operator } = options
  let { amount, unit } = options

  if (operator === '=' && unit !== '$') {
    unit = '$'
  }

  if (unit === '%') {
    amount = price.times(amount).dividedBy(100)
  }

    let rawPrice = computeNewPrice(price, operator, amount)
    rawPrice = roundUpPrice(parseInt(rawPrice.toFixed(0), 10))
    
    const newPrice = `$${rawPrice.toFixed(2)}`
    const priceInCents = parseInt(rawPrice.times(100).toFixed(0), 10)
    
    return { ...product, newPrice, priceInCents }
})
