/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() => {
  let remove_all_library_content_selectors
  $('.app-selector').on('click', function (event) {
    if (ga) {
      ga('send', 'event', 'button', 'click', this.id)
    }
    return window.location = gon.next_step_path
  })

  $('.prepare-scene').attr("href", gon.prepare_scene_url)
  $('.px-support').attr("href", gon.support_url)

  $('#request_application_form').on('submit', function (event) {
    if (ga) {
      ga('send', 'event', 'button', 'click', 'submit request application')
    }
    event.preventDefault()
    return $.ajax({
      type: 'POST',
      url: gon.form_url,
      data: $(this).serialize(),
      success(data) {
        refresh_request_application_form()
        return false
      },
      error(data) {
        refresh_request_application_form()
        return false
      },
    })
  })

  var refresh_request_application_form = function () {
    $('#request_application_form').parent().replaceWith($('#modal-thank-you'))
    $('#modal-thank-you').show()
  }

  $('#applicationModal').on('show.bs.modal', (event) => {
    if (ga) {
      ga('send', 'event', 'button', 'click', 'request application')
    }
  })

  $('#applicationModal .close').on('click', (event) => {
    if (ga) {
      ga('send', 'event', 'button', 'click', 'close request application')
    }
  })

  $('.help-button').on('click', function (event) {
    if (ga) {
      ga('send', 'event', 'button', 'click', `help ${this.id}`)
    }
  })

  $('.next-button').on('click', function (event) {
    if (ga) {
      ga('send', 'event', 'button', 'click', `next ${this.id}`)
    }
  })

  const selected_library_content_ids = () => $("input[name^='library_content_name_']").map(function () {
    if ($(this)) {
      $(this).val()
    }
  }).get()

  const selected_library_content = () => $("input[name^='library_content_name_']").map(function () {
    if ($(this).select2('data')) {
      return {
        id: $(this).select2('data').id,
        name: $(this).select2('data').text,
      }
    }
  }).get()

  $('#publish-product-form-submit').on('click', (event) => {
    if ($('#publish_product_uses_library_content')[0]) {
      if ($('#publish_product_uses_library_content')[0].checked) {
        if (selected_library_content().length > 0) {
          $('#publish_product_selected_parts_library_content').val(JSON.stringify(selected_library_content()))
          return true
        }
        alert(gon.must_add_library_content_message)
        return false
      }
    }
    true
  })

  let library_selector_generated_count = 0
  let library_selector_current_count = 0

  $('#publish_product_uses_library_content').on('click', function (event) {
    if (this.checked) {
      $('#add-selector-base').clone().show().attr('id', 'add-selector')
        .appendTo(this)
      return add_library_content_selector()
    }
    remove_all_library_content_selectors()
    return library_selector_current_count = 0
  })

  var add_library_content_selector = function () {
    if (library_selector_current_count >= gon.max_allowed_library_content) {
      $('#max-allow-content-message').show().appendTo($('#library-content-selectors-container .select2-container:last'))
      return
    }

    library_selector_generated_count += 1
    library_selector_current_count += 1

    $('.library-content-selector').clone().appendTo('#library-content-selectors-container').toggle()
      .attr('name', `library_content_name_${library_selector_generated_count}`)
      .removeClass('library-content-selector')
      .select2({
        minimumInputLength: gon.library_content_suggester_min_length,
        multiple: false,
        width: '60%',
        initSelection(element, cb) {
          return cb(element.val().split(',').map((i, _) => ({ id: i, text: i })))
        },

        createSearchChoice(term) {
          return null
        },

        ajax: {
          url() {
            return $(this).data('route')
          },
          dataType: 'json',
          quietMillis: gon.library_content_suggester_delay_ms,
          cache: true,
          data(term, page) {
            const request = { query: {} }
            request.query[$(this).data('field')] = term
            request.ids = () => selected_library_content_ids()
            return request
          },

          results(data) {
            return {
              results: data,
            }
          },
        },
      })
      .on('select2-selecting', (event) => add_library_content_selector())

    $('#add-selector').prependTo($('#library-content-selectors-container .select2-container:last'))

    if (library_selector_current_count > 1) {
      return $('#remove-selector-base').clone().show().attr('id', `remove-selector-${library_selector_generated_count - 1}`)
        .attr('data-selector-id', library_selector_generated_count - 1)
        .prependTo($('#library-content-selectors-container .select2-container').eq(-2))
        .on('click', function (event) {
          return remove_library_content_selector(this)
        })
    }
  }

  var remove_library_content_selector = function (selector) {
    $(`#${selector.id}`).parent().remove()
    $(`input[name='library_content_name_${$(selector).attr('data-selector-id')}']`).remove()

    library_selector_current_count -= 1
    if (library_selector_current_count < gon.max_allowed_library_content) {
      $('#max-allow-content-message').hide()
    }
  }

  return remove_all_library_content_selectors = function () {
    $('#library-content-selectors-container').empty()
    $('#max-allow-content-message').hide()
  }
})
