import { useEffect, useRef, useState } from 'react'
import Sortable from 'sortablejs'
import { ASC, DESC } from '../components/products/constants'

export default function useReorder() {
  const [order, setOrder] = useState()
  const sortableEl = useRef()
  const sortable = useRef()

  useEffect(() => {
    if (!sortable.current && sortableEl.current) {
      sortable.current = new Sortable(sortableEl.current, {
        draggable: '.reorder-enabled',
        filter: '.reorder-disabled',
        onEnd: () => {
          const sortableChildren = sortable.current.toArray()
          setOrder(sortableChildren)
        }
      })
    }
  }, [sortableEl])

  return {
    sortableEl,
    order
  }
}
