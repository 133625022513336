/* global gon */
import { CSRFToken } from './helpers'

export const featureGraphParams = ({ formState, featureState }) => {
  let newFeatureIds = featureState.features.concat(featureState.brandFeatures || []).map(
    (feature) => feature.id
  )
  if (featureState.licenseFeature) {
    // FG widget don't set jg_unidentified_origin
    newFeatureIds = newFeatureIds.concat([featureState.licenseFeature])
  }
  if (featureState.collectionFeature) {
    newFeatureIds = newFeatureIds.concat([featureState.collectionFeature])
  }
  return {
    authenticity_token: CSRFToken(),
    turbosquid_product_form: {
      draft_id: formState.draftId,
      product_name: formState.name,
    },
    feature_ids: newFeatureIds,
    missing_brand: featureState.missingBrand,
    license: formState.license,
  }
}
