/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';

const PanelSpinner = createReactClass({
  displayName: 'PanelSpinner',
  hideText() {
    return (this.props.hideText != null ? this.props.hideText.toString() : undefined) === 'true'
  },
  spin(wrapper) {
    const opts = { lines: 9, length: 5, width: 3, radius: 6, trail: 60, color: '#888', zIndex: 1000 }
    this.spinner = new Spinner(opts).spin(wrapper)

    if (!this.hideText()) {
      return $(this.spinner.el).append(`<div class='loading_text'>${I18n.t('dashboard.loading_data')}</div>`)
    }
  },
  render() {
    return <div className="loading-panel" ref={this.spin} />
  },
})

export default PanelSpinner;
