import { useSelected } from 'slate-react'
import React from 'react'
import PropTypes from 'prop-types'

const RichTextareaElement = ({ attributes, childrenNode, element }) => {
  const children = childrenNode

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol {...attributes}>
          {children}
        </ol>
      )
    case 'productLink':
      const selected = useSelected()
      const style = selected ? { boxShadow: '0 0 0 1px #B4D5FF' } : {}

      return (
        <a {...attributes} href="#product-id" style={style}>
          {children}
        </a>
      )
    default:
      return (
        <p {...attributes}>
          {children}
        </p>
      )
  }
}

RichTextareaElement.propTypes = {
  attributes: PropTypes.object.isRequired,
  childrenNode: PropTypes.any,
  element: PropTypes.object.isRequired,
}

RichTextareaElement.defaultProps = {
  childrenNode: null,
}

export default RichTextareaElement
