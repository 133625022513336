import { connect } from 'react-redux'
import ProductTitlePrice from '../../../components/publisher/ProductSpecs/ProductTitlePrice'
import { filterNotifications } from '../../../products/react_components/helpers'
import {
  selectLastActionDraftSaving,
  selectFormActionInProgress,
  selectCertifications,
  selectPublishValidationTriggered,
} from '../../../products/react_components/selectors/formSelectors'

import { updateName,
  updatePrice,
  fetchConstraints } from '../../../products/react_components/actions/form_slice_actions'
import { toggleInfo } from '../../../products/react_components/actions/publisher_ui_slice_actions'

const highlightPanel = (alerts) => (
  alerts.find((a) => a.alert_panels.find((p) => p === 'name'))
)

const mapStateToProps = (state) => {
  const { formState, uiState } = state
  const { alerts } = formState
  const { formValidationErrors, values } = formState.turbosquid_product_form

  return {
    errors: formValidationErrors,
    name: values.name,
    price: values.price,
    notifications: filterNotifications(alerts, 'name'),
    highlightPanel: highlightPanel(alerts),
    showInfo: uiState.namePanel.showInfo,
    lastActionDraftSaving: selectLastActionDraftSaving(state),
    formActionInProgress: selectFormActionInProgress(state),
    certifications: selectCertifications(state),
    isLoading: uiState.isLoading,
    publishValidationTriggered: selectPublishValidationTriggered(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateNameWithConstraints: (name, errors) => dispatch(fetchConstraints({ name, errors })),
  updateName: (name, errors) => dispatch(updateName({ name, errors, constraintsPending: true })),
  updatePrice: (price, errors) => dispatch(updatePrice({ price, errors })),
  toggleInfo: (value) => dispatch(toggleInfo({ panel: 'namePanel', value })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTitlePrice)
