/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
require('jquery.cookie')
require('bootstrap')

this.DashboardClient = {
  getInitialState() {
    return {}
  },
  url() {
    if (typeof this.endpoint === 'function') {
      return this.endpoint()
    }
    return this.props.url
  },
  componentDidMount() {
    $.ajax({
      url: this.url(),
      dataType: 'json',
      success: (response) => this.setState({ data: response }),
      error: (xhr, status, error) => this.state.error = error,
    })
    return this.attachTooltip()
  },
  componentDidUpdate() {
    return this.attachTooltip()
  },
  attachTooltip() {
    return $('[data-toggle="tooltip"]').tooltip()
  },
  reportsDisabled() {
    return gon.reports_disabled
  },
  panelChevronClass(panelID) {
    if ($.cookie(panelID)) {
      return 'fa fa-caret-down'
    }
    return 'fa fa-caret-up'
  },
  panelCollapseClass(panelID) {
    if ($.cookie(panelID)) {
      return 'collapse'
    }
    return 'collapse in'
  },
}

module.exports = this.DashboardClient
