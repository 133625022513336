import { useState } from 'react'
import { STEMCELL_CERTIFICATION } from '../components/products/constants'

export default function useCertifications({ product }) {
  const [certifications, setCertifications] = useState(product.certifications)

  const onAddStemcellCertification = (certification) => {
    setCertifications(prevCerts => ({
      ...prevCerts,
      [certification]: {
        id: certification,
        submitted: true
      }
    }))
  }

  const onRemoveStemcellCertification = (certification) => {
    const {[certification.id]: value, ...remainingCerts } = certifications
    setCertifications(() => remainingCerts)
    
  }

  return {
    certifications,
    isStemcellCertified: !!certifications[STEMCELL_CERTIFICATION],
    onAddStemcellCertification,
    onRemoveStemcellCertification,
  }
}
