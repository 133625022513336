/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import MoneyUtility from './common/money_utility';
import ChartUtility from './common/chart_utility';
import PaymentSentItem from './payments_sent_item';

const PaymentSent = createReactClass({
  displayName: 'PaymentSent',
  mixins: [MoneyUtility, ChartUtility],
  item_count() {
    if (this.props.fee > 0) {
      return 3
    }
    return 4
  },
  render() {
    const footer_i18n_key = `payment_type_note_${this.props.type.toLowerCase()}`
    return (
      <div>
        <div className="row text-center">
          <h4>
            {I18n.t('dashboard.payments.payment_sent')}
            {'\
           \
'}
            <span className="text-muted">{this.verboseDate(this.props.payment_date)}</span>
          </h4>
        </div>
        <div className="row text-center">
          <PaymentSentItem type="payment_amount" amount={this.props.amount} item_count={this.item_count()} />
          <PaymentSentItem type="withholdings_subtracted" amount={this.props.withholdings} item_count={this.item_count()} />
          <PaymentSentItem type="type" amount={this.props.type} item_count={this.item_count()} />
          {this.props.fee > 0 && <PaymentSentItem type="fee" amount={this.props.fee} item_count={this.item_count()} />}
        </div>
        <div className="row text-center"><div className="col-xs-12 panel-footnote"><span className="text-muted">{I18n.t(`dashboard.payments.${footer_i18n_key}`)}</span></div></div>
        <div className="panel-divider payments-panel-collapse collapse in" />
      </div>
    )
  },
})

export default PaymentSent;
