import React from 'react'
import DateCell from '../products/CustomTableCells/DateCell'
import DraftNameCell from './DraftNameCell'

export const defaultColumns = [
  {
    Header: 'Name',
    id: 'name',
    minWidth: 450,
    filterable: false,
    Cell: (row) => <DraftNameCell {...row.original} />, // eslint-disable-line
  },
  {
    Header: 'Date Modified',
    id: 'modified_at',
    minWidth: 200,
    accessor: 'modified_at',
    filterable: false,
    Cell: (props) => DateCell({ ...props.original, value: props.value, displayOptions: { classes: 'classic_updated_at_cell' } }),
  },
  {
    Header: 'Type',
    accessor: 'type',
    id: 'type',
    minWidth: 200,
    sortable: false,
    filterable: false,
    Cell: ({ value }) => <span className="type_name_cell">{ value }</span>, // eslint-disable-line
  },
]
