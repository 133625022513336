/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'
import ReactSpinner from '../../../products/react-spinner'
import DiscardModal from './DiscardModal'
import { getDeleteProductDraftUrl } from '../../../utils/UrlHelper'
import ValidationTriggeringButton from './ValidationTriggeringButton'

const i18nOptions = {
  scope: 'turbosquid.products.product_header',
}

const AUTOSAVE_INTERVAL = 30000

class PublisherButtonsTop extends React.Component {
  constructor(props) {
    super(props)
    this.saveDraftRef = null
    this.state = {
      showDiscardModal: false,
    }
  }

  componentDidMount() {
    const { saveDraftRef } = this
    const that = this
    const { disableDraftPendingChanges } = this.props
    this.autosaveTimeout = setInterval(() => {
      const { draftSavePendingChanges } = that.props
      if (draftSavePendingChanges) {
        this.saveDraft({ target: saveDraftRef, isCustom: true })
      }
    }, AUTOSAVE_INTERVAL)
    window.addEventListener('beforeunload', this.handleBeforeunload)
    setTimeout(() => { disableDraftPendingChanges() }, 500)
  }

  componentWillUnmount() {
    clearInterval(this.autosaveTimeout)
    window.removeEventListener('beforeunload', this.handleBeforeunload)
  }

  handleBeforeunload = (event) => {
    const e = event || window.event
    const {
      draftSavePendingChanges,
      isLoading,
      disableLeavePagePrompt,
    } = this.props
    if (
      (draftSavePendingChanges
        || isLoading)
      && !disableLeavePagePrompt) {
      e.preventDefault()
      e.returnValue = ''
    }
  }

  publishProduct = (event, stemcell ,fromTimeout = false) => {
    const { publishProduct } = this.props
    let product = {
      value: event.target.value,
      name: event.target.name,
    }

    if (stemcell) {
      product = {
        ...product,
        stemcell
      }
    }

    if (fromTimeout) {
      publishProduct(product)
    }

    if (!this.isPublishDisabled()) {
      publishProduct(product)
    }
  }

  previewDraft = () => {
    const { previewDraft } = this.props
    if (!this.isPreviewDisabled()) {
      previewDraft()
    }
  }

  saveDraft = (event) => {
    const { saveDraft: saveDraftProp } = this.props
    if (!this.isSaveDraftDisabled()) {
      if (event && !event.isCustom) {
        event.preventDefault()
      }
      saveDraftProp(event.target)
      if (gon.product.id <= 0) {
        window.removeEventListener('beforeunload', this.handleBeforeunload)
        window.addEventListener('beforeunload', this.handleBeforeunload)
      }
    }
  }

  isSaveDraftDisabled = () => {
    const { isLoading, draftSavePendingChanges } = this.props
    return isLoading || !draftSavePendingChanges
  }

  isPublishDisabled = () => {
    const { validationPassed } = this.props
    return !validationPassed.status || this.hasInProcessTask()
  }

  isPreviewDisabled = () => {
    const { draftId } = this.props
    return (Number(draftId) <= 0) || this.hasInProcessTask()
  }

  displayDiscardModal = () => {
    this.setState({ showDiscardModal: true })
  }

  hideDiscardModal = () => {
    this.setState({ showDiscardModal: false })
  }

  handleDiscardDraft = () => {
    const {
      discardDraft,
      draftId,
    } = this.props
    discardDraft(getDeleteProductDraftUrl(draftId))
    this.setState({ showDiscardModal: false })
  }

  hasInProcessTask = () => {
    const {
      isLoading,
    } = this.props
    return isLoading
  }

  render = () => {
    const {
      isLoading,
      draftId,
      checkmate,
      oldCheckmate,
      stemcell,
      draftSaving
    } = this.props
    const isPublishDisabled = this.isPublishDisabled()
    const isSaveDraftDisabled = this.isSaveDraftDisabled()
    const isPreviewDisabled = this.isPreviewDisabled()
    const { showDiscardModal } = this.state
    return (
      <div className="pull-right action-btns top-btns">
        <p className="autosave-message">
          <button type="button" className="btn btn-default btn-discard" disabled={!draftId} onClick={this.displayDiscardModal}>
            {I18n.t('discard_draft', i18nOptions)}
          </button>
          <DiscardModal
            show={showDiscardModal}
            hideModal={this.hideDiscardModal}
            discardDraft={this.handleDiscardDraft}
          />
          <button
            type="button"
            name="commit"
            className={`btn btn-default btn-save ${isSaveDraftDisabled ? 'disabled' : ''}`}
            disabled={isSaveDraftDisabled}
            ref={(input) => { this.saveDraftRef = input }}
            onClick={this.saveDraft}
          >
            {I18n.t('save_draft', i18nOptions)}
          </button>
          <a href={gon.info_publishing_products_on_TurboSquid} target="_blank" rel="noopener noreferrer" className="publishing-link-holder">
            <i className="fa fa-question-circle" title={I18n.t('learn_about_publishing', i18nOptions)} />
          </a>
        </p>
        <div className="action-buttons-wrapper">
          <ValidationTriggeringButton
            type="submit"
            name="publish"
            className={`btn btn-default btn-action-top btn-publish ${isPublishDisabled ? 'disabled' : ''}`}
            disabled={isPublishDisabled}
            onClick={(e) => this.publishProduct(e, stemcell)}
            toggle={isLoading ? I18n.t('publish_changes_disabled', i18nOptions) : ''}
          >
            {I18n.t('publish', i18nOptions)}
          </ValidationTriggeringButton>
          {
            (isLoading || draftSaving)
            && (
              <div className="col-sm-1 spinner-container pull-right">
                <ReactSpinner />
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

PublisherButtonsTop.propTypes = {
  isLoading: PropTypes.bool,
  saveDraft: PropTypes.func.isRequired,
  publishProduct: PropTypes.func.isRequired,
  draftSavePendingChanges: PropTypes.bool,
  draftId: PropTypes.string,
  changesToFieldsWithTermConstraints: PropTypes.bool,
  previewDraft: PropTypes.func.isRequired,
  disableDraftPendingChanges: PropTypes.func,
  disableLeavePagePrompt: PropTypes.bool,
  discardDraft: PropTypes.func.isRequired
}

PublisherButtonsTop.defaultProps = {
  isLoading: false,
  draftSavePendingChanges: false,
  draftId: null,
  changesToFieldsWithTermConstraints: false,
  disableDraftPendingChanges: null,
  disableLeavePagePrompt: false,
}

export default PublisherButtonsTop
