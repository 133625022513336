/* global I18n, gon */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'seamless-immutable'
import Icon from '../../../products/react_components/icon'
import FeatureWidget from '../FeatureAssignmentWidget/FeatureWidget'
import { mediaTypeDropdownData } from '../../../products/products_lookups'
import { infoIconClasses, getErrors } from '../../../products/react_components/helpers'
import FieldErrors from '../../../products/react_components/form_components/field_errors'
import { ProductContext } from '../../../contexts/ProductContext'

const i18nOptions = {
  scope: 'turbosquid.products.product_categories_panel',
}

const panelPopoverHTML = () => (
  I18n.t('panel_popover.html', {
    ...i18nOptions,
    categories_faq_url: gon.categories_faq_url,
    how_to_use_feature_graph_tool_url: gon.how_to_use_feature_graph_tool_url,
  })
)

const panelClasses = (highlight) => {
  if (highlight) {
    return 'panel panel-info categories-panel'
  }
  return 'panel panel-default categories-panel'
}

const ProductCategories = (props) => {
  const {
    updateFeatures,
    highlight_panel: highlightPanel,
    showInfo,
    features,
    toggleInfo,
    mediaType,
    errors,
    formActionInProgress,
    updateFeaturesWithConstraints,
    categoryPage,
    updateCollection,
    collectionFeature,
    updateLicense,
  } = props
  const context = useContext(ProductContext)
  const syncOnChange = context?.syncFields?.syncOnChange
  const syncFields = context?.syncFields?.fields
  const collectionData = context?.syncFields?.collectionFeature
  const handleSyncFeatures = syncOnChange
    ? (newFeatures) => {
      syncOnChange({
        name: 'features',
        value: newFeatures
      })
    }
    : () => {}
  const mutableFeatures = Immutable.asMutable(features)
  if (mediaType === mediaTypeDropdownData[0].id) {
    return (
      <div className={panelClasses(highlightPanel)}>
        <div className="panel-heading">
          <button type="button" data-toggle="collapse" data-target="#categories-panel-instructions" tabIndex="-1" onClick={() => toggleInfo(!showInfo)}>
            {I18n.t('categories', i18nOptions)}
            <Icon type={infoIconClasses(showInfo)} tabIndex="-1" />
          </button>
        </div>
        <div className="panel-body panel-collapse collapse in" id="categories-panel-body">
          <div className="panel-body-container">
            <div
              id="categories-panel-instructions"
              className={showInfo ? 'panel-instructions collapse in' : 'panel-instructions collapse'}
              dangerouslySetInnerHTML={{ __html: panelPopoverHTML() }}
            />
            <FeatureWidget
              features={mutableFeatures}
              updateFeatures={updateFeatures}
              formActionInProgress={formActionInProgress}
              updateFeaturesWithConstraints={updateFeaturesWithConstraints}
              categoryPage={categoryPage}
              updateCollection={updateCollection}
              collectionFeature={collectionFeature}
              updateLicense={updateLicense}
              syncFeatures={handleSyncFeatures}
              syncFields={syncFields}
              collectionData={collectionData}
            />
            {
              getErrors(errors).featureIds
              && <FieldErrors errors={getErrors(errors).featureIds} classNames="text-error align-left-text-red" />
            }
          </div>
        </div>
      </div>
    )
  }
  return null
}

ProductCategories.propTypes = {
  highlight_panel: PropTypes.object,
  features: PropTypes.array,
  showInfo: PropTypes.bool,
  updateFeatures: PropTypes.func,
  updateFeaturesWithConstraints: PropTypes.func,
  toggleInfo: PropTypes.func.isRequired,
  mediaType: PropTypes.number.isRequired,
  errors: PropTypes.object,
  formActionInProgress: PropTypes.string,
  categoryPage: PropTypes.bool,
  updateCollection: PropTypes.func.isRequired,
  collectionFeature: PropTypes.number,
  updateLicense: PropTypes.func.isRequired,
}

ProductCategories.defaultProps = {
  highlight_panel: {},
  updateFeatures: null,
  updateFeaturesWithConstraints: null,
  features: [],
  showInfo: false,
  errors: {},
  formActionInProgress: '',
  categoryPage: false,
  collectionFeature: 0,
}

export default ProductCategories
