/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'

const i18nOptions = {
  scope: 'turbosquid.products.product_images_panel',
}

const SearchImagePlaceholder = ({ children }) => (
  <React.Fragment key="">
    <div className="col-sm-6 col-md-4 col-lg-3 file-content image signature-image preview-aspect-ratio-square search-image-placeholder" data-type="thumbnail" style={{ zIndex: 999 }}>
      <p className="signature">
        { I18n.t('search_image', i18nOptions) }
      </p>
      <div className="file-container thumbnail">
        <div className="preview-data">
          <div className="preview-wrapper thumbnail" style={{ cursor: 'not-allowed' }} />
        </div>
      </div>
    </div>
    {children}
  </React.Fragment>
)

SearchImagePlaceholder.propTypes = {
  children: PropTypes.any,
}

SearchImagePlaceholder.defaultProps = {
  children: null,
}

export default SearchImagePlaceholder
