import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Uploader, { TURBOSQUID_API_ADAPTER } from '../lib/squid-uploads-sdk';
import { getProcess } from '../services/upload-service'
import {
  ASSOCIATED, CANCELED, CUSTOMER,
  NATIVE,
  PENDING,
  PREVIEW, PROMOTIONAL,
  QUEUED, SUCCESS,
  terminalUploadStatus,
  TEXTURE, THUMBNAIL, TURNTABLE, VIEWER
} from '../components/products/constants'
import {
  filterStatus,
  filterType,
  filterErrored,
  filterTurntableCandidate, filterThumbnails, count, partOfSet,
} from '../utils/ProductFiles'
import * as UploadService from '../services/upload-service'
import useInterval from './useInterval'
import {  isEqual } from 'lodash'
import Bottleneck from 'bottleneck'
import { useMutation } from 'react-query'

const PROCESS_POLL_INTERVAL = 5000;

export default function useUploads(endpoint, csrfToken) {
  const [credentials, setCredentials] = useState();
  const [allUploads, setUploads] = useState({});
  const limiter = useRef(
    new Bottleneck({
      maxConcurrent: 3,
      minTime: 500,
    })
  );
  const canceled = useRef({})
  const uploader = useRef(
    new Uploader({
      endpoint,
      csrfToken,
      adapter: TURBOSQUID_API_ADAPTER,
    })
  );
  const lastPoll = useRef([])
  const pending = filterStatus(allUploads, PENDING)
  const queued = filterStatus(allUploads, QUEUED)
  const queuedIds = Object.keys(queued)

  const {
    mutateAsync: getCredentials
  } = useMutation(
    'getCredentials',
    () => uploader.current.getCredentials()
  )

  const {
    mutateAsync: getProcess
  } = useMutation(
    'getProcess',
    ({ ids, csrfToken }) => UploadService.getProcess({ ids, csrfToken })
  )

  useEffect(() => {
    getCredentials(undefined)
      .then((credentials) => setCredentials(credentials))
  }, []);

  const cancelUploads = (ids) => {
    canceled.current = ids.reduce((acc, id) => ({
      ...acc,
      [id]: true
    }), canceled.current)
    ackUploads(ids)
  }

  const initUpload = (upload) => {
    const { file, type, attributes } = upload
    const key = `${Date.now()}-${file.name}`;
    setUploads((prevUploads) => ({
      ...prevUploads,
      [key]: {
        type,
        file,
        attributes,
        managedUpload: {
          abort: () => cancelUploads([key])
        },
        status: PENDING,
      }
    }));
    return { key, upload }
  }

  const upload = async (key, { file, type, attributes }) => {
    if (canceled.current[key]) {
      // Skip cancelled uploads
      return Promise.resolve()
    }
    const { managedUpload, process } = uploader.current.upload(
      file,
      (event) => {
        // Update upload on progress
        setUploads((prevUploads) => {
          // Ignore progress for canceled uploads
          if (!prevUploads[key]) {
            return prevUploads
          }
          return {
            ...prevUploads,
            [key]: {
              ...prevUploads[key],
              loaded: event.loaded,
              total: event.total,
            }
          }
        });
      },
      {
        ...attributes,
        resource: UploadService.mapTypeToApiResource(type)
      },
    );
    // Add managed upload with abort handler
    setUploads((prevUploads) => ({
      ...prevUploads,
      [key]: {
        type,
        file,
        attributes,
        status: PENDING,
        managedUpload
      }
    }));

    try {
      const uploaded = await process
      // Update finished upload
      setUploads((prevUploads) => {
        const { [key]: value, ...rest } = prevUploads
        return value ? {
          ...rest,
          [uploaded.id]: {
            type,
            file,
            attributes: {
              ...value.attributes,
              ...uploaded.attributes
            },
            status: QUEUED
          }
        } : rest
      });
    } catch (e) {
      if (e.message.includes('Request aborted')) {
        // Remove cancelled upload
        setUploads((prevUploads) => {
          const { [key]: _, ...rest } = prevUploads
          return rest
        });
      } else {
        // Update failed upload
        setUploads((prevUploads) => {
          return {
            ...prevUploads,
            [key]: {
              ...prevUploads[key],
              status: CANCELED
            }
          }
        });
      }
    }
  };

  const uploadMultiple = async (files) => {
    const initiated = files.map(initUpload)
    await Promise.all(
      initiated.map(({ key, upload: file }) => limiter.current.schedule(() => upload(key, file)))
    )
  }

  const pollProcess = async (ids) => {
    const data = await getProcess({
      ids,
      csrfToken
    });
    const completed = data.reduce((acc, upload) => {
      if (queued[upload.id]
        && terminalUploadStatus.includes(upload.attributes.status)
      ) {
        const { type, attributes } = queued[upload.id]
        return {
          ...acc,
          [upload.id]: {
            type,
            attributes: {
              ...attributes,
              ...upload.attributes
            },
            status: upload.attributes.status
          }
        }
      } else {
        return acc
      }
    }, {})
    setUploads((prevUploads) => ({
      ...prevUploads,
      ...completed,
    }));
    return data.map(u => u.id)
  };

  useInterval(() => {
    if (!partOfSet(lastPoll.current, queuedIds) && !count(pending)) {
      lastPoll.current = queuedIds
      pollProcess(queuedIds)
        .then(() => lastPoll.current = [])
    }
  }, !!queuedIds.length ? PROCESS_POLL_INTERVAL : null)

  const ackUploads = (ids) => {
    setUploads(prevUploads => {
      const i = ids.reduce((remainingUploads, id) => {
        const { [id]: _, ...rest } = remainingUploads
        return rest || {}
      }, prevUploads)
      return i
    })
  }

  const uploads = useMemo(() => ({
    [NATIVE]: filterType(allUploads, NATIVE),
    [PREVIEW]: filterType(allUploads, PREVIEW),
    [THUMBNAIL]: filterThumbnails(allUploads, [THUMBNAIL]),
    [TURNTABLE]: filterTurntableCandidate(allUploads),
    [ASSOCIATED]: filterType(allUploads, [
      TEXTURE,
      PROMOTIONAL,
      CUSTOMER,
      VIEWER
    ]),
    [TEXTURE]: filterType(allUploads, TEXTURE),
  }), [allUploads])

  const errored = useMemo(() => ({
    [NATIVE]: filterErrored(uploads[NATIVE]),
    [THUMBNAIL]: filterErrored(uploads[THUMBNAIL]),
    [TEXTURE]: filterErrored(uploads[TEXTURE]),
  }), [uploads])

  const uploadsInProgress = count(pending) + count(queued) > 0

  return {
    getProcess,
    credentials,
    upload: uploadMultiple,
    ackUploads,
    all: allUploads,
    uploads,
    errored,
    uploadsInProgress,
    cancelUploads
  };
};
