/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('click', 'a[href="#"], a[disabled]', (event) => event.preventDefault())
$(document).on('click', (event) => $('[data-toggle="popover"]').each(function () {
  if (!$(this).is(event.target) && $(this).has(event.target).length === 0 && $('.popover').has(event.target).length === 0) {
    $(this).popover('hide')
  } else {
    $(this).popover('show')
  }
}))
