import { connect } from 'react-redux'
import ProductDraftID from '../product_draft_id'

const mapStateToProps = ({ formState }) => ({
  draftId: formState.draftId || '',
})

export default connect(
  mapStateToProps
)(ProductDraftID)
