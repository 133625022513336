/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { productEditPageUrl, product3DPageUrl, productPageUrl } from '../helpers'

const ActionCell = (props) => {
  const { turbosquid_id: turbosquidId, pendingProductIds, media_type, bundle: isBundle, seo_url: seo_url } = props
  const editIcon = <i className="fa fa-edit" data-toggle="tooltip" title={I18n.t('turbosquid.products.edit_product')} />
  const tooltipIcon = <i className="fa fa-question" data-toggle="tooltip" title={I18n.t('turbosquid.products.non_3d_tooltip')} />
  const is3dProduct = media_type === "3d_model"
  const editURL = isBundle ? `${gon.turbosquid_products_path}/${turbosquidId}/edit_bundle_product` : productEditPageUrl(turbosquidId)
  let actionHtml
  if (pendingProductIds && pendingProductIds.includes(turbosquidId)) {
    actionHtml = (
      <span className="action-icon">
        <i className="fa fa-eye" data-toggle="tooltip" title={I18n.t('turbosquid.products.preview_product')} />
        {is3dProduct ? editIcon : tooltipIcon}
      </span>
    )
  } else {
    actionHtml = (
      <span className="action-icon">
        
        <a href={seo_url && seo_url.length > 0 ? product3DPageUrl(seo_url) : productPageUrl(turbosquidId)} target="_blank" rel="noopener noreferrer">
          <i className="fa fa-eye" data-toggle="tooltip" title={I18n.t('turbosquid.products.preview_product')} />
        </a>
        <a
          href={is3dProduct ? editURL : "/"}
          onClick={is3dProduct ? () => {} : e => e.preventDefault()}
          rel="noopener noreferrer"
        >
          {is3dProduct ? editIcon : tooltipIcon}
        </a>
      </span>
    )
  }

  return actionHtml
}

ActionCell.propTypes = {
  turbosquid_id: PropTypes.string.isRequired,
  pendingProductIds: PropTypes.array,
}

ActionCell.defaultProps = {
  pendingProductIds: [],
}

export default ActionCell
