import { publisherUISlice } from '../reducers/publisher_ui_slice'

export const {
  syncDraft,
  publishProduct,
  toggleInfo,
  saveCredentials,
  saveFileFormats,
  resetNotificationsOnPublishAttempt,
  fileValidate,
  togglePanel,
  notificationTurntableNotValid,
  clearNotificationTurntableNotValid,
  turntableCount,
  removeTurntableFromUploader,
  clearUploadFilename,
  saveTurntableFiles,
  turntableCapReached,
  clearDuplicateUploadFile,
  duplicateUploadFile,
  invalidTurntableName,
  invalidFileSize,
  resetLoading,
  toggleOverlay,
  setFileUploaderError,
  removeFileUploaderError,
  setDataValidationState,
  clearDuplicateUploadPendingUndoFile,
  duplicateUploadPendingUndoFile,
  hideDuplicateUploadPendingUndoFile,
  resetDuplicatePendingUndoFileAlertUpdateNow,
} = publisherUISlice.actions
