import { useEffect } from 'react'

export default function useConfirmLeave(wait) {

  const handleBeforeunload = (event) => {
    if (wait) {
      event.preventDefault()
      event.returnValue = ''
    }
  }

  useEffect(() => {
    if (wait) {
      window.addEventListener('beforeunload', handleBeforeunload)
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
  }, [wait])
}
