/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import NumberUtility from './common/number_utility';
import PaymentItem from './payments_payment_item';

const PaymentPrevious = createReactClass({
  displayName: 'PaymentPrevious',
  mixins: [NumberUtility],
  render() {
    return (
      <div>
        <div className="row text-center"><h4>{I18n.t('dashboard.payments.previous_payments')}</h4></div>
        <div className="row text-center">
          {this.props.payments.map((payment, index) => <PaymentItem key={this.randomKey(payment.paid_at)} payment={payment} index={index} />)}
        </div>
      </div>
    )
  },
})

export default PaymentPrevious;
