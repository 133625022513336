/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const PaymentUtility = {
  nextPaymentDate(lastPaymentDate) {
    if (lastPaymentDate != null) {
      const last = moment(lastPaymentDate, moment.ISO_8601).utcOffset(-360);
      const now = moment(new Date())
      if (last.month() !== now.month()) {
        const payDate = moment(now.format('YYYY-MM-15'))
        if (payDate.day() >= 6) {
          const offset = 8 - payDate.day()
          payDate.add(offset, 'days')
        }
        return payDate
      }
    }
  },

  afterPaymentDate(lastPaymentDate) {
    return moment(new Date()) >= this.nextPaymentDate(lastPaymentDate)
  },
}

export default PaymentUtility;
