/* global gon, I18n */
import React from 'react'
import PropTypes from 'prop-types'
import IdleTimer from 'react-idle-timer'

import DraftAlertContainer from '../../containers/publisher/EditHeader/DraftAlertContainer'
import ProductDraftIDConnected from './connected/product_draft_id_connected'
import ProductHeaderEditFormContainer from '../../containers/publisher/EditHeader/ProductHeaderEditFormContainer'
import PublisherNotificationsContainer from '../../containers/publisher/EditHeader/PublisherNotificationsContainer'
import ProductTitlePriceContainer from '../../containers/publisher/ProductSpecs/ProductTitlePriceContainer'
import ProductCertificationContainer from '../../containers/publisher/ProductSpecs/ProductCertificationContainer'
import ProductLicenseContainer from '../../containers/publisher/ProductSpecs/ProductLicenseContainer'
import ProductCategoriesContainer from '../../containers/publisher/ProductSpecs/ProductCategoriesContainer'
import ProductTagsContainer from '../../containers/publisher/ProductSpecs/ProductTagsContainer'
import ProductDescriptionContainer from '../../containers/publisher/ProductSpecs/ProductDescriptionContainer'
import ProductSpecifications3dModelContainer from '../../containers/publisher/ProductSpecs/ProductSpecifications3dModelContainer'
import StemcellCertificationContainer from '../../containers/publisher/ProductSpecs/StemcellCertificationContainer'
import { IdleTimeOutModal } from './IdleTimeOutModal'
import SpinnerOverlay from './SpinnerOverlay'
import ProductFilesPanel from '../../components/publisher/ProductFiles/ProductFilesPanel'
import Providers from './Providers'
import { QueryClient, QueryClientProvider } from 'react-query'
import {StemCellModal} from "../../components/publisher/StemCellModal";

const TIMEOUT_SESSION_INTERVAL = 3600000
const queryClient = new QueryClient()

const Publisher = () => (
  <QueryClientProvider client={queryClient}>
    <Providers gon={gon}>
      <div className="row main-form-wrapper new-opp">
        <div className="product-header-wrapper">
          <div id="js-turbosquid-product-header">
            <ProductHeaderEditFormContainer />
            <div className="row edit-header">
              <div className="col-sm-12 PublisherAlerts">
                <DraftAlertContainer />
                <PublisherNotificationsContainer />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 panes-wrapper">
          <div className="col-xs-12 col-sm-8 opp-left-pane">
            <ProductFilesPanel />
          </div>
          <div className="col-xs-12 col-sm-4 opp-right-pane">
            <div className="opp-right-pane-wrapper">
              <StemcellCertificationContainer/>
              <ProductTitlePriceContainer />
              <ProductCertificationContainer />
              <ProductSpecifications3dModelContainer />
              <ProductDescriptionContainer />
              <ProductTagsContainer />
              <ProductCategoriesContainer />
              <ProductLicenseContainer />
            </div>
          </div>
        </div>
      </div>
      <StemCellModal />
    </Providers>
  </QueryClientProvider>
)

class PublisherForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { idle: false }
    this.idleTimer = null
  }

  handleOnIdle = () => {
    this.setState({ idle: true })
  }

  handleStay = () => {
    this.setState({ idle: false })
  }

  render = () => {
    const { idle } = this.state
    const { showOverlay } = this.props

    return (
      <div
        id="js-edit-turbosquid-product-form"
        className="edit_turbosquid_product_form"
      >
        <IdleTimer
          ref={(ref) => { this.idleTimer = ref }}
          timeout={TIMEOUT_SESSION_INTERVAL}
          onIdle={(e) => this.handleOnIdle(e)}
        />
        <ProductDraftIDConnected />
        <Publisher />
        {
          idle
          && (
            <IdleTimeOutModal
              handleStay={this.handleStay}
            />
          )
        }
        { showOverlay && (
          <SpinnerOverlay>
            { I18n.t('turbosquid.products.product_files_panel.processing') }
          </SpinnerOverlay>
        )}
      </div>
    )
  }
}

PublisherForm.propTypes = {
  getInitialTermsConstraints: PropTypes.func.isRequired,
  getFileFormats: PropTypes.func.isRequired,
}

export default PublisherForm
