/* global gon, I18n */
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string'
import { indexI18nOptions } from './constants'

export const safeTranslate = (key) => {
  const result = I18n.t(key, indexI18nOptions)
  if (/\[missing/.test(result)) {
    return key
  }
  return result
}

export const currentSelection = (key, search) => {
  const filterState = queryString.parse(search)
  return filterState[key] || 'All'
}

export function encodeQuery(object) {
  const filteredObject = {}
  Object.keys(object).forEach((key) => {
    if (object[key].toString().toLowerCase() !== 'all') {
      filteredObject[key] = object[key]
    }
  })
  return `?${queryString.stringify(filteredObject)}`
}

export const handleErrors = (response) => {
  if (!response.ok) {
    const i18nErrorOptions = { error: response.statusText }
    const message = I18n.t('turbosquid_products.messages.failed_request', i18nErrorOptions)
    throw new Error(message)
  }

  return response
}

export const flattenArray = (array = []) => (
  array.reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
)

export const buildUrlParams = (key, value) => {
  let url = ''
  const term = key
  if (value.length === 0) return url
  url += `&${term}=${encodeURIComponent(value)}`
  return url
}

export const productPageUrl = (id) => `${gon.turbosquid_product_root}${id}`
export const product3DPageUrl = (seo_url) => `${gon.turbosquid_site_url}/${seo_url}`
export const productEditPageUrl = (id) => `${gon.turbosquid_products_path}/${id}/edit`
export const productEditCategoryUrl = (id) => `${gon.turbosquid_products_path}/${id}/edit`
export const assetManagerUrl = (id) => (
  `${gon.asset_manager_url}Index.cfm?stgAction=getProduct&intType=4&intProductId=${id}`
)

export const cookie = {
  getCookie(key) {
    const data = document.cookie
    let startIndex = data.indexOf(`${key}=`)

    if (startIndex > -1) {
      startIndex = startIndex + key.length + 1
      let endIndex = data.indexOf(';', startIndex)
      endIndex = endIndex < 0 ? data.length : endIndex
      return decodeURIComponent(data.substring(startIndex, endIndex))
    }
    return ''
  },

  setCookie(key, value, days, path) {
    const cur = new Date()
    cur.setTime(cur.getTime() + days * 24 * 60 * 60 * 1000)
    document.cookie = `${key}=${encodeURIComponent(value)};expires=${days === undefined ? '' : cur.toUTCString()};path=${path === undefined ? '' : path}`
  },

  delCookie(key) {
    const data = this.getCookie(key)
    if (data !== false) {
      this.setCookie(key, data, -1)
    }
  },
}

export const getDownloadUrl = ({ productId, file }) => (
  `${gon.turbosquid_site_url}/Download/${productId}_${file.id}`
)

export const getFileExtension = (fileName) => {
  const names = fileName.toLowerCase().split('.')
  if (names.length  === 1) return 'unknown'
  return names.pop()
}