import {
  CERTIFIED_PREVIEWS,
  MIN_PREVIEW_HEIGHT,
  MIN_PREVIEW_WIDTH, MIN_TURNTABLE_HEIGHT, MIN_TURNTABLE_WIDTH, NO,
  REGULAR, TURNTABLE, UNKNOWN, UV, WIREFRAME
} from '../components/products/constants'
import { useContext } from 'react'
import { ProductContext } from '../contexts/ProductContext'
import { count, filterMinDimensions, filterThumbnailType, filterType, hasMarmosetFile } from '../utils/ProductFiles'
import { hasPreviewOverrideFeature } from '../components/publisher/ProductFiles/ProductFilesNumberValidations'

export default function usePreviewSpecs({
  searchPreview,
  previews,
  associated,
  overridePreviewRequirements,
}) {
  const {
    syncFields,
    certifications: {
      certifications
    },
    collectionTaxonomyId,
    legacyProductDate,
    product: {
      createdAt
    }
  } = useContext(ProductContext)

  const regular = filterThumbnailType(previews, [REGULAR])
  // Do not require min dimensions for previews that are part of old products
  const previewCandidates = createdAt > legacyProductDate
    ? filterMinDimensions(regular, MIN_PREVIEW_WIDTH, MIN_PREVIEW_HEIGHT)
    : regular
  const allTurntables = filterType(previews, [TURNTABLE])
  const turntables = filterMinDimensions(allTurntables, MIN_TURNTABLE_WIDTH, MIN_TURNTABLE_HEIGHT)
  const wireframes = filterThumbnailType(previews, [WIREFRAME])
  const uvs = filterThumbnailType(previews, [UV])

  const { features, uvMapped, unwrappedUVs } = syncFields.fields
  const isCollection = !!features.find(f => f.id === collectionTaxonomyId)
  const certifiedPreviewsEnabled = certifications[CERTIFIED_PREVIEWS]
  // Do not require turntables or search previews for old products
  const turntableRequired = (() => {
    if (overridePreviewRequirements || createdAt <= legacyProductDate) {
      return false
    }
  })()
  const searchPreviewRequired = isCollection
    && (!overridePreviewRequirements && createdAt > legacyProductDate)
  const wireframeRequired = createdAt > legacyProductDate
  const uvRequired =
    uvMapped
    && ![UNKNOWN, NO].includes(unwrappedUVs)

  const previewCount = count(previewCandidates)
  const wireframeCount = count(wireframes)
  const uvCount = count(uvs)
  const turntableCount = count(turntables) + (hasMarmosetFile(associated) ? 1 : 0)
  const searchPreviewCount = !!searchPreview ? 1 : 0

  // A certified preview certification will be added to the product
  // automatically in the backend if certain conditions are met.
  // This "active" boolean should be true if the product is expected
  // to be certified.
  const certifiedPreviewsActive = (!!searchPreview && turntableCount) || overridePreviewRequirements

  return {
    isCollection,
    certifiedPreviewsActive,
    certifiedPreviewsEnabled,
    turntableRequired,
    searchPreviewRequired,
    wireframeRequired,
    uvRequired,
    previewCount,
    wireframeCount,
    uvCount,
    turntableCount,
    searchPreviewCount
  }
}
