/* global I18n */
import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { ASSOCIATED, filesI18nOptions, NATIVE, PREVIEW, PRODUCT_FILES_PANEL } from '../../products/constants'
import { UploadContext } from '../../../contexts/UploadContext'
import { FileContext } from '../../../contexts/FileContext'
import NativeFiles from './NativeFiles'
import UploadsProgress from '../../../components/publisher/ProductFiles/UploadsProgress'
import PreviewFiles from './PreviewFiles'
import AssociatedFiles from './AssociatedFiles'
import UndoDeleteNotification from './UndoDeleteNotification'
import {
  count,
  fileErrors, nativeErrors,
  previewErrors,
} from '../../../utils/ProductFiles'
import { useFormContext } from 'react-hook-form'
import { UIContext } from '../../../contexts/UIContext'
import { NotificationContext } from '../../../contexts/NotificationContext'
import { useSelector } from 'react-redux'

export default function ProductFilesPanel() {
  const { currentTab, setCurrentTab } = useContext(UIContext)
  const { files } = useContext(FileContext)
  const { uploads } = useContext(UploadContext)
  const { clearNotifications, notifications } = useContext(NotificationContext)
  const { formState: { errors: formErrors } } = useFormContext()
  const displayAlerts = useSelector(state => state.formState.displayAlerts)

  const errors = {
    [NATIVE]: nativeErrors(formErrors, files[NATIVE]),
    [PREVIEW]: previewErrors(formErrors),
    [ASSOCIATED]: fileErrors(formErrors, files[ASSOCIATED]),
  }

  const titles = {
    [NATIVE]: I18n.t('files', filesI18nOptions),
    [PREVIEW]: I18n.t('images', filesI18nOptions),
    [ASSOCIATED]: I18n.t('textures_and_other_files', filesI18nOptions),
  }

  const tabs = Object.keys(titles).reduce((acc, key) => {
    const fileCount = count(files[key])
    const hasErrors = errors[key].length
    const inParens = [
      ...(fileCount ? [fileCount] : []),
      ...(hasErrors ? ['!'] : [])
    ].join(' ')
    const text = inParens ? `${titles[key]} (${inParens})` : titles[key]
    const classes = [
      hasErrors ? 'has-error' : '',
      uploads[key].length ? 'uploading' : ''
    ].join(' ')
    const el = <span className={classes}>{text}</span>
    return { ...acc, [key]: el }
  }, {})

  // Clear notifications when changing tabs
  useEffect(() => {
    if (notifications.length) {
      clearNotifications()
    }
  }, [currentTab])

  return (
    <div className="panel panel-default files-panel files-panel-container">
      <Tabs
        id={PRODUCT_FILES_PANEL}
        activeKey={currentTab}
        onSelect={setCurrentTab}
      >
        <Tab eventKey={NATIVE} title={tabs.native} className={displayAlerts ? 'with-alert' : ''}>
          <NativeFiles />
        </Tab>
        <Tab eventKey={PREVIEW} title={tabs.preview} className={displayAlerts ? 'with-alert' : ''}>
          <PreviewFiles />
        </Tab>
        <Tab eventKey={ASSOCIATED} title={tabs.associated} className={displayAlerts ? 'with-alert' : ''}>
          <AssociatedFiles />
        </Tab>
      </Tabs>
      <UndoDeleteNotification />
      <UploadsProgress />
    </div>
  )
}
