import React, { useContext, useEffect } from 'react'
import usePreviews from '../../hooks/usePreviews'

import Dropzone from '../../components/publisher/ProductFiles/Dropzone'
import Notification from '../../components/publisher/ProductFiles/Notification'
import { filesI18nOptions } from '../../components/products/constants'
import { acceptImageExtensions } from '../../utils/ImageValidations'
// import { count } from '../../../utils/ProductFiles'
import PreviewFile from '../../components/publisher/ProductFiles/PreviewFile'
import CertifiedPreviews from '../../components/publisher/ProductFiles/CertifiedPreviews'
import PreviewSpecs from '../../components/publisher/ProductFiles/PreviewSpecs'
import BundlesCertifiedPreviews from '../../components/publisher/ProductFiles/BundlesCertifiedPreviews'


export default function CollectionPreviewFiles() {
  const {
    previewSpecs,
    sortableEl,
    thumbnailInputRef,
    handleThumbnailInput,
    disabled,
    notifications,
    clear,
    allPreviews,
    orderedPreviews
  } = usePreviews()

  return (
    <div className="panel panel-default images-panel files-panel image-files product-image-files-container">
      <div
        id={'product-images-container' /* used for testing */}
        className="panel-body panel-collapse collapse in panel-files-wrapper panel-image-files preview-container">
        <div className="panel-body-container">
          <PreviewSpecs {...previewSpecs} bundles />
          <BundlesCertifiedPreviews />
          {/* <CollectionPreviewValidations {...previewSpecs} /> */}
          <Dropzone className="dzu-dropzone" disabled={disabled} onChange={handleThumbnailInput}>
            <div className="image-files-wrapper upload-area col-sm-12 col-xs-12">
              <div className="tab-alert-container">
                {notifications.map(n =>
                  <Notification key={n.message} notification={n} clear={clear} />
                )}
              </div>
              <div className="product-images-draft">
                <div className="uploader-sort-holder">
                  <div className="full-size-uploader-no-cloud">
                    <div className="upload-files-label">
                      <div className="upload-files-label-title multi-buttons">
                      <span className="bundles-browse-button-holder">
                        <span className="drag-files-label">{I18n.t('drag_preview_files_here_or', filesI18nOptions)}</span>
                        <label
                          className={`browse-files-link ${disabled ? 'disabled' : ''}`}
                          htmlFor="preview-input">
                          {I18n.t('browse', filesI18nOptions)}
                        </label>
                        <input
                          ref={thumbnailInputRef}
                          type="file"
                          id="preview-input"
                          multiple="multiple"
                          style={{ display: 'none' }}
                          onChange={handleThumbnailInput}
                          accept={acceptImageExtensions}
                          disabled={disabled}
                        />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {!count(allPreviews) && (
                <div className="full-size-uploader">
                  <i data-testid="upload-icon" className="fa fa-cloud-upload" />
                </div>
              )} */}
              <div className="row file-list-wrapper image-files-wrapper">
                <div className="js-product-images" ref={sortableEl}>
                  {orderedPreviews.map(file => (
                    <PreviewFile
                      key={file.id}
                      file={file}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Dropzone>
        </div>
      </div>
    </div>
  )
}