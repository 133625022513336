/* global I18n, gon */
import React from 'react'

const i18nOptions = {
  scope: 'turbosquid.products.product_files_panel',
}

const displayBody = (title, texts, idx) => {
  if (title === I18n.t('marmoset_viewer', i18nOptions)) {
    return (
      <div className="column-container-body">
        <span>{I18n.t('marmoset_viewer_body', i18nOptions)}</span>
        <a
          href={gon.info_generating_marmoset_mview_files}
          target="_blank"
          rel="noopener noreferrer"
          className="learn-more-url"
        >
          {I18n.t('learn_more', i18nOptions)}
        </a>
        <span>{I18n.t('marmoset_viewer_body_end', i18nOptions)}</span>
      </div>
    )
  } return (
    <div className="column-container-body">{texts[idx]}</div>
  )
}

export const helpTextColumns = (images, titles, texts) => (
  <div className="help-text-columns-container">
    {titles.map((title, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={idx} className="column-container">
        <img src={images[idx]} alt={title} />
        <div className="column-container-title">{title}</div>
        {displayBody(title, texts, idx)}
      </div>
    ))}
  </div>
)
