import React, { useEffect, useState } from 'react'
import { Alert, Fade } from 'react-bootstrap'

export default function FadeAlert({
  timeout = 3000,
  onTimeout = () => {},
  children,
  className
}) {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
      setTimeout(() => {
          onTimeout()
      }, timeout)
    }, timeout)
  }, [])

  return (
    <Fade in={visible} timeout={timeout} appear>
      <Alert aria-hidden={!visible} className={className}>
        <div>
          {children}
        </div>
      </Alert>
    </Fade>
  )
}
