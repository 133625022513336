import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import { createEditor, Range, Transforms } from 'slate'
import { withHistory } from 'slate-history'
import { isHotkey, isKeyHotkey } from 'is-hotkey'
import RichTextToolbar from '../../../shared/RichTextToolBar'
import RichTextareaLeaf from './RichTextareaLeaf'
import RichTextareaElement from './RichTextareaElement'
import { withCustomizeEditor, toggleMark } from '../../../utils/RichTextUtils'
import { serialize } from '../../../utils/slate/html_serializer'
import { serialize as serializeToText } from '../../../utils/slate/text_serializer'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const selection = window.getSelection()

const RichTextArea = (props) => {
  const richBodyRef = useRef(null)
  const { value: initValue, disabled, onChange, onBlur } = props
  const editor = useMemo(() => withHistory(withCustomizeEditor(withReact(createEditor()))), [])

  const [value, setValue] = useState(initValue)
  const [editorSelection, setEditorSelection] = useState({})

  const renderElement = useCallback(({ attributes, children, element }) => (
    <RichTextareaElement attributes={attributes} childrenNode={children} element={element} />), [])

  const renderLeaf = useCallback(({ attributes, children, leaf }) => (
    <RichTextareaLeaf attributes={attributes} childrenNode={children} leaf={leaf} />), [])

  useEffect(() => {
    if (ReactEditor.isFocused(editor) && selection.anchorNode) {
      const rangeRect = selection.getRangeAt(0).getBoundingClientRect()
      const richBodyRect = richBodyRef.current.getBoundingClientRect()
      const { clientHeight } = richBodyRef.current

      if (rangeRect.y < richBodyRect.y) {
        richBodyRef.current.scrollTop -= richBodyRect.y - rangeRect.y
      } else if (rangeRect.bottom > richBodyRect.bottom) {
        const newScrollTop = rangeRect.bottom - clientHeight - richBodyRect.y
        richBodyRef.current.scrollTop += newScrollTop + rangeRect.height
      }
    }
  }, [value, editor])

  useEffect(() => {
    // Add DOM change observer to avoid editor crashes caused by browser extension(ex. Bug Magnet).
    const observerConfig = { attributes: false, childList: true, subtree: true }
    const callback = (mutationsList, _observer) => {
      const richTextContentNode = mutationsList.find((m) => m.target.className && m.target.className === 'rich-text-content')
      if (richTextContentNode
        && richTextContentNode.type === 'childList'
        && richTextContentNode.addedNodes.length > 0
        && richTextContentNode.removedNodes.length > 0) {
        // Reset rich text Dom to prevent slate crash
        richTextContentNode.target.innerHTML = ''
        const addedTextList = []
        richTextContentNode.addedNodes.forEach((n) => addedTextList.push(n.textContent))
        richTextContentNode.removedNodes.forEach((n) => {
          richTextContentNode.target.appendChild(n)
        })
        // Append text to the end
        const newDataTransfer = new DataTransfer()
        newDataTransfer.setData('text/plain', addedTextList.join(' '))
        ReactEditor.insertData(editor, newDataTransfer)
      }
    }
    const observer = new MutationObserver(callback)
    observer.observe(richBodyRef.current, observerConfig)
    return () => {
      observer.disconnect()
    }
  })

  const handleChange = (slateValue) => {
    setValue(slateValue)
    if (ReactEditor.isFocused(editor)) {
      onChange(serialize({ children: slateValue }))
    }
  }

  const handleKeydown = (event) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault()
        const mark = HOTKEYS[hotkey]
        toggleMark(editor, mark)
        return
      }
    }

    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event

      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset', reverse: true })
        return
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset' })
        return
      }
    }
  }

  const handleBlur = () => {
    const html = serialize({ children: value })
    setEditorSelection(editor.selection)
    setValue(value)
    onBlur(html)
  }

  const removeMarkup = () => {
    const newValue = [{
      type: 'paragraph',
      children: [{ text: serializeToText({ children: value }) }],
    }]

    // at this moment this is the only way of setting the value for slate from an external source
    // slate.js might change in the future to expose a method to allow this behaviour directly
    // https://github.com/ianstormtaylor/slate/pull/4768/files/cfe9e9aed0d81a8dedba86cc0ab926bff46ae695
    editor.children = newValue
    editor.selection = { anchor: { path: [0,0], offset:0 }, focus: { path: [0,0], offset: 0 } }
    editor.history = { redos: [], undos: [] }
    setValue(newValue)
  }

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <div className="rich-text-container">
        <RichTextToolbar disabled={disabled} editorSelection={editorSelection} removeMarkup={removeMarkup} />
        <div className="rich-text-body" data-testid="description-input" ref={richBodyRef}>
          <Editable
            className={disabled ? 'rich-text-content disabled' : 'rich-text-content'}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            readOnly={disabled}
            onBlur={handleBlur}
            onKeyDown={handleKeydown}
          />
        </div>
      </div>
    </Slate>
  )
}

RichTextArea.propTypes = {
  value: PropTypes.array,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
}

RichTextArea.defaultProps = {
  value: [{ type: 'paragraph', children: [{ text: '' }] }],
  disabled: false,
  maxLength: 0,
}

export default RichTextArea
