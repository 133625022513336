/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import MoneyUtility from './common/money_utility';
import ChartUtility from './common/chart_utility';

const PaymentItem = createReactClass({
  displayName: 'PaymentItem',
  mixins: [MoneyUtility, ChartUtility],
  itemClass() {
    const xsClass = this.props.index === 0 ? 'hidden-xs' : 'col-xs-6'
    return `col-sm-4 panel-vertical-divider ${xsClass}`
  },
  render() {
    const money = this.cents_to_currency_parts(this.props.payment.payment_value)
    return (
      <div className={this.itemClass()}>
        <h5 className="text-muted mobile-payment-date">{this.verboseDate(this.props.payment.paid_at)}</h5>
        <h3>
          {money.main_unit}
          <span className="cents text-muted">
            .
            {money.fractional_unit}
          </span>
        </h3>
      </div>
    )
  },
})

export default PaymentItem;
