/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Icon from '../../../products/react_components/icon'
import {
  getErrors,
  infoIconClasses,
  stripHTMLFromString,
  placeCaretAtEnd,
  clearTitleAndTagsForbiddenWords,
  IsActionPublish,
} from '../../../products/react_components/helpers'
import FieldErrors from '../../../products/react_components/form_components/field_errors'
import { SaveDraftValidationShaper, PublishValidationShaper } from '../../../utils/SaveValidations'
import DisableableField from '../../../products/react_components/form_components/DisableableField'

const i18nOptions = {
  scope: 'turbosquid.products.product_tags_panel',
}

const validationSchema = yup.object().shape(SaveDraftValidationShaper())
const publishValidationSchema = yup.object().shape(PublishValidationShaper())

const SPECIAL_SEPARATORS = [String.fromCharCode(160), ' ', ',', ';', '%', '&', '[', ']', '{', '}', '<', '>', '(', ')', '_', '@', '/', '?', '$', '#', '+', '=']

class ProductTags extends React.Component {
  constructor(props) {
    super(props)
    this.tagsDiv = React.createRef()
    this.tagsEdit = React.createRef()
    this.state = {
      tags: props.tags ? props.tags : '',
    }
  }

  clearTagText = (text) => (
    stripHTMLFromString(text)
      .replace(/\r\n/g, ' ')
      .replace(/\n/g, ' ')
      .trim()
  )

  onKeyDown = (e) => {
    if ([10, 13].includes(e.keyCode)) {
      e.preventDefault()
    }

    const { tags: tagList } = this.state
    if (e.keyCode === 8) {
      const selectionObject = window.getSelection()
      if (selectionObject.anchorOffset < 1) {
        const tagArray = tagList.split(' ')
        const newTagStr = tagList ? tagArray.slice(0, tagArray.length - 1).join(' ') : ''
        this.updateTags(newTagStr)
      }
    }
  }

  onKeyUp = (e) => {
    const { innerText } = e.target
    const editDivText = this.clearTagText(innerText)
    const { tags: tagList } = this.state
    const tagsText = `${tagList} ${editDivText.trim()}`.trim()
    if (tagsText.length > gon.product_validations.tag_name_length_max) {
      const sliceTags = tagsText.slice(0, gon.product_validations.tag_name_length_max)
      const sliceTagsArray = sliceTags.split(' ')
      const newTagsArray = tagsText.split(' ')
      if (newTagsArray.length - sliceTagsArray.length === 1) {
        // Sliced a tag
        this.tagsEdit.current.innerText = ''
        placeCaretAtEnd(this.tagsEdit.current)
      } else {
        this.updateTags(sliceTagsArray.slice(0, sliceTagsArray.length - 1).join(' ').trim())
        this.tagsEdit.current.innerText = sliceTagsArray.slice(-1)
        placeCaretAtEnd(this.tagsEdit.current)
      }
    } else {
      const tags = this.clearTagText(innerText)
      if (e.keyCode === 32 || SPECIAL_SEPARATORS.includes(tags.slice(-1))) {
        this.updateTags()
      } else {
        const { updateTags: updateTagsProp } = this.props
        const strippedValue = this.clearTagText(this.tagsDiv.current.innerText).replace(/[,;.]/g, '').slice(0, gon.product_validations.tag_name_length_max)
        updateTagsProp(clearTitleAndTagsForbiddenWords(strippedValue).trim())
      }
    }
  }

  onPaste = (e) => {
    e.preventDefault()
    const { tags: tagList } = this.state
    const tagsText = this.clearTagText(e.clipboardData.getData('Text'))
    let tags = `${tagList} ${tagsText}`
    if (tags.length >= gon.product_validations.tag_name_length_max) {
      tags = tags.slice(0, gon.product_validations.tag_name_length_max)
    }
    this.updateTags(tags)
  }

  handleTagsClick = (e, disabled) => {
    if (disabled) {
      e.preventDefault()
    } else {
      placeCaretAtEnd(this.tagsEdit.current)
    }
  }

  handleDeleteTag = (e) => {
    e.stopPropagation()
    const { tags } = this.state
    const newTags = tags.split(' ').filter((tag, index) => (
      e.currentTarget.getAttribute('data-tag') !== tag || Number(e.currentTarget.getAttribute('data-index')) !== index
    ))
    const newTagsStr = newTags.join(' ').trim()
    this.updateTags(newTagsStr, true)
  }

  renderTag = (tag, key, disabled) => (
    <div
      key={key}
      className="tags-item"
    >
      <span>{tag}</span>
      <button
        type="button"
        className={disabled ? 'btn-delete-tags disabled-cursor' : 'btn-delete-tags'}
        data-tag={tag}
        data-index={key}
        disabled={disabled}
        onClick={this.handleDeleteTag}
      >
        <span aria-hidden="true"><i className="fa fa-times" /></span>
      </button>
    </div>
  )

  contentEditableHtml = (tags, disabled) => (tags ? tags.split(' ').map((tag, key) => this.renderTag(tag, key, disabled)) : '')

  updateTags = (tags = null, validate = false) => {
    const { updateTags: updateTagsProp, publishValidationTriggered } = this.props
    let strippedValue
    if (tags === null) {
      const elem = this.tagsDiv.current
      const { innerText } = elem
      strippedValue = this.clearTagText(innerText).replace(/[,;]/g, '')
    } else {
      strippedValue = tags
    }
    strippedValue = clearTitleAndTagsForbiddenWords(strippedValue)
      .trim()
      .split(' ')
      .filter((t) => t !== '.')
      .join(' ')
    this.setState({
      tags: strippedValue || '',
    })
    this.tagsEdit.current.innerText = ''
    if (validate) {
      yup.reach(publishValidationTriggered ? publishValidationSchema : validationSchema, 'tags')
        .validate(strippedValue)
        .then((_valid) => updateTagsProp(strippedValue))
        .catch((exception) => updateTagsProp(strippedValue, exception.errors))
    }
  }

  panelClasses = () => {
    const { highlightPanel } = this.props
    if (highlightPanel) {
      return 'panel panel-warning tags-panel'
    }
    return 'panel panel-default tags-panel'
  }

  toggleTagsInfo = () => {
    const { showInfo, toggleInfo } = this.props
    toggleInfo(!showInfo)
  }

  render = () => {
    const {
      showInfo,
      errors,
      formActionInProgress,
    } = this.props

    const { tags } = this.state
    return (
      <div className={this.panelClasses()}>
        <div className="panel-heading">
          <button type="button" data-toggle="collapse" data-target="#tags-panel-instructions" onClick={this.toggleTagsInfo} tabIndex="-1">
            {I18n.t('tags', i18nOptions)}
            <Icon type={infoIconClasses(showInfo)} tabIndex="-1"/>
          </button>
        </div>
        <div className="panel-body panel-collapse collapse in" id="tags-panel-body">
          <div className="panel-body-container">
            <div id="tags-panel-instructions" className={showInfo ? 'panel-instructions collapse in' : 'panel-instructions collapse'}>
              <p>{I18n.t('panel_popover', i18nOptions)}</p>
            </div>

            <div className={`form-group turbosquid_product_form_tags ${Object.keys(getErrors(errors)).includes('tags') ? 'has-errors' : ''}`}>
              <label className="control-label" htmlFor="turbosquid_product_form_tags" tabIndex="-1">
                {I18n.t('tags', i18nOptions)}
              </label>
                <DisableableField
                  defaultDisabled={IsActionPublish(formActionInProgress)}
                >
                  {(disabled) => (
                    <div
                      ref={this.tagsDiv}
                      onClick={(e) => this.handleTagsClick(e, disabled)}
                      className={disabled ? 'form-control tags-container disabled-cursor' : 'form-control tags-container'}
                    >
                      {this.contentEditableHtml(tags, disabled)}
                      <div
                        className="tags-edit"
                        contentEditable={!disabled}
                        onBlur={() => this.updateTags(null, true)}
                        onKeyUp={this.onKeyUp}
                        onKeyDown={this.onKeyDown}
                        onPaste={this.onPaste}
                        ref={this.tagsEdit}
                      />
                    </div>
                  )}
                </DisableableField>
              <FieldErrors errors={getErrors(errors).tags} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ProductTags.propTypes = {
  highlightPanel: PropTypes.object,
  showInfo: PropTypes.bool,
  tags: PropTypes.string,
  errors: PropTypes.object,
  updateTags: PropTypes.func.isRequired,
  toggleInfo: PropTypes.func.isRequired,
  formActionInProgress: PropTypes.string,
  publishValidationTriggered: PropTypes.bool,
}

ProductTags.defaultProps = {
  highlightPanel: null,
  errors: {},
  showInfo: false,
  tags: '',
  formActionInProgress: '',
  publishValidationTriggered: false,
}

export default ProductTags
