import { post, del } from './service'

const certificationUrl = (draftId, certificationId) => {
  let url = `/turbosquid/products/${draftId}/certifications`
  if (certificationId) {
    url += `/${certificationId}`
  }

  return url
}

export const saveCertificationQuery = async (payload) => {
  const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
  const {
    body,
    draftId,
  } = payload
  const url = certificationUrl(draftId)

  const result = await post(
    url,
    { ...body, authenticity_token: authenticityToken, draft_id: draftId.toString() }
  )

  return result
}

export const deleteCertificationQuery = async (params, certificationId) => {
  if (certificationId) {
    const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
    const {
      draftId,
    } = params
    const url = certificationUrl(draftId, certificationId)
    await del(
      url,
      { ...params, authenticity_token: authenticityToken, draft_id: draftId.toString() }
    )
  }
}
