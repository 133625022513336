import React from 'react'
import PropTypes from 'prop-types'

const FeatureHiddenInputs = (props) => {
  const { features } = props
  const filteredFeatures = features.filter((feature) => feature.assignment_type.toUpperCase() !== 'X')
  return (
    <div className="turbosquid-product-feature-ids">
      {
        filteredFeatures.map((feature) => (
          <input
            className="turbosquid-product-feature-id"
            name="feature_ids[]"
            type="hidden"
            value={feature.id}
            key={`feature-hidden-input-${feature.id}`}
          />
        ))
      }
    </div>
  )
}

export default FeatureHiddenInputs

FeatureHiddenInputs.propTypes = {
  features: PropTypes.array,
}

FeatureHiddenInputs.defaultProps = {
  features: [],
}
