import { useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ProductContext } from '../contexts/ProductContext'
import { NotificationContext } from '../contexts/NotificationContext'
import { FileContext } from '../contexts/FileContext'
import { UploadContext } from '../contexts/UploadContext'
import useTurntableCandidates from './useTurntableCandidates'
import { COLLECTION, FAILED, THUMBNAIL, TURNTABLE } from '../components/products/constants'
import { usePreviewsOrder } from './usePreviewsOrder'
import usePreviewSpecs from './usePreviewSpecs'
import {
  arrayFrom,
  checkThumbnailType,
  count,
  filterStatusNot,
  filterType,
  imageResolution
} from '../utils/ProductFiles'
import useFileUploads from './useFileUploads'
import { mapThumbnailType } from '../services/file-service'
import { mapPreviewFileUpload, mapTurntableFileUpload } from '../services/upload-service'
import { previewBatchSize, uniqueName } from '../components/publisher/ProductFiles/filters'
import {
  fileName,
  fileSize, maxPreviews,
  previewFileExtension,
  previewMaxDimensions, turntableFileLimit, turntableImageName
} from '../components/publisher/ProductFiles/validators'
import { formatAlert } from './useNotifications'
import { getFileInfo } from '../utils/TurntableUtils'

export default function usePreviews() {
  const [selected, setSelected] = useState({})
  const form = useFormContext()
  const {
    draft,
    draftId,
    tips,
    isCollection,
    overridePreviewRequirements,
    certifications: { isBeingInspected },
  } = useContext(ProductContext)
  const { alert, clear, notifications } = useContext(NotificationContext)
  const {
    files: { preview: allPreviews, associated },
    clearFiles,
    updateFiles,
    clearFile,
    csrfToken,
    deleteFiles
  } = useContext(FileContext)
  const { uploads, ackUploads, cancelUploads } = useContext(UploadContext)
  const {
    clearCandidates,
    turntableCandidates,
    errors: turntableErrors
  } = useTurntableCandidates({
    previews: uploads[TURNTABLE],
    cancelUploads,
    ackUploads,
    updateFiles,
    clearFile,
    draftId,
    csrfToken
  })

  const {
    sortableEl,
    orderedPreviews,
    searchPreview,
    sortAlpha
  } = usePreviewsOrder({
    allPreviews,
    updateFiles,
    draftId,
    csrfToken,
    overridePreviewRequirements
  })

  const previewSpecs = usePreviewSpecs({
    previews: orderedPreviews,
    searchPreview,
    associated,
    overridePreviewRequirements
  })

  const existingFiles = {
    ...filterType(allPreviews, [THUMBNAIL]),
    ...filterType(allPreviews, [TURNTABLE]),
    ...filterStatusNot(uploads[THUMBNAIL], FAILED)
  }
  const displaySearchPlaceholder = !overridePreviewRequirements && !searchPreview && !!count(allPreviews)
  const existingFileCount = count(existingFiles)

  const {
    ready: previewUploadsReady,
    inputRef: thumbnailInputRef,
    handleFileUploads: handleThumbnailInput
  } = useFileUploads({
    mapUpload: async (file) => {
      const { width, height } = await imageResolution(file)
      const thumbnailType = mapThumbnailType(
        isCollection ? COLLECTION : checkThumbnailType(file.name, width, height)
      )
      return mapPreviewFileUpload({
        file,
        draft,
        thumbnailType,
        watermarked: false
      })
    },
    batchValidators: [maxPreviews(existingFiles, isCollection)],
    batchFilters: [uniqueName(existingFiles), previewBatchSize(existingFileCount)],
    validators: [fileSize, fileName, previewFileExtension, previewMaxDimensions],
    onError: errors => errors.forEach(message => alert(formatAlert(message))),
  })

  const {
    ready: turntableUploadsReady,
    inputRef: turntableInputRef,
    handleFileUploads: handleTurntableInput
  } = useFileUploads({
    mapUpload: file => mapTurntableFileUpload({
      file,
      draft,
      turntableId: getFileInfo(file.name).id,
    }),
    batchValidators: [turntableFileLimit(turntableCandidates, uploads[TURNTABLE])],
    batchFilters: [uniqueName(uploads[TURNTABLE])],
    validators: [
      fileSize,
      fileName,
      previewFileExtension,
      previewMaxDimensions,
      turntableImageName(tips?.naming?.turntables)
    ],
    onError: errors => errors.forEach(message => alert(formatAlert(message))),
  })

  const handleBulkSelect = (id) => {
    const { [id]: value, ...rest } = selected
    setSelected(
      value
        ? rest ?? {}
        : { ...selected, [id]: allPreviews[id] }
    )
  }

  const handleSelectAll = () => {
    const selection = count(selected)
      ? {}
      : Object.keys(allPreviews).reduce((acc, key) => ({
        ...acc,
        [key]: allPreviews[key]
      }), {})
    setSelected(selection)
  }

  const handleBulkDelete = () => {
    const selectionArray = arrayFrom(selected)
    deleteFiles(
      { files: selectionArray },
      {
        onSuccess: () => {
          setSelected({})
          clearFiles(selectionArray)
        },
        onError: e => console.error(e)
      }
    )
  }

  const handleSetThumbnailType = (type) => {
    arrayFrom(selected).forEach(item => {
      form.setValue(`${item.id}.thumbnailType`, type)
    })
  }

  const disabled = !previewUploadsReady || !turntableUploadsReady || isBeingInspected

  return {
    previewSpecs,
    turntableCandidates,
    turntableErrors,
    sortableEl,
    selected,
    handleBulkSelect,
    handleSelectAll,
    handleBulkDelete,
    handleSetThumbnailType,
    thumbnailInputRef,
    handleThumbnailInput,
    turntableInputRef,
    handleTurntableInput,
    displaySearchPlaceholder,
    sortAlpha,
    disabled,
    notifications,
    clear,
    clearCandidates,
    allPreviews,
    searchPreview,
    orderedPreviews,
  }
}