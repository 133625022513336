/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import StemcellQueueItem from './stemcell_queue_item';
import PanelSpinner from './common/panel_spinner';
import DashboardClient from './common/dashboard_client';

const StemcellQueue = createReactClass({
  displayName: 'StemcellQueue',
  mixins: [DashboardClient],
  endpoint() {
    return '/turbosquid/dashboard_widgets/stemcell_queue';
  },
  hasStemcellQueue() {
    return this.state.data == null || (this.state.data.stemcell_queue != null ? this.state.data.stemcell_queue.length : undefined) > 0
  },
  linkClass() {
    if (this.hasStemcellQueue()) {
      return 'panel-info-link';
    }
    return 'pull-right';
  },
  resourceLink() {
    return gon.stem_resources_url;
  },
  reviewSpecLink() {
    return gon.stem_review_spec_url;
  },
  render() {
    return (
      <div className="panel-body">
        <div className="panel-heading-text row">
          <div className="col-xs-12">
            {this.hasStemcellQueue() ? <a className="pull-right toggle-collapse js-toggle-collapse" data-toggle="collapse" data-target=".stemcell-panel-collapse" href="#"><i className={this.panelChevronClass('stemcell-container')} /></a> : undefined}
            <span className="panel-heading-label">{I18n.t('dashboard.stemcell.stemcell')}</span>
            <div className={this.linkClass()}><a href={this.resourceLink()}><i className="fa fa-book" /></a></div>
          </div>
        </div>
        <div className={`panel-divider stemcell-panel-collapse ${this.panelCollapseClass('stemcell-container')}`} />
        <div className={`stemcell-panel-collapse ${this.panelCollapseClass('stemcell-container')}`}>
          {(() => {
            if (this.state.data != null) {
              if (this.state.data.stemcell_queue.length > 0) {
                return (
                  <div className="panel-overflow">
                    <table className="table table-light stemcell-queue">
                      <thead>
                        <tr>
                          <th className='assetName'>{I18n.t('dashboard.checkmate.asset_name')}</th>
                          <th className='status'>{I18n.t('dashboard.checkmate.status')}</th>
                          <th className='updated'>{I18n.t('dashboard.checkmate.updated')}</th>
                        </tr>
                      </thead>
                      <tbody>{this.state.data.stemcell_queue.map((item) => <StemcellQueueItem key={item.id} queue_item={item} />)}</tbody>
                    </table>
                  </div>
                )
              }
              return (
                <div className="text-center stemcell-widget">
                  <div className="panel-empty">
                    <h3>{I18n.t('dashboard.stemcell.maximize_earnings')}</h3>
                    <p className="text-muted">{I18n.t('dashboard.stemcell.stemcell_ready')}</p>
                  </div>
                  <div className="row publish-product-action text-center">
                    <div className="col-xs-12 text-center">
                      <a className="btn btn-warning first-button" href={this.resourceLink()}>{I18n.t('dashboard.stemcell.learn_more')}</a>
                      <a className="btn btn-warning" href={this.reviewSpecLink()}>{I18n.t('dashboard.stemcell.review_spec')}</a>
                    </div>
                  </div>
                </div>
              )
            }
            return <PanelSpinner /> 
            })()}
        </div>
      </div>
    )
  },
})

export default StemcellQueue;
