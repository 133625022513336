/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import ChartUtility from './common/chart_utility';

const CheckMateQueueItem = createReactClass({
  displayName: 'CheckMateQueueItem',
  mixins: [ChartUtility],
  jobUrl(id) {
    return gon.freshwater_job_url + id
  },

  status(code) {
    if (this.requiresAction(code)) {
      return I18n.t('dashboard.checkmate.status_code.requires_action')
    }
    return I18n.t(`dashboard.checkmate.status_code.${code}`)
  },

  productStaged() {
    return !['waiting', 'pre_processed', 'hold'].includes(this.props.queue_item.status_code)
  },

  requiresAction(code) {
    return _.includes(['needs_publisher_fixes', 'needs_file_fixes'], code)
  },

  render() {
    let cell; let
      icon
    const status = this.status(this.props.queue_item.status_code)

    if (this.requiresAction(this.props.queue_item.status_code)) {
      icon = <i className="fa fa-exclamation-circle text-danger" />
    }
    if (this.props.queue_item.freshwater_job_id != null && this.productStaged()) {
      cell = <a href={this.jobUrl(this.props.queue_item.freshwater_job_id)}>{status}</a>
    } else {
      cell = status
    }

    return (
      <tr>
        <td>
          <span className={this.props.queue_item.certification} />
          {this.props.queue_item.product_name}
        </td>
        <td>
          {icon}
          {' '}
          {cell}
        </td>
        <td className="text-muted">{this.formattedDate(this.props.queue_item.updated_at)}</td>
      </tr>
    )
  },
})

export default CheckMateQueueItem;
