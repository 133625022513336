/* global I18n, gon */
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../products/react_components/icon'
import StemcellQueueStatus from './StemcellQueueStatus'
import { infoIconClasses } from '../../../products/react_components/helpers'
import { debounce } from 'lodash'
import { ProductContext } from '../../../contexts/ProductContext'
import { mapApiToCertId } from '../../../services/gon-service'
import { stemcellFromCerts } from '../../../utils/ProductFiles'

const i18nOptions = {
  scope: 'turbosquid.products.stemcell_certification_panel',
}

const StemcellCertificationForm = (props) => {
  const {
    showInfo,
    toggleInfo,
    certifications,
    collectionFeature,
    pendingCancelStemcellSubmission,
    cancelSubmission,
  } = props

  const [showTooltips, setShowTooltips] = useState(false)
  const [stemcellCheckbox, setCheckbox] = useState(false)
  const { certifications: certContext } = useContext(ProductContext)
  const { onAddStemcellCertification, onRemoveStemcellCertification } = certContext
  const stemcellCertification = certifications.find(certification => certification.id === 'stemcell')

  const stemcellValidationList = [
    {
      text: I18n.t('stemcell_validation_message.format_validation', i18nOptions),
    },
    {
      text: I18n.t('stemcell_validation_message.unwrapped_uvw', i18nOptions),
      tooltip: {
        heading: I18n.t('cert_info.make_materials.header', i18nOptions),
        text: [
          I18n.t('cert_info.make_materials.first_paragraph', i18nOptions),
          I18n.t('cert_info.make_materials.second_paragraph', i18nOptions),
        ],
        buttonLabel: I18n.t('cert_info.make_materials.button_text', i18nOptions),
        buttonLink: gon.stemcell_textures_materials_url
      }
    },
    {
      text: I18n.t('stemcell_validation_message.named_materials', i18nOptions),
      tooltip: {
        heading: I18n.t('cert_info.name_materials.header', i18nOptions),
        text: [
          I18n.t('cert_info.name_materials.paragraph', i18nOptions),
        ],
        buttonLabel: I18n.t('cert_info.name_materials.button_text', i18nOptions),
        buttonLink: gon.stemcell_naming_materials_url
      }
    }
  ]

  const debounceHandleTooltipsShow = debounce((e) => {
    if (collectionFeature) {
      setShowTooltips(e.type === 'mouseover')
    } else if (showTooltips) {
      setShowTooltips(false)
    }
  }, 100)
  const handleTooltipsShow = (e) => {
    // Prevents React from resetting its properties
    e.persist()
    debounceHandleTooltipsShow(e)
  }

  const handleChange = (event) => {
    setCheckbox(!stemcellCheckbox);
    let certificationValue = event.target.value;
    const currentCertification = stemcellFromCerts(certContext.certifications)
      
    if (currentCertification) {
      onRemoveStemcellCertification(currentCertification)
      certificationValue = 'none'
    } else {
      onAddStemcellCertification(mapApiToCertId(event.target.value))
    }
    
    return props.updateCertification(certificationValue)
  }

  
  if (stemcellCertification) {
    return (
      <StemcellQueueStatus
        certification={stemcellCertification}
        pendingCancelStemcellSubmission={pendingCancelStemcellSubmission}
        cancelSubmission={cancelSubmission}
      />
    )
  }

  return (
    <div className="stemcell-certification-container">
      <div id="stemcell-message-container">
        <div className="container" id="stemcell-message">
          <div className="col-md-12">
            <div className="row">
              <h3>{I18n.t('maximize_earnings', i18nOptions)}</h3>
              <p>{I18n.t('unlock_benefits', i18nOptions)}</p>
              <ul>
                <li>{I18n.t('benefits.higher_search_rankings', i18nOptions)}</li>
                <li>{I18n.t('benefits.expand_search', i18nOptions)}</li>
                <li>{I18n.t('benefits.priority_features', i18nOptions)}</li>
              </ul>
              <a href={gon.stemcell_resources_url} target="_blank" className='btn btn-default btn-action-top btn-learn-more'>{I18n.t('benefits.learn_more', i18nOptions)}</a>
            </div>
          </div>
        </div>
      </div>
      
      <div className="stemcell-validation-container">
        <div className="stemcell-validation-message">{I18n.t('stemcell_validation_message.header', i18nOptions)}</div>
        <div>{I18n.t('stemcell_validation_message.body', i18nOptions)}</div>
          <ul className="stemcell-validation-list">
            { stemcellValidationList.map((item, key) => {
              return (
                <li key={key} className="stemcell-validation-list-item">
                  <i className='fa fa-check list-icon'></i>
                  <span>
                    {item.text}
                    {item.tooltip && 
                      <button
                        type="button"
                        data-toggle="collapse"
                        data-target={`#checkbox-instructions-${key}`}
                        onClick={(_) => toggleInfo(!showInfo)}
                        className="checkbox-info-button"
                        tabIndex="-1"
                      >
                        <Icon type={infoIconClasses(showInfo)} tabIndex="-1"/>
                        <span className="stemcell-certification-title">
                          <i className='fa fa-info-circle'></i>
                          <div>
                            <h4>{item.tooltip.heading}</h4>
                            {item.tooltip.text.map((text, key) => 
                              <p key={key}>{text }</p>
                            )}
                            <a href={item.tooltip.buttonLink} target="_blank" rel="noopener noreferrer" tabIndex="-1">{item.tooltip.buttonLabel}</a>
                          </div>
                        </span>
                      </button>}
                  </span>
                </li>
              )
            }) }
          </ul>
          <div className="form-group optional form-group-checkbox form-group-stemcell-validation">
              <input
                className="boolean optional"
                id="addStemcell"
                name="addStemcell"
                onChange={handleChange}
                type="checkbox"
                value="stemcell_v2"
                checked={stemcellCheckbox}
              />

              <label htmlFor="addStemcell" className="control-label" id="addStemcell-label">
                {I18n.t('stemcell_validation_message.add_stemcell', i18nOptions)}
              </label>
          </div>
      </div>
    </div>
  )
  
}

StemcellCertificationForm.propTypes = {
  showInfo: PropTypes.bool,
  toggleInfo: PropTypes.func.isRequired,
  certifications: PropTypes.array,
  collectionFeature: PropTypes.number,
}

StemcellCertificationForm.defaultProps = {
  showInfo: false,
  certifications: [],
  collectionFeature: 0,
}

export default StemcellCertificationForm
