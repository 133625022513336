import { connect } from 'react-redux'
import PublisherButtonsTop from '../../../components/publisher/EditHeader/PublisherButtonsTop'
import { defaultFields, specificFields } from '../../../products/product_required_fields'
import { mergeAlerts } from '../../../products/react_components/helpers'

import {
  selectStemcell,
} from '../../../products/react_components/selectors/formSelectors'
import { selectNotifications } from '../../../products/react_components/selectors/uiSelectors'

import {
  saveTurntableCandidates,
  discardDraft,
  previewDraft,
  disableDraftPendingChanges, saveDraft
} from '../../../products/react_components/actions/form_slice_actions'

import { publishProduct } from '../../../products/react_components/actions/publisher_ui_slice_actions'

import { asyncQueueAction } from '../../../components/products/constants'

const completeData = (values) => {
  if (defaultFields.find((value, _index) => typeof values[value] === 'undefined' || values[value] === 0)) return false

  const extraFields = specificFields[values.mediaType]
  if (extraFields.find((value, _index) => typeof values[value] === 'undefined' || values[value] === 0)) return false

  return true
}

const publisherButton = (alerts, errors, values) => {
  if (alerts.length || Object.keys(errors).filter((key) => typeof errors[key] !== 'undefined').length > 0) {
    return 'disabled'
  }

  if (!completeData(values)) {
    return 'disabled'
  }

  return 'publish'
}

const mapStateToProps = (state) => ({
  isLoading: state.uiState.isLoading,
  draftId: state.formState.draftId,
  draftSaving: state.formState.turbosquid_product_form.draftSaving,
  draftSavePendingChanges: state.formState.turbosquid_product_form.draftSavePendingChanges,
  updatedAt: state.formState.updatedAt,
  changesToFieldsWithTermConstraints: state.formState.changesToFieldsWithTermConstraints,
  publish_button: publisherButton(
    mergeAlerts(state),
    state.formState.turbosquid_product_form.formValidationErrors,
    state.formState.turbosquid_product_form.values,
  ),
  stemcell: selectStemcell(state),
  publishNotifications: selectNotifications(state),
  validationPassed: state.formState.dataValidationPassed,
  disableLeavePagePrompt: state.formState.disableLeavePagePrompt,
  displayAlerts: state.formState.displayAlerts,
})

const mapDispatchToProps = (dispatch) => ({
  saveDraft: (event) => dispatch(saveDraft({ name: event.name, value: event.value })),
  previewDraft: () => dispatch(previewDraft()),
  publishProduct: (event, url, method) => dispatch(publishProduct({ event, url, method })),
  saveTurntableCandidates: (candidate) => dispatch(saveTurntableCandidates({ candidate })),
  discardDraft: (url) => dispatch(discardDraft({ url })),
  disableDraftPendingChanges: () => dispatch(disableDraftPendingChanges()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublisherButtonsTop)
