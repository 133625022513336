import { connect } from 'react-redux'
import ProductHeaderEditForm from '../../../components/publisher/EditHeader/ProductHeaderEditForm'
import { filterNotifications } from '../../../products/react_components/helpers'
import { selectDraft, selectDataValidationState } from '../../../products/react_components/selectors/formSelectors'

const mapStateToProps = (state) => ({
  name: state.formState.turbosquid_product_form.values.name,
  status: state.formState.status,
  createdAt: state.formState.createdAt,
  updatedAt: state.formState.updatedAt,
  notifications: filterNotifications(state.formState.alerts, 'name'),
  hasDraft: selectDraft(state),
  validationPassed: selectDataValidationState(state),
  isLoading: state.uiState.isLoading,
  draftSaving: state.formState.turbosquid_product_form.draftSaving,
})

export default connect(
  mapStateToProps,
)(ProductHeaderEditForm)
