import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { safeTranslate } from '../helpers'
import { filterToAggregation } from '../constants'

export const menuItemCount = (key, value, aggregations) => {
  if (key && aggregations) {
    if (value.toLowerCase() !== 'all') {
      let formattedResult = '(0)'
      const data = aggregations[filterToAggregation[key]]
      if (data && data.buckets) {
        const result = data.buckets.filter((bucket) => bucket.key === value)[0]
        if (result) {
          formattedResult = `(${result.doc_count})`
        }
      }
      return formattedResult
    }
  }
  return ''
}

const MenuListItem = (key, value, search, aggregations) => {
  const searchKey = key.toLowerCase()
  const searchState = queryString.parse(search)
  searchState[searchKey] = value
  if (value.toString().toLowerCase() === 'all') {
    delete searchState[searchKey]
  }
  searchState.page = 1
  const linkOptions = {
    pathname: '/turbosquid/products',
    search: `?${queryString.stringify(searchState)}`,
    state: {
      loading: true,
      data: [],
      search: queryString.stringify(searchState),
    },
  }

  return (
    <li key={value}>
      <Link to={linkOptions}>
        {safeTranslate(value)}
        {` ${menuItemCount(key, value, aggregations)}`}
      </Link>
    </li>
  )
}

export default MenuListItem
