import React from 'react'
import useUploads from '../../hooks/useUploads'
import useFiles from '../../hooks/useFiles'
import { FormProvider, useForm } from 'react-hook-form'
import { ProductContext } from '../../contexts/ProductContext'
import { FileContext } from '../../contexts/FileContext'
import { UploadContext } from '../../contexts/UploadContext'
import useNotifications from '../../hooks/useNotifications'
import { NotificationContext } from '../../contexts/NotificationContext'
import useProduct from '../../hooks/useProduct'
import { UIContext } from '../../contexts/UIContext'
import useUI from '../../hooks/useUI'
import useConfirmLeave from '../../hooks/useConfirmLeave'

export default function Providers({ gon, children }) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content
  const ui = useUI()
  const notifications = useNotifications()
  const form = useForm({ mode: 'onTouched', defaultValues: {} })
  const product = useProduct(gon, form, ui, csrfToken)

  const {
    productId,
    draftId,
    endpoints,
    product: {
      files: productFiles,
      previews: productPreviews
    },
  } = product
  const uploads = useUploads(endpoints.uploads, csrfToken)
  const files  = useFiles({
    draftId,
    productId,
    csrfToken,
    initialFiles: productFiles,
    initialPreviews: productPreviews,
    allUploads: uploads.all,
    ackUploads: uploads.ackUploads,
  })

  useConfirmLeave(uploads.uploadsInProgress)

  return (
    <FormProvider {...form}>
      <ProductContext.Provider value={{ ...product }}>
        <FileContext.Provider value={{ ...files }}>
          <UploadContext.Provider value={{ ...uploads }}>
            <UIContext.Provider value={{ ...ui }}>
              <NotificationContext.Provider value={{ ...notifications }}>
                {children}
              </NotificationContext.Provider>
            </UIContext.Provider>
          </UploadContext.Provider>
        </FileContext.Provider>
      </ProductContext.Provider>
    </FormProvider>
  )
}
