import React from 'react'
import PropTypes from 'prop-types'
import PostDescription from './common/PostDescription'

const NewsItem = (props) => {
  const { post } = props

  return (
    <li className="in">
      <div className="message">
        <a href={post.link} className="name">{post.title}</a>
        <span className="datetime text-muted">{moment(post.created_at).fromNow()}</span>
        <PostDescription post={post} />
      </div>
    </li>
  )
}

export default NewsItem


NewsItem.propTypes = {
  post: PropTypes.object.isRequired,
}
