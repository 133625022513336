/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import ChartUtility from './common/chart_utility';

const StemcellProductQueueItem = createReactClass({
  displayName: 'StemcellProductQueueItem',
  mixins: [ChartUtility],
  jobUrl(id) {
    return gon.freshwater_job_url + id
  },

  status(code) {
    if (!code) {
        return I18n.t('dashboard.stemcell.status.not_available')
    }

    if (this.requiresAction(this.props.queue_item)) {
      return I18n.t('dashboard.stemcell.status.requires_action')
    }
    return I18n.t(`dashboard.stemcell.status.${code}`)
  },

  requiresAction(item) {
    const freshwaterJob = this.freshwaterJob(item);

    if (!freshwaterJob || !freshwaterJob.current_task) {
        return false;
    }

    return _.includes(['StemCell Upload', 'StemCell V2 Upload', 'StemCell Verfication'], freshwaterJob.current_task)
  },

  freshwaterJobKey(item) {
    const freshwaterJob = this.freshwaterJob(item);

    if (!freshwaterJob) {
        return null;
    }

    return freshwaterJob.job_key;
  },

  freshwaterJob(item) {
    if (item.attributes.metadata.stemcell.freshwater_jobs.length > 0) {
        return item.attributes.metadata.stemcell.freshwater_jobs[0];
    }

    return null;
  },

  render() {
    let cell, icon
    const status = this.status(this.props.queue_item.attributes.metadata.stemcell.stemcell_status)

    if (this.requiresAction(this.props.queue_item)) {
      icon = <i className="fa fa-exclamation-circle text-danger" />
    }
    if (this.freshwaterJobKey(this.props.queue_item) != null) {
      cell = <a href={this.jobUrl(this.freshwaterJobKey(this.props.queue_item))} target="_blank" rel="noopener noreferrer">{status}</a>
    } else {
      cell = status
    }

    return (
      <tr>
        <td>
          {this.props.queue_item.attributes.product_name}
        </td>
        <td>
          {icon}
          {' '}
          {cell}
        </td>
        <td className="text-muted">{this.formattedDate(this.props.queue_item.attributes.updated_at)}</td>
      </tr>
    )
  },
})

export default StemcellProductQueueItem;
