import React from 'react'
import PropTypes from 'prop-types'
import FeatureRow from './FeatureRow'

const FeatureRows = (props) => {
  const {
    addFeatureCallback,
    removeFeatureCallback,
    assignedFeatureIds,
    features,
    categoryPage,
    errors,
  } = props

  const isHidden = (feature) => feature.assignment_type.toUpperCase() === 'X'
  const processedAssignedFeatureIds = assignedFeatureIds || features.map((feature) => feature.id)
  const ancestorFeatureIds = features.map((node) => node.ancestry_ids)
  const visibleFeatures = features.filter((feature) => !isHidden(feature)) || []
  return (
    <ul className="feature-assignment-rows">
      {
        visibleFeatures.map((feature, index) => (
          <FeatureRow
            key={`feature-row-${index}`} // eslint-disable-line
            feature={feature}
            assignedFeatureIds={processedAssignedFeatureIds}
            ancestorFeatureIds={ancestorFeatureIds}
            addFeatureCallback={addFeatureCallback}
            removeFeatureCallback={removeFeatureCallback}
            categoryPage={categoryPage}
            errors={errors}
          />
        ))
      }
    </ul>
  )
}

export default FeatureRows

FeatureRows.propTypes = {
  addFeatureCallback: PropTypes.func.isRequired,
  removeFeatureCallback: PropTypes.func.isRequired,
  assignedFeatureIds: PropTypes.array,
  features: PropTypes.array,
  errors: PropTypes.object,
}

FeatureRows.defaultProps = {
  assignedFeatureIds: [],
  features: [],
  errors: {},
}
