/* global I18n */
import React, { useContext, useState } from 'react'
import ProductFileUploadProgress from './ProductFileUploadProgress'
import ReactSpinner from '../../../products/react-spinner'
import { UploadContext } from '../../../contexts/UploadContext'
import { arrayFrom } from '../../../utils/ProductFiles'
import {
  ASSOCIATED,
  failedUploadStatus,
  filesI18nOptions,
  NATIVE,
  pendingUploadStatus, QUEUED, successfulUploadStatus,
  THUMBNAIL, TURNTABLE
} from '../../products/constants'

export default function UploadsProgress() {
  const [panelClosed, setPanelClosed] = useState(true)
  const { uploads, upload: retryUpload } = useContext(UploadContext)
  const native = arrayFrom(uploads[NATIVE])
  const thumbnail = arrayFrom(uploads[THUMBNAIL])
  const turntableCandidates = arrayFrom(uploads[TURNTABLE])
  const associated = arrayFrom(uploads[ASSOCIATED])
  const allUploads = [ ...native, ...thumbnail, ...turntableCandidates, ...associated ]
  const pendingUploads = allUploads.filter((f) => pendingUploadStatus.includes(f.status))
  const failedUploads = allUploads.filter((f) => failedUploadStatus.includes(f.status))
  const visibleUploads = [...pendingUploads, ...failedUploads]
  const sections = [
    {
      title: I18n.t('files', filesI18nOptions),
      files: native
    },
    {
      title: I18n.t('images', filesI18nOptions),
      files: thumbnail
    },
    {
      title: I18n.t('preview_turntables', filesI18nOptions),
      files: turntableCandidates
    },
    {
      title: I18n.t('textures_and_other_files', filesI18nOptions),
      files: associated
    },
  ]

  return (
    <div
      aria-label="Uploads list"
      aria-hidden={visibleUploads.length === 0}
      className={`uploads-progress-wrapper ${visibleUploads.length === 0 ? 'inactive' : ''} ${panelClosed ? ' closed' : ''}`}
    >
      <div className="uploads-header">
        <span className="uploading">
          <span className="label">{ I18n.t('uploading', filesI18nOptions) }</span>
          {
            pendingUploads.length > 0
            && (<span className="value">{`: ${allUploads.length} ${I18n.t('items', filesI18nOptions)}`}</span>)
          }
          {
            failedUploads.length > 0
            && (<span className="value">{` ${failedUploads.length} ${I18n.t('failed_items', filesI18nOptions)}`}</span>)
          }
        </span>
        <button
          aria-label="Toggle uploads"
          className={`toggle-panel ${panelClosed ? 'fa fa-angle-up' : 'fa fa-angle-down'}`}
          type="button"
          onClick={() => setPanelClosed(!panelClosed)}
        />
        {
          pendingUploads.length > 0
          && (<div className="uploading-spinner"><ReactSpinner /></div>)
        }
      </div>
      <div className="uploads-container">
        {
          visibleUploads.length > 0
          && (
            <div className="uploads">
              <ul>
                {sections.map(section => {
                  if (section.files.length) {
                    return (
                      <div key={section.title} className="uploads-section">
                        <span className="uploads-title">{section.title}</span>
                        <ul>
                          {
                            section.files
                              .map((upload) => (
                              <li key={upload.id}>
                                <ProductFileUploadProgress
                                  id={upload.id}
                                  name={upload.attributes.name}
                                  failed={failedUploadStatus.includes(upload.status)}
                                  queued={[QUEUED].includes(upload.status)}
                                  success={successfulUploadStatus.includes(upload.status)}
                                  cancelFileProgress={upload.managedUpload}
                                  retryFile={() => retryUpload([upload])}
                                  fileType={upload.type}
                                  totalBytes={upload.total}
                                  uploadedBytes={upload.loaded}
                                  progress={upload.loaded / upload.total * 100}
                                />
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    )
                  }
                })}
              </ul>
            </div>
          )
        }
      </div>

    </div>
  )
}
