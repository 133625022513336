/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import ChartUtility from './common/chart_utility';

const StemcellTurboSquidQueueItem = createReactClass({
  displayName: 'StemcellTurboSquidQueueItem',
  mixins: [ChartUtility],
  jobUrl(id) {
    return gon.freshwater_job_url + id
  },

  status(metadata) {
    const { certification_jobs, stemcell_status } = metadata
    let stemcellJob = this.stemcellJob(certification_jobs)

    if (!stemcellJob) {
      stemcellJob = stemcell_status
    }

    if (stemcellJob) {
      return stemcellJob.status_code ? stemcellJob.status_code : stemcellJob.status.toLowerCase();
    }

    return 'not_available';
  },

  freshwaterJobKey(metadata) {
    const { certification_jobs } = metadata
    const stemcellJob = this.stemcellJob(certification_jobs)
    
    if (stemcellJob) {
      return stemcellJob.job_key
    }

    return null
  },

  stemcellJob(certification_jobs) {
    return certification_jobs && certification_jobs.find(job => job.certification_code === 'stemcell')
  },

  requiresAction(status) {
    ['action_required', 'error'].includes(status)
  },

  render() {
    let cell, icon
    const turbosquid_metadata = this.props.queue_item.attributes.metadata.turbosquid;
    const status = this.status(turbosquid_metadata)
    const freshwater_job_key = this.freshwaterJobKey(turbosquid_metadata)

    if (this.requiresAction(status)) {
      icon = <i className="fa fa-exclamation-circle text-danger" />
    }
    if (freshwater_job_key != null) {
      cell = <a href={this.jobUrl(freshwater_job_key)} target="_blank" rel="noopener noreferrer">{I18n.t(`dashboard.stemcell.status.${status}`)}</a>
    } else {
      cell = I18n.t(`dashboard.stemcell.status.${status}`)
    }

    return (
      <tr>
        <td>
          {this.props.queue_item.attributes.product_name}
        </td>
        <td>
          {icon}
          {' '}
          {cell}
        </td>
        <td className="text-muted">{this.formattedDate(this.props.queue_item.attributes.updated_at)}</td>
      </tr>
    )
  },
})

export default StemcellTurboSquidQueueItem;
