import React, { createContext, useContext } from 'react'
import useBundles from '../../hooks/useBundles'
import { ProductContext } from '../../contexts/ProductContext'

export const BundlesContext = createContext()

export default function BundlesProvider({ children }) {
  const { product, csrfToken } = useContext(ProductContext)
  const collections = useBundles({ product, csrfToken })
  return (
    <BundlesContext.Provider value={collections}>
       {children}
    </BundlesContext.Provider>
  )
}