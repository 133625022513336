import { connect } from 'react-redux'
import DismissableNotification from '../../../components/publisher/EditHeader/DismissableNotification'
import { dismissNotification } from '../../../products/react_components/actions/shared_actions'

const mapDispatchToProps = (dispatch) => (
  { dismissNotification: (notificationId) => dispatch(dismissNotification({ id: notificationId })) }
)

const mapStateToProps = (state, ownProps) => {
  const { notificationId } = ownProps
  const notification = state.uiState.notifications[notificationId]
  return {
    dismissed: notification ? notification.dismissed : false,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DismissableNotification)
