/* global I18n, gon */
import * as yup from 'yup'
import {
  FORBIDDEN_WORDS,
  checkForbiddenWords,
  checkForbiddenForCategory,
  checkPhoneNumber,
  LOWER_PRICE_BOUND,
  HIGHER_PRICE_BOUND,
  checkForbiddenCharacters,
  checkProductLinks,
} from '../products/react_components/helpers'

export const REQUIRED_NATIVE_MAIN_FILE_FIELDS = ['formatVersion', 'renderer']

const maxPositiveValue = 2147483647

const i18nOptions = {
  product_name_panel: {
    scope: 'turbosquid.products.product_name_panel',
  },
  product_specification_panel: {
    scope: 'turbosquid.products.product_specifications_panel',
  },
  product_tags_panel: {
    scope: 'turbosquid.products.product_tags_panel',
  },
  product_description_panel: {
    scope: 'turbosquid.products.product_description_panel',
  },
}

export const fieldLabels = (scope) => {
  const translationScope = scope || i18nOptions.product_specification_panel
  return {
    name: I18n.t('name', translationScope),
    price: I18n.t('price', translationScope),
    vertices: I18n.t('vertices', translationScope),
    polygons: I18n.t('polygons', translationScope),
    geometry: I18n.t('geometry', translationScope),
    mediaType: I18n.t('media_type', translationScope),
    unwrappedUVs: I18n.t('unwrapped_uvs_label', translationScope),
    length: I18n.t('length', translationScope),
    frameRate: I18n.t('frame_rate', translationScope),
    width: I18n.t('width', translationScope),
    height: I18n.t('height', translationScope),
    colorDepth: I18n.t('color_depth', translationScope),
    description: I18n.t('description', translationScope),
    tags: I18n.t('tags', translationScope),
    license: I18n.t('license', translationScope),
    certifications: I18n.t('certifications', translationScope),
    featureIds: I18n.t('category', translationScope),
    not_allowed_category: I18n.t('category', translationScope),
  }
}

export function checkForbiddenWordsValidator(forbiddenWords) {
  // eslint-disable-next-line func-names
  return this.test('forbidden-words', '', function (value) {
    return checkForbiddenWords(value, forbiddenWords, this)
  })
}

export function checkForbiddenForCategoryValidator() {
  return this.test('forbidden-words', '', function (value) {
    return checkForbiddenForCategory(value, this)
  })
}

export function checkPhoneNumberValidator() {
  // eslint-disable-next-line func-names
  return this.test('phoneNumber', '', function (value) {
    return checkPhoneNumber(value, this)
  })
}

export function priceZeroOrGreater(minValue, maxValue, errorMessage) {
  return this.test('price-zero-or-greater', errorMessage, (value) => {
    const castValue = parseFloat(value)
    return (castValue >= 0
      ? castValue === 0 || (castValue >= minValue && castValue <= maxValue)
      : true
    )
  })
}

export function minValueString(minValue, errorMessage) {
  return this.test('min-value-string', errorMessage, (value) => {
    if (value === '') return true // if value was not given it's valid
    const castValue = parseFloat(value)
    return !!castValue && castValue >= minValue
  })
}

export function maxValueString(maxValue, errorMessage) {
  return this.test('max-value-string', errorMessage, (value) => {
    if (value === '') return true // if value was not given it's valid
    const castValue = parseFloat(value)
    return !!castValue && castValue < maxValue
  })
}

export function checkForbiddenCharactersValidator(key) {
  return this.test('forbidden-characters', '', function (value) {
    return checkForbiddenCharacters(value, this, key)
  })
}

export function checkProductLinksValidator(skipValidate = false) {
  return this.test('productLinks', '', function (value) {
    return skipValidate || checkProductLinks(value, this)
  })
}

const requiredMessage = () => (
  () => ({
    messageForNotif: I18n.t('required_field_notif', {
      ...i18nOptions.product_specification_panel,
    }),
    messageForInline: I18n.t('required_field', { ...i18nOptions.product_specification_panel }),
  })
)

export const conditionalMediaTypeFields = (mediaType) => {
  const mediaTypeFields = {
    models: ['vertices', 'polygons', 'geometry', 'unwrappedUVs'],
    textures: ['width', 'height', 'colorDepth'],
    motion: ['length', 'frameRate'],
  }
  switch (mediaType) {
    case 2:
      return mediaTypeFields.models
    case 3:
      return mediaTypeFields.textures
    case 5:
      return mediaTypeFields.motion
    default:
      return [
        ...mediaTypeFields.models,
        ...mediaTypeFields.textures,
        ...mediaTypeFields.motion,
      ]
  }
}

export const SaveDraftValidationShaper = (mediaType, skipProductLinkValidate = false) => {
  yup.addMethod(
    yup.string, 'checkForbiddenWords', checkForbiddenWordsValidator
  )

  yup.addMethod(
    yup.array, 'checkForbiddenForCategory', checkForbiddenForCategoryValidator
  )

  yup.addMethod(
    yup.string, 'priceZeroOrGreater', priceZeroOrGreater
  )

  yup.addMethod(
    yup.string, 'minValueString', minValueString
  )

  yup.addMethod(
    yup.string, 'maxValueString', maxValueString
  )

  yup.addMethod(
    yup.string, 'checkPhoneNumber', checkPhoneNumberValidator
  )

  yup.addMethod(
    yup.string, 'checkForbiddenCharacters', checkForbiddenCharactersValidator
  )

  yup.addMethod(
    yup.string, 'checkProductLinks', checkProductLinksValidator
  )

  let conditionalFields = {}
  switch (mediaType) {
    case 2:
      conditionalFields = {
        geometry: yup
          .string(),
        unwrappedUVs: yup
          .string(),
      }
      break
    case 3:
      conditionalFields = {
        width: yup
          .number()
          .min((gon.product_validations || {}).width_gte,
            () => ({
              messageForNotif: I18n.t('width_validation_min_notif', i18nOptions.product_specification_panel),
              messageForInline: I18n.t('width_validation_min', i18nOptions.product_specification_panel),
            })),
        height: yup
          .number()
          .min(
            (gon.product_validations || {}).height_gte,
            () => ({
              messageForNotif: I18n.t('height_validation_min_notif', i18nOptions.product_specification_panel),
              messageForInline: I18n.t('height_validation_min', i18nOptions.product_specification_panel),
            })
          ),
        colorDepth: yup
          .number(),
      }
      break
    case 5:
      conditionalFields = {
        length: yup
          .number()
          .min(
            (gon.product_validations || {}).length_gte,
            () => ({
              messageForNotif: I18n.t('length_validation_min_notif', i18nOptions.product_specification_panel),
              messageForInline: I18n.t('length_validation_min', i18nOptions.product_specification_panel),
            })
          ),
        frameRate: yup
          .number(),
      }
      break
    default:
  }

  return {
    ...conditionalFields,
    name: yup
      .string()
      .checkForbiddenWords(FORBIDDEN_WORDS.filter((w) => (w !== '@')))
      .checkForbiddenCharacters()
      .max(
        (gon.product_validations || {}).name_length_max,
        () => ({
          messageForNotif: I18n.t('name_validation_max_length_notif',
            { ...i18nOptions.product_name_panel,
              upper_bound: (gon.product_validations || {}).name_length_max,
            }),
          messageForInline: I18n.t('name_validation_max_length',
            { ...i18nOptions.product_name_panel,
              upper_bound: (gon.product_validations || {}).name_length_max,
            }),
        })
      ),
    price: yup
      .string()
      .priceZeroOrGreater(LOWER_PRICE_BOUND, HIGHER_PRICE_BOUND,
        () => ({
          messageForNotif: I18n.t('price_zero_or_greater_notif',
            { ...i18nOptions.product_name_panel,
              lower_bound: LOWER_PRICE_BOUND,
              higher_bound: HIGHER_PRICE_BOUND,
            }),
          messageForInline: I18n.t('price_zero_or_greater',
            { ...i18nOptions.product_name_panel,
              lower_bound: LOWER_PRICE_BOUND,
              higher_bound: HIGHER_PRICE_BOUND,
            }),
        })),
    description: yup
      .string()
      .checkForbiddenWords(FORBIDDEN_WORDS)
      .checkPhoneNumber()
      .checkProductLinks(skipProductLinkValidate)
      .max(
        (gon.product_validations || {}).description_length_max,
        () => ({
          messageForNotif: I18n.t('description_exceeded_max_length',
            { ...i18nOptions.product_description_panel }),
          messageForInline: I18n.t('description_exceeded_max_length',
            { ...i18nOptions.product_description_panel }),
        })
      ),
    tags: yup
      .string()
      .checkForbiddenCharacters("tags")
      .checkForbiddenWords(FORBIDDEN_WORDS)
      .checkPhoneNumber(),
    category: yup
      .array()
      .checkForbiddenForCategory(),
  }
}

export const PublishCategoryValidationShaper = () => {
  yup.addMethod(
    yup.array, 'checkForbiddenForCategory', checkForbiddenForCategoryValidator
  )

  return {
    category: yup
      .array()
      .checkForbiddenForCategory(),
  }
}

export const PublishValidationShaper = (mediaType, skipProductLinkValidate = false) => {
  const SaveDraftValidationShape = SaveDraftValidationShaper(mediaType, skipProductLinkValidate)

  let conditionalFields = {}
  switch (mediaType) {
    case 2:
      conditionalFields = {
        vertices: yup
          .string()
          .minValueString(
            (gon.product_validations || {}).vertices_number_gte,
            () => ({
              messageForNotif: I18n.t('vertices_validations_min_notif', i18nOptions.product_specification_panel),
              messageForInline: I18n.t('required_field', i18nOptions.product_specification_panel),
            })
          )
          .maxValueString(
            maxPositiveValue,
            () => ({
              messageForNotif: I18n.t('validations_max_notif', i18nOptions.product_specification_panel),
              messageForInline: I18n.t('required_field', i18nOptions.product_specification_panel),
            })
          ).required(requiredMessage()),
        polygons: yup
          .string()
          .minValueString(
            (gon.product_validations || {}).polygons_number_gte,
            () => ({
              messageForNotif: I18n.t('polygons_validations_min_notif', i18nOptions.product_specification_panel),
              messageForInline: I18n.t('required_field', i18nOptions.product_specification_panel),
            })
          )
          .maxValueString(
            maxPositiveValue,
            () => ({
              messageForNotif: I18n.t('validations_max_notif', i18nOptions.product_specification_panel),
              messageForInline: I18n.t('required_field', i18nOptions.product_specification_panel),
            })
          ).required(requiredMessage()),
        geometry: SaveDraftValidationShape.geometry
          .required(requiredMessage()),
        unwrappedUVs: SaveDraftValidationShape.unwrappedUVs
          .required(requiredMessage()),
      }
      break
    case 3:
      conditionalFields = {
        width: SaveDraftValidationShape.width
          .required(requiredMessage()),
        height: SaveDraftValidationShape.height
          .required(requiredMessage()),
        colorDepth: SaveDraftValidationShape.colorDepth
          .required(requiredMessage()),
      }
      break
    case 5:
      conditionalFields = {
        length: SaveDraftValidationShape.length
          .required(requiredMessage()),
        frameRate: SaveDraftValidationShape.frameRate
          .required(requiredMessage()),
      }
      break
    default:
  }
  return {
    ...conditionalFields,
    name: SaveDraftValidationShape.name
      .required(requiredMessage()),
    price: SaveDraftValidationShape.price
      .required(requiredMessage()),
    description: SaveDraftValidationShape.description
      .required(requiredMessage()),
    tags: SaveDraftValidationShape.tags
      .required(requiredMessage()),
    category: SaveDraftValidationShape.category
      .required(requiredMessage()),
  }
}

export const licenseValueMapper = {
  royalty_free_editorial_uses_only: 'royalty_free_editorial_uses_only',
  royalty_free_all_extended_uses: 'royalty_free_all_extended_uses',
}
