/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/* global gon */

const logout_url = typeof gon !== 'undefined' && gon !== null ? gon.discourse_sso_logout_url : undefined;
if (logout_url) {
  $.ajax(logout_url, { dataType: 'jsonp' });
}
