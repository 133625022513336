/* global gon */

import { call, put, select } from 'redux-saga/effects'
import { filterTokens, tokenize } from '../reducers/publisher_reducer_methods'

import { updateTags,
  updateName,
  updateDescription,
  updateConstraints,
  updateFeatures,
  constraintsFetchFailed,
} from '../actions/form_slice_actions'
import { stripHTMLFromString } from '../helpers'

export const constraintsFetchSucceeded = (changes) => {
  if (typeof changes.payload.features !== 'undefined') {
    return updateFeatures({
      constraints: changes.constraints,
      errors: changes.payload.errors,
      features: changes.payload.features,
      constraintsPending: false })
  }

  if (typeof changes.payload.description !== 'undefined') {
    return updateDescription({
      constraints: changes.constraints,
      errors: changes.payload.errors,
      description: changes.payload.description,
      constraintsPending: false })
  }

  if (typeof changes.payload.name !== 'undefined') {
    return updateName({
      constraints: changes.constraints,
      errors: changes.payload.errors,
      name: changes.payload.name,
      constraintsPending: false })
  }

  if (typeof changes.payload.tags !== 'undefined') {
    return updateTags({
      constraints: changes.constraints,
      errors: changes.payload.errors,
      tags: changes.payload.tags,
      constraintsPending: false })
  }
  return updateConstraints({ changes })
}

const getConstraintsForTerms = (params) => {
  const defaultResponse = { terms: [] }

  if (!params.length) {
    return defaultResponse
  }

  const url = new URL(gon.constraints_url)
  const terms = Array.from(new Set(params))
  url.searchParams.append('terms', terms)

  const options = {
    method: 'GET',
    credentials: 'same-origin',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }

  return fetch(url, options).then((response) => response.json()).catch((_) => defaultResponse)
}

const getFormState = (state) => (
  state.formState
)

const sanitizedArrayFrom = ({ description, name, tags }) => (
  [].concat(...[name, tags, stripHTMLFromString(description)].map(
    (term) => term && filterTokens(tokenize(term))
  ))
)

const formValuesFrom = (formState = {}) => (
  (formState.turbosquid_product_form || {}).values || {}
)

const injectExceptionChanges = (changes, e) => {
  const errors = typeof changes.errors !== 'undefined' ? [...changes.errors, e.message] : [e.message]
  let fieldName
  let fieldValue
  if (typeof changes.tags !== 'undefined') {
    fieldName = 'tags'
    fieldValue = changes.tags
  }
  if (typeof changes.description !== 'undefined') {
    fieldName = 'description'
    fieldValue = changes.description
  }

  return { ...changes, fieldName, fieldValue, errors }
}

function* findConstraints(oldState, newState) {
  const newTerms = sanitizedArrayFrom(formValuesFrom(newState))
  const oldTerms = sanitizedArrayFrom(formValuesFrom(oldState))
  const addedTerms = newTerms.filter((term) => term.length > 0 && !oldTerms.includes(term))

  const constraints = (oldState.constraints || []).filter(
    (constraint) => newTerms.includes(constraint.term)
  )

  // TSQ-25013: Disconnect banned/editorial word check in OPP for now
  /*
  if (addedTerms.length) {
    const response = yield call(getConstraintsForTerms, addedTerms)
    constraints = constraints.concat(response.terms)
  }
  */

  return constraints
}

export function* fetchConstraintsFromServer(params = {}) {
  const changes = { ...params }
  try {
    const oldState = yield select(getFormState)
    let useState = {}
    if (!Object.keys(changes.payload).includes('name') && !Object.keys(changes.payload).includes('description')) {
      useState = oldState
    }
    const updatedValues = { ...oldState.turbosquid_product_form.values, ...changes.payload }

    const newState = { ...oldState, turbosquid_product_form: { values: updatedValues } }

    changes.constraints = yield findConstraints(useState, newState)
    yield put(constraintsFetchSucceeded(changes))
  } catch (e) {
    const newChanges = injectExceptionChanges(changes, e)
    yield put(constraintsFetchFailed({ newChanges }))
  }
}

export function* fetchInitialConstraintsFromServerSaga() {
  try {
    const oldState = {}
    const newState = yield select(getFormState)
    const constraints = yield findConstraints(oldState, newState)

    yield put(constraintsFetchSucceeded({ constraints }))
  } catch (e) {
    yield put(constraintsFetchFailed({ error: e.message }))
  }
}
