/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import NumberUtility from './common/number_utility';

const PaymentEstimateWarning = createReactClass({
  displayName: 'PaymentEstimateWarning',
  render() {
    const amount = (this.props.estimated / 100).toFixed(2)
    const threshold = this.props.threshold / 100

    const earningText = I18n.t('dashboard.payments.your_unpaid_royalties_info')
    const thresholdText = I18n.t('dashboard.payments.payment_threshold_info', { time: gon.payment_threshold_month })
    const atThatTimeText = I18n.t('dashboard.payments.at_that_time')
    const memberServiceText = I18n.t('dashboard.payments.member_services_info')

    return (
      <p className="text-left">
        <i className="fa fa-info" />
        {earningText}
        {'\
         \
'}
        <strong>
          $
          {amount}
        </strong>
        {'\
         \
'}
        {thresholdText}
        {'\
         \
'}
        <strong>
          $
          {threshold}
        </strong>
        {'\
         \
'}
        {atThatTimeText}
        {'\
         \
'}
        {memberServiceText}
        {'\
         \
'}
        <a href={gon.new_ticket_url} className="alert-link">{I18n.t('dashboard.payments.member_services')}</a>
      </p>
    )
  },
})

export default PaymentEstimateWarning;
