/* global gon, I18n */
import React from 'react'
import PublisherAlerts from './PublisherAlerts'
import PublisherWarnings from './PublisherWarnings'
import PublisherInfo from './PublisherInfo'

const PublisherNotifications = ({
  notificationLevel,
  formErrors,
  mainFiles,
  termErrors,
  ambiguousTermErrors,
  prohibitedWordsAlerts,
  activeTab,
  changeTab,
  turntableErrors,
  turntableCapReached,
  displayAlerts,
}) => (
  <div className="row publisher-header-notifications">
    <PublisherAlerts
      notificationLevel={notificationLevel}
      formErrors={formErrors}
      mainFiles={mainFiles}
      termErrors={termErrors}
      ambiguousTermErrors={ambiguousTermErrors}
      prohibitedWordsAlerts={prohibitedWordsAlerts}
      activeTab={activeTab}
      displayAlerts={displayAlerts}
    />
    <PublisherWarnings
      turntableErrors={turntableErrors}
      changeTab={changeTab}
    />
    <PublisherInfo
      turntableCapReached={turntableCapReached}
    />
  </div>
)
export default PublisherNotifications
