import { useEffect, useRef } from 'react'
import { isArray } from 'lodash'

export function usePrevious(value) {
  const ref = useRef(isArray(value) ? [] : undefined);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
