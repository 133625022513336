import { connect } from 'react-redux'
import ProductCertification from '../../../components/publisher/ProductSpecs/ProductCertification'
import * as formSelectors from '../../../products/react_components/selectors/formSelectors'

import { toggleInfo } from '../../../products/react_components/actions/publisher_ui_slice_actions'

const mapStateToProps = (state) => ({
  certifications: formSelectors.selectCertifications(state),
  showInfo: state.uiState.certificationPanel.showInfo,
  mediaType: state.formState.turbosquid_product_form.values.mediaType,
  formActionInProgress: state.formState.formActionInProgress,
  features: state.featureState.features,
  draftId: formSelectors.selectDraftId(state),
  collectionFeature: state.featureState.collectionFeature,
})

const mapDispatchToProps = (dispatch) => ({
  toggleInfo: (value) => dispatch(toggleInfo({ panel: 'certificationPanel', value })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCertification)
