/* global I18n */
import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import {
  bundleProductsI18Options,
  BUNDLES_PANEL,
  filesI18nOptions,
  PREVIEW, PRODUCT,
} from '../../components/products/constants'
import { UploadContext } from '../../contexts/UploadContext'
import { FileContext } from '../../contexts/FileContext'
import { ProductContext } from '../../contexts/ProductContext'
import UploadsProgress from '../../components/publisher/ProductFiles/UploadsProgress'
import UndoDeleteNotification from '../../components/publisher/ProductFiles/UndoDeleteNotification'
import {
  previewErrors, titlesWithErrors,
} from '../../utils/ProductFiles'
import { useFormContext } from 'react-hook-form'
import { UIContext } from '../../contexts/UIContext'
import { NotificationContext } from '../../contexts/NotificationContext'
import BundlesPreviewFiles from './BundlesPreviewFiles'
import BundlesProductSelector from './BundlesProductSelector'
import { BundlesContext } from './BundlesProvider'

export default function BundlesPanel() {
  const { files } = useContext(FileContext)
  const { uploads } = useContext(UploadContext)
  const { setIsBundleProduct } = useContext(ProductContext)
  const { currentTab, setCurrentTab } = useContext(UIContext)
  const { clearNotifications, notifications } = useContext(NotificationContext)
  const { formState: { errors: formErrors } } = useFormContext()

	useEffect(() => {
    setIsBundleProduct(true)
  }, []);

  const errors = {
    [PRODUCT]: [],
    [PREVIEW]: previewErrors(formErrors),
  }

  const titles = {
    [PRODUCT]: I18n.t('bundle_products', bundleProductsI18Options),
    [PREVIEW]: I18n.t('bundle_images', filesI18nOptions),
  }

  const tabs = titlesWithErrors(files, uploads, titles, errors)

  // Set starting tab
  useEffect(() => {
    setCurrentTab(PRODUCT)
  }, [])

  // Clear notifications when changing tabs
  useEffect(() => {
    if (notifications.length) {
      clearNotifications()
    }
  }, [currentTab])

  return (
    <div className="panel panel-default files-panel files-panel-container">
      <Tabs
        id={BUNDLES_PANEL}
        activeKey={currentTab}
        onSelect={setCurrentTab}
      >
        <Tab eventKey={PRODUCT} title={tabs[PRODUCT]} className={false ? 'with-alert' : ''}>
          <BundlesProductSelector />
        </Tab>
        <Tab eventKey={PREVIEW} title={tabs[PREVIEW]} className={false ? 'with-alert' : ''}>
          <BundlesPreviewFiles />
        </Tab>
      </Tabs>
      {/* <UndoDeleteNotification /> */}
      <UploadsProgress />
    </div>
  )
}