import React, { forwardRef, useContext } from 'react'
import { ProductContext } from '../../../contexts/ProductContext'

export default forwardRef(function SyncField(props, ref) {
  const { syncName, syncChecked, onChange, ...formFieldProps } = props
  const { syncFields: { syncOnChange } } = useContext(ProductContext)

  const handleOnChange = (props) => {
    const { target } = props.nativeEvent
      ? props.nativeEvent
      : { target: { value: props.value } }
    // syncChecked resolves to the previous
    // value of the checkbox (before click)
    const value = target.type === 'checkbox'
      ? !syncChecked
      : target.value
    syncOnChange({
      name: syncName,
      value
    })
    onChange(props.nativeEvent ?? props)
  }

  const innerProps = {
    ...formFieldProps,
    onChange: handleOnChange
  }

  return props.render(innerProps)
})
