/* global gon, I18n */
import React from 'react'
import PropTypes from 'prop-types'

const i18nOptions = {
  scope: 'turbosquid.products.stemcell_certification_panel.stemcell_widget',
}

const jobUrl = (jobId) => gon.freshwater_job_url + jobId

const FreshwaterHandler = ({ job_key: jobId, status_code: status }) => (
  <div>
    <i className="stemcell-status fa fa-exclamation-circle" />
    <a className="action-required" href={jobUrl(jobId)} target="_blank" rel="noopener noreferrer">
      <span className="stemcell-status">
        {I18n.t(status, i18nOptions)}
      </span>
    </a>
  </div>
)

FreshwaterHandler.propTypes = {
  job_key: PropTypes.string.isRequired,
  status_code: PropTypes.string.isRequired,
}

const StemcellCell = (props) => {
  const { certification_jobs, stemcell_status } = props
  let stemcellJob = certification_jobs && certification_jobs.find(job => job.certification_code === 'stemcell')

  if (!stemcellJob) {
    stemcellJob = stemcell_status
  }

  if (stemcellJob) {
    const stemcellJobStatus = stemcellJob.status_code ? stemcellJob.status_code : stemcellJob.status.toLowerCase()
    let result = I18n.t(stemcellJobStatus, i18nOptions)
    
    const handlers = {
      action_required: FreshwaterHandler,
      error: FreshwaterHandler,
    }
    const handler = handlers[stemcellJobStatus]
    if (handler) {
      result = handler(stemcellJob)
    }
    return (
      <div className="stemcell-products-queue stemcell-products-status-wrapper">
        { result }
      </div>
    )
  }
  return ''
}

export default StemcellCell
