/* global I18n */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import Checkbox from './Checkbox'
import { arrayFrom, count, filterType } from '../../../utils/ProductFiles'
import {
  ASC,
  DESC, MIN_UV_HEIGHT, MIN_UV_WIDTH,
  MIN_WIREFRAME_HEIGHT,
  MIN_WIREFRAME_WIDTH,
  REGULAR,
  TURNTABLE,
  UV,
  WIREFRAME
} from '../../products/constants'

const i18nOptions = {
  scope: 'turbosquid.products.product_images_panel',
}

export default function PreviewBulkOption({
  selected,
  totalCount,
  onSelectAll,
  onBulkDelete,
  onSetThumbnailType,
  onSortAlpha,
  disabled
}) {
  const [sortAlpha, setSortAlpha] = useState(undefined)
  const selectedCount = count(selected)
  const selectedArray = arrayFrom(selected)
  const allSelected = selectedCount > 0 && selectedCount === totalCount
  const meetsWireframeRequirements = selectedArray.every(({ attributes }) => {
    return attributes.sourceWidth >= MIN_WIREFRAME_WIDTH && attributes.sourceHeight >= MIN_WIREFRAME_HEIGHT
  })
  const meetsUvRequirements = selectedArray.every(({ attributes }) => {
    return attributes.sourceWidth >= MIN_UV_WIDTH && attributes.sourceHeight >= MIN_UV_HEIGHT
  })
  const canUpdateType = !count(filterType(selected, [TURNTABLE]))
  const bulkOptions = [
    {
      label: I18n.t('turbosquid.products.product_header.delete'),
      value: 'bulk-delete-key',
      disabled: disabled || !selectedCount,
      handler: onBulkDelete,
    },
    {
      label: I18n.t('set_as_image', i18nOptions),
      value: REGULAR,
      disabled: disabled || !selectedCount || !canUpdateType,
      handler: () => onSetThumbnailType(REGULAR),
    },
    {
      label: I18n.t('set_as_wireframe', i18nOptions),
      value: WIREFRAME,
      disabled: disabled || !selectedCount || !canUpdateType || !meetsWireframeRequirements,
      handler: () => onSetThumbnailType(WIREFRAME),
    },
    {
      label: I18n.t('set_as_uv', i18nOptions),
      value: UV,
      disabled: disabled || !selectedCount || !canUpdateType || !meetsUvRequirements,
      handler: () => onSetThumbnailType(UV),
    },
  ]

  useEffect(() => {
    if (sortAlpha) {
      onSortAlpha(sortAlpha)
    }
  }, [sortAlpha])

  const handleSortAlpha = () => {
    const order = (() => {
      if (sortAlpha) {
        return sortAlpha === ASC ? DESC : ASC
      } else {
        return ASC
      }
    })()

    setSortAlpha(order)
  }

  return (
    <div className="preview-bulk-options">
      <Checkbox
        id="bulk-select-all"
        selected={allSelected}
        onSelect={onSelectAll}
      />
      <div className="selected-count-container">
        <span className="selected-count">{count(selected)}</span>
        <span>{I18n.t('selected', i18nOptions)}</span>
      </div>
      <div className="btn-bulk-container dropdown">
        <DropdownButton
          bsStyle="default"
          title=". . ."
          noCaret
          className="btn-bulk-option"
          id="bulk-select-dropdown"
        >
          {bulkOptions.map((option) => (
            <MenuItem
              key={option.value}
              eventKey={option.value}
              disabled={option.disabled}
              onSelect={option.handler}
            >
              {option.label}
            </MenuItem>
          ))}
        </DropdownButton>
      </div>
      <div
        onClick={handleSortAlpha}
        className={`uploader-sort-holder ${sortAlpha ? '' : 'disable-sort'}`}
      >
        <i className={`fa fa-sort-alpha-${sortAlpha ?? ASC}`} />
      </div>
    </div>
  )
}

PreviewBulkOption.propTypes = {
  selected: PropTypes.object.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onBulkDelete: PropTypes.func.isRequired,
  onSetThumbnailType: PropTypes.func.isRequired,
}
