/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

require('../../../i18n/translations')
import createReactClass from 'create-react-class';
import DashboardClient from './common/dashboard_client';
import SalesRoyaltiesItem from './sale_royalties_chart_item';
import PanelSpinner from './common/panel_spinner';
import SalesChart from './sales_chart';

const Sales = createReactClass({
  displayName: 'Sales',
  mixins: [DashboardClient],
  endpoint() {
    return '/turbosquid/dashboard_widgets/sales.json'
  },
  priorMonths() {
    let sales
    if (this.state.data.aggregations.error == null) {
      sales = this.state.data.aggregations.sales_by_month.slice(0, 3).reverse()
      while (sales.length < 3) {
        let date;
        if (sales[0] != null && sales[0].from_as_string) {
          date = new Date(sales[0].from_as_string)
          date.setMonth(date.getMonth() - 1)
        } else {
          date = new Date()
          date.setDate(1)
        }

        sales.unshift({
          key: date.toISOString(),
          from: date.getTime(),
          from_as_string: date.toISOString(),
          royalties: {
            value: 0,
          },
          royalties_pixelsquid: {
            value: 0,
          },
          royalties_checkmate: {
            value: 0,
          },
          gross: {
            value: 0,
          },
          gross_checkmate: {
            value: 0,
          },
          gross_pixelsquid: {
            value: 0,
          },
        })
      }
    }
    return sales
  },
  render() {
    return (
      <div className="panel-body text-left">
        <div className="panel-heading-text row">
          <div className="col-xs-12">
            <a className="pull-right toggle-collapse js-toggle-collapse" data-toggle="collapse" data-target=".sales-panel-collapse" href="#"><i className={this.panelChevronClass('sales-container')} /></a>
            <ul className="nav nav-tabs">
              <li className="active"><a href="#royalties" data-toggle="tab" className="panel-heading-label">{I18n.t('dashboard.sales.royalties')}</a></li>
              <li><a href="#sales" data-toggle="tab" className="panel-heading-label">{I18n.t('dashboard.sales.sales')}</a></li>
              <li className="panel-info-link"><a href="#sales-chart-tab" data-toggle="tab"><i className="fa fa-line-chart" /></a></li>
            </ul>
          </div>
        </div>
        <div className={`panel-divider sales-panel-collapse ${this.panelCollapseClass('sales-container')}`} />
        <div className={`sales-panel-collapse ${this.panelCollapseClass('sales-container')}`}>
          {this.reportsDisabled() || __guard__(this.state.data != null ? this.state.data.aggregations : undefined, ({error}) => error) ? <ReportError /> : this.state.data != null ? (
            <div className="tab-content" id="salesTab">
              <div id="royalties" className="tab-pane fade active in" role="tabpanel"><div className="row text-center">{this.priorMonths().map((sale, index) => <SalesRoyaltiesItem key={sale.key} sale={sale} index={index} />)}</div></div>
              <div id="sales" className="tab-pane fade" role="tabpanel"><div className="row text-center">{this.priorMonths().map((sale, index) => <SalesRoyaltiesItem key={sale.key} sale={sale} index={index} type="gross" />)}</div></div>
              <div role="tabpanel" className="tab-pane fade" id="sales-chart-tab"><SalesChart aggregations={this.state.data.aggregations} /></div>
            </div>
          ) : <PanelSpinner />}
        </div>
      </div>
    );
  },
})

export default Sales;

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined
}
