/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import DashboardClient from './common/dashboard_client';
import ChartUtility from './common/chart_utility';
import PanelSpinner from './common/panel_spinner';

const PixelSquidQueue = createReactClass({
  displayName: 'PixelSquidQueue',
  mixins: [DashboardClient, ChartUtility],
  hasPixelSquidQueue() {
    return this.state.data == null || (this.state.data.pixelsquid_queue != null ? this.state.data.pixelsquid_queue.length : undefined) > 0
  },
  pixelsquid_link() {
    if (this.hasPixelSquidQueue()) {
      return gon.pixelsquid_news_url
    }
    return `${gon.pixelsquid_news_url}getting-started-pixelsquid/ps-getting-started/`
  },
  link_class() {
    if (this.hasPixelSquidQueue()) {
      return 'panel-info-link'
    }
    return 'pull-right'
  },
  endpoint() {
    return '/turbosquid/dashboard_widgets/pixelsquid_queue.json'
  },
  render() {
    return (
      <div className="panel-body">
        <div className="panel-heading-text row">
          <div className="col-xs-12">
            {this.hasPixelSquidQueue() ? <a className="pull-right toggle-collapse js-toggle-collapse" data-toggle="collapse" data-target=".pixelsquid-panel-collapse" href="#"><i className={this.panelChevronClass('pixelsquid-container')} /></a> : undefined}
            <span className="panel-heading-label">{I18n.t('dashboard.pixelsquid.pixelsquid')}</span>
            <div className={this.link_class()}><a href={this.pixelsquid_link()}><i className="fa fa-book" /></a></div>
          </div>
        </div>
        <div className={`panel-divider pixelsquid-panel-collapse ${this.panelCollapseClass('pixelsquid-container')}`} />
        <div className={`pixelsquid-panel-collapse ${this.panelCollapseClass('pixelsquid-container')}`}>
          {(() => {
            if (this.state.data != null) {
              const items = this.state.data.pixelsquid_queue.filter((item) => Object.keys(item).length > 0)
              if (items.length > 0) {
                return (
                  <div className="panel-overflow">
                    <table className="table table-light checkmate-queue">
                      <thead>
<tr>
<th>{I18n.t('dashboard.pixelsquid.asset_name')}</th><th>{I18n.t('dashboard.pixelsquid.status')}</th>
<th>{I18n.t('dashboard.pixelsquid.updated')}</th>
</tr>
</thead>
                      <tbody>
  {items.map((item) => <tr key={item.id}><td>{item.name}</td><td>{item.freshwater_status === 'artist_action_required' ? <i className="fa fa-exclamation-circle text-danger" /> : undefined}{`\
                               \
`}<a href={item.external_url}>{I18n.t(`dashboard.pixelsquid.${item.freshwater_status}`)}</a></td><td className="text-muted">{this.verboseDate(item.updated_at)}</td></tr>)}
</tbody>
                    </table>
                  </div>
                )
              }
              return (
                <div className="panel-empty text-center">
                  <h3>{I18n.t('dashboard.pixelsquid.top_ps_artists_earn')}</h3>
                  <p className="text-muted">{I18n.t('dashboard.pixelsquid.earn_more_income')}</p>
                  <div className="row publish-product-action"><div className="col-xs-12 text-center"><a className="btn btn-warning" href={gon.pixelsquid_learn_more_url}>{I18n.t('dashboard.pixelsquid.learn_more')}</a></div></div>
                </div>
              )
            }
            return <PanelSpinner />
          })()}
        </div>
      </div>
    )
  },
})

export default PixelSquidQueue;
