/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { timeFormat } from 'd3-time-format'
import PanelSpinner from './common/panel_spinner';
import Chart from './common/chart';

const SalesChart = createReactClass({
  displayName: 'SalesChart',
  dataPoints(aggregation, type) {
    return this.props.aggregations.sales_by_month.map(month => month[`${aggregation}${type}`].value / 100);
  },

  nonZeroMonths(aggregation) {
    return _.find(this.props.aggregations.sales_by_month, month => (month[`${aggregation}_pixelsquid`].value > 0) || (month[`${aggregation}_checkmate`].value > 0) || (month[aggregation].value > 0));
  },

  zeroSeries(value) {
    return value === 0;
  },

  months() {
    return this.props.aggregations.sales_by_month.map(function(month) {
      const formatMonth = timeFormat("%Y-%m-%d")
      const date = new Date(month.from_as_string);
      return formatMonth(date);
    });
  },

  x() {
    const data = this.months();
    data.unshift('x');
    if (data.length < 3) { data.push( timeFormat("%Y-%m-%d")(new Date()) ); }
    return data;
  },

  series(aggregation) {
    let turbosquid;
    const series = [];
    if (!this.dataPoints(aggregation, '').every(this.zeroSeries)) {
      turbosquid = this.dataPoints(aggregation, '');
      if (turbosquid.length < 2) { turbosquid.unshift(0); }
      turbosquid.unshift(I18n.t(`dashboard.sales.${aggregation}`).toUpperCase());
      series.push(turbosquid);
    }
    if (!this.dataPoints(aggregation, '_checkmate').every(this.zeroSeries)) {
      const checkmate = this.dataPoints(aggregation, '_checkmate');
      if (turbosquid.length < 2) { checkmate.unshift(0); }
      checkmate.unshift(I18n.t('dashboard.checkmate.checkmate').toUpperCase());
      series.push(checkmate);
    }
    if (!this.dataPoints(aggregation, '_pixelsquid').every(this.zeroSeries)) {
      const pixelsquid = this.dataPoints(aggregation, '_pixelsquid');
      if (turbosquid.length < 2) { pixelsquid.unshift(0); }
      pixelsquid.unshift(I18n.t('dashboard.publishing.partner_sites').toUpperCase());
      series.push(pixelsquid);
    }
    return series;
  },

  render() {
    if (this.props.aggregations != null) {
      const royaltiesSeries = this.series('royalties')
      royaltiesSeries.unshift(this.x())

      const salesSeries = this.series('gross')
      salesSeries.unshift(this.x())

      const nonZeroRoyalties = this.nonZeroMonths('royalties')
      const nonZeroGross = this.nonZeroMonths('gross')

      if (nonZeroGross != null) {
        return <Chart toltip_value="$" data={salesSeries} />
      }
      return <div />
    }
    <PanelSpinner hideText="true" />
  },
})

export default SalesChart;
