import React, { useContext, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { ProductContext } from '../../../contexts/ProductContext'
import {
  NATIVE_COUNT,
  NATIVE, filesI18nOptions, PRIMARY_COUNT, STEMCELL_COUNT
} from '../../products/constants'
import { count, filterPrimary } from '../../../utils/ProductFiles'
import { UIContext } from '../../../contexts/UIContext'
import { getFileExtension } from '../../products/helpers'

// Use this component to register native validations
// that aren't tied to a specific user input.

const hidden = { display: 'none' }

const message = (translation, setCurrentTab) => {
  return (
    <>
      <a onClick={() => setCurrentTab(NATIVE)} className="field-link">
        {`${I18n.t('files', filesI18nOptions)}: `}
      </a>
      <span className="error-messages">{I18n.t(translation, filesI18nOptions)}</span>
    </>
  )
}

export default function NativeValidations({ files, formats }) {
  const { setCurrentTab } = useContext(UIContext)
  const { register, setValue } = useFormContext()
  const {
    validations: { minNativeCount },
    certifications: { isStemcellCertified },
  } = useContext(ProductContext)
  const initialized = useRef(false)
  const fileCount = count(files)
  const primaryFiles = filterPrimary(files) ?? {}
  const primaryCount = count(primaryFiles)
  const stemcellCompatibleFiles = Object.values(primaryFiles).filter(file => file.attributes && ['zip', 'rar'].includes(getFileExtension(file.attributes.name))) ?? {}
  const stemcellCompatibleFilesCount = count(stemcellCompatibleFiles)

  useEffect(() => {
    setValue(`${NATIVE}.${NATIVE_COUNT}`, fileCount, { shouldValidate: initialized.current })
    setValue(`${NATIVE}.${PRIMARY_COUNT}`, primaryCount, { shouldValidate: initialized.current })
    setValue(`${NATIVE}.${STEMCELL_COUNT}`, stemcellCompatibleFilesCount, { shouldValidate: initialized.current })
    initialized.current = true
  }, [fileCount, primaryCount, stemcellCompatibleFilesCount])

  return (
    <>
      <input
        style={hidden}
        value={fileCount}
        {...register(
          `${NATIVE}.${NATIVE_COUNT}`,
          {
              required: true,
              min: {
                value: minNativeCount,
                message: message('main_file_of_type_required', setCurrentTab)
              }
            }
          )
        }
      />
      <input
        style={hidden}
        value={primaryCount}
        {...register(
          `${NATIVE}.${PRIMARY_COUNT}`,
          {
            required: isStemcellCertified,
            min: {
              value: isStemcellCertified? 1 : 0,
              message: message('one_native_file_required', setCurrentTab)
            }
          }
        )
        }
      />
      <input
        style={hidden}
        value={stemcellCompatibleFilesCount}
        {...register(
          `${NATIVE}.${STEMCELL_COUNT}`,
          {
            required: isStemcellCertified,
            min: {
              value: isStemcellCertified ? 1 : 0,
              message: message('one_archive_file_required', setCurrentTab)
            }
          }
        )
        }
      />
    </>
  )
}
