// Just an adapted version of https://github.com/react-tools/react-table/blob/master/src/hoc/selectTable/index.js
// Don't show a checkbox if the row is is checkmate and disable checkbox during pending updates

import React from 'react'
import PropTypes from 'prop-types'
import { PENDING_ASSET_TYPE } from './constants'

const defaultSelectInputComponent = (props) => {
  const { selectType, checked, disabled, id, row, onClick: onClickHandler } = props
  return (
    <input
      type={selectType || 'checkbox'}
      checked={checked}
      disabled={disabled}
      onClick={(e) => {
        const { shiftKey } = e
        e.stopPropagation()
        onClickHandler(id, shiftKey, row)
      }}
      onChange={() => {}}
    />
  )
}

defaultSelectInputComponent.propTypes = {
  selectType: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  row: PropTypes.object,
  onClick: PropTypes.func.isRequired,
}

defaultSelectInputComponent.defaultProps = {
  id: undefined,
  row: undefined,
  disabled: false,
}

/* eslint-disable */
export default (Component) => {
  const wrapper = class RTSelectTable extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        resized: []
      }
    }
    // this is so we can expose the underlying ReactTable to get at the sortedData for selectAll
    getWrappedInstance() {
      if (!this.wrappedInstance) console.warn('RTSelectTable - No wrapped instance')
      if (this.wrappedInstance.getWrappedInstance) return this.wrappedInstance.getWrappedInstance()
      return this.wrappedInstance
    }

    rowSelector(row) {
      const {
        toggleSelection,
        selectType,
        keyField,
        SelectInputComponent,
        isPending,
        isSelected,
      } = this.props

      if (!row || !Object.prototype.hasOwnProperty.call(row, keyField)) return null

      const key = row[keyField]
      const disabled = isPending(key)
      const checked = !disabled && isSelected(key)

      const inputProps = {
        checked,
        disabled,
        onClick: toggleSelection,
        selectType,
        id: row[keyField],
        row,
      }
      if (row.type === PENDING_ASSET_TYPE) return null
      return React.createElement(SelectInputComponent, inputProps)
    }

    headSelector(row) {
      const { selectType } = this.props
      if (selectType === 'radio') return null

      const { toggleAll, selectAll: checked, SelectAllInputComponent } = this.props
      const inputProps = {
        checked,
        onClick: toggleAll,
        selectType,
      }

      return React.createElement(SelectAllInputComponent, inputProps)
    }

    render() {
      const {
        columns: originalCols,
        isSelected,
        toggleSelection,
        toggleAll,
        keyField,
        selectAll,
        selectType,
        selectWidth,
        SelectAllInputComponent,
        SelectInputComponent,
        ...rest
      } = this.props
      const select = {
        id: '_selector',
        accessor: () => 'x', // this value is not important
        Header: this.headSelector.bind(this),
        headerClassName: 'row-selector',
        className: 'row-selector',
        Cell: (ci) => this.rowSelector.bind(this)(ci.original),
        width: selectWidth || 50,
        filterable: false,
        sortable: false,
        resizable: false,
        style: { textAlign: 'center' },
      }
      const columns = [select, ...originalCols]
      const that = this
      const extra = {
        columns,
        resized: this.state.resized,
        onResizedChange: (newResized, event) => {
          const newResizedState = newResized.map((r) => {
            const column = originalCols.find((c) => c.id === r.id || c.accessor === r.id)
            if (column && r.value < column.minWidth) {
              return { ...r, value: column.minWidth }
            }
            return r
          })
          that.setState({
            resized: newResizedState
          })
        }
      }
      return <Component {...rest} {...extra} ref={(r) => (this.wrappedInstance = r)} />
    }
  }

  wrapper.displayName = 'RTSelectTable'
  wrapper.defaultProps = {
    keyField: '_id',
    isSelected: (key) => {},
    isPending: (key) => false,
    selectAll: false,
    toggleSelection: (key, shift, row) => {},
    toggleAll: () => {},
    selectType: 'check',
    SelectInputComponent: defaultSelectInputComponent,
    SelectAllInputComponent: defaultSelectInputComponent,
  }

  return wrapper
}
