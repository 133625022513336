/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import DismissableNotificationContainer from '../../../containers/publisher/EditHeader/DismissableNotificationContainer'

const i18nOptions = {
  scope: 'turbosquid.products.product_header',
}

const PublisherWarnings = ({
  turntableErrors,
  changeTab,
}) => {
  return (
    <div className="col-xs-12">
      {/* Turntable processing failure warning */}
      {
        turntableErrors.length > 0
        &&
        (
          <DismissableNotificationContainer notificationLevel="warning" notificationId="turntableErrorNotifications">
            <div>
              <p className="notification-message">{I18n.t('turbosquid.products.product_header.warning')}</p>
              <ul className="error-summary">
                <li>
                  <a href="#turbosquid_upload_turntable" className="field-link" onClick={() => changeTab(2)}>
                    <u>
                      {I18n.t('turbosquid.products.product_images_panel.turntables')}
                      :
                    </u>
                  </a>
                  <span>
                    {' '}
                    {I18n.t('turbosquid.products.product_images_panel.processing_error_turntables_message')}
                  </span>
                </li>
              </ul>
            </div>
          </DismissableNotificationContainer>
        )
      }
    </div>
  )
}


PublisherWarnings.propTypes = {
  turntableErrors: PropTypes.array,
  changeTab: PropTypes.func.isRequired,
}

PublisherWarnings.defaultProps = {
  turntableErrors: [],
}

export default PublisherWarnings
