/* global I18n, gon */
import React from 'react'
import Select from 'react-select'
import * as yup from 'yup'
import FormField from '../../../products/react_components/form_components/form_field'
import FormFieldGroup from '../../../products/react_components/form_components/form_field_group'
import { PublishValidationShaper, SaveDraftValidationShaper } from '../../../utils/SaveValidations'
import { IsActionPublish } from '../../../products/react_components/helpers'

import SyncField from './SyncField'

const i18nOptions = {
  scope: 'turbosquid.products.product_specifications_panel',
}

const panelPopoverHTML = () => (
  I18n.t('panel_popover.html', {
    ...i18nOptions,
    choosing_geometry_type_url: gon.choosing_geometry_type_url,
    polygon_count_url: gon.polygon_count_url,
    knowledge_base_url: gon.knowledge_base_url,
    unwrapped_uvs_url: gon.unwrapped_uvs_url,
    texture_references_url: gon.texture_references_url,
  })
)

const optionsForSelect = (collection, { translationKey }) => collection.map(
  (value) => ({
    value,
    label: I18n.t([i18nOptions.scope, translationKey, value]),
  })
)

const optionsForSelectGeometry = ({ translationKey }) => {
  return gon.geometry_types.map(
    (value) => ({
      value,
      label: I18n.t([i18nOptions.scope, translationKey, value]),
    })
  )
}

const draftValidationSchema = yup.object().shape(SaveDraftValidationShaper(2))
const publishValidationSchema = yup.object().shape(PublishValidationShaper(2))
const getPublishFieldSchema = (publishValidationTriggered, fieldName) => (
  publishValidationTriggered ? yup.reach(publishValidationSchema, fieldName) : undefined
)
const getPublishValidate = (publishValidationTriggered) => (
  publishValidationTriggered ? (name, value) => (
    yup.reach(publishValidationSchema, name).validate(value)
  ) : undefined
)

const checkboxFields = (props) => ({
  type: 'checkbox',
  formName: 'turbosquid_product_form',
  formActionInProgress: props.formActionInProgress,
  fields: [
    {
      fieldName: 'textures',
      label: () => I18n.t('textures', i18nOptions),
      checked: props.textures,
    },
    {
      fieldName: 'materials',
      label: () => I18n.t('materials', i18nOptions),
      checked: props.materials,
    },
    {
      fieldName: 'rigged',
      label: () => I18n.t('rigged', i18nOptions),
      checked: props.rigged,
    },
    {
      fieldName: 'animated',
      label: () => I18n.t('animated', i18nOptions),
      checked: props.animated,
    },
    {
      fieldName: 'uvMapped',
      label: () => I18n.t('uv_mapped', i18nOptions),
      checked: props.uvMapped,
    },
  ],
})

const ProductSpecifications3dModel = (props) => {
  const {
    formActionInProgress,
    publishValidationTriggered,
  } = props
  return (
    <div className="panel panel-default specifications-panel" id="js-turbosquid-product-specifications-panel">
      <div className="panel-heading">
        <button type="button" className="js-more-info" data-toggle="collapse" data-target="#specifications-panel-instructions" tabIndex="-1">
          {I18n.t('specification', i18nOptions)}
          <i className="fa fa-question-circle" />
        </button>
      </div>
      <div className="panel-body panel-collapse collapse in" id="specification-panel-body">
        <div className="panel-body-container">
          <div id="specifications-panel-instructions" className="panel-instructions collapse" dangerouslySetInnerHTML={{ __html: panelPopoverHTML() }/* eslint-disable-line */} />
          <div className="row">
            <div className="col-xs-6 geometry-specs 3d-model-specs">
              <FormField
                fieldName="geometry"
                valueField="geometry"
                formName="turbosquid_product_form"
                label={I18n.t('geometry_type_label', i18nOptions)}
                options={optionsForSelectGeometry({ translationKey: 'geometry_types' })}
                fieldSchema={yup.reach(draftValidationSchema, 'geometry')}
                type="react-select"
                searchable={false}
                clearable={false}
                disabled={IsActionPublish(formActionInProgress)}
                validate={(name, value) => yup.reach(draftValidationSchema, name).validate(value)}
                render={({ refs, ...innerProps }) => (
                  <Select ref={refs} {...innerProps} options={innerProps.options} isDisabled={innerProps.disabled} />
                )}
              />

              <FormField
                fieldName="polygons"
                formName="turbosquid_product_form"
                valueField="polygons"
                label={I18n.t('polygons', i18nOptions)}
                placeholder={I18n.t('polygons', i18nOptions)}
                type="number"
                inputtype="number"
                disabled={IsActionPublish(formActionInProgress)}
                isDisabled={IsActionPublish(formActionInProgress)}
                render={({ refs, ...innerProps }) => <input ref={refs} className="string optional form-control" {...innerProps} />}
                maxLength={gon.product_validations.polygons_number_gte * 10}
                fieldSchema={getPublishFieldSchema(publishValidationTriggered, 'polygons')}
                validate={getPublishValidate(publishValidationTriggered)}
              />

              <FormField
                fieldName="vertices"
                formName="turbosquid_product_form"
                valueField="vertices"
                label={I18n.t('vertices', i18nOptions)}
                placeholder={I18n.t('vertices', i18nOptions)}
                type="number"
                inputtype="number"
                disabled={IsActionPublish(formActionInProgress)}
                render={({ refs, ...innerProps }) => <input ref={refs} className="string optional form-control" {...innerProps} />}
                maxLength={gon.product_validations.vertices_number_gte * 10}
                fieldSchema={getPublishFieldSchema(publishValidationTriggered, 'vertices')}
                validate={getPublishValidate(publishValidationTriggered)}
              />

              <FormField
                fieldName="unwrappedUVs"
                valueField="unwrappedUVs"
                formName="turbosquid_product_form"
                label={I18n.t('unwrapped_uvs_label', i18nOptions)}
                options={optionsForSelect(gon.uv_types, { translationKey: 'unwrapped_uvs' })}
                fieldSchema={yup.reach(draftValidationSchema, 'unwrappedUVs')}
                type="react-select"
                searchable={false}
                clearable={false}
                disabled={IsActionPublish(formActionInProgress)}
                isDisabled={IsActionPublish(formActionInProgress)}
                validate={(name, value) => yup.reach(draftValidationSchema, name).validate(value)}
                render={({ refs, ...innerProps }) => {
                  return (
                    <SyncField
                      syncName="unwrappedUVs"
                      className="boolean optional"
                      {...innerProps}
                      render={(inceptionProps) => {
                        return (
                          <Select
                            ref={refs}
                            {...inceptionProps}
                            options={inceptionProps.options}
                            isDisabled={inceptionProps.disabled}
                          />
                        )
                      }}
                    />
                  )
                }}
              />
            </div>

            <div className="col-xs-6 boolean-specs 3d-model-specs">
              <FormFieldGroup
                {...checkboxFields(props)}z
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSpecifications3dModel
