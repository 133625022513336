import { CSRFToken } from './helpers'

const previewReducer = (accumulator, preview) => {
  accumulator[preview.id] = {
    id: preview.id,
    image_type: preview.image_type,
  }

  return accumulator
}

export const productParams = (state) => {
  const { featureState, formState } = state
  const formValues = formState.turbosquid_product_form.values

  let newFeatureIds = featureState.features.concat(featureState.brandFeatures || []).map(
    (feature) => feature.id
  )
  if (featureState.licenseFeature) {
    newFeatureIds = newFeatureIds.concat([featureState.licenseFeature])
  }
  if (featureState.collectionFeature) {
    newFeatureIds = newFeatureIds.concat([featureState.collectionFeature])
  }

  return {
    authenticity_token: CSRFToken(),
    turbosquid_product_form: {
      draft_id: formState.draftId,
      name: formValues.name,
      price: formValues.price,
      status: formState.status,
      certifications: formState.certifications || null,
      geometry: formValues.geometry,
      vertices: formValues.vertices || 0,
      polygons: formValues.polygons || 0,
      unwrapped_u_vs: formValues.unwrappedUVs,
      materials: formValues.materials,
      rigged: formValues.rigged,
      animated: formValues.animated,
      textures: formValues.textures,
      uv_mapped: formValues.uvMapped,
      length: formValues.length || null,
      frame_rate: formValues.frameRate,
      biped: formValues.biped,
      loopable: formValues.loopable,
      width: formValues.width || null,
      height: formValues.height || null,
      color_depth: formValues.colorDepth,
      tileable: formValues.tileable,
      alpha_channel: formValues.alphaChannel,
      multiple_layers: formValues.multipleLayers,
      license:         formState.license,
      display_tags:    formValues.tags,
      description:     formValues.description,
    },
    previews: formState.previews.reduce(previewReducer, {}),
    feature_ids: newFeatureIds,
    missing_brand: featureState.missingBrand,
  }
}
