/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { statusList } from '../constants'

const i18nOptions = { scope: 'turbosquid_products.status_preview' }

const validStatusList = statusList
const productCanBeUpdated = (product) => (
  validStatusList.includes(product.status)
)

function ProductsStatusPreview(props) {
  const { products } = props
  const display = products.length ? 'block' : 'none'

  const updatableProducts = products.filter(productCanBeUpdated)
  const statusWarningDisplay = updatableProducts.length === products.length ? 'none' : 'block'

  return (
    <>
      <div id="bulk-update-status-caption" className="col-sm-12 bulk-update-status-caption" style={{ display }}>
        <span>{I18n.t('title', { ...i18nOptions, count: products.length })}</span>
        <p className="text-warning" style={{ display: statusWarningDisplay }}>{I18n.t('status_notes', { ...i18nOptions })}</p>
      </div>
      <table id="bulk-update-status-previewer" className="table table-striped table-fixed" style={{ display }}>
        <thead>
          <tr>
            <th className="col-sm-8 left-column name">{I18n.t('name', i18nOptions)}</th>
            <th className="col-sm-2 old-status">{I18n.t('status', i18nOptions)}</th>
            <th className="col-sm-2 right-column new-status">{I18n.t('new_status', i18nOptions)}</th>
          </tr>
        </thead>
        <tbody>
          {
            products.map((product) => (
              <tr id={`preview-${product.turbosquid_id}`} key={product}>
                <td className="col-sm-8 left-column name">{product.name}</td>
                <td className="col-sm-2 old-status">{product.status}</td>
                <td className="col-sm-2 right-column new-status">
                  {product.newStatus}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  )
}

export default ProductsStatusPreview

ProductsStatusPreview.propTypes = {
  products: PropTypes.array.isRequired,
}
