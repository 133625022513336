import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { syncDraft as syncDraftUI } from '../products/react_components/actions/publisher_ui_slice_actions'
import { syncDraft as syncDraftForm } from '../products/react_components/actions/form_slice_actions'
import { arrayFrom } from '../utils/ProductFiles'
import { CERTIFIED_PREVIEWS } from '../components/products/constants'

export default function useSyncLegacyDraft({ draft, certifications, isSaving }) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (draft) {
      const { id, name, updatedAt, createdAt } = draft
      const certifiedPreviews = arrayFrom(certifications).find(c => c.id === CERTIFIED_PREVIEWS)
      dispatch(syncDraftUI({ isSaving }))
      dispatch(syncDraftForm({
        id,
        name,
        updatedAt: updatedAt ? updatedAt.toString() : undefined,
        createdAt: createdAt ? createdAt.toString(): undefined,
        certifications: {
          certifiedPreviews
        },
      }))
    }
  }, [draft, certifications, isSaving])
}
