/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import CheckMateQueueItem from './checkmate_queue_item';
import PanelSpinner from './common/panel_spinner';
import DashboardClient from './common/dashboard_client';

const CheckMateQueue = createReactClass({
  displayName: 'CheckMateQueue',
  mixins: [DashboardClient],
  endpoint() {
    return '/turbosquid/dashboard_widgets/checkmate_queue.json'
  },
  render() {
    if (!gon.checkmate_enabled) {
      return;
    }

    if (this.state.data != null) {
      if (this.state.data.checkmate_queue.length > 0) {
        return (
          <div className="panel-overflow">
            <table className="table table-light checkmate-queue">
              <thead>
                <tr>
                  <th>{I18n.t('dashboard.checkmate.asset_name')}</th>
                  <th>{I18n.t('dashboard.checkmate.status')}</th>
                  <th>{I18n.t('dashboard.checkmate.updated')}</th>
                </tr>
              </thead>
              <tbody>{this.state.data.checkmate_queue.map((item) => <CheckMateQueueItem key={item.product_turbosquid_id} queue_item={item} />)}</tbody>
            </table>
          </div>
        )
      } if (this.props.tier_setting.checkmate_published_count > 0) {
        return <div className="panel-empty text-center"><p className="text-muted">{I18n.t('dashboard.checkmate.no_products_awaiting')}</p></div>
      }
      return (
        <div className="text-center">
          <div className="panel-empty">
            <h3>{I18n.t('dashboard.checkmate.increase_your_sales_potential')}</h3>
            <p className="text-muted">{I18n.t('dashboard.checkmate.boost_sales')}</p>
          </div>
          <div className="row publish-product-action"><div className="col-xs-12 text-center"><a className="btn btn-warning" href={gon.checkmate_home_url}>{I18n.t('dashboard.checkmate.discover_checkMate')}</a></div></div>
        </div>
      )
    }
    return <PanelSpinner />
  },
})

export default CheckMateQueue;
