/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const d3 = require('d3-format')

this.NumberUtility = {
  formatCurrency(float, symbol) {
    const currency = symbol || ''
    const result = `${currency} ${d3.format(',')(float)}`
    return result
  },
  formatInteger(integer) {
    return d3.format('.0f')(integer)
  },
  randomKey(prefix) {
    return `${prefix}_${Math.random().toString()}`
  },
}

module.exports = this.NumberUtility
