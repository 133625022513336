import { post, get } from './service'
import { mapPreview } from './gon-service'
import { TURNTABLE } from '../components/products/constants'

export const create = ({ fileIds, draftId, csrfToken }) => {
  return post(`/turbosquid/products/${draftId}/turntables`, {
    file_ids: fileIds,
    draft_id: draftId.toString(),
    authenticity_token: csrfToken,
    turntable_type: 'image',
    watermarked: false
  })
}

export const pollTurntablesReady = ({ ids, draftId }) => {
  return get(`/turbosquid/products/${draftId}/turntables/bulk_poll?ids[]=${ids.join(',')}`)
    .then(result => result.map(p => ({
      ...mapPreview(p),
      type: TURNTABLE,
      status: p.status,
      jobId: p.job_id
    })))
}
