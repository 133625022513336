import React from 'react'
import PropTypes from 'prop-types'

const Icon = (props) => {
  const { type } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <i className={`fa fa-${type}`} {...props} />
  )
}

Icon.propTypes = {
  type: PropTypes.string,
}

Icon.defaultProps = {
  type: '',
}

export default Icon
