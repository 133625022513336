import { useRef } from 'react'
import { useMutation } from 'react-query'

export default function useMutations(mutationKey, mutationFn) {
  // Tracking multiple mutations
  const mutations = useRef({})

  const {
    mutateAsync,
    // These only track one mutation at a time
    // isLoading,
    // isSuccess,
    // reset
  } = useMutation(mutationKey, mutationFn)

  const requestStart = (id) => {
    mutations.current = {
      ...mutations.current,
      [id]: {
        isLoading: true,
        isSuccess: false,
        isError: false,
      }
    }
  }

  const requestError = (id) => {
    mutations.current = {
      ...mutations.current,
      [id]: {
        isLoading: false,
        isSuccess: false,
        isError: true,
      }
    }
  }

  const requestSuccess = (id) => {
    mutations.current = {
      ...mutations.current,
      [id]: {
        isLoading: false,
        isSuccess: true,
        isError: false,
      }
    }
  }

  const reset = (requestId) => {
    mutations.current = {
      ...mutations.current,
      [requestId]: {
        isLoading: false,
        isSuccess: false,
        isError: false,
      }
    }
  }

  const request = async (requestId, body) => {
    requestStart(requestId)
    try {
      const resp = await mutateAsync(body)
      requestSuccess(requestId)
      return resp
    } catch (e) {
      requestError(requestId)
      throw e
    }
  }

  return {
    mutations,
    mutateAsync: request,
    reset
  }
}
