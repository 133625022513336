/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';

const ReportError = createReactClass({
  displayName: 'ReportError',
  supportURL() {
    return gon.ts_support_home_url
  },
  render() {
    return (
      <div className="col-xs-12">
        <div className="data-error">
          <strong>{I18n.t('dashboard.sales.data_warehouse_down.title')}</strong>
          {I18n.t('dashboard.sales.data_warehouse_down.description')}
          <a href={this.supportURL()} target="_blank">{I18n.t('dashboard.sales.data_warehouse_down.contact_support')}</a>
          {'.\
'}
        </div>
      </div>
    )
  },
})

export default ReportError;
