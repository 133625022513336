import React from 'react'
import PropTypes from 'prop-types'

export default class FeatureSelectOption extends React.PureComponent {
  render() {
    const {
      option,
      innerProps,
      isFocused,
      innerRef,
    } = this.props
    const {
      term,
      text,
      parent,
      thumbnail_url: thumbnailUrl,
    } = option

    const index = term ? text.toUpperCase().indexOf(term.toUpperCase()) : 0

    const prefix = text.substring(0, index)
    const termLength = term ? term.length : 0
    const remainder = text.substring(index + termLength, text.length)
    const getClassName = isFocused ? 'result focused' : 'result'
    return (
      <div id={innerProps.id} ref={innerRef} key={innerProps.key} className={getClassName} onMouseOver={innerProps.onMouseOver} onFocus={() => 0} onClick={innerProps.onClick} role="button" tabIndex={innerProps.tabIndex}>
        {
          thumbnailUrl
          && <img className="thumb" src={thumbnailUrl} alt={text} />
        }
        <div className="term">
          { prefix }
          <span className="match">{term || ''}</span>
          { remainder }
          { parent
            && <span className="ancestor">{` in ${parent}`}</span>}
        </div>
      </div>
    )
  }
}

FeatureSelectOption.propTypes = {
  option: PropTypes.object,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool,
  innerRef: PropTypes.any,
}

FeatureSelectOption.defaultProps = {
  option: null,
  isFocused: false,
  innerRef: null,
}
