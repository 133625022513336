import { connect } from 'react-redux'
import ProductTags from '../../../components/publisher/ProductSpecs/ProductTags'
import { filterNotifications } from '../../../products/react_components/helpers'
import { toggleInfo } from '../../../products/react_components/actions/publisher_ui_slice_actions'
import { fetchConstraints } from '../../../products/react_components/actions/form_slice_actions'
import { selectPublishValidationTriggered } from '../../../products/react_components/selectors/formSelectors'

const highlightPanel = (alerts) => (
  alerts.find((a) => a.alert_panels.find((p) => p === 'tags'))
)

const mapStateToProps = (state) => {
  const { formState, uiState } = state
  const { alerts } = formState
  const { formValidationErrors, values } = formState.turbosquid_product_form

  return {
    errors: formValidationErrors,
    tags: values.tags,
    notifications: filterNotifications(alerts, 'tags'),
    highlightPanel: highlightPanel(alerts),
    showInfo: uiState.tagsPanel.showInfo,
    formActionInProgress: formState.formActionInProgress,
    publishValidationTriggered: selectPublishValidationTriggered(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateTags: (tags, errors) => dispatch(fetchConstraints({ tags, errors })),
  toggleInfo: (value) => dispatch(toggleInfo({ panel: 'tagsPanel', value })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTags)
