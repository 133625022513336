import React, { useContext, useEffect } from 'react'
import { roundedSizeInMBFromB } from '../../../products/react_components/helpers'
import FileIcon from './FileIcon'
import { Controller } from 'react-hook-form'
import { FileContext } from '../../../contexts/FileContext'
import ReactSpinner from '../../../products/react-spinner'
import { reactSelectStyle } from '../../../utils/Styles'
import Select from 'react-select'
import useFileForm from '../../../hooks/useFileForm'
import { filesI18nOptions, NATIVE, stemcellCertifiable3DFormats, stemcellCcertifiablRenderers } from '../../products/constants'
import {
  recognizeFormat,
  nativeErrorMessage as message,
  filter3dModels
} from '../../../utils/ProductFiles'
import FormError from './FormError'
import { ProductContext } from '../../../contexts/ProductContext'
import { UIContext } from '../../../contexts/UIContext'

export const availableRenderers = (selectedFileFormat) => ([
  ...Object.values(selectedFileFormat.renderers)
    .filter((renderer) => renderer.id !== 'other')
    .map((renderer) => ({
      value: renderer.id,
      label: renderer.attributes.name,
    })),
  {
    value: 'other',
    label: I18n.t('other', filesI18nOptions),
  },
])

export default function NativeFile({ file, formats = [], onSetPrimary, onRemovePrimary }) {
  const { id, attributes } = file
  const { name, size, isNative } = attributes
  const { setCurrentTab } = useContext(UIContext)
  const {
    validations,
    certifications: { isStemcellCertified },
  } = useContext(ProductContext)
  const { deleteFiles, pendingDelete } = useContext(FileContext)
  const {
    register,
    control,
    setValue,
    formState: {
      errors: allErrors
    },
    fileFormValues,
    isReady,
  } = useFileForm(file, {
    isNative: attributes.isNative,
    format: attributes.format,
    formatVersion: attributes.formatVersion,
    renderer: attributes.renderer,
    rendererVersion: attributes.rendererVersion
  })
  const errors = allErrors ?? {}
  const format = fileFormValues.format
  const applicableFormats = formats
  const formatOptions = applicableFormats
    .filter(filter3dModels)
    .map(f => ({ label: f.name, value: f.id }))
  const formatData = (formats || []).find(f => f.id === format)
  const rendererOptions = formatData ? availableRenderers(formatData) : []
  const extensionIsNative = !!formatData?.native
  const isPendingDelete = pendingDelete.includes(id)

  useEffect(() => {
    if (formats && !format) {
      const recognized = recognizeFormat(name, formats)
      if (recognized) {
        setValue(`${id}.format`, recognized)
      }
    }
  }, [formats])

  const handleDeleteFile = () => deleteFiles({ files: [file] })

  const disabled = !isReady

  const handleFormatValidation = (format) => {
    if (isStemcellCertified && isNative) {
      return stemcellCertifiable3DFormats.includes(format) || message('format_not_available_for_stemcell', setCurrentTab)
    }

    return true;
  }

  const handleRendererValidation = (renderer) => {
    if (isStemcellCertified && isNative) {
      return stemcellCcertifiablRenderers.includes(renderer) || message('renderer_not_available_for_stemcell', setCurrentTab)
    }

    return true;
  }

  return (
    <li>
      <div className="panel-body panel-collapse collapse in file-display native-file-display">
        <div className="row file-header">
          {isNative && (
            <button
              onClick={() => onRemovePrimary(file)}
              className="clear-native-flag"
              disabled={disabled}
            >
              {I18n.t('native', filesI18nOptions)}
            </button>
          )}
          <span className="file-name">{name}</span>
          <span className="file-size">{`${roundedSizeInMBFromB(size)} MB`}</span>
          {
            isPendingDelete
              ? <span className="delete-file-spinner-container pull-right"><ReactSpinner /></span>
              : <button disabled={disabled} onClick={handleDeleteFile} className="fa fa-times pull-right delete-file" />
          }
        </div>
        <div className="row file-specs">
          <FileIcon />
          <div className="col-md-4">
            <div className="form-group select optional"
              data-testid="file-type-dropdown">
              <label htmlFor={`${id}.format`} className="select optional control-label" >{I18n.t('file_type', filesI18nOptions)}</label>
              <Controller
                id={`${id}.format`}
                name={`${id}.format`}
                control={control}
                rules={{
                  required: {
                    value: isNative,
                    message: isStemcellCertified ? message('format_not_available_for_stemcell', setCurrentTab) : message('format_required', setCurrentTab)
                  },
                  validate: (v) => handleFormatValidation(v)
                }}
                render={({ field: { onChange, onBlur } }) => {
                  return (
                    <Select
                      id={`${id}.format`}
                      aria-label={I18n.t('file_type', filesI18nOptions)}
                      className="select optional"
                      value={formatOptions.find(f => f.value === format)}
                      searchable={true}
                      onBlur={onBlur}
                      onChange={({ value }) => onChange(value)}
                      styles={reactSelectStyle}
                      options={formatOptions}
                      isDisabled={disabled}
                    />
                  )
                }}
              />
              {errors[id]?.format && <FormError error={errors[id].format} />}
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group string">
              <label htmlFor={`${id}.formatVersion`} className="string optional control-label" >{I18n.t('version', filesI18nOptions)}</label>
              <input
                type="text"
                id={`${id}.formatVersion`}
                className="string optional form-control"
                data-testid="filetype-version-input"
                maxLength={validations.versionMaxLength}
                disabled={disabled}
                {...register(`${id}.formatVersion`, {
                  required: {
                    value: extensionIsNative,
                    message: message('format_version_required', setCurrentTab)
                  }
                })}
              />
              {errors[id]?.formatVersion && <FormError error={errors[id].formatVersion} />}
            </div>
          </div>
          {extensionIsNative && (
            <>
              <div className="col-md-4">
                <div className="form-group select optional"
                  data-testid="renderer-used-dropdown">
                  <label htmlFor={`${id}.renderer`} className="select optional control-label" >{I18n.t('renderer', filesI18nOptions)}</label>
                  <Controller
                    id={`${id}.renderer`}
                    name={`${id}.renderer`}
                    control={control}
                    rules={{
                      required: {
                        value: extensionIsNative,
                        message: isStemcellCertified ? message('renderer_not_available_for_stemcell', setCurrentTab) : message('renderer_required', setCurrentTab)
                      },
                      validate: (v) => handleRendererValidation(v)
                    }}
                    render={({ field: { onBlur, onChange, value: rendererValue } }) => {
                      return (
                        <Select
                          id={`${id}.renderer`}
                          aria-label={I18n.t('renderer', filesI18nOptions)}
                          className="select optional"
                          value={rendererOptions.find(r => r.value === rendererValue)}
                          searchable={true}
                          onBlur={onBlur}
                          onChange={({ value: updatedValue }) => onChange(updatedValue)}
                          styles={reactSelectStyle}
                          options={rendererOptions}
                          isDisabled={disabled}
                        />
                      )
                    }}
                  />
                  {errors[id]?.renderer && <FormError error={errors[id].renderer} />}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group string">
                  <label htmlFor={`${id}.rendererVersion`} className="string optional control-label" >{I18n.t('version', filesI18nOptions)}</label>
                  <input
                    type="text"
                    id={`${id}.rendererVersion`}
                    className="string optional form-control"
                    data-testid="renderer-version-input"
                    maxLength={validations.versionMaxLength}
                    disabled={disabled}
                    {...register(`${id}.rendererVersion`, { maxLength: validations.versionMaxLength })}
                  />
                  {errors[id]?.rendererVersion && <FormError error={errors[id].rendererVersion} />}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="row file-tools">
          {!disabled && !isNative && extensionIsNative && (
            <button
              type="button"
              className={'select-as-native'}
              onClick={() => onSetPrimary(file)}
            >
              {I18n.t('select_as_native_file_format', filesI18nOptions)}
            </button>
          )}
        </div>
      </div>
    </li>
  )
}
