/* global I18n */
import React from 'react'
import MenuListItem from './MenuListItem'
import { currentSelection, safeTranslate } from '../helpers'

const CustomDropdownMenu = (
  filterKey,
  options,
  search,
  aggregations
) => {
  const displayedOptions = ['All'].concat(options)
  const dropdownID = `dropdown_${filterKey}`
  return (
    <li className="dropdown" id={dropdownID}>
      <button type="button" className="dropdown-toggle filter" data-toggle="dropdown">
        <span>
          {safeTranslate(filterKey)}
          :
          {' '}
        </span>
        <span className="current-selection">{safeTranslate(currentSelection(filterKey, search))}</span>
      </button>
      <ul className="dropdown-menu" aria-labelledby="search-status">
        {displayedOptions.map((option) => MenuListItem(filterKey, option, search, aggregations))}
      </ul>
    </li>
  )
}

export default CustomDropdownMenu
