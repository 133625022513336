import React from 'react'
import { Modal } from 'react-bootstrap'

const i18nOptions = {
  scope: 'turbosquid.products.timeout',
}

const customStyles = {
  content: {
    top: '16%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
  },
}

export const IdleTimeOutModal = ({ handleStay }) => (
  <Modal show className="timeout-modal-container">
    <Modal.Dialog>
      <Modal.Header>
        <button
          className="btn-close"
          type="button"
          onClick={handleStay}
        />
        <Modal.Title>{I18n.t('page_session_timeout', i18nOptions)}</Modal.Title>
        <div>{I18n.t('inactivity', i18nOptions)}</div>
      </Modal.Header>
      <div className="separator" />
      <Modal.Footer>
        <button className="btn-gray">
          <a href={gon.turbosquid_products_path}>{I18n.t('return_to_my_products', i18nOptions)}</a>
        </button>
        <button onClick={handleStay} className="btn-orange">{I18n.t('continue_editing', i18nOptions)}</button>
      </Modal.Footer>
    </Modal.Dialog>
  </Modal>
)
