import React, { useContext } from 'react'
import { FileContext } from '../../../contexts/FileContext'
import Dropzone from './Dropzone'
import { arrayFrom, count, filterStatusNot, filterType } from '../../../utils/ProductFiles'
import { ProductContext } from '../../../contexts/ProductContext'
import { mapTextureFileUpload } from '../../../services/upload-service'
import useFileUploads from '../../../hooks/useFileUploads'
import AssociatedFile from './AssociatedFile'
import {
  ASSOCIATED,
  CUSTOMER, FAILED,
  filesI18nOptions,
  MAX_UPLOADABLE_FILES,
  PROMOTIONAL,
  TEXTURE,
  VIEWER
} from '../../products/constants'
import { NotificationContext } from '../../../contexts/NotificationContext'
import Notification from './Notification'
import { helpTextColumns } from './HelpTextColumns'
import imgMarmoset from '../../../../../assets/images/MarmosetIcon-01.png'
import imgPromotional from '../../../../../assets/images/PromotionalIcon-01.png'
import imgCustomerOnlyIcon from '../../../../../assets/images/CustomerOnlyIcon-01.png'
import { fileName, fileSize } from './validators'
import { formatAlert } from '../../../hooks/useNotifications'
import { UploadContext } from '../../../contexts/UploadContext'
import { uniqueName } from './filters'

export default function AssociatedFiles() {
  const { draft } = useContext(ProductContext)
  const { files: { associated }} = useContext(FileContext)
  const { uploads } = useContext(UploadContext)
  const texture = filterType(associated, [TEXTURE])
  const customer = filterType(associated, [CUSTOMER])
  const promotional = filterType(associated, [PROMOTIONAL])
  const viewer = filterType(associated, [VIEWER])
  const { alert, clear, notifications } = useContext(NotificationContext)
  const { ready, handleFileUploads } = useFileUploads({
    // Associated files are defaulted to texture files
    mapUpload: file => mapTextureFileUpload({ file, draft }),
    maxFiles: MAX_UPLOADABLE_FILES,
    batchFilters: [uniqueName({
      ...associated,
      ...filterStatusNot(uploads[ASSOCIATED], FAILED)
    })],
    validators: [fileSize, fileName],
    onError: errors => errors.forEach(message => alert(formatAlert(message))),
  })

  const disabled = !ready

  return (
    <div className={`panel panel-default files-panel native-files product-native-files-container files-panel-container ${!!count(associated) ? 'has-files' : ''}`}>
      <div className="panel-body panel-collapse collapse in panel-files-wrapper panel-native-files">
        <div className="panel-body-container">
          <Dropzone className="dzu-dropzone" disabled={disabled} onChange={handleFileUploads} className="associated-files-container">
            <div className="file-list-wrapper associated-files-wrapper">
              <div className="tab-alert-container">
                {notifications.map(n =>
                  <Notification key={n.message} notification={n} clear={clear} />
                )}
              </div>
              <div className="full-size-uploader-no-cloud single-upload">
                <div className="upload-files-label">
                  <div className="upload-files-label-title browse-button">
                    <span className="drag-files-label">{I18n.t('drag_files_here_or', filesI18nOptions)}</span>
                    <label className={`browse-files-link ${disabled ? 'disabled' : ''}`} htmlFor="texture-input">
                      {I18n.t('browse', filesI18nOptions)}
                    </label>
                    <input
                      type="file"
                      id="texture-input"
                      multiple="multiple"
                      style={{ display: 'none' }}
                      onChange={handleFileUploads}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
              <ul className="file-list">
                {arrayFrom(texture).map(file => <AssociatedFile key={file.id} file={file} />)}
                {arrayFrom(customer).map(file => <AssociatedFile key={file.id} file={file} />)}
                {arrayFrom(promotional).map(file => <AssociatedFile key={file.id} file={file} />)}
                {arrayFrom(viewer).map(file => <AssociatedFile key={file.id} file={file} />)}
              </ul>
              {!count(associated) && (
                <div className="full-size-uploader">
                  <div className="full-size-uploader">
                    <i data-testid="upload-icon" className="fa fa-cloud-upload" />
                  </div>
                  {
                    helpTextColumns([imgPromotional, imgMarmoset, imgCustomerOnlyIcon],
                      [
                        I18n.t('textures_customer_only_downloads',filesI18nOptions),
                        I18n.t('marmoset_viewer', filesI18nOptions),
                        I18n.t('promotional_downloads', filesI18nOptions)
                      ],
                      [
                        I18n.t('textures_customer_only_downloads_body', filesI18nOptions),
                        I18n.t('marmoset_viewer_body', filesI18nOptions),
                        I18n.t('promotional_downloads_body', filesI18nOptions)
                      ]
                    )
                  }
                </div>
              )}
            </div>
          </Dropzone>
        </div>
      </div>
    </div>
  )
}
