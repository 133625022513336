/* global I18n */
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import ProductLicense from '../../../components/publisher/ProductSpecs/ProductLicense'
import { filterNotifications, mergeAlerts, mergeProhibitedWords, arrayToList } from '../../../products/react_components/helpers'
import { selectLicense } from '../../../products/react_components/selectors/formSelectors'
import {
  prohibitExtendedUses,
  disableEditorialLicense,
} from '../../../products/react_components/LicenseHelpers'
import { toggleInfo } from '../../../products/react_components/actions/publisher_ui_slice_actions'

import { updateBrandFeatures, updateLicense } from '../../../products/react_components/actions/form_slice_actions'

const highlightPanel = (alerts) => (
  alerts.find((a) => a.alert_panels.find((p) => p === 'license'))
)

const prohibitedWordsAlerts = (state) => {
  const words = mergeProhibitedWords(state)
  const terms = isEmpty(words) ? false : arrayToList(words)
  return terms ? [{
    notifications: [{
      panel: 'categories',
      message: I18n.t('turbosquid.products.edit.prohibited', { term: terms }),
    }],
    alert_panels: ['license'],
    type: 'publish_not_allowed',
    level: 'warning',
  }] : []
}

const mapStateToProps = (state) => {
  const prohibitedAlerts = prohibitedWordsAlerts(state)
  const alertMessages = prohibitedAlerts.concat(mergeAlerts(state))
  const { formState: { constraints = [] } } = state

  return {
    license: selectLicense(state),
    notifications: filterNotifications(alertMessages, 'license'),
    highlight_panel: highlightPanel(alertMessages),
    prohibit_extended_uses: prohibitExtendedUses(alertMessages, constraints),
    prohibit_editorial_uses: disableEditorialLicense(alertMessages),
    showInfo: state.uiState.licensePanel.showInfo,
    errors: state.formState.turbosquid_product_form.formValidationErrors,
    formActionInProgress: state.formState.formActionInProgress,
    brandFeatures: state.featureState.brandFeatures,
    focusBrandInput: state.formState.focusBrandInput,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLicense: (license) => dispatch(updateLicense({ license })),
  toggleInfo: (value) => dispatch(toggleInfo({ panel: 'licensePanel', value })),
  updateBrandFeatures: (features) => dispatch(updateBrandFeatures({ features })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductLicense)
