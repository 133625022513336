/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { Overlay, Popover } from 'react-bootstrap'

class ToggleAccess extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: props.checked,
      disabled: props.disabled || false,
      size: props.size || '',
      alignClass: props.alignClass || '',
      showPopover: props.showPopover || false,
      target: props.target || null,
    }
  }

  toggle = (e) => {
    const { checked, disabled } = this.state
    const { onChange } = this.props
    if (!disabled) {
      this.setState({
        checked: !checked,
      })
      if (onChange) onChange(!checked)
    }
    this.setState({ target: e.target, showPopover: checked })
  }

  render = () => {
    const { checked, disabled, size, alignClass, showPopover, target } = this.state
    return (
      <div className={`switch-container ${alignClass}`}>
        <button
          type="button"
          className={`switch switch-${size} ${checked ? 'switch-checked' : ''} ${disabled ? 'switch-disabled' : ''}`}
          onClick={this.toggle}
        >
          <span className="switch-inner" />
        </button>
        <Overlay
          show={showPopover}
          target={target}
          placement="left"
          container={document.body}
          rootClose
          onHide={() => this.setState({ showPopover: false })}
        >
          <Popover id="toggleAccess-popover-contained">
            <p dangerouslySetInnerHTML={{ // eslint-disable-line
              __html: I18n.t('turbosquid_products.current_publisher_popover_html'),
            }}
            />
          </Popover>
        </Overlay>
        <div className="switch-tip">{I18n.t('turbosquid_products.current_publisher_label')}</div>
      </div>
    )
  }
}

export default ToggleAccess

ToggleAccess.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  alignClass: PropTypes.string,
  onChange: PropTypes.func,
  showPopover: PropTypes.bool,
  target: PropTypes.object,
}

ToggleAccess.defaultProps = {
  checked: false,
  disabled: false,
  size: 'small',
  alignClass: '',
  onChange: null,
  showPopover: false,
  target: null,
}
