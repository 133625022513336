import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'

function AlertBox(props) {
  const { resetAlertBox, timeout, type, message, isBundleAlert } = props
  setTimeout(() => { resetAlertBox() }, timeout || 1800000)

  return (
    <Alert bsStyle={type} onDismiss={resetAlertBox} style={{padding: isBundleAlert ? '1rem 3rem 0.5rem 1.25rem': ''}}
    >
      {message}
    </Alert>
  )
}

export default AlertBox

AlertBox.propTypes = {
  resetAlertBox: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  type: PropTypes.string,
  message: PropTypes.string.isRequired,
}

AlertBox.defaultProps = {
  timeout: 1800000,
  type: '',
}
