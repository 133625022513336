import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'spin'

class ReactSpinner extends React.Component {
  componentDidMount() {
    const { color, config } = this.props

    const spinConfig = {
      width: 2,
      radius: 6,
      length: 3,
      color,
      ...config,
    }

    // eslint-disable-next-line no-undef
    this.spinner = new Spinner(spinConfig)
    // eslint-disable-next-line react/no-string-refs
    this.spinner.spin(this.refs.container)
  }

  componentWillUnmount = () => {
    this.spinner.stop()
  }

  render = () => (
    // eslint-disable-next-line react/no-string-refs
    <span ref="container" />
  )
}

ReactSpinner.propTypes = {
  config: PropTypes.object,
  color: PropTypes.string,
}

ReactSpinner.defaultProps = {
  config: {},
  color: 'black',
}

export default ReactSpinner
