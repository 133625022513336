const getValueFromEvent = ({ checked, type, value }) => (
  type === 'checkbox' ? checked : value
)

const getNameFromEvent = ({ name }) => {
  const matches = name.match(/\[(\w+)\]/)
  return (matches ? matches.pop() : name)
}

export const handleChanges = (dispatch) => ({
  handleSelectChange: (name, value) => {
    dispatch({ type: 'UPDATE_SPECIFICATIONS', name, value })
  },
  handleInputChange: ({ target }) => {
    const name = getNameFromEvent(target)
    const value = getValueFromEvent(target)
    dispatch({ type: 'UPDATE_SPECIFICATIONS', name, value })
  },
})
