/* global I18n */
import React from 'react'
import { fieldLabels } from './SaveValidations'
import { lockForCertWithFiles } from '../products/react_components/certification_helpers'

export const idForSpecField = (key) => `turbosquid_product_form_${key}`

export const idFromErrorKey = (key) => {
  if (Object.keys(fieldLabels()).find((label) => key === label)) {
    if (key == 'not_allowed_category') return 'brand_feature_widget'
    return idForSpecField(key)
  }

  return undefined
}

export const translationFromErrorKey = (key) => (
  fieldLabels()[key]
)

export const fileTypeToFileTabLookup = {
  mainFiles: 'product-files-native-pane',
  previews: 'product-files-image-pane',
  associatedFiles: 'product-files-associated-pane',
}

export const fileTypeToFileTabEventKeyLookup = {
  mainFiles: 1,
  previews: 2,
  associatedFiles: 3,
}

export const idFromErrorKeyMainFiles = (key, id) => (
  `turbosquid_product_form_native_files_${id}_${key}`
)

