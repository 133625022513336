/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { UV, WIREFRAME } from '../../components/products/constants'

const i18nOptions = {
  scope: 'turbosquid.products.badge',
}

const getBadgeName = (imageType) => {
  switch (imageType) {
    case UV:
      return I18n.t('uv', i18nOptions)
    case WIREFRAME:
      return I18n.t('wire', i18nOptions)
    default:
      return ''
  }
}

const Badge = ({ type, imageType, className }) => {
  const name = getBadgeName(imageType)
  return (
    <div className={`badge-container ${className || ''} ${(name === '' && type !== 'turntable') ? 'hide' : ''}`}>
      {
        type === 'turntable'
        && (
          <span className="block">360&deg;</span>
        )
      }
      <span className={`block ${(name === '') ? 'hide' : ''}`}>{name}</span>
    </div>
  )
}

Badge.propTypes = {
  type: PropTypes.string,
  imageType: PropTypes.string,
  className: PropTypes.string,
}

Badge.defaultProps = {
  type: '',
  imageType: 'thumbnail',
  className: '',
}

export default Badge
