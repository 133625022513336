import React, { useContext, useState } from 'react'
import Dropzone from './Dropzone'
import {
  arrayFrom,
  checkThumbnailType,
  count,
  filterStatusNot,
  filterType,
  imageResolution
} from '../../../utils/ProductFiles'
import { ProductContext } from '../../../contexts/ProductContext'
import { FileContext } from '../../../contexts/FileContext'
import useFileUploads from '../../../hooks/useFileUploads'
import { mapPreviewFileUpload, mapTurntableFileUpload } from '../../../services/upload-service'
import {
  FAILED,
  filesI18nOptions,
  THUMBNAIL,
  TURNTABLE,
} from '../../products/constants'
import PreviewFile from './PreviewFile'
import { UploadContext } from '../../../contexts/UploadContext'
import useTurntableCandidates from '../../../hooks/useTurntableCandidates'
import TurntableCandidate from './TurntableCandidate'
import PreviewBulkOption from './PreviewBulkOption'
import PreviewSpecs from './PreviewSpecs'
import CertifiedPreviews from './CertifiedPreviews'
import SearchImagePlaceholder from './SearchImagePlaceholder'
import {
  acceptImageExtensions,
} from '../../../utils/ImageValidations'
import { NotificationContext } from '../../../contexts/NotificationContext'
import Notification from './Notification'
import {
  fileName,
  fileSize,
  previewFileExtension,
  previewMaxDimensions,
  turntableFileLimit,
  turntableImageName
} from './validators'
import { formatAlert } from '../../../hooks/useNotifications'
import PreviewValidations from './PreviewValidations'
import { useFormContext } from 'react-hook-form'
import { usePreviewsOrder } from '../../../hooks/usePreviewsOrder'
import { getFileInfo } from '../../../utils/TurntableUtils'
import { mapThumbnailType } from '../../../services/file-service'
import usePreviewSpecs from '../../../hooks/usePreviewSpecs'
import { previewBatchSize, uniqueName } from './filters'

export default function PreviewFiles() {
  const [selected, setSelected] = useState({})
  const form = useFormContext()
  const {
    draft,
    draftId,
    tips,
    overridePreviewRequirements,
  } = useContext(ProductContext)
  const { alert, clear, notifications } = useContext(NotificationContext)
  const {
    files: { preview: allPreviews, associated },
    clearFiles,
    updateFiles,
    clearFile,
    csrfToken,
    deleteFiles
  } = useContext(FileContext)
  const { uploads, ackUploads, cancelUploads } = useContext(UploadContext)
  const {
    clearCandidates,
    turntableCandidates,
    errors: turntableErrors
  } = useTurntableCandidates({
    previews: uploads[TURNTABLE],
    cancelUploads,
    ackUploads,
    updateFiles,
    clearFile,
    draftId,
    csrfToken
  })

  const {
    sortableEl,
    orderedPreviews,
    searchPreview,
    sortAlpha
  } = usePreviewsOrder({
    allPreviews,
    updateFiles,
    draftId,
    csrfToken,
    overridePreviewRequirements
  })

  const previewSpecs = usePreviewSpecs({
    previews: orderedPreviews,
    searchPreview,
    associated,
    overridePreviewRequirements
  })

  const existingFiles = {
    ...filterType(allPreviews, [THUMBNAIL]),
    ...filterType(allPreviews, [TURNTABLE]),
    ...filterStatusNot(uploads[THUMBNAIL], FAILED)
  }
  const displaySearchPlaceholder = !overridePreviewRequirements && !searchPreview && !!count(allPreviews)
  const existingFileCount = count(existingFiles)

  const {
    ready: previewUploadsReady,
    inputRef: thumbnailInputRef,
    handleFileUploads: handleThumbnailInput
  } = useFileUploads({
    mapUpload: async (file) => {
      const { width, height } = await imageResolution(file)
      const thumbnailType = mapThumbnailType(checkThumbnailType(file.name, width, height))
      return mapPreviewFileUpload({
        file,
        draft,
        thumbnailType,
        watermarked: false
      })
    },
    batchFilters: [uniqueName(existingFiles), previewBatchSize(existingFileCount)],
    validators: [fileSize, fileName, previewFileExtension, previewMaxDimensions],
    onError: errors => errors.forEach(message => alert(formatAlert(message))),
  })

  const {
    ready: turntableUploadsReady,
    inputRef: turntableInputRef,
    handleFileUploads: handleTurntableInput
  } = useFileUploads({
    mapUpload: file => mapTurntableFileUpload({
      file,
      draft,
      turntableId: getFileInfo(file.name).id,
    }),
    batchValidators: [turntableFileLimit(turntableCandidates, uploads[TURNTABLE])],
    batchFilters: [uniqueName(uploads[TURNTABLE])],
    validators: [
      fileSize,
      fileName,
      previewFileExtension,
      previewMaxDimensions,
      turntableImageName(tips.naming.turntables)
    ],
    onError: errors => errors.forEach(message => alert(formatAlert(message))),
  })

  const handleBulkSelect = (id) => {
    const { [id]: value, ...rest } = selected
    setSelected(
      value
        ? rest ?? {}
        : { ...selected, [id]: allPreviews[id] }
    )
  }

  const handleSelectAll = () => {
    const selection = count(selected)
      ? {}
      : Object.keys(allPreviews).reduce((acc, key) => ({
        ...acc,
        [key]: allPreviews[key]
      }), {})
    setSelected(selection)
  }

  const handleBulkDelete = () => {
    const selectionArray = arrayFrom(selected)
    deleteFiles(
      { files: selectionArray },
      {
        onSuccess: () => {
          setSelected({})
          clearFiles(selectionArray)
        },
        onError: e => console.error(e)
      }
    )
  }

  const handleSetThumbnailType = (type) => {
    arrayFrom(selected).forEach(item => {
      form.setValue(`${item.id}.thumbnailType`, type)
    })
  }

  const disabled = !previewUploadsReady || !turntableUploadsReady

  return (
    <div className="panel panel-default images-panel files-panel image-files product-image-files-container">
      <div
        id={'product-images-container' /* used for testing */}
        className="panel-body panel-collapse collapse in panel-files-wrapper panel-image-files preview-container">
        <div className="panel-body-container">
          <PreviewSpecs {...previewSpecs} />
          <CertifiedPreviews {...previewSpecs} />
          <PreviewValidations {...previewSpecs} />
          {!!turntableCandidates.length && (
            <div className="image-files-wrapper">
              <div className="product-images-draft js-product-images">
                {turntableCandidates.map(turntable => (
                    <TurntableCandidate
                      key={turntable.id}
                      turntable={turntable}
                      errors={turntableErrors[turntable.id] ?? []}
                      clearCandidates={() => clearCandidates(turntable.id)}
                    />
                  )
                )}
              </div>
            </div>
          )}
          <Dropzone className="dzu-dropzone" disabled={disabled} onChange={handleThumbnailInput}>
            <div className="image-files-wrapper upload-area col-sm-12 col-xs-12">
              <div className="tab-alert-container">
                {notifications.map(n =>
                  <Notification key={n.message} notification={n} clear={clear} />
                )}
              </div>
              <div className="product-images-draft">
                <div className="uploader-sort-holder">
                  <div className="full-size-uploader-no-cloud">
                    <div className="upload-files-label">
                      <div className="upload-files-label-title multi-buttons">
                      <span className="browse-button-holder">
                        <span className="drag-files-label">{I18n.t('drag_preview_files_here_or', filesI18nOptions)}</span>
                        <label
                          className={`browse-files-link ${disabled ? 'disabled' : ''}`}
                          htmlFor="preview-input">
                          {I18n.t('browse', filesI18nOptions)}
                        </label>
                        <input
                          ref={thumbnailInputRef}
                          type="file"
                          id="preview-input"
                          multiple="multiple"
                          style={{ display: 'none' }}
                          onChange={handleThumbnailInput}
                          accept={acceptImageExtensions}
                          disabled={disabled}
                        />
                      </span>
                        <span className="turntable-button-holder">
                        <span className="drag-files-label">{I18n.t('click_to', filesI18nOptions)}</span>
                        <label
                          className={`browse-files-link ${disabled ? 'disabled' : ''}`}
                          htmlFor="turntable-input">
                          {I18n.t('upload_turntable', filesI18nOptions)}
                        </label>
                        <input
                          ref={turntableInputRef}
                          type="file"
                          id="turntable-input"
                          multiple="multiple"
                          style={{ display: 'none' }}
                          onChange={handleTurntableInput}
                          accept={acceptImageExtensions}
                          disabled={disabled}
                        />
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!disabled && !!count(allPreviews) && (
                <PreviewBulkOption
                  selected={selected}
                  totalCount={count(allPreviews)}
                  onSelectAll={handleSelectAll}
                  onBulkDelete={handleBulkDelete}
                  onSetThumbnailType={handleSetThumbnailType}
                  onSortAlpha={sortAlpha}
                />
              )}
              {!count(allPreviews) && (
                <div className="full-size-uploader">
                  <i data-testid="upload-icon" className="fa fa-cloud-upload" />
                </div>
              )}
              <div className="row file-list-wrapper image-files-wrapper">
                <div className="js-product-images" ref={sortableEl}>
                  {displaySearchPlaceholder && <SearchImagePlaceholder />}
                  {!!searchPreview && (
                    <PreviewFile
                      key={searchPreview.id}
                      file={searchPreview}
                      selected={selected[searchPreview.id]}
                      onSelect={handleBulkSelect}
                      signaturePreview={true}
                    />
                  )}
                  {orderedPreviews.map(file => (
                    <PreviewFile
                      key={file.id}
                      file={file}
                      selected={selected[file.id]}
                      onSelect={handleBulkSelect}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Dropzone>
        </div>
      </div>
    </div>
  )
}
