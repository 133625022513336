import { invert, isEqual } from 'lodash'
import { get, del, patch, put } from './service'
import {
  ASSOCIATED_FILES,
  CUSTOMER,
  NATIVE, PRODUCT_FILES,
  PROMOTIONAL,
  REGULAR,
  TEXTURE, TEXTURE_FILES,
  THUMBNAIL, THUMBNAIL_FILES, TURNTABLE,
  UV, VIEWER,
  WIREFRAME
} from '../components/products/constants'
import { defaultNativeFileAttributes } from './upload-service'

export const deleteFiles = ({
  files,
  productId,
  draftId,
  csrfToken
}) => {
  return Promise.all(files.map(({ id, type }) =>
    deleteFile({
      fileId: id,
      fileType: type,
      productId,
      draftId,
      csrfToken
    })
  ))
}

export const deleteFile = ({
  productId,
  draftId,
  fileId,
  fileType,
  csrfToken
}) => {
  const resource = mapTypeToApiResource(fileType)
  return del(`/turbosquid/products/${productId}/${resource}/${fileId}`, {
    authenticity_token: csrfToken,
    product_id: productId.toString(),
    draft_id: draftId.toString(),
    id: fileId.toString(),
    type: mapTypeToApi(fileType)
  })
}

export const patchFile = ({
  resource,
  productId,
  draftId,
  file,
  csrfToken
}) => {
  return patch(`/turbosquid/products/${draftId}/${resource}/${file.id}`, {
    authenticity_token: csrfToken,
    product_id: productId.toString(),
    draft_id: draftId.toString(),
    id: file.id.toString(),
    ...mapFileAttributesToApi(file, draftId),
  })
}

export const restoreFile = ({
  productId,
  draftId,
  file,
  csrfToken
}) => {
  const resource = mapTypeToApiResource(file.type)
  return put(`/turbosquid/products/${productId}/${resource}/${file.id}`, {
    authenticity_token: csrfToken,
    draft_id: draftId.toString(),
    id: file.id.toString(),
    type: mapTypeToApi(file.type),
    restore: true
  })
}

export const mapFileAttributesToApi = (file, draftId) => {
  switch (file.type) {
    case NATIVE:
      return mapNativeFileAttributesToApi(file.attributes)
    case THUMBNAIL:
      return {
        thumbnail: {
          draft_id: draftId.toString(),
          id: file.id,
          ...mapPreviewFileAttributesToApi(file.attributes)
        }
      }
    case TEXTURE:
    case PROMOTIONAL:
    case CUSTOMER:
    case VIEWER:
      return {
        old_type: mapTypeToApi(file.type),
        type: mapTypeToApi(file.attributes.fileType),
        ...mapTextureFileAttributesToApi(file.attributes),
      }
    default:
      throw new Error('invalid resource')
  }
}

export const mapNativeFileAttributesToApi = (file) => ({
  name: file.name,
  size: file.size,
  file_format: file.format,
  format_version: file.formatVersion,
  renderer: file.renderer,
  renderer_version: file.rendererVersion,
  is_native: !!file.isNative,
})

export const mapPreviewFileAttributesToApi = (file) => ({
  name: file.name,
  size: file.size,
  thumbnail_type: mapThumbnailType(file.thumbnailType)
})

export const mapTextureFileAttributesToApi = (file) => ({
  name: file.name,
  size: file.size,
  description: file.description,
})

export const mapThumbnailType = (type) => {
  switch (type) {
    case REGULAR:
    case WIREFRAME:
      return type
    case UV:
      return 'uv_map'
    default:
      throw new Error('invalid thumbnail type')
  }
}

export const isStale = (apiData, appData) => {
  return !isEqual(apiData, appData)
}

const typeToApiResource = {
  [NATIVE]: 'product_files',
  [TEXTURE]: 'associated_files',
  [VIEWER]: 'associated_files',
  [PROMOTIONAL]: 'associated_files',
  [CUSTOMER]: 'associated_files',
  [THUMBNAIL]: 'thumbnails',
  [TURNTABLE]: 'turntables'
}

export const mapTypeToApiResource = (type) => typeToApiResource[type]

const typeToApi = {
  [NATIVE]: 'product_file',
  [TEXTURE]: 'texture_file',
  [VIEWER]: 'viewer_file',
  [PROMOTIONAL]: 'promotional_file',
  [CUSTOMER]: 'customer_file',
  [THUMBNAIL]: 'thumbnail',
  [TURNTABLE]: 'turntable'
}

export const mapTypeToApi = (type) => typeToApi[type]

export const typeToDefaultFileAttributes = (type) => {
  switch (type) {
    case NATIVE:
      return defaultNativeFileAttributes
    case TEXTURE:
    case PROMOTIONAL:
    case VIEWER:
    case CUSTOMER:
      return {}
    case TURNTABLE:
    case THUMBNAIL:
      return {}
    default:
      throw new Error('invalid resource')
  }
}
