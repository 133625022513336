import { useState } from 'react'
import { ERROR, notificationTypes, WARNING } from '../components/products/constants'

export const formatAlert = (message) => {
  if (typeof message === 'string') {
    return { type: ERROR, message }
  } else if (message.type && notificationTypes.includes(message.type)) {
    return message
  } else {
    return { type: ERROR, message: 'An unknown error has occurred' }
  }
}

export default function useNotifications() {
  const [notifications, setNotifications] = useState([])

  const alert = (notification) => {
    switch (notification.type) {
      case WARNING:
      case ERROR:
        return setNotifications([
          ...notifications,
          notification
        ])
      default:
        throw new Error('invalid notification type')
    }
  }

  const clear = (message) => {
    setNotifications(prevNotifications => prevNotifications.filter(n => n.message !== message))
  }

  const clearNotifications = () => {
    setNotifications([])
  }

  return {
    notifications,
    alert,
    clear,
    clearNotifications
  }
}
