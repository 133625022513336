/* global gon, I18n */
import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'seamless-immutable'
import FeatureRows from './FeatureRows'
import FeatureHiddenInputs from './FeatureHiddenInputs'
import FeatureSelectInput from './FeatureSelectInput'
import {Overlay, Tooltip} from 'react-bootstrap'
import { debounce } from 'lodash'
import ReactDOM from "react-dom";

export default class FeatureWidget extends React.Component {
  constructor(props) {
    super(props)
    const { features } = this.props
    this.state = { features, showCollectionTooltip: false }
    this.debounceHandleTooltipShow = debounce((e) => {
      const { showCollectionTooltip } = this.state
      if (showCollectionTooltip) {
        this.setState({
          showCollectionTooltip: false
        })
      }
    }, 100)
    this.collectionCheckboxRef = React.createRef()
  }

  getUpdateFeatures = (assignedFeatures, updateWithConstraint = true) => {
    const { updateFeatures, updateFeaturesWithConstraints, featureType } = this.props
    if (updateWithConstraint && featureType !== 'brand') {
      return updateFeaturesWithConstraints(assignedFeatures)
    }
    return updateFeatures(assignedFeatures)
  }

  addFeature = (node) => {
    const newNode = Immutable.asMutable(node)
    newNode.added = true
    const { features } = this.state
    const { featureType, updateFeaturesWithConstraints, syncFeatures } = this.props

    const existingNode = features.find((feature) => feature.id === newNode.id)
    if (!existingNode) {
      const newFeatures = features.map((feature) => {
        const newFeature = Immutable.asMutable(feature)
        if (newNode.parentId === newFeature.id.toString()) {
          return newNode
        }
        if (newNode.id !== newFeature.id) {
          newFeature.added = false
          return newFeature
        }
        return newFeature
      })

      if (!newNode.parentId) {
        newFeatures.push(newNode)
      }
      this.setState({ features: newFeatures })
      if (featureType !== 'brand') {
        updateFeaturesWithConstraints(newFeatures)
      }
      this.updateStore(newFeatures)
      if (syncFeatures) {
        syncFeatures(newFeatures)
      }

      return newFeatures
    }
    return features
  }

  removeFeature = (id) => {
    const { syncFeatures } = this.props
    const { features } = this.state
    let newFeatures = features.map((feature) => {
      const newFeature = Immutable.asMutable(feature)
      newFeature.added = false
      return newFeature
    })
    newFeatures = newFeatures.filter((feature) => feature.id !== id)
    this.setState({ features: newFeatures })
    this.updateStore(newFeatures)
    if (syncFeatures) {
      syncFeatures(newFeatures)
    }
    return newFeatures
  }

  updateStore = (features) => {
    const assignedFeatures = features.filter((feature) => feature.assignment_type.toUpperCase() !== 'X')
    this.getUpdateFeatures(assignedFeatures)
  }

  getAncestryByType = () => {
    const { featureType } = this.props
    return featureType === 'brand' ? gon.feature_graph_brand_path_id : gon.feature_graph_object_path_id
  }

  handleCollectionChange = (e) => {
    const { updateCollection, syncFields, syncFeatures, collectionData } = this.props
    const collectionId = gon.collection_taxonomy_id
    updateCollection(e.target.checked)
    if (syncFeatures && syncFields) {
      if(e.target.checked) {
        const existingCollectionFeature = syncFields.features.find(f => f.id === collectionId)
        if (!existingCollectionFeature) {
          syncFeatures([
            ...syncFields.features,
            { id: collectionId }
          ])
        }
      } else {
        syncFeatures(
          syncFields.features.filter(f => f.id !== collectionId)
        )
      }
    }
  }

  renderCollectionCheckbox = () => {
    const { collectionFeature } = this.props
    return (
      <div className="form-group optional  form-group-checkbox checkbox" ref={this.collectionCheckboxRef} onFocus={() => false} onMouseOver={this.handleCollectionDisableTooltipShow} onMouseLeave={this.handleCollectionDisableTooltipShow}>
        <input className="boolean optional" value="1" type="checkbox" name="collection" id="collection-form-collection" defaultChecked={Boolean(collectionFeature)} onChange={this.handleCollectionChange} />
        <label className="control-label" htmlFor="collection-form-collection" tabIndex="0">
          {I18n.t('feature_assignment_widget.collection')}
        </label>
      </div>
    )
  }

  renderCollectionDisableTooltip = () => (
    <Overlay
      placement="left"
      target={() => ReactDOM.findDOMNode(this.collectionCheckboxRef.current)}
      show={this.state.showCollectionTooltip}
    >
      <Tooltip id="create-collection-tooltip" onMouseOver={this.handleCollectionDisableTooltipShow} onMouseLeave={this.handleCollectionDisableTooltipShow} onFocus={() => false}>
        {I18n.t('feature_assignment_widget.cm_cannot_part_of_collection')}
      </Tooltip>
    </Overlay>
  )

  handleCollectionDisableTooltipShow = (e) => {
    // Prevents React from resetting its properties
    e.persist()
    this.debounceHandleTooltipShow(e)
  }

  render() {
    const {
      featureType,
      formActionInProgress,
      categoryPage,
      autoFocus,
      errors,
      updateLicense,
    } = this.props
    const { features } = this.state
    const assignedFeatureIds = features.map((feature) => feature.id)
    return (
      <div className="feature-assignment-widget" id={`${featureType == 'brand' ? 'brand_feature_widget' : ''}`}>
        {
          featureType !== 'brand'
          && (
            <p className="feature-assignment-help-tips">
              {I18n.t('feature_assignment_widget.object_category_help_tips_1')}
            </p>
          )
        }

        <FeatureHiddenInputs features={features} />

        <FeatureRows
          features={features}
          assignedFeatureIds={assignedFeatureIds}
          addFeatureCallback={this.addFeature}
          removeFeatureCallback={this.removeFeature}
          categoryPage={categoryPage}
          errors={featureType=='brand' ? errors : {}}
        />

        <div className="feature-assignment-search" data-testid="fg-category-entry">
          <div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="control-label">
              {I18n.t('turbosquid.products.product_categories_panel.categories')}
            </label>
          </div>
          <FeatureSelectInput
            key="feature-select-react-#{assignedFeatureIds.join '/'}"
            assignedFeatureIds={assignedFeatureIds}
            addFeatureCallback={this.addFeature}
            formActionInProgress={formActionInProgress}
            closeMenuOnSelect
            categoryPage={categoryPage}
            ancestry={this.getAncestryByType()}
            showChevron={false}
            featureType={featureType}
            autoFocus={autoFocus}
            updateLicense={updateLicense}
          />
        </div>
        {
          (features.length > 0 && featureType === 'object')
            ? (
              <div className="feature-assignment-collection-container">
                <p>{I18n.t('feature_assignment_widget.collection_help_tips')}</p>
                <div>
                  {this.renderCollectionCheckbox()}
                </div>
              </div>
            )
            : (
              <div />
            )
        }
      </div>
    )
  }
}

FeatureWidget.propTypes = {
  features: PropTypes.array,
  syncFeatures: PropTypes.func,
  syncFields: PropTypes.object,
  updateFeatures: PropTypes.func.isRequired,
  formActionInProgress: PropTypes.string,
  updateFeaturesWithConstraints: PropTypes.func,
  featureType: PropTypes.string,
  categoryPage: PropTypes.bool,
  autoFocus: PropTypes.bool,
  updateCollection: PropTypes.func,
  collectionFeature: PropTypes.number,
  updateLicense: PropTypes.func,
  errors: PropTypes.object,
}

FeatureWidget.defaultProps = {
  features: [],
  formActionInProgress: '',
  updateFeaturesWithConstraints: null,
  featureType: 'object',
  categoryPage: false,
  autoFocus: false,
  updateCollection: null,
  collectionFeature: 0,
  updateLicense: null,
  errors: {},
}
