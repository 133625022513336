/* eslint no-restricted-syntax: ["error", "BinaryExpression[operator='in']"] */

import DOMPurify from 'dompurify'
import { Editor, Element, Text, Transforms, Node, Range } from 'slate'
import { ReactEditor } from 'slate-react'
import { deserialize } from './slate/deserializer'

const BLOCK_TYPES = [
  'bulleted-list',
  'numbered-list',
  'block-quote',
  'heading-one',
  'heading-two',
  'paragraph',
  'list-item',
]
const LIST_TYPES = [
  'numbered-list',
  'bulleted-list',
]

const NUMBER_REGEX = /^(?:\d+)$/

const eligibleForLink = (editor) => {
  const { selection } = editor
  if (!selection) return false

  const selectedText = Editor.string(editor, selection)
  if (selectedText.match(NUMBER_REGEX)) {
    return true
  }

  return false
}

export const isBlockActive = (editor, format) => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
  })

  return !!match
}

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const setBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  })

  const listType = isList ? 'list-item' : format
  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : listType,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const toggleLink = (editor, format) => {
  const text = window.prompt('Enter the URL for the Turbosquid product:')
  const hostnames = ['turbosquid.com', 'www.turbosquid.com']
  let url

  try {
    url = new URL(text)
  } catch (_) {
    return false
  }

  // only links from this hostname are allowed
  if (!hostnames.includes(url.hostname)) {
    return false
  }

  const matches = url.pathname.match(/\/(\d+)$/) || url.pathname.match(/-(\d+)$/)
  const id = Number.parseInt(matches[1])

  if (!id) {
    return false
  }

  // remove old link if one is selected, this will be replace with the new link
  if (isBlockActive(editor, format)) {
    Transforms.removeNodes(editor, {
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
    })
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const linkNode = { type: 'productLink', id, children: [{ text: String(id) }] }
  const textNode = { text: ' ' }

  if (isCollapsed) {
    Transforms.insertNodes(editor, [linkNode, textNode, textNode])
  } else {
    // collapse the selection first, then add an white space to separate the text and the link
    Transforms.collapse(editor, { edge: 'end' })
    Transforms.insertNodes(editor, [textNode, linkNode])
    Transforms.move(editor, { distance: 1 })
  }
}

export const toggleBlock = (editor, format, editorSelection) => {
  if (!ReactEditor.isFocused(editor)) {
    ReactEditor.focus(editor)
    setTimeout(() => {
      Transforms.setSelection(editor, editorSelection)
      setBlock(editor, format)
    }, 200)
  } else {
    setBlock(editor, format)
  }
}

export const toggleMark = (editor, format, editorSelection) => {
  if (!ReactEditor.isFocused(editor)) {
    ReactEditor.focus(editor)
    setTimeout(() => {
      Transforms.setSelection(editor, editorSelection)
      Editor.addMark(editor, format, true)
    }, 200)
  } else {
    const isActive = isMarkActive(editor, format)
    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  }
}

// https://docs.slatejs.org/concepts/11-normalizing#built-in-constraints
// Slatejs has this rule:
//   The top-level editor node can only contain block nodes.
//   If any of the top-level children are inline or text nodes they will be removed.
// In order to prevent lossing nodes when pasting html, wrap top level inline nodes in paragraphs
// Also if muliple inline nodes are adjacent, group them into the same paragraph
export const normalizeTopLevelNodes = (nodes) => {
  const newNodes = []
  let normalizables = []

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]

    if (BLOCK_TYPES.includes(node.type)) {
      if (normalizables.length > 0) {
        newNodes.push({ type: 'paragraph', children: normalizables })
        normalizables = []
      }

      newNodes.push(node)
    } else {
      normalizables.push(node)
    }
  }

  // if last nodes are inline nodes, wrap them in a paragraph
  if (normalizables.length > 0) {
    newNodes.push({ type: 'paragraph', children: normalizables })
  }

  return newNodes
}

// Remove whitespaces between tags
export const removeFormatting = (text) => text.replace(/>\s+</g, '> <')

// Some white space characters are converted to &nbsp. Just replace them with normal spaces.
// Also remove Line and Paragraph Separators
export const filterCharacters = (text) => (
  text
    .replace(new RegExp(String.fromCharCode(160), 'g'), ' ') // space
    .replace(new RegExp(String.fromCharCode(8232), 'g'), '') // line separator
    .replace(new RegExp(String.fromCharCode(8233), 'g'), '') // paragraph separator
)

export const withCustomizeEditor = (editor) => {
  const { normalizeNode, insertBreak, insertData, isInline } = editor

  editor.isInline = (element) => (
    ['productLink'].includes(element.type) || isInline(element)
  )

  editor.insertBreak = () => {
    const { selection } = editor
    const breakNode = Node.get(editor, selection.focus.path)
    const parentNode = Node.parent(editor, selection.focus.path)
    const listNode = Node.parent(editor, ReactEditor.findPath(editor, parentNode))
    if (Text.isText(breakNode)
      && !breakNode.text
      && parentNode.type === 'list-item'
      && LIST_TYPES.includes(listNode.type)
    ) {
      toggleBlock(editor, listNode.type)
    } else {
      insertBreak()
    }
  }

  editor.insertData = (data) => {
    const htmlText = (data.getData('text/html')).trim()
    const plainText = (data.getData('text/plain')).trim()
    const html = htmlText.length > 0 ? htmlText : plainText

    if (html) {
      const sanitizedHTML = DOMPurify.sanitize(removeFormatting(filterCharacters(html)))
      const parsed = new DOMParser().parseFromString(sanitizedHTML, 'text/html')
      const fragment = normalizeTopLevelNodes(deserialize(parsed.body))
      Transforms.insertFragment(editor, fragment)

      return
    }

    insertData(data)
  }

  editor.normalizeNode = (entry) => {
    const [node, path] = entry

    if (path.length === 0) {
      // When on the first level of nodes, wrap all text node in a paragraph
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Text.isText(child) || editor.isInline(child)) {
          Transforms.wrapNodes(editor, { type: 'paragraph', children: [] }, { at: childPath })
          return
        }
      }
    }

    // make sure that list types have all the elements wrapped in a list-item
    if (LIST_TYPES.includes(node.type)) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Text.isText(child)) {
          Transforms.removeNodes(editor, { at: childPath })
          return
        }

        if (Element.isElement(child) && child.type !== 'list-item') {
          Transforms.setNodes(editor, { type: 'list-item' }, { at: childPath })
          return
        }
      }
    }

    // do not allow block type elements in a list item element
    if (Element.isElement(node) && node.type === 'list-item') {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) && BLOCK_TYPES.includes(child.type)) {
          Transforms.unwrapNodes(editor, { at: childPath })
          return
        }
      }
    }

    normalizeNode(entry)
  }

  return editor
}

export const containsHTML = (description) => {
  const doc = new DOMParser().parseFromString(DOMPurify.sanitize(description), 'text/html')
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1)
}
