/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import NumberUtility from './number_utility';

const MoneyUtility = {
  dollars_to_currency_parts(dollars) {
    return this.cents_to_currency_parts(dollars * 100)
  },
  cents_to_currency_parts(cents) {
    const value = (cents / 100).toFixed(2)
    const parts = value.split('.')
    return { main_unit: NumberUtility.formatCurrency(parts[0], '$'), fractional_unit: parts[1] }
  },
}

export default MoneyUtility;
