import { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import * as FileService from '../services/file-service'
import Bottleneck from 'bottleneck'

const UNDO_DELETE_TIMEOUT = 6000

export default function useDeleteFiles({draftId, productId, csrfToken, updateFiles, clearFile }) {
  const [pendingDelete, setPendingDelete] = useState([])
  const [deletedFiles, setDeletedFiles] = useState([])
  const timeout = useRef(0)
  const limiter = useRef(
    new Bottleneck({
      maxConcurrent: 5,
      minTime: 500
    })
  );
  const { mutateAsync: deleteFile } = useMutation(
    'deleteFile',
    ({ file }) => {
      setPendingDelete(prevPendingDelete => [...prevPendingDelete, file.id])
      return FileService.deleteFile({
        fileId: file.id,
        fileType: file.type,
        productId,
        draftId,
        csrfToken
      }).then(() => {
        setPendingDelete(prevPendingDelete =>
          prevPendingDelete.filter(id => id !== file.id)
        )
        setDeletedFiles(prevDeletedFiles => [...prevDeletedFiles, file])
        clearFile(file.id)
      })
    }
  )
  const {
    mutate: undoDeleteFiles,
    isLoading: pendingUndoDelete
  } = useMutation(
    'undoDeleteFiles',
    () => Promise.all(deletedFiles.map(file =>
      FileService.restoreFile({
        file,
        productId,
        draftId,
        csrfToken
      }).then(() => {
        updateFiles(deletedFiles)
        setDeletedFiles([])
        setPendingDelete([])
      })
    ))
  )

  useEffect(() => {
    if (deletedFiles.length) {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
      timeout.current = setTimeout(() => setDeletedFiles([]), UNDO_DELETE_TIMEOUT)
    }
  }, [deletedFiles])

  const deleteFiles = ({ files }) => {
    return Promise.all(
      files.map(file => limiter.current.schedule(() =>
        deleteFile({ file }))
      )
    )
  }
  return {
    deletedFiles,
    deleteFiles,
    pendingDelete,
    undoDeleteFiles,
    pendingUndoDelete,
  }
}
