/* global I18n, gon */
import React, { useContext, useState } from 'react'
import { timeFormat } from 'd3-time-format'
import DiscardModal from '../../components/publisher/EditHeader/DiscardModal'
import { ProductContext } from '../../contexts/ProductContext'
import DismissableNotification from './DismissableNotification'

const formatTime = timeFormat('%B %d, %Y %I:%M %p')

export default function DraftAlert() {
  {
    const {
      draftId,
      updatedAt,
      handleDeleteDraft,
      product: { id }
    } = useContext(ProductContext)

    const [showDiscardModal, setShowDiscardModal] = useState(false)

    const date = formatTime(new Date(updatedAt)) // eslint-disable-line
    const notificationId = 'draftAlert'

    if (id !== '0' && draftId && updatedAt) {
      return (
        <DismissableNotification
          notificationId={notificationId}
          notificationLevel="info"
          extraClasses="draft-alert"
        >
          <div className="notification-holder">
            <p dangerouslySetInnerHTML={{ // eslint-disable-line
              __html: I18n.t('turbosquid.products.product_alerts.draft_warning.htmlDate',
                { date }),
            }}
            />
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p
              onClick={() => setShowDiscardModal(true)}
              dangerouslySetInnerHTML={{ // eslint-disable-line
                __html: I18n.t('turbosquid.products.product_alerts.draft_warning.htmlDiscard'),
              }}
            />
            <p dangerouslySetInnerHTML={{ // eslint-disable-line
              __html: I18n.t('turbosquid.products.product_alerts.draft_warning.htmlChanges'),
            }}
            />
          </div>

          <DiscardModal
            show={showDiscardModal}
            hideModal={() => setShowDiscardModal(false)}
            discardDraft={handleDeleteDraft}
          />

        </DismissableNotification>
      )
    }
    return null
  }
}