/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

const i18nOptions = {
  scope: 'turbosquid.products.product_license_panel',
}

const BrandModal = ({ show, hideModal }) => (
  <Modal show={show} className="add-missing-brand-modal-container" onHide={hideModal}>
    <Modal.Dialog>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('missing_brand', i18nOptions)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div
            className="form-group missing-brand-body"
            dangerouslySetInnerHTML={{ __html: I18n.t('missing_brand_body', i18nOptions) }}
          />
          <div className="form-group missing-brand-tip">{I18n.t('missing_brand_tip', i18nOptions)}</div>
        </div>
      </Modal.Body>
    </Modal.Dialog>
  </Modal>
)

BrandModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired
}

export default BrandModal
