/* global I18n, gon */
import React from 'react'
import { uniq } from 'lodash'

export const validate = (item, validators) => {
  return validators.flatMap(v => {
    return v(item)
  })
}

const i18nOptions = {
  scope: 'turbosquid.products.product_validation',
}

export const FORBIDDEN_WORDS = ['@', '.com', '.net', '.org', 'www', '.zip', '.rar', '.3ds', '.max']
export const PRODUCT_TITLE_FORBIDDEN_WORDS = ['[', ']', '{', '}', '<', '>', '(', ')', '_', '%', '&', '/', '?', '$', '#', '+', '=']
export const ASSOCIATED_FILE_DESCRIPTION_LENGTH = 50
export const LOWER_PRICE_BOUND = 1
export const HIGHER_PRICE_BOUND = 20000000
export const RESPONSE_STATUS = {
  notFound: 404,
  ok: 200,
  unprocessableEntity: 422,
}
export const DESCRIPTION_PRODUCT_LINKS_LENGTH = 20

export const renderWarning = (notification, index) => (
  <div className="alert alert-warning" key={`notification-${index}`}>
    {notification}
  </div>
)

export const infoIconClasses = (visible) => {
  if (visible) {
    return 'fa fa-chevron-circle-up'
  }
  return 'fa fa-question-circle'
}

// TODO: should probably be removed altogether
export const getErrors = (errors) => errors

export const disabledOnPublish = (formActionInProgress) => formActionInProgress === 'save_draft'

export const IsActionPublish = (formActionInProgress) => formActionInProgress === 'publish'

export const CSRFToken = () => (
  document.querySelector('meta[name="csrf-token"]').content
)

export const inputHasErrorsClass = (errors) => (
  typeof errors !== 'undefined' && errors.length > 0 ? 'has-errors' : ''
)

export const filterNotifications = (alerts, panelType) => {
  const notifications = []

  alerts.forEach((alert) => {
    alert.notifications.forEach((notification) => {
      if (notification.panel === panelType) {
        notifications.push(notification.message)
      }
    })
  })

  return notifications
}

export const arrayToList = (array) => {
  if (array.length > 1) {
    const list = array.slice(0, array.length - 1).map((t) => `"${t}"`).join(', ')
    return `${list} and "${array[array.length - 1]}"`
  }

  return `"${array[0]}"`
}

export const mergeAlerts = (state) => (
  state.featureState.alerts.concat(state.formState.alerts)
)

export const mergeProhibitedWords = (state) => {
  const featureProhibitedWords = state.featureState.prohibitedWords || []
  const formProhibitedWords = state.formState.prohibitedWords || []
  return featureProhibitedWords
    .filter((t) => !formProhibitedWords.includes(t))
    .concat(formProhibitedWords)
}

export const noEmailorURLError = (words, isText = false) => (
  isText ? `${I18n.t('the_word_was_included', { ...i18nOptions, words })} ` : (
    <>
      {`${I18n.t('the_word_was_included', { ...i18nOptions, words })} `}
      <a target="_blank" rel="noopener noreferrer" href={I18n.t('terms_agreement_url', { ...i18nOptions })}> </a>
    </>
  )
)

export const noEmailorURLErrorText = (words) => (
  I18n.t('the_word_was_included', { ...i18nOptions, words })
)

export function checkForbiddenWords(value, forbiddenWords, that) {
  const errors = []
  const { path, createError, isText = false } = that

  const valueToLowerCase = value.toLowerCase()
  forbiddenWords.forEach((word) => {
    if (valueToLowerCase.includes(word)) {
      errors.push(word)
    }
  })

  if (errors.length > 0) {
    return createError(
      {
        path,
        message: {
          messageForNotif: noEmailorURLError(errors.join(', '), isText),
          messageForInline: noEmailorURLError(errors.join(', '), isText),
        },
      }
    )
  }

  return true
}

export function checkNotAllowedCategory(items) {
  const notAllowedItems = items.filter(
    (feature) => feature.constraints
    && feature.constraints.restrictions
    && feature.constraints.restrictions.includes('publish_not_allowed'))

  if(notAllowedItems.length) {
    return notAllowedItems.map((elem) => elem.text)
  }
  return []
}

export function checkForbiddenForCategory(items, that) {
  const { path, createError } = that
  const notAllowedItemList = checkNotAllowedCategory(items)
  const term = notAllowedItemList.join(', ')
  if (notAllowedItemList.length) {
    return createError(
      {
        path,
        message: {
          messageForNotif: I18n.t('prohibited', { scope: 'turbosquid.products.edit', term }),
          messageForInline: I18n.t('prohibited', { scope: 'turbosquid.products.edit', term }),
          items: notAllowedItemList,
        },
      }
    )
  }
  return true
}

export function checkPhoneNumber(value, that) {
  const { path, createError } = that
  const phoneRe = /\+?\(?([0-9]{3})\)?[-.]?\(?([0-9]{3})\)?[-.]?\(?([0-9]{4})\)?/
  if (phoneRe.test(value)) {
    return createError(
      {
        path,
        message: {
          messageForNotif: I18n.t('phone_number_included', { ...i18nOptions }),
          messageForInline: I18n.t('phone_number_included', { ...i18nOptions }),
        },
      }
    )
  }
  return true
}

export function checkProductLinks(value, that) {
  const { path, createError } = that
  const linkRe = /\[productID\](.+?)\[\/productID\]/g
  const matchIds = [...value.matchAll(linkRe)].map(
    ([_capturingAll, capturingGroup]) => capturingGroup
  ).join(' ').trim().split(' ')
  if (matchIds.length > DESCRIPTION_PRODUCT_LINKS_LENGTH) {
    return createError(
      {
        path,
        message: {
          messageForNotif: I18n.t('product_links_exceed', { ...i18nOptions }),
          messageForInline: I18n.t('product_links_exceed', { ...i18nOptions }),
        },
      }
    )
  }
  return true
}

const renderProductStatusError = () => (
  <>
    <>{I18n.t('suspended_awaiting_review_warning.model_is_awaiting_review', i18nOptions)} </>
    <>{I18n.t('suspended_awaiting_review_warning.please', i18nOptions)} </>
    <a href={gon.support_home_url} rel="noreferrer" target="_blank" tabIndex="-1">
      {I18n.t('suspended_awaiting_review_warning.contact_support', i18nOptions)}
    </a>
    <> {I18n.t('suspended_awaiting_review_warning.for_further_assistance', i18nOptions)}</>
  </>
)

export const stripHTMLFromString = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

export const sizeInMB = (sizeInKB) => (sizeInKB / 1000).toFixed(2)
export const sizeInMBFromB = (sizeInB) => (sizeInB / 1048576).toFixed(2)

export const roundedSizeInMBFromB = (sizeInB) => {
  const MBSize = 1048576
  const rawSizeInMB = sizeInB / MBSize
  if (rawSizeInMB >= 10) {
    return Math.round(rawSizeInMB)
  }
  if (rawSizeInMB > 0.1) {
    return Math.round(rawSizeInMB * 10) / 10
  }
  if (rawSizeInMB < 0.1) {
    return Math.round(rawSizeInMB * 100) / 100 || 0.01
  }
  return rawSizeInMB
}

export const mouseOutside = (coordinates, element) => (
  coordinates.x < element.x
  || coordinates.x > element.width + element.x
  || coordinates.y < element.y
  || coordinates.y > element.y + element.height
)

export const placeCaretAtEnd = (el) => {
  el.focus()
  if (typeof window.getSelection !== 'undefined'
      && typeof document.createRange !== 'undefined') {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange()
    textRange.moveToElementText(el)
    textRange.collapse(false)
    textRange.select()
  }
}

export const clearTitleAndTagsForbiddenWords = (str) => (
  str.replace(/[[\]{}<>()_%&@/?$#+=]/g, '')
)

export const previewNotificationError = (error, learnMoreUrl) => (
  <React.Fragment key={error}>
    {error}
    &nbsp;
    <a className="learn-more-link" href={learnMoreUrl} target="_blank" rel="noreferrer">{I18n.t('turbosquid.products.product_files_panel.learn_more')}</a>
  </React.Fragment>
)

export function checkForbiddenCharacters(value, that, key) {
  const { path, createError } = that
  const characters = key === "tags" ? value.match(/[\u0401\u0451\u0410-\u044f]/g) : value.match(/[^a-zA-Z0-9-\s]/g)
  if (characters) {
    return createError(
      {
        path,
        message: {
          messageForNotif: I18n.t('the_character_was_included', { ...i18nOptions, characters: uniq(characters).join(', ') }),
          messageForInline: I18n.t('the_character_was_included', { ...i18nOptions, characters: uniq(characters).join(', ') }),
        },
      }
    )
  }
  return true
}
