import React from 'react'
import PropTypes from 'prop-types'

const FieldErrors = ({ errors, classNames }) => (
  <div className={`errors form-field-error text-error ${classNames}`}>
    {
      errors
      && errors.map((error) => (
        error
        && error.messageForInline
        && (<p key={error.messageForInline}>{error.messageForInline}</p>)
      ))
    }
  </div>
)

export default FieldErrors

FieldErrors.propTypes = {
  errors: PropTypes.array,
  classNames: PropTypes.string,
}

FieldErrors.defaultProps = {
  errors: [],
  classNames: '',
}
