const sendGa = (evtName) => {
  if (ga) {
    ga('send', 'event', 'button', 'click', evtName)
  }
}

const setupGa = () => {
  $('.create-spinner').on('click', (_evt) => {
    sendGa('create spinner')
  })

  $('.create-shot').on('click', (_evt) => {
    sendGa('create shot')
  })

  $('#CreateProduct .close').on('click', (_evt) => {
    sendGa('close create product')
  })
}

const setupDatepickers = () => {
  const dateFormat = 'MMM YYYY'
  const urlDateFormat = 'YYYY-MM-D'

  const setDate = (datePickerElem, date) => {
    datePickerElem.datepicker(
      'setDate',
      moment(date).format(dateFormat)
    )
  }

  const getDate = (datePickerElem, method = 'getDate') => {
    return moment(datePickerElem.datepicker(method))
  }

  const opts = {
    format: 'M yyyy',
    autoclose: true,
    minViewMode: 'months',
    maxViewMode: 'years',
    endDate: moment().format(dateFormat),
  }

  const startDateElem = $('#start-date')
  const endDateElem = $('#end-date')

  startDateElem.datepicker(opts).on('changeDate', (e) => {
    const currentStartDate = moment(e.date)
    const currentStartDateClone = currentStartDate.clone()
    const currentEndDate = getDate(endDateElem)

    let maxEndDate

    if (currentStartDate.isAfter(currentEndDate)) {
      maxEndDate = currentStartDateClone.add(1, 'month')
    } else {
      maxEndDate = moment.min(
        currentStartDateClone.add(5, 'months'),
        getDate(endDateElem)
      )
    }

    if (!maxEndDate.isSame(currentEndDate, 'month')) {
      setDate(endDateElem, maxEndDate)
    }
  })

  endDateElem.datepicker(opts).on('changeDate', (e) => {
    const currentStartDate = getDate(startDateElem)
    const currentEndDate = moment(e.date)
    const currentEndDateClone = currentEndDate.clone()

    let minStartDate

    if (currentEndDate.isBefore(currentStartDate))
      minStartDate = currentEndDateClone.subtract(1, 'month')
    else {
      minStartDate = moment.max(
        currentEndDateClone.subtract(5, 'months'),
        currentStartDate
      )
    }

    if (!minStartDate.isSame(currentStartDate, 'month')) {
      setDate(startDateElem, minStartDate)
    }
  })

  $('#set-dates').on('click', (_evt) => {
    const params = new URLSearchParams(window.location.search)
    const startDate = getDate(startDateElem).startOf('month')
    const endDate = getDate(endDateElem).endOf('month')

    params.delete('start_date')
    params.delete('end_date')
    params.delete('page')

    params.append('start_date', startDate.format(urlDateFormat))
    params.append('end_date', endDate.format(urlDateFormat))

    window.location.search = params.toString()
  })
}

const disableExportButton = () => {
  const btn = $('#csv-export')
  btn.attr('disabled', true)
  btn.find('#pending').removeClass('hidden')
  btn.find('#initial').addClass('hidden')
}

const enableExportButton = () => {
  const btn = $('#csv-export')
  btn.attr('disabled', false)
  btn.find('#pending').addClass('hidden')
  btn.find('#initial').removeClass('hidden')
}

const pollForExport = (uuid) => {
  const onStop = (intv) => {
    clearInterval(intv)
    enableExportButton()
  }

  const interval = setInterval(() => {
    $.ajax({
      url: `/exports/${uuid}`,
      success: (data) => {
        if (data.error) {
          onStop(interval)
          alert("There was a problem exporting data to a CSV. Please try again and contact support if this problem happens again.")
        } else if (data.ready) {
          onStop(interval)
          window.location.href = data.url
        }
      }
    })
  }, 2000)
}

const setupCsvExport = () => {
  const btn = $('#csv-export')
  btn.on('click', (e) => {
    e.preventDefault()
    disableExportButton()

    $.ajax({
      type: "GET",
      url: `/products.csv${window.location.search}`,
      success: pollForExport
    })
  })
}

const setupSideCollapse = () => {
  const span = $('#sidebar-wrapper span.icon')
  const icon = span.find('i')
  icon.on('click', (e) => {
    if (icon.hasClass('fa-filter')) {
      $('#sidebar-wrapper').css('margin-left', '-200px')
      icon.removeClass('fa-filter').addClass('fa-angle-double-left')
      $('#sidebar-wrapper .sidebar-content').css('opacity', '1')
      $('#wrapper').css('padding-left', '200px')
    } else {
      $('#sidebar-wrapper').css('margin-left', '-356px')
      icon.removeClass('fa-angle-double-left').addClass('fa-filter')
      $('#sidebar-wrapper .sidebar-content').css('opacity', '0')
      $('#wrapper').css('padding-left', '44px')
    }
  })
}

const setupTableHeader = () => {
  if (!$('#product-header').length) {
    return;
  }
  const headerBarPos = $('#product-header').offset().top
  $(window).scroll(function() {
    let headerBar = $('#product-header')
    let p = document.documentElement.scrollTop || document.body.scrollTop
    if(p > headerBarPos && $(window).width() >= 996){
      $(headerBar).addClass("product-header-fixed");
      $('#page-content-wrapper .stats-header').attr("style","padding-top:" + headerBar.height()+"px");
    }else{
      $(headerBar).removeClass("product-header-fixed");
      $('#page-content-wrapper .stats-header').removeAttr("style");
    }
  });
}

const runIt = () => {
  setupGa()
  setupDatepickers()
  setupCsvExport()
  setupSideCollapse()
  setupTableHeader()
}

(() => { $(runIt) }).call(this)
