/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { timeFormat } from 'd3-time-format'
import checkEmptyCell from './checkEmptyCell'
import { PENDING_ASSET_TYPE } from '../constants'

const dateFormat = timeFormat('%b %d, %Y')

const DateCell = ({
  value,
  displayOptions,
  is_pending_update: isPendingUpdate,
  pendingProductIds,
  turbosquid_id: turbosquidId,
  type: productType,
}) => {
  const displayPendingLabel = ((isPendingUpdate || pendingProductIds.includes(turbosquidId))
    && displayOptions.classes === 'classic_updated_at_cell'
    && productType !== PENDING_ASSET_TYPE)
  const spanClass = displayPendingLabel ? `${displayOptions.classes} label label-default` : displayOptions.classes
  return (
    <span className={spanClass} style={{ margin: 0 }}>
      { displayPendingLabel ? I18n.t('turbosquid_products.syncing_data') : dateFormat(new Date(value)) }
    </span>
  )
}

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
  displayOptions: PropTypes.object,
  is_pending_update: PropTypes.bool,
  pendingProductIds: PropTypes.array,
  turbosquid_id: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  type: PropTypes.string,
}

DateCell.defaultProps = {
  displayOptions: {},
  is_pending_update: false,
  pendingProductIds: [],
  turbosquid_id: '',
  type: '',
}

export default checkEmptyCell(DateCell)
