/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'

const ViewDrafts = ({ alignClass }) => (
  <a
    href={gon.turbosquid_drafts_path}
    className={`btn btn-default view-drafts ${alignClass}`}
  >
    {I18n.t('turbosquid_products.view_drafts')}
  </a>
)

export default ViewDrafts

ViewDrafts.propTypes = {
  alignClass: PropTypes.string,
}

ViewDrafts.defaultProps = {
  alignClass: '',
}
