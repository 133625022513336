/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'
import PendingDraftsService from '../../services/PendingDraftsService'

const i18nOptions = {
  scope: 'turbosquid_products.bulk_removal',
}

const formatProductParams = (draft) => ({
  id: draft.id,
  type: 'draft',
  attributes: {
    remove: 1,
  },
})

const buildUpdateParams = (drafts) => (
  drafts.map(formatProductParams)
)

class RemovalForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { inProgress: false }
  }

  saveChanges = (action, params, submitId) => {
    const { drafts, handleBulkUpdateSubmit, handlePendingSubmitsChanges } = this.props

    PendingDraftsService.saveDraftChanges(action, params)
      .then((response) => {
        const draftIds = drafts.map((draft) => draft.id)
        const data = { batchID: response.id, draftIds }
        this.setState({ inProgress: false })
        handlePendingSubmitsChanges(submitId)
        handleBulkUpdateSubmit(data)
      })
      .catch((error) => {
        this.setState({ inProgress: false })
        handlePendingSubmitsChanges(submitId)
        handleBulkUpdateSubmit({ error })
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { drafts, handlePendingSubmitsChanges } = this.props
    this.setState({ inProgress: true })

    const removableDrafts = buildUpdateParams(drafts)
    const submitId = new Date().getTime()
    handlePendingSubmitsChanges(submitId)

    const params = {
      authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
      drafts: removableDrafts }

    this.saveChanges(e.target.action, params, submitId)
  }

  render() {
    const { inProgress } = this.state
    const { drafts } = this.props

    return (
      <form className="form-inline" method="post" action={gon.turbosquid_draft_batches_path} onSubmit={this.handleSubmit}>
        <div className="bulk-status-form-fields">
          <p>{I18n.t('confirm_removal_selection', i18nOptions)}</p>
        </div>

        <div className="btn-wrapper">
          <button type="submit" disabled={inProgress || !drafts.length} className="btn btn-info btn-bulk-submit">
            {I18n.t('turbosquid_products.save_bulk_changes')}
          </button>

          <div className="loader" style={{ display: inProgress ? 'inline-block' : 'none' }} />
        </div>
      </form>
    )
  }
}

export default RemovalForm

RemovalForm.propTypes = {
  drafts: PropTypes.array.isRequired,
  handleBulkUpdateSubmit: PropTypes.func.isRequired,
  handlePendingSubmitsChanges: PropTypes.func.isRequired,
}
