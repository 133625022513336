import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FieldErrors from './field_errors'
import { reactSelectStyle } from '../../../utils/Styles'
import { changeInput } from '../actions/form_slice_actions'

class FormField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectionStart: 0,
      selectionEnd: 0,
    }
  }

  shouldComponentUpdate(nextProps) {
    const { type, value, options } = this.props
    const selectType = ['react-select', 'react-select-price']

    return !(selectType.includes(type) && value === nextProps.value && options.length === nextProps.options.length)
  }

  componentDidUpdate() {
    const { type } = this.props
    const { selectionStart, selectionEnd } = this.state
    const updateSelectFor = ['text', 'textarea']

    if (typeof this.inputRef !== 'undefined'
      && updateSelectFor.indexOf(type) !== -1
      && this.inputRef.selectionStart !== selectionStart
      && this.inputRef.selectionEnd !== selectionEnd) {
      this.inputRef.selectionStart = selectionStart
      this.inputRef.selectionEnd = selectionEnd
    }
  }

  setInputRef = (element) => {
    this.inputRef = element
  }

  convertValue = (type, value) => {
    let parsedValue
    switch (type) {
      case 'number':
        parsedValue = parseFloat(value)
        if (parsedValue || parsedValue === 0) {
          return parsedValue
        }
        return undefined

      default:
        return value
    }
  }

  handleValidation = (e) => {
    const {
      eventPreProcess,
      postprocess,
      fieldSchema,
      inputtype,
      validate,
      changeInputProp,
      formName,
    } = this.props

    if (typeof eventPreProcess === 'function') {
      eventPreProcess(e)
    }
    const elem = e.target

    if (e.type === 'keypress') {
      const validNumberPattern = /[0-9]/
      if (inputtype === 'number' && !validNumberPattern.test(String.fromCharCode(e.which))) {
        e.preventDefault()
        return false
      }
      return true
    }

    const keepCursorPosition = ['number', 'text']
    if (keepCursorPosition.indexOf(elem.type) !== -1) {
      this.setState({
        selectionStart: elem.selectionStart,
        selectionEnd: elem.selectionEnd,
      })
    }

    let value = elem.type === 'checkbox' ? elem.checked : elem.value
    if (typeof postprocess !== 'undefined') {
      value = this.getProcessedValue(value, postprocess)
    }

    let convertedValue = value
    if (fieldSchema !== undefined) {
      convertedValue = this.convertValue(inputtype, value)
    }

    if (typeof validate === 'function') {
      fieldSchema
        .validate(convertedValue)
        .then((valid) => changeInputProp(elem.name, valid, formName))
        .catch((error) => changeInputProp(elem.name, convertedValue, formName, error))
    } else {
      changeInputProp(elem.name, convertedValue, formName)
    }
  }

  onChange = (e) => this.handleValidation(e)

  onBlur = (e) => this.handleValidation(e)

  onPaste = (e) => this.handleValidation(e)

  onInput = (e) => this.handleValidation(e)

  onKeyPress = (e) => this.handleValidation(e)

  getEventObject = (e, action) => {
    const { type, fieldName } = this.props
    switch (type) {
      case 'react-select-price':
      case 'react-select':
        if (typeof e.value !== 'undefined') {
          const event = { target: { name: fieldName, value: e.value } }
          return action(event)
        }
        return null
      default:
        return action(e)
    }
  }

  getValue = (value) => {
    const { type, options } = this.props
    if (type === 'react-select-price') {
      return options.filter((elem) => elem.value === parseFloat(value).toFixed())
    }
    if (type === 'react-select') {
      return options.filter((elem) => elem.value === value)
    }

    return value
  }

  getProcessedValue = (value, process) => {
    const displayedValue = this.getValue(value)
    if (typeof process === 'function') {
      return typeof value !== 'undefined' ? process(displayedValue) : ''
    }

    return typeof value !== 'undefined' ? displayedValue : ''
  }

  getCustomStyles = (type) => {
    if (type === 'react-select' || type === 'react-select-price') {
      return reactSelectStyle
    }

    return ''
  }

  render() {
    const {
      formName,
      fieldName,
      label,
      render,
      options,
      type,
      value,
      preprocess,
      rows,
      maxLength,
      clearable,
      disabled,
      searchable,
      inputtype,
      checked,
      errors,
    } = this.props
    const inputId = `${formName}_${fieldName}`
    const inputLabel = <label className="control-label" key={`input_label_${fieldName}`} htmlFor={inputId}>{label}</label>

    const inputRender = render({
      options,
      type,
      value: this.getProcessedValue(value, preprocess),
      name: fieldName,
      id: inputId,
      onChange: (e) => this.getEventObject(e, this.onChange),
      onBlur: (e) => this.getEventObject(e, this.onBlur),
      onInput: (e) => this.getEventObject(e, this.onInput),
      onPaste: (e) => this.getEventObject(e, this.onPaste),
      onKeyPress: (e) => this.getEventObject(e, this.onKeyPress),
      rows,
      maxLength,
      clearable,
      searchable,
      disabled: disabled,
      refs: this.setInputRef,
      inputtype,
      key: inputId,
      checked,
      styles: this.getCustomStyles(type),
    })
    const formGroupTypeClass = typeof type !== 'undefined' ? `form-group-${type}` : ''
    const formHasErrorsClass = errors.length ? 'has-errors' : ''

    return (
      <div className={`form-group optional ${formHasErrorsClass} ${formGroupTypeClass} ${inputId}`} id={`form-group-${inputId}`} tabIndex="-1">
        { type === 'checkbox' ? [inputRender, inputLabel] : [inputLabel, inputRender] }
        <span className="text-muted" />
        <FieldErrors errors={errors} />
      </div>
    )
  }
}

const getItemFromState = (type, state, ownProps) => {
  if (typeof ownProps.valueGroup !== 'undefined') {
    return state[ownProps.valueGroup][ownProps.valueField]
  }

  return state.formState[ownProps.formName][type][ownProps.valueField]
}

const getErrorsFromState = (state, ownProps) => (
  state.formState[ownProps.formName].formValidationErrors[ownProps.fieldName] || []
)

const mapStateToProps = (state, ownProps) => ({
  value: getItemFromState('values', state, ownProps),
  errors: getErrorsFromState(state, ownProps),
  touched: getItemFromState('touched', state, ownProps) || false,
})
const mapDispatchToProps = (dispatch) => ({
  changeInputProp: (name, value, formName, errors) => dispatch(
    changeInput({ name, value, formName, errors })
  ),
})

FormField.propTypes = {
  formName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  options: PropTypes.array,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  preprocess: PropTypes.func,
  postprocess: PropTypes.func,
  eventPreProcess: PropTypes.func,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  inputtype: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.array.isRequired,
  fieldSchema: PropTypes.object,
  validate: PropTypes.func,
  changeInputProp: PropTypes.func.isRequired,
}

FormField.defaultProps = {
  options: [],
  value: undefined,
  inputtype: undefined,
  rows: undefined,
  preprocess: undefined,
  postprocess: undefined,
  eventPreProcess: undefined,
  maxLength: undefined,
  clearable: undefined,
  searchable: undefined,
  checked: undefined,
  disabled: false,
  fieldSchema: undefined,
  validate: undefined,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormField)
