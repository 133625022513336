/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';

const PaymentAccountIssueWarning = createReactClass({
  displayName: 'PaymentAccountIssueWarning',
  render() {
    const accountIssueText = I18n.t(gon.payment_issues.account_issue_i18n_key)
    return (
      <p className="text-left">
        <i className="fa fa-info" />
        <span className="text-right">
          {accountIssueText}
          {'\
           \
'}
          <a href={gon.payment_issues.account_issue_link_url} className="alert-link">{I18n.t(gon.payment_issues.account_issue_link_i18n_key)}</a>
        </span>
      </p>
    )
  },
})

export default PaymentAccountIssueWarning;
