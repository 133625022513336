/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { roundedSizeInMBFromB } from '../../../products/react_components/helpers'
import { filesI18nOptions } from '../../products/constants'

const ProductFileUploadProgress = ({
  failed,
  queued,
  success,
  name,
  cancelFileProgress,
  progress,
  totalBytes,
  uploadedBytes,
}) => (
  <div className="file-upload-progress">
    <span className="file-name">{name}</span>
    <div className="progress-container">
      <div className={!failed && (progress === 100 || queued || success) ? 'progress-bar-striped' : 'progress-bar'} data-processing={I18n.t('processing', filesI18nOptions)} data-failed={failed ? I18n.t('failed', filesI18nOptions) : undefined}>
        <div className={failed ? 'progress-level retry-upload' : 'progress-level'} style={{ width: `${queued || success ? 100 : progress}%` }} />
      </div>
      {!queued && !failed && progress !== 100 && (
        <button
          aria-label="cancel-upload"
          type="button"
          className="fa fa-times pull-right btn-cancel-upload"
          onClick={(e) => {
            if (!!cancelFileProgress?.abort) {
              cancelFileProgress.abort()
            }
          }}
        />
      )}
    </div>
    {failed && <span className="upload-failed-text">{I18n.t('upload_failed', filesI18nOptions)}</span>}
    <span className="progress-info">
      {
        (uploadedBytes > 0 && totalBytes > 0)
        && `
          ${roundedSizeInMBFromB(uploadedBytes)}MB ${I18n.t('of', filesI18nOptions)}
          ${roundedSizeInMBFromB(totalBytes)}MB (${Math.round(uploadedBytes / totalBytes * 100)}% ${I18n.t('is_done', filesI18nOptions) /* eslint-disable-line */})
        `
      }
    </span>
  </div>
)

ProductFileUploadProgress.propTypes = {
  failed: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cancelFileProgress: PropTypes.object,
  progress: PropTypes.number,
  fileType: PropTypes.string.isRequired,
  totalBytes: PropTypes.number,
  uploadedBytes: PropTypes.number,
}

ProductFileUploadProgress.defaultProps = {
  failed: false,
  progress: 0,
  totalBytes: undefined,
  uploadedBytes: undefined,
}

export default ProductFileUploadProgress
