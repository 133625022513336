import React from 'react'
import PropTypes from 'prop-types'
import FormField from './form_field'
import { IsActionPublish } from '../helpers'
import SyncField from '../../../components/publisher/ProductSpecs/SyncField'

const FormFieldGroup = (props) => {
  const {
    formName,
    type,
    fields,
    formActionInProgress,
  } = props
  return (
    <>
      {
        fields.map(({ fieldName, label, checked }) => (
          <FormField
            key={`${fieldName}_${formName}`}
            fieldName={fieldName}
            label={label()}
            type={type}
            formName={formName}
            checked={checked}
            disabled={IsActionPublish(formActionInProgress)}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            render={({ refs, ...innerProps}) => {
              return (
                <SyncField
                  syncName={fieldName}
                  syncChecked={checked}
                  className="boolean optional"
                  {...innerProps}
                  render={({ render: _, ...inceptionProps }) => {
                    return (
                      <input ref={refs} {...inceptionProps} />
                    )
                  }}
                />
              )
            }}
          />
        ))
      }
    </>
  )
}

export default FormFieldGroup

FormFieldGroup.propTypes = {
  formName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fields: PropTypes.array,
  formActionInProgress: PropTypes.string,
}

FormFieldGroup.defaultProps = {
  fields: [],
  formActionInProgress: '',
}
