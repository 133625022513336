/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import DownloadAssetFiles from './DownloadAssetFiles'
import { get } from '../../../services/service'

class DownloadModal extends React.Component {
  constructor(props) {
    super(props)

    const { products } = props
    const productIds = products.map((product) => product.turbosquid_id)
    const productId = productIds[0]

    this.state = {
      productId,
      assetFiles: [],
      inProgress: false,
    }
  }

  componentDidMount() {
    this.fetchDownloads()
  }

  fetchDownloads = () => {
    const { updateDownloadModalTitle, handleBulkUpdateSubmit } = this.props
    const { productId } = this.state
    const url = `/turbosquid/products/${productId}/download.json`
    this.setState({ inProgress: true })
    updateDownloadModalTitle(I18n.t('turbosquid_products.download_modal_title'))
    get(url)
      .then((response) => {
        this.setState({
          assetFiles: response.data,
          productId,
          inProgress: false,
        })
      })
      .catch((error) => {
        this.setState({ inProgress: false })
        handleBulkUpdateSubmit({ error })
      })
  }

  render() {
    const {
      assetFiles,
      inProgress,
      productId,
    } = this.state
    return (
      <div>
        <DownloadAssetFiles assetFiles={assetFiles} productId={productId} />
        <div className="btn-wrapper">
          <div className="loader" style={{ display: inProgress ? 'inline-block' : 'none' }} />
        </div>
      </div>
    )
  }
}

export default DownloadModal

DownloadModal.propTypes = {
  products: PropTypes.array.isRequired,
  handleBulkUpdateSubmit: PropTypes.func.isRequired,
  updateDownloadModalTitle: PropTypes.func.isRequired,
}
