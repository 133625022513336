import React from 'react'

export default function Checkbox({ id, selected, onSelect, label }) {
  const inputId = `checkbox-${id}`
  return (
    <div className="form-group optional form-group-checkbox">
      <input
        id={inputId}
        type="checkbox"
        checked={!!selected}
        onChange={() => onSelect(id)}
        style={{ display: 'none' }}
      />
      <label
        htmlFor={inputId}
        className="control-label"
      >
        {label}
      </label>
      <span className="text-muted" />
    </div>
  )
}
