// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs'
import { get } from './service'

const sortKey = (field) => {
  const parts = [field.id]
  if (field.desc) {
    parts.push('desc')
  }
  return parts.join(' ')
}

const draftDataUrl = (pageNumber, sortColumn) => {
  const search = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const page = search.page || pageNumber + 1
  const path = '/turbosquid/drafts.json'
  const { size } = search
  let sortName = null
  if (sortColumn) {
    sortName = sortKey(sortColumn)
  }
  const query = {
    page,
    size,
    sort: sortName,
  }
  const params = {}
  Object.keys(query).forEach((key) => {
    if (query[key] !== undefined && query[key] !== null) {
      params[key] = query[key]
    }
  })

  return path + qs.stringify(params, { addQueryPrefix: true })
}

export const getDrafts = (state) => {
  const url = draftDataUrl(state, state.sorted[0])
  return get(url)
}
