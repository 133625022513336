/* global gon */
import { post, get } from './service'

class PendingDraftsService {
  bulkUpdateStatusUrl = (batchID) => `${gon.turbosquid_draft_batches_path}/${batchID}`

  getBulkUpdateStatus = (batchID) => {
    const url = this.bulkUpdateStatusUrl(batchID)
    return get(url)
  }

  saveDraftChanges = (url, data) => post(url, data)
}

export default new PendingDraftsService()
