export const fileUploadType = {
  associatedFiles: 'associatedFiles',
  previews: 'previews',
  previewsTurntables: 'previewsTurntables',
  mainFiles: 'mainFiles',
}

export const tabMapper = {
  mainFiles: 1,
  previews: 2,
  previewsTurntables: 2,
  associatedFiles: 3,
}
