/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'

const FilterBulkActions = ({ updateRemovalModalVisibility }) => (
  <div className="in-page-action-button-group bulk-actions-group">
    <div
      className="btn-group"
      id="bulk-actions-group"
      role="group"
    >
      <button
        type="button"
        className="btn btn-default dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {I18n.t('turbosquid_products.bulk_actions.title')}
      </button>
      <ul className="dropdown-menu">
        <li>
          <button
            type="button"
            id="bulk-remove-link"
            onClick={() => updateRemovalModalVisibility(true)}
          >
            {I18n.t('turbosquid_products.bulk_actions.removal')}
          </button>
        </li>
      </ul>
    </div>
  </div>
)

export default FilterBulkActions

FilterBulkActions.propTypes = {
  updateRemovalModalVisibility: PropTypes.func.isRequired,
}
