const buildExtensionPattern = (extensions) => {
  let pattern = '[_.-][0-9]{2,}\\.'
  const joinedExtensions = extensions.join('|')
  if (extensions.length > 0) {
    pattern += extensions.length === 1 ? extensions[0] : `(${joinedExtensions})`
  }
  pattern += '$'
  return pattern
}

export const getFileInfo = (filename) => {
  const fileInfo = {
    type: 'thumbnail',
  }

  const extensions = ['jpg', 'png', 'gif', 'bmp', 'tiff']
  const pattern = buildExtensionPattern(extensions)

  const regexp = new RegExp(pattern, 'gi')
  const match = regexp.exec(filename)

  if (match !== null) {
    const name = filename.substring(0, match.index === 0 ? 0 : match.index)
    const key = filename.substring(0, match.index === 0 ? 1 : match.index + 1)
    const rest = filename.substring(match.index + 1)
    const position = parseInt(rest.split('.')[0], 10)
    const base = rest.split('.')[0].length
    fileInfo.name = name
    fileInfo.position = position
    fileInfo.type = 'turntable'
    fileInfo.key = key
    fileInfo.id = `${key}${base}`
  }
  return fileInfo
}

const calculateAllNumbers = (min, max) => {
  const numbers = []
  for (let i = min + 1; i < max; i++) {
    numbers.push(i)
  }
  return numbers
}

const minTurntableImages = 12

export const consecutiveCheck = (positions) => {
  const arr = [...positions]
  const missing = []
  arr.sort((a, b) => a - b)
  let min = 0
  let max = 0
  if (arr.length <= minTurntableImages && arr[0] > 0) arr.push(minTurntableImages + 1)
  if (arr.length <= minTurntableImages && arr[0] === 0) arr.push(minTurntableImages)
  if (arr[0] > 1) arr.unshift(0)
  for (let i = 0; i <= arr.length - 2; i++) {
    min = arr[i]
    max = arr[i + 1]
    if (min + 1 !== max) {
      missing.push(...calculateAllNumbers(min, max))
    }
  }

  if (missing.length !== 0) {
    return missing
  }
  return []
}

export const getTurntablesFromCandidates = (previews) => {
  const turntableCandidatesLookup = {}
  const errors = []
  const turntablesCandidates = previews.reduce((acc, value) => {
    const { name, id, attributes: { url200, width, height } } = value
    const fileInfo = getFileInfo(name)
    const fileKey = fileInfo.key
    const { position } = fileInfo
    let isDuplicate = false
    if (fileKey) {
      const keyName = `${fileKey}_${width}_${height}_${name.length}`
      if (acc[keyName]) {
        const hasItem = acc[keyName].items.find((item) => item.position === position)
        if (hasItem) {
          isDuplicate = true
        } else if (acc[keyName].nameLength === name.length) {
          acc[keyName] = {
            first: position <= acc[keyName].first ? position : acc[keyName].first,
            items: [...acc[keyName].items, {
              fileId: id,
              position,
              thumb: url200,
              name,
              width,
              height,
            }],
            nameLength: name.length,
          }
        }
      } else {
        acc[keyName] = {
          first: position,
          items: [{
            fileId: id,
            position,
            thumb: url200,
            name,
            width,
            height,
          }],
          duplicates: [],
          nameLength: name.length,
        }
      }
      if (!isDuplicate) {
        turntableCandidatesLookup[id] = { name: keyName, position }
      }
    }
    return acc
  }, {})

  return {
    turntableCandidatesLookup,
    turntablesCandidates,
    errors,
  }
}
