import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import withSelectableRows from './withSelectableRows'
import { PENDING_ASSET_TYPE } from './constants'

const CheckboxTable = withSelectableRows(ReactTable)

class SelectableReactTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selection: [],
      selectAll: false,
    }
    this.selection = []
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps) {
    let { selection } = this.state
    const { pendingProductIds } = this.props
    const intersection = selection.filter((key) => pendingProductIds.includes(key))

    if (intersection.length > 0) {
      selection = selection.filter((key) => !intersection.includes(key))
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selection, selectAll: false })
      this.selection = selection
    }
  }

  toggleSelection = (key) => {
    const { handleSelectionChange, data } = this.props
    let { selection } = this.state
    if (this.isPending(key)) return null

    selection = [...selection]
    const keyIndex = selection.indexOf(key)

    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ]
    } else {
      selection.push(key)
    }

    this.setState({ selection })
    this.selection = selection
    handleSelectionChange(selection.length, data.filter((p) => selection.includes(p.turbosquid_id)))
    return null
  }

  toggleAll = () => {
    let { selectAll } = this.state
    const { keyField, handleSelectionChange, data } = this.props
    selectAll = !selectAll
    const selection = []

    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance()
      const currentRecords = wrappedInstance.getResolvedState().sortedData

      currentRecords.forEach((item) => {
        // eslint-disable-next-line no-underscore-dangle
        const value = item._original[keyField]

        if (!this.isPending(value)) {
          selection.push(value)
        }
      })
    }

    this.setState({ selectAll, selection })
    this.selection = selection
    handleSelectionChange(selection.length, data.filter((p) => selection.includes(p.turbosquid_id)))
  }

  isSelected = (key) => {
    const { selection } = this.state
    return selection.includes(key)
  }

  isPending = (key) => {
    const { pendingProductIds } = this.props
    return pendingProductIds.includes(key)
  }

  render() {
    const { toggleSelection, toggleAll, isSelected, isPending } = this
    const { selectAll } = this.state

    const checkboxProps = {
      ...this.props,
      selectAll,
      isSelected,
      isPending,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        const { keyField } = this.props
        const turbosquidId = r.original[keyField]

        let className = ''
        const result = {}

        if (this.isPending(turbosquidId)) {
          className += ' pending-product-update '
        }

        className += r.original.type === PENDING_ASSET_TYPE ? ' pending-asset pending-asset__new-product ' : ' '

        if (className !== '') {
          result.className = className
        }
        return result
      },
    }

    return (
      <CheckboxTable ref={(r) => { this.checkboxTable = r }} {...checkboxProps} /> // eslint-disable-line
    )
  }
}

SelectableReactTable.propTypes = {
  pendingProductIds: PropTypes.array,
  handleSelectionChange: PropTypes.func,
  keyField: PropTypes.string,
  data: PropTypes.array,
}

SelectableReactTable.defaultProps = {
  pendingProductIds: [],
  handleSelectionChange: null,
  keyField: '',
  data: [],
}

export default SelectableReactTable
