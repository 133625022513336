import { connect } from 'react-redux'
import ProductSpecifications3dModelPanel from '../../../components/publisher/ProductSpecs/ProductSpecifications3dModel'
import { handleChanges } from '../../../products/react_components/product_specifications_with_form_actions'

const mapStateToProps = ({ formState }) => {
  const {
    animated,
    geometry,
    materials,
    polygons,
    rigged,
    textures,
    unwrappedUVs,
    uvMapped,
    vertices,
  } = formState.turbosquid_product_form.values

  return {
    animated,
    geometry,
    materials,
    polygons,
    rigged,
    textures,
    unwrappedUVs,
    uvMapped,
    vertices,
    formActionInProgress: formState.formActionInProgress,
    publishValidationTriggered: formState.publishValidationTriggered,
  }
}

const mapDispatchToProps = (dispatch) => handleChanges(dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSpecifications3dModelPanel)
