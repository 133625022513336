/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { buildUrlParams } from '../helpers'

class SidebarContent extends React.Component {
  clickFolder = (e, index, id) => {
    const { setActiveFilterId, resetLink, setSelectedFolder, selectedFolder } = this.props
    if (selectedFolder === index) {
      e.preventDefault()
      resetLink()
    } else {
      setSelectedFolder(index)
      setActiveFilterId(id)
    }
  }

  render() {
    let filtersList
    let activeButtonClass = 'active'
    const {
      filters,
      addFilter,
      editFilter,
      filtersLoading,
      crudError,
      selectedFolder,
      activeFilterId,
    } = this.props
    if (filtersLoading) filtersList = <li className="loader" />
    if (crudError) filtersList = crudError

    if (filters.length !== 0) {
      const sortedFilters = filters.sort((a, b) => a.key && a.key.localeCompare(b.key))
      filtersList = sortedFilters.sort().map((el, index) => {
        let urlString = ''
        const activeClass = (selectedFolder === index || activeFilterId === el.id) ? 'active' : ''
        if (activeClass === 'active') activeButtonClass = ''
        Object.entries(el.data).forEach(([key, value]) => {
          urlString += buildUrlParams(key, value)
        })
        const linkOptions = {
          pathname: '/turbosquid/products',
          search: `?${urlString.slice(1)}`,
        }
        return (
          <li
            key={el.id}
            className={activeClass}
          >
            <Link
              to={linkOptions}
              onClick={(e) => { this.clickFolder(e, index, el.id) }}
              title={el.key}
            >
              <i className="fa fa-caret-left" />
              {el.key}
            </Link>
            <button type="button" onClick={() => editFilter(el.id)}>
              <i className="fa fa-pencil" />
            </button>
          </li>
        )
      })
    }
    return (
      <>
        <ul>
          {filtersList}
        </ul>
        <div className="btn-wrapper">
          <button type="submit" className={`btn btn-info ${activeButtonClass}`} onClick={addFilter}>
            <i className="fa fa-plus" />
            {I18n.t('turbosquid_products.filter_titles.modal_new_filter')}
          </button>
        </div>
      </>
    )
  }
}

export default SidebarContent

SidebarContent.propTypes = {
  setActiveFilterId: PropTypes.func.isRequired,
  resetLink: PropTypes.func.isRequired,
  setSelectedFolder: PropTypes.func.isRequired,
  selectedFolder: PropTypes.number,
  addFilter: PropTypes.func.isRequired,
  editFilter: PropTypes.func.isRequired,
  filtersLoading: PropTypes.bool.isRequired,
  crudError: PropTypes.string,
  activeFilterId: PropTypes.string,
  filters: PropTypes.array.isRequired,
}

SidebarContent.defaultProps = {
  selectedFolder: null,
  crudError: null,
  activeFilterId: null,
}
