import useProductSearchSelect from './useProductSearchSelect'
import useMetadataForm from './useMetadataForm'
export default function useBundles({ product, csrfToken }) {
  const searchSelect = useProductSearchSelect()
  const metadataForm = useMetadataForm()

  return {
    searchSelect,
    metadataForm
  }
}