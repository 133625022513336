import React from 'react'
import { useFormContext } from 'react-hook-form'
import { displayAlerts } from '../../../products/react_components/actions/form_slice_actions'
import { useDispatch } from 'react-redux'
import { resetNotificationsOnPublishAttempt } from '../../../products/react_components/actions/publisher_ui_slice_actions'

export default function ValidationTriggeringButton({ type, name, disabled, onClick, className, toggle, children }) {
  const form = useFormContext()
  const dispatch = useDispatch()
  const handleClick = (event) => {
    event.persist()
    form.trigger(undefined, { shouldFocus: true })
      .then(isValid => {
        if (isValid) {
          onClick(event)
        } else {
          dispatch(resetNotificationsOnPublishAttempt())
          dispatch(displayAlerts())
        }
      })
  }
  return (
    <button
      type={type}
      name={name}
      className={className}
      disabled={disabled}
      onClick={handleClick}
      data-toggle={toggle}
    >
      {children}
    </button>
  )
}
