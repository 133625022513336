/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import queryString from 'query-string'

import SelectableReactTable from '../../components/products/SelectableReactTable'
import { defaultColumns } from '../../components/drafts/columnsMyDrafts'
import Paginator from '../../shared/Paginator'
import * as DraftsService from '../../services/DraftsService'
import AlertBox from '../../shared/AlertBox'
import RemovalForm from '../../components/drafts/RemovalForm'
import GenericModal from '../../shared/GenericModal'
import FilterBulkActions from '../../components/drafts/FilterBulkActions'
import ManageProductButtons from '../../components/drafts/ManageProductButtons'
import { encodeQuery } from '../../components/products/helpers'

class MyDrafts extends React.Component {
  constructor(props) {
    super(props)

    const searchObject = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    const queryPage = parseInt(searchObject.page || 1, 10)
    const queryPageSize = parseInt(searchObject.size || gon.default_page_size, 10)

    searchObject.page = queryPage
    searchObject.size = queryPageSize
    this.state = {
      data: [],
      loading: true,
      pages: null,
      pageSize: queryPageSize,
      search: qs.stringify(searchObject),
      total: 0,
      alert: { message: '', type: '' },
      tableColumns: defaultColumns,
      showRemovalModal: false,
      allowDuplicate: true,
      sorted: this.defaultSorted(searchObject),
    }
  }

  componentDidUpdate(prevProps, _nextProps) {
    const { alert, updatedDrafts } = this.props
    const oldTimestamp = prevProps.alert ? prevProps.alert.timestamp : null
    const { message, type, timestamp } = alert
    const { id: batchId } = updatedDrafts

    if (oldTimestamp !== timestamp) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ alert: { message, type } })
    }

    if (prevProps.updatedDrafts.id !== batchId) {
      this.updateDraftsState(updatedDrafts)
    }
  }

  updateDraftsState = (response = {}) => {
    const draftsData = {}
    const errors = []
    let alert
    const { data: stateData } = this.state

    response.attributes.drafts.forEach((draft) => {
      if (draft.attributes.errors) {
        errors.push(draft.attributes.errors)
      } else {
        const { remove } = draft.attributes
        draftsData[draft.id] = { remove }
      }
    })

    const data = stateData.map((oldData) => {
      const draft = draftsData[parseInt(oldData.id, 10)]

      if (draft) {
        if (draft.remove !== undefined) {
          return null
        }
      }
      return oldData
    }).filter((product) => product !== null)

    if (errors.length > 0) {
      let errorMessage = I18n.t('turbosquid_products.messages.partial_update')
      errorMessage = errorMessage.concat(' ', [...new Set(errors.flat())].join('; '), '.')
      alert = {
        type: 'danger',
        message: errorMessage,
      }
    } else {
      alert = { type: 'success', message: I18n.t('turbosquid_products.messages.update_done'), timeout: 5000 }
    }

    this.setState({ alert, data })
    window.scrollTo(0, 0)
  }

  fetchData = (state, instance, showLoading = true) => {
    this.setState({ loading: showLoading }, () => {
      const { location } = this.props
      const { search } = location
      DraftsService.getDrafts(state)
        .then((response) => {
          const searchObject = qs.parse(search, { ignoreQueryPrefix: true })
          const nextState = {
            data: response.data,
            pages: response.meta.total_pages,
            total: response.meta.total_count,
            search: qs.stringify(searchObject),
            loading: false,
          }
          this.setState(nextState)
        })
    })
  }

  getCheckedDraftIds = () => (this.checkboxTable ? this.checkboxTable.state.selection : [])

  getUpdateableDrafts = () => {
    const { data: drafts } = this.state
    const checkedIds = this.getCheckedDraftIds()
    return drafts.filter((draft) => checkedIds.includes(draft.id))
  }

  handleBulkUpdateSubmit = (data) => {
    const { handleBulkUpdateSubmit: propsHandleBulkUpdateSubmit } = this.props
    this.setState({ showRemovalModal: false })
    propsHandleBulkUpdateSubmit(data)
  }

  toggleRemovalModal = (value) => {
    const checkedDraftIds = this.getCheckedDraftIds()

    if (checkedDraftIds.length) {
      this.setState({ showRemovalModal: value })
    } else {
      const message = I18n.t('turbosquid_products.messages.no_drafts_checked')
      this.setState({ alert: { type: 'danger', message } })
    }
  }

  resetAlertBox = () => {
    const { resetParentAlert } = this.props
    this.setState({ alert: {} })
    resetParentAlert()
  }

  renderAlertBox = () => {
    const { alert } = this.state
    if (alert.message) {
      return (
        <AlertBox
          type={alert.type}
          message={alert.message}
          timeout={alert.timeout}
          resetAlertBox={this.resetAlertBox}
        />
      )
    }
    return null
  }

  handleSelectionChange = (length) => {
    const allowDuplicate = length <= 1

    // eslint-disable-next-line react/no-unused-state
    this.setState({ allowDuplicate })
  }

  sortChange = (newSorted, _column, _additive) => {
    const { search } = this.state
    const stateSearch = queryString.parse(search)
    const { history } = this.props
    const { id, desc } = newSorted[0]
    stateSearch.page = 1
    stateSearch.sort = desc ? `${id} desc` : id
    const newStateDiff = { page: 1, search: queryString.stringify(stateSearch) }
    this.setState(newStateDiff)
    history.push({
      pathname: '/turbosquid/drafts',
      search: encodeQuery(stateSearch),
      state: newStateDiff,
    })
  }

  defaultSorted = (search) => {
    if (search.sort) {
      const sortData = search.sort.split(' ')
      let desc = false
      if (sortData[1] && sortData[1] === 'desc') {
        desc = true
      }
      return [{ id: sortData[0], desc }]
    }
    return []
  }

  render() {
    const {
      sorted,
      total,
      pages,
      data,
      loading,
      tableColumns,
      search,
      showRemovalModal,
    } = this.state

    const { location, pendingDraftIds, handlePendingSubmitsChanges } = this.props
    let { pageSize } = this.state
    const searchObject = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (searchObject.size) {
      pageSize = parseInt(searchObject.size, 10)
    }

    return (
      <div className="my-products my-drafts">
        <div className="page-toolbar">
          <div className="search-and-actions">
            <div className="in-page-actions">
              <FilterBulkActions
                updateRemovalModalVisibility={this.toggleRemovalModal}
              />
            </div>
          </div>
          <div className="nav-pills action-buttons">
            <ManageProductButtons alignClass="pull-right" />
          </div>
        </div>
        {this.renderAlertBox()}
        <div className="my-products-content clearfix">
          <div className="my-products-results">
            <SelectableReactTable
              manual
              data={data}
              columns={tableColumns}
              keyField="id"
              loading={loading}
              onFetchData={this.fetchData}
              onPageChange={this.pageChange}
              onSortedChange={this.sortChange}
              handleSelectionChange={this.handleSelectionChange}
              showPagination={false}
              className="-striped my-products-table"
              minRows={0}
              defaultSorted={sorted}
              filtered={location.search}
              pendingProductIds={pendingDraftIds}
              ref={(table) => { this.checkboxTable = table }}
            />
          </div>
          <Paginator
            total={total}
            page={parseInt(searchObject.page || 1, 10)}
            pages={pages}
            pageSize={pageSize}
            search={search}
            pageSizes={gon.page_sizes}
            pathName="/turbosquid/drafts"
          />
        </div>
        <GenericModal
          title={I18n.t('turbosquid_products.removal_modal_title')}
          className="bulk"
          show={showRemovalModal}
          hideModal={() => this.toggleRemovalModal(false)}
        >
          <RemovalForm
            drafts={this.getUpdateableDrafts()}
            handleBulkUpdateSubmit={this.handleBulkUpdateSubmit}
            handlePendingSubmitsChanges={handlePendingSubmitsChanges}
          />
        </GenericModal>
      </div>
    )
  }
}

export default MyDrafts

MyDrafts.propTypes = {
  alert: PropTypes.object.isRequired,
  updatedDrafts: PropTypes.object.isRequired,
  pendingDraftIds: PropTypes.array.isRequired,
  handleBulkUpdateSubmit: PropTypes.func.isRequired,
  handlePendingSubmitsChanges: PropTypes.func.isRequired,
  resetParentAlert: PropTypes.func.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
}
MyDrafts.defaultProps = {
  location: null,
  history: null,
}
