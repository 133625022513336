/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'
import { timeFormat } from 'd3-time-format'
import DismissableNotificationContainer from '../../../containers/publisher/EditHeader/DismissableNotificationContainer'
import DiscardModal from './DiscardModal'

const formatTime = timeFormat('%B %d, %Y %I:%M %p')

class DraftAlert extends React.Component {
  constructor(props) {
    super(props)

    this.state = { showDiscardModal: false }
  }

  displayDiscardModal = () => {
    this.setState({ showDiscardModal: true })
  }

  hideDiscardModal = () => {
    this.setState({ showDiscardModal: false })
  }

  handleDiscardDraft = () => {
    const { discardDraft } = this.props
    discardDraft(`${gon.delete_product_draft_url}?discard_type=notification`)
    this.setState({ showDiscardModal: false })
  }

  render() {
    const {
      draftId,
      hadDraft,
      notifications,
      updatedAt,
    } = this.props
    const date = formatTime(new Date(this.props.updatedAt)) // eslint-disable-line
    const notificationId = 'draftAlert'
    const notification = notifications[notificationId]
    const { showDiscardModal } = this.state
    if (gon.product.id !== '0' && draftId && updatedAt && hadDraft) {
      return (
        <DismissableNotificationContainer
          notificationId={notificationId}
          notificationLevel="info"
          dismissed={notification && notification.dismissed}
          extraClasses="draft-alert"
        >
          <div className="notification-holder">
            <p dangerouslySetInnerHTML={{ // eslint-disable-line
              __html: I18n.t('turbosquid.products.product_alerts.draft_warning.htmlDate',
                { date }),
            }}
            />
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p
              onClick={this.displayDiscardModal}
              dangerouslySetInnerHTML={{ // eslint-disable-line
                __html: I18n.t('turbosquid.products.product_alerts.draft_warning.htmlDiscard'),
              }}
            />
            <p dangerouslySetInnerHTML={{ // eslint-disable-line
              __html: I18n.t('turbosquid.products.product_alerts.draft_warning.htmlChanges'),
            }}
            />
          </div>

          <DiscardModal
            show={showDiscardModal}
            hideModal={this.hideDiscardModal}
            discardDraft={this.handleDiscardDraft}
          />

        </DismissableNotificationContainer>
      )
    }
    return null
  }
}

DraftAlert.propTypes = {
  updatedAt: PropTypes.string,
  draftId: PropTypes.string,
  notifications: PropTypes.object,
  hadDraft: PropTypes.bool,
  discardDraft: PropTypes.func,
}

DraftAlert.defaultProps = {
  updatedAt: '',
  draftId: null,
  notifications: {},
  hadDraft: false,
  discardDraft: null,
}

export default DraftAlert
