/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import DashboardClient from './common/dashboard_client';
import PanelSpinner from './common/panel_spinner';
import ReportError from './report_error';
import SaleDetail from './sale_detail';

const SalesHistory = createReactClass({
  displayName: 'SalesHistory',
  mixins: [DashboardClient],
  endpoint() {
    return '/turbosquid/dashboard_widgets/sales_history.json'
  },
  reportURL() {
    return gon.sale_report_url
  },
  render() {
    return (
      <div className="panel-body text-left">
        <div className="panel-heading-text row">
          <div className="col-xs-12">
            <a className="pull-right toggle-collapse js-toggle-collapse" data-toggle="collapse" data-target=".activity-panel-collapse" href="#"><i className={this.panelChevronClass('sales-history-container')} /></a>
            <span className="panel-heading-label">{I18n.t('dashboard.sales.sales_activity')}</span>
            <div className="panel-info-link"><a href={this.reportURL()} target="_blank"><i className="fa fa-table" /></a></div>
          </div>
        </div>
        <div className={`panel-divider activity-panel-collapse ${this.panelCollapseClass('sales-history-container')}`} />
        <div className={`activity-panel-collapse ${this.panelCollapseClass('sales-history-container')}`}>
          <div className="panel-overflow">
            {(() => {
              if (this.reportsDisabled() || __guard__(this.state.data != null ? this.state.data.aggregations : undefined, ({error}) => error)) {
                return <ReportError />
              } if (this.state.data != null) {
                if ((this.state.data.sales_history != null ? this.state.data.sales_history.length : undefined) > 0) {
                  return (
                    <table className="table table-striped">
                      <thead>
                        <tr>
  <th className="text-muted">{I18n.t('dashboard.sales.date')}</th>
<th className="text-muted">{I18n.t('dashboard.sales.product_name')}</th>
  <th className="text-muted">{I18n.t('dashboard.sales.country')}</th>
<th className="text-muted text-right">{I18n.t('dashboard.sales.price')}</th>
  <th className="text-muted text-right">{I18n.t('dashboard.sales.royalties')}</th>
</tr>
                      </thead>
                      <tbody>{__guard__(this.state.data != null ? this.state.data.sales_history : undefined, (x1) => x1.map((sale, index) => <SaleDetail key={index} sale={sale} />))}</tbody>
                    </table>
                  )
                }
              } else {
                return <PanelSpinner />
              }
            })()}
          </div>
        </div>
      </div>
    );
  },
})

export default SalesHistory;

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined
}
