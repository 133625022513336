import React from 'react'
import PropTypes from 'prop-types'

const RichTextareaLeaf = ({ attributes, childrenNode, leaf }) => {
  let newChildren = childrenNode
  if (leaf.bold) {
    newChildren = <strong>{newChildren}</strong>
  }

  if (leaf.code) {
    newChildren = <code>{newChildren}</code>
  }

  if (leaf.italic) {
    newChildren = <em>{newChildren}</em>
  }

  if (leaf.underline) {
    newChildren = <u>{newChildren}</u>
  }

  return <span {...attributes}>{newChildren}</span>
}

RichTextareaLeaf.propTypes = {
  attributes: PropTypes.object.isRequired,
  childrenNode: PropTypes.any,
  leaf: PropTypes.object.isRequired,
}

RichTextareaLeaf.defaultProps = {
  childrenNode: null,
}

export default RichTextareaLeaf
