/* global gon */

$(() => {
  $('.contract-container').on('scroll', () => $('#squidguild-agree').removeClass('disabled'))
  $('.js-toggle-collapse').click(function () {
    $(this).children('i').toggleClass('fa-caret-down').toggleClass('fa-caret-up')
    const panelID = $(this).closest('.panel').attr('id')
    if ($.cookie(panelID)) {
      return $.removeCookie(panelID)
    }
    $.cookie(panelID, 'show')
  })
  $('.flash .close').click(function () {
    $.ajax({
      type: 'PUT',
      data: {
        id: $(this).attr('id'),
      },
      url: gon.dimiss_notification_url,
    })
  })
})
