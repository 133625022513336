import { useContext, useEffect, useRef, useState } from 'react'
import { validate } from '../utils/ProductFiles'
import { UploadContext } from '../contexts/UploadContext'
import { UPLOAD_FAILED } from '../components/products/constants'
import { reduceFilter } from '../components/publisher/ProductFiles/filters'

export default function useFileUploads({
  mapUpload,
  batchFilters = [],
  batchValidators = [],
  validators = [],
  onSuccess,
  onError
}) {
  const [ready, setReady] = useState(false)
  const { upload, credentials } = useContext(UploadContext)
  const input = useRef()

  useEffect(() => {
    if (!ready && credentials) {
      setReady(true)
    }
  }, [credentials])

  const handleFileUploads = async ({ target }) => {
    if (!target.files?.length) return
    const files = Array.from(target.files)

    // Filter files as a batch
    // Warn if files are filtered out

    const {
      files: filteredFiles,
      warnings
    } = batchFilters.reduce(reduceFilter, { files, warnings: [] })

    // Validate the new files as a batch
    // Error if the batch doesn't pass validation

    const batchValidationErrors = await Promise.all(batchValidators.map(validator => {
      const errors = validator(files)
      return errors.then ? errors : Promise.resolve(errors)
    }))

    // Validate the files individually
    // Error if the file doesn't pass validation

    const fileValidationErrors = await Promise.all(files.flatMap(file => validate(file, validators)))

    const validationErrors = [
      ...batchValidationErrors.flatMap(i => i),
      ...fileValidationErrors.flatMap(i => i),
    ]
    const alerts = [
      ...warnings,
      ...validationErrors
    ]
    if (alerts.length) {
      onError(alerts)
    }
    if (!validationErrors.length) {
      try {
        const uploads = await Promise.all(filteredFiles.map(f => {
          const mappedUpload = mapUpload(f)
          return mappedUpload.then ? mappedUpload : Promise.resolve(mappedUpload)
        }))
        await upload(uploads)
        !!onSuccess && onSuccess(filteredFiles)
      } catch (e) {
        console.error(e)
        onError([UPLOAD_FAILED])
      }
    }
    // Reset input ref
    if (input.current) {
      input.current.value = null;
    }
  }

  return {
    ready,
    handleFileUploads,
    inputRef: input
  }
}
