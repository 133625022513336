/* global I18n */
import React from 'react'

const i18nOptions = {
  scope: 'turbosquid.products.product_header',
}

const statusesDescription = {
  online: {
    i18nKey: 'online',
    className: 'status online',
    href: '#',
  },
  awaiting_review: {
    i18nKey: 'awaiting_review',
    className: 'status warning',
    href: '#',
  },
  suspended: {
    i18nKey: 'suspended',
    className: 'status danger',
    href: '#',
  },
  suppressed: {
    i18nKey: 'suppressed',
    className: 'status danger',
    href: '#',
  },
  suspended_awaiting_review: {
    i18nKey: 'suspended_awaiting_review',
    className: 'status danger',
    href: '#',
  },
  not_for_sale: {
    i18nKey: 'not_for_sale',
    className: 'status not-for-sale',
    href: '#',
  },
  private: {
    i18nKey: 'private',
    className: 'status private',
    href: '#',
  },
  offline: {
    i18nKey: 'offline',
    className: 'status offline',
    href: '#',
  },
  draft: {
    i18nKey: 'draft',
    className: 'status draft',
    href: '#',
  },
}

// eslint-disable-next-line max-len
export const statusDescription = (status) => statusesDescription[status] || statusesDescription.offline

export const productStatusLink = (status) => {
  const { className, href, i18nKey } = statusDescription(status)

  return (
    <a href={href} className={className}>
      {I18n.t(i18nKey, i18nOptions)}
    </a>
  )
}
