/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class QuickPreviewOverlay {
  constructor() {
    this.$quickPreviewOverlay = $('#js-quick-preview-overlay');

    this.$quickPreviewOverlay.on('mouseleave', () => $(document).trigger('quick-preview-overlay.mouse.leave.quickPreview'));
  }

  height() {
    this.$quickPreviewOverlay.height();
  }

  show({offset, width, $itemOverlayContent}) {
    this.$quickPreviewOverlay.show();
    this.$quickPreviewOverlay.offset(offset);
    this.$quickPreviewOverlay.width(width);
    const content = $itemOverlayContent.clone();
    this.$quickPreviewOverlay.html(content);
    content.show().removeClass('hidden');
  }

  hide() {
    this.$quickPreviewOverlay.hide();
  }
}

export default QuickPreviewOverlay;
