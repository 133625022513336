/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'
import CreateNewProduct from '../../components/products/SearchHeader/CreateNewProduct'

const ManageProductButtons = ({ alignClass }) => (
  <>
    <CreateNewProduct alignClass="pull-right"/>
    <a
      href={gon.turbosquid_products_path}
      id="view-published-product"
      className={`btn btn-default view-published-products-btn ${alignClass}`}
    >
      {I18n.t('turbosquid_products.view_published_products')}
    </a>
  </>
)

export default ManageProductButtons

ManageProductButtons.propTypes = {
  alignClass: PropTypes.string,
}

ManageProductButtons.defaultProps = {
  alignClass: '',
}
