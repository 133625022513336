import React, { useEffect } from 'react'

export default function Dropzone({ disabled, onChange, className, children }) {
  useEffect(() => {
    const preventDefault = (e) => e.preventDefault()
    document.addEventListener('drop', preventDefault)
    document.addEventListener('dragover', preventDefault)
    return () => {
      document.removeEventListener('drop', preventDefault)
      document.removeEventListener('dragover', preventDefault)
    }
  }, [])

  const onDragEnter = (event) => event.preventDefault()
  const onDrop = (event) => {
    if (disabled) return
    event.preventDefault()
    const files = (() => {
      if (event.dataTransfer.items) {
        const list = []
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          if (event.dataTransfer.items[i].kind === 'file') {
            list.push(event.dataTransfer.items[i].getAsFile())
          }
        }
        return list
      } else {
        return event.dataTransfer.files
      }
    })()
    if (files?.length) onChange({ target: { files }})
  }
  return (
    <div
      data-testid="dropzone"
      aria-dropeffect="execute"
      className={className}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      style={{ height: '100%' }}
    >
      {children}
    </div>
  )
}
