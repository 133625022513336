/* global I18n, gon */
import DOMPurify from 'dompurify'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { getErrors, stripHTMLFromString, IsActionPublish } from '../../../products/react_components/helpers'
import FieldErrors from '../../../products/react_components/form_components/field_errors'
import { SaveDraftValidationShaper, PublishValidationShaper } from '../../../utils/SaveValidations'
import RichTextArea from '../../../products/react_components/form_components/RichTextArea'
import { deserialize } from '../../../utils/slate/deserializer'
import { containsHTML, removeFormatting, normalizeTopLevelNodes } from '../../../utils/RichTextUtils'
import DisableableField from '../../../products/react_components/form_components/DisableableField'

const i18nOptions = {
  scope: 'turbosquid.products.product_description_panel',
}

const getSlateValue = (description) => {
  const sanitizedDescription = DOMPurify.sanitize(description)

  if (containsHTML(description)) {
    const document = new DOMParser().parseFromString(removeFormatting(sanitizedDescription), 'text/html')
    return normalizeTopLevelNodes(deserialize(document.body))
  }

  return [{
    type: 'paragraph',
    children: [{ text: sanitizedDescription }],
  }]
}

const panelClasses = (highlightPanel = false) => {
  if (highlightPanel) {
    return 'panel panel-warning description-panel'
  }
  return 'panel panel-default description-panel'
}

function ProductDescription(props) {
  const {
    collectionFeature,
    description: value,
    errors,
    formActionInProgress,
    highlightPanel,
    publishValidationTriggered,
    updateDescription: updateDescriptionWithoutConstraints,
    updateDescriptionWithConstraints,
  } = props

  const [description, setDescription] = useState(value)
  const skipProductLinkValidation = collectionFeature > 0

  const validationSchema = yup.object().shape(
    SaveDraftValidationShaper(null, skipProductLinkValidation)
  )

  const publishValidationSchema = yup.object().shape(
    PublishValidationShaper(null, skipProductLinkValidation)
  )

  const updateDescription = (value, options = {
    updateWithConstraint: true, draftSavePendingChanges: true, validate: false }) => {
    const updateFunc = options.updateWithConstraint
      ? updateDescriptionWithConstraints
      : updateDescriptionWithoutConstraints

    const stripValue = stripHTMLFromString(value)
    const html = stripValue.trim().length ? value : ''
    if (options.validate) {
      yup.reach(publishValidationTriggered ? publishValidationSchema : validationSchema, 'description')
        .validate(html)
        .then((_valid) => updateFunc(html, null, options.draftSavePendingChanges))
        .catch((exception) => updateFunc(html, exception.errors))
    }
  }

  const syncDescription = (text) => {
    setDescription(text)
    updateDescription(text, {
      updateWithConstraint: true,
      draftSavePendingChanges: true,
      validate: true,
    })
  }

  const onBlurDescription = (text) => {
    syncDescription(text)
  }

  const onInput = (text) => {
    syncDescription(text)
  }

  const hasErrors = Object.keys(getErrors(errors)).includes('description') ? 'has-errors' : ''

  return (
    <div className={panelClasses(highlightPanel)}>
      <div className="panel-body panel-collapse collapse in" id="description-panel-body">
        <div className="panel-body-container">
          <div className={`form-group turbosquid_product_form_description ${hasErrors}`}>
            <div className="description-title">
              <label className="control-label" htmlFor="turbosquid_product_form_description">
                {I18n.t('description', i18nOptions)}
              </label>
            </div>
              <DisableableField
                defaultDisabled={IsActionPublish(formActionInProgress)}
              >
                {(disabled) => (
                  <RichTextArea
                    disabled={disabled}
                    onChange={onInput}
                    onBlur={onBlurDescription}
                    value={getSlateValue(description)}
                  />
                )}
              </DisableableField>
            <FieldErrors errors={getErrors(errors).description} />
          </div>
        </div>
      </div>
    </div>
  )
}

ProductDescription.propTypes = {
  description: PropTypes.string,
  highlightPanel: PropTypes.object,
  toggleInfo: PropTypes.func.isRequired,
  updateDescription: PropTypes.func.isRequired,
  updateDescriptionWithConstraints: PropTypes.func.isRequired,
  errors: PropTypes.object,
  formActionInProgress: PropTypes.string,
  publishValidationTriggered: PropTypes.bool,
  collectionFeature: PropTypes.number,
}

ProductDescription.defaultProps = {
  highlightPanel: null,
  description: '',
  errors: {},
  formActionInProgress: '',
  publishValidationTriggered: false,
  collectionFeature: 0,
}

export default ProductDescription
