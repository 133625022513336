import React from 'react'
import PropTypes from 'prop-types'

const statusLabelClass = (status) => `label label-default label-${status}`

const StatusCell = ({ status }) => (
  <span className={statusLabelClass(status)}>{status}</span>
)

StatusCell.propTypes = {
  status: PropTypes.string,
}

StatusCell.defaultProps = {
  status: '',
}

export default StatusCell
