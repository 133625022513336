/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import DashboardClient from './common/dashboard_client';

const CheckmateHeader = createReactClass({
  displayName: 'CheckmateHeader',
  mixins: [DashboardClient],
  render() {
    if (this.props.is_squid_guild) {
      return (
        <div className="panel-heading-text row">
          <div className="col-xs-12">
            <a className="pull-right toggle-collapse js-toggle-collapse" data-toggle="collapse" data-target=".checkmate-panel-collapse" href="#"><i className={this.panelChevronClass('checkmate-container')} /></a>
            <ul className="nav nav-tabs">
              <li className="active"><a href="#queue" data-toggle="tab">{I18n.t('dashboard.checkmate.checkmate')}</a></li>
              {this.props.checkmate_published_count > 0 ? <li><a href="#leaderboard" data-toggle="tab">{I18n.t('dashboard.checkmate.leaderboard')}</a></li> : undefined}
            </ul>
            <div className="panel-info-link"><a href={gon.checkmate_home_url}><i className="fa fa-book" /></a></div>
          </div>
        </div>
      )
    }
    return (
      <div className="panel-heading-text row">
        <div className="col-xs-12">
          <a className="pull-right toggle-collapse js-toggle-collapse" data-toggle="collapse" data-target=".checkmate-panel-collapse" href="#"><i className={this.panelChevronClass('checkmate-container')} /></a>
          <span className="panel-heading-label">{I18n.t('dashboard.checkmate.squidguild')}</span>
          <div className="panel-info-link"><a href={gon.squidguild_info_url}><i className="fa fa-info-circle" /></a></div>
        </div>
      </div>
    )
  },
})

export default CheckmateHeader;
