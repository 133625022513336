/* global gon */

import { get } from './service'
import { filterTokens, tokenize } from '../products/react_components/reducers/publisher_reducer_methods'

class ConstraintsService {
  findConstraints = (productName) => {
    const defaultResponse = { terms: [] }
    const terms = filterTokens(tokenize(productName))

    if (terms.length) {
      const url = `${gon.constraints_url}?terms=${terms.join(',')}`

      return get(url).catch((_) => Promise.resolve(defaultResponse))
    }

    return Promise.resolve(defaultResponse)
  }
}

export default new ConstraintsService()
