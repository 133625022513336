/* global gon */

import { takeEvery, takeLatest, all } from 'redux-saga/effects'
import { createRateLimitQueue } from './rate_limit_saga'

import { fetchConstraintsFromServer, fetchInitialConstraintsFromServerSaga } from './terms_saga'
import {
  publishProductData,
  saveDraftData,
  saveFeatureGraph,
  previewDraftPage,
  checkDraftId,
  cancelStemcellSubmissionStart,
  discardDraftStart,
} from './form_saga'
import { formSlice } from '../reducers/form_slice'
import { publisherUISlice } from '../reducers/publisher_ui_slice'

const rateLimitQueue = createRateLimitQueue({ rateLimit: gon.api_rate_limit })

function* rootSaga() {
  const sagas = []
  const formActions = formSlice.actions
  const publisherUIActions = publisherUISlice.actions

  sagas.push([
    yield takeLatest(formActions.fetchInitialConstraints, fetchInitialConstraintsFromServerSaga),
    yield takeEvery(formActions.saveDraft, saveDraftData),
    yield takeEvery(formActions.fetchConstraints, rateLimitQueue.enqueue, fetchConstraintsFromServer),
    yield takeEvery(publisherUIActions.publishProduct, rateLimitQueue.enqueue, publishProductData),
    yield takeEvery(formActions.previewDraft, rateLimitQueue.enqueue, previewDraftPage),
    yield takeLatest(
      formActions.cancelStemcellSubmissionStart, rateLimitQueue.enqueue, cancelStemcellSubmissionStart
    ),
    yield takeLatest(formActions.discardDraft, rateLimitQueue.enqueue, discardDraftStart),
    yield takeLatest(formActions.saveDraftId, checkDraftId),
    yield takeEvery(formActions.saveFeatureGraph, saveFeatureGraph),
    yield rateLimitQueue.watch(),
  ])

  yield all(sagas)
}

export default rootSaga
