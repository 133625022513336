import React from 'react'
import PropTypes from 'prop-types'

const DismissableNotification = ({
  children,
  notificationId,
  dismissNotification,
  notificationLevel,
  extraClasses,
  dismissed,
}) => (
  <div className={`dismissable-notification notification-top alert alert-${notificationLevel}
    ${extraClasses} ${dismissed ? 'dismiss-notifications' : ''}`}
  >
    {children}
    <button
      className="btn-close"
      type="button"
      onClick={() => dismissNotification(notificationId)}
      tabIndex="0"
    />
  </div>
)

export default DismissableNotification

DismissableNotification.propTypes = {
  dismissNotification: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  notificationId: PropTypes.string,
  notificationLevel: PropTypes.string,
  extraClasses: PropTypes.string,
  dismissed: PropTypes.bool,
}

DismissableNotification.defaultProps = {
  notificationLevel: 'warning',
  extraClasses: '',
  dismissed: false,
  notificationId: '',
}
