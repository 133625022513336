/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const tooltip = () => (
  <Tooltip id="create-collection-tooltip">
    {I18n.t('turbosquid_products.select_valid_assets')}
  </Tooltip>
)

const CreateCollection = (props) => {
  const { disabled, createCollection } = props
  const disabledClassName = disabled ? 'disabled' : ''
  return (
    <>
      {
        disabled ? (
          <OverlayTrigger placement="right" overlay={tooltip()}>
            <div className={disabledClassName}>
              <button
                type="button"
                id="create-new-collection"
                className="btn create-collection-btn"
                disabled={disabled}
                onClick={createCollection}
              >
                {I18n.t('turbosquid_products.bulk_actions.create_collection')}
              </button>
            </div>
          </OverlayTrigger>
        ) : (
          <div className={disabledClassName}>
            <button
              type="button"
              id="create-new-collection"
              className="btn create-collection-btn"
              disabled={disabled}
              onClick={createCollection}
            >
              {I18n.t('turbosquid_products.bulk_actions.create_collection')}
            </button>
          </div>
        )
      }
    </>
  )
}

export default CreateCollection

CreateCollection.propTypes = {
  createCollection: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

CreateCollection.defaultProps = {
  disabled: false,
}
