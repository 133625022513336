/* global gon, I18n */
import * as yup from 'yup'
import { isEmpty, uniqueId } from 'lodash'
import { call, delay, put, select, all } from 'redux-saga/effects'
import { productParams } from '../product_params'
import { featureGraphParams } from '../feature_graph_params'
import {
  selectDraftId,
  selectSpecs,
  selectFeatureIds,
  selectFeatures,
  selectBrandFeatures,
  selectLicense,
  selectConstraints,
  selectProhibitedWordsError,
  selectCreatingDraft,
  selectCollectionFeature,
} from '../selectors/formSelectors'
import {
  PublishValidationShaper,
  SaveDraftValidationShaper,
  PublishCategoryValidationShaper,
  REQUIRED_NATIVE_MAIN_FILE_FIELDS,
} from '../../../utils/SaveValidations'

import {
  prohibitExtendedUses,
  disableEditorialLicense,
  prohibitCustomUses,
} from '../LicenseHelpers'
import {
  mergeAlerts,
  CSRFToken,
  stripHTMLFromString,
  checkNotAllowedCategory,
  RESPONSE_STATUS,
} from '../helpers'
import { formCertifications } from '../certification_helpers'

import {
  saveCertificationQuery,
  deleteCertificationQuery,
} from '../../../services/CertificationService'
import { addCertification } from '../../../services/draft-service'

import {
  startSubmit,
  requestFailed,
  requestSucceeded,
  saveDraftId,
  saveDraftDone,
  publishProductInvalid,
  saveValidationErrors,
  updateLastActionDraftSaving,
  updateFormActionInProgress,
  updateCertification,
  setCreatingDraft,
  setDraftSavePendingChanges,
  addAsyncQueue,
  removeAsyncQueueItem,
  setDisableLeavePagePrompt,
  setStemcell,
  cancelStemcellSubmission,
} from '../actions/form_slice_actions'

import { resetNotificationsOnPublishAttempt, toggleOverlay } from '../actions/publisher_ui_slice_actions'

import { cookie } from '../../../components/products/helpers'
import { BATCH_COOKIE_NAME, asyncQueueAction } from '../../../components/products/constants'

const fetchWrapper = (url, options, returnStatus = false) => (
  fetch(url, options).then(async (response) => {
    const responseData = await response.json()
    if (returnStatus) {
      return { ...responseData, responseStatus: response.status }
    }
    return responseData
  }).catch((error) => ({ error }))
)

export function* makeRequest(url, options, args = {}) {
  const { returnStatus = false, addQueue = true } = args
  const id = uniqueId(`${asyncQueueAction.fetch}-`)
  let response
  if (addQueue) {
    yield put(addAsyncQueue({ task: { id }, pattern: asyncQueueAction.fetch }))
    response = yield call(fetchWrapper, url, options, returnStatus)
    yield put(removeAsyncQueueItem({ id, pattern: asyncQueueAction.fetch }))
  } else {
    response = yield call(fetchWrapper, url, options, returnStatus)
  }
  return response
}

function* addAsyncQueueForCallback(pattern, { callback = () => { }, args = [] }) {
  const id = uniqueId(`${pattern}-`)
  yield put(addAsyncQueue({ task: { id }, pattern }))
  const response = yield call(callback, ...args)
  yield put(removeAsyncQueueItem({ id, pattern }))
  return response
}

const submitPublisherForm = (params) => {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(params),
    credentials: 'same-origin',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
  return makeRequest(gon.turbosquid_product_url, options, { returnStatus: true })
}

const createNewDraft = () => {
  const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
  const options = {
    method: 'POST',
    body: JSON.stringify({ authenticity_token: authenticityToken }),
    credentials: 'same-origin',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
  const productId = gon.product.id
  const url = `/turbosquid/products/${productId}/create_draft`
  return makeRequest(url, options, { returnStatus: true })
}

export function* checkDraftId() {
  let draftId = yield select(selectDraftId)
  if (!draftId) {
    let creatingDraft = yield select(selectCreatingDraft)
    while (creatingDraft) {
      yield delay(500)
      creatingDraft = yield select(selectCreatingDraft)
    }
    draftId = yield select(selectDraftId)
    if (!draftId) {
      yield put(setCreatingDraft({ status: true }))
      const response = yield call(createNewDraft)
      if (response.id) {
        yield put(saveDraftId({ draftId: response.id }))
        yield put(setCreatingDraft({ status: false }))
        return response.id
      } else if (response.responseStatus && Number(response.responseStatus) === RESPONSE_STATUS.notFound) {
        yield put(setDraftSavePendingChanges({ status: false }))
        yield put(setDisableLeavePagePrompt({ disable: true }))
        // redirect to my products if draft create failed
        window.location.href = gon.turbosquid_products_path
      }
    }
  }
  return draftId
}

export const getFiles = (state, key = null) => {
  if (!key) return []
  const { formState } = state
  return formState[key]
}

export const getPendingDeleteFile = (state, { fileType, id }) => {
  const { formState } = state
  return formState.pendingDeleteFiles.find((f) => f.fileType === fileType && f.id === id)
}

function* saveForm({ name, value, draftSaving = true, force = false }) {
  if (draftSaving || force) {
    yield put(startSubmit({ draftSaving }))
    const formData = yield select(productParams)
    const params = { ...formData, [name]: value }
    const response = yield call(submitPublisherForm, params)
    const responseData = yield response
    if (response && response.error) {
      return false
    }

    if (responseData && Number(responseData.responseStatus) === RESPONSE_STATUS.notFound) {
      yield put(setDisableLeavePagePrompt({ disable: true }))
      window.location.href = gon.turbosquid_products_path
      return false
    } else {
      if (!isEmpty(responseData.product)) {
        responseData.product = formCertifications(response.product)
      }
      yield put(requestSucceeded({ response: responseData, draftSaving }))
    }
  } else {
    yield put(updateFormActionInProgress({}))
  }
  return true
}

const validateSpecFields = async (fields, schema) => {
  const schemaKeys = Object.keys(schema.fields)
  const errors = {}
  let fieldValue = ''
  const promises = Object.keys(fields).map(async (key) => {
    if (schemaKeys.find((element) => element === key)) {
      fieldValue = fields[key]
      if (key === 'description' && !stripHTMLFromString(fields[key]).trim().length) {
        fieldValue = ''
      }
      try {
        await yup.reach(schema, key).validate(fieldValue)
      } catch (error) {
        errors[key] = error.errors
      }
    }
  })
  await Promise.all(promises)
  return errors
}

function* validateSpecsForPublish() {
  const formSpecs = yield select(selectSpecs)
  const collectionFeature = yield select(selectCollectionFeature)
  const { mediaType = 2 } = formSpecs.values
  const validationSchema = yup.object().shape(PublishValidationShaper(mediaType, collectionFeature > 0))
  return yield validateSpecFields(formSpecs.values, validationSchema)
}

function* validateSpecsForPublishCategory() {
  const formSpecs = yield select(selectSpecs)
  const validationSchema = yup.object().shape(PublishCategoryValidationShaper())
  const errors = validateSpecFields(formSpecs.values, validationSchema)

  const featureErrors = yield* validFeaturesRequired(errors)
  if (featureErrors) errors.featureIds = featureErrors

  return errors
}

function* validateSpecsForSaveDraft() {
  const formSpecs = yield select(selectSpecs)
  const collectionFeature = yield select(selectCollectionFeature)
  const { mediaType = 2 } = formSpecs.values
  const validationSchema = yup.object().shape(SaveDraftValidationShaper(mediaType, collectionFeature > 0))
  return yield validateSpecFields(formSpecs.values, validationSchema)
}

function validateMainFile(attributes, filterField = []) {
  let errors = {}
  if (attributes.isNative || attributes.certifiable) {
    REQUIRED_NATIVE_MAIN_FILE_FIELDS.forEach((field) => {
      if (!filterField.includes(field)) {
        const translationName = field === 'formatVersion' ? 'format_version' : field
        const errorTranslation = I18n.t(
          `${translationName}_required`,
          { scope: 'turbosquid.products.product_files_panel' }
        )
        if (attributes[field] !== undefined && (!attributes[field] || attributes[field] < 0)) {
          if (errors[field] && !errors[field].find((element) => element === errorTranslation)) {
            errors[field] = errors[field].concat(errorTranslation)
          } else {
            errors[field] = [errorTranslation]
          }
        } else if (attributes[field] && (attributes[field].length > 0 || attributes[field] >= 0)) {
          if (errors[field]) {
            errors[field] = errors[field].filter(
              (errorMessage) => errorMessage !== errorTranslation
            )
          }
        }
      }
    })
  } else {
    errors = {}
  }
  return errors
}

function* validateLicense() {
  const allState = yield select()
  const license = yield select(selectLicense)
  const alertMessages = mergeAlerts(allState)
  const { formState: { constraints = [] } } = allState

  const prohibitsExtendedUses = prohibitExtendedUses(alertMessages, constraints)
  const prohibitsEditorialUses = disableEditorialLicense(alertMessages)
  const prohibitsCustomUses = prohibitCustomUses(alertMessages)

  const licenseErrors = []

  if (
    !license
    || (prohibitsExtendedUses && license === 'royalty_free_all_extended_uses')
    || (prohibitsEditorialUses && license === 'royalty_free_editorial_uses_only')
    || (prohibitsCustomUses && license === 'custom')) {
    licenseErrors.push({
      messageForNotif: I18n.t('license_required_notif', { scope: 'turbosquid.products.product_specifications_panel' }),
      messageForInline: I18n.t('license_required', { scope: 'turbosquid.products.product_specifications_panel' }),
    })
  }

  return licenseErrors
}

const getMainFileFieldErrors = (file, allFileFormats) => {
  const selectedFileFormat = allFileFormats.find(
    (value) => value.id === file.attributes.format
  )
  const selectedRenderer = selectedFileFormat ? selectedFileFormat.renderers.find(
    (r) => r.id === file.attributes.renderer
  ) : false
  const certifiable = selectedFileFormat ? selectedFileFormat.certifiable : false
  const renderer = selectedRenderer ? file.attributes.renderer : '-1'
  const filterField = []
  if (!selectedFileFormat || !selectedFileFormat.renderers || selectedFileFormat.renderers.length <= 0) {
    filterField.push('renderer')
  }
  return validateMainFile({ ...file.attributes, certifiable, renderer }, filterField)
}

const validFeatures = (features) => {
  const validItems = features.map((feature) => {
    if (!feature.constraints || !feature.constraints.restrictions) {
      return true
    } return !feature.constraints.restrictions.includes('publish_not_allowed')
  })
  return validItems.every((x) => x === true)
}

function* validFeaturesRequired() {
  const featureIds = yield select(selectFeatureIds)

  if (!featureIds || !featureIds.length) {
    return [
      {
        messageForNotif: I18n.t('one_category_required_notif', { scope: 'turbosquid.products.product_specifications_panel' }),
        messageForInline: I18n.t('one_category_required', { scope: 'turbosquid.products.product_specifications_panel' }),
      },
    ]
  }
  return null
}

function* validatePublishData() {
  const errors = yield validateSpecsForPublish()
  const features = yield select(selectFeatures)

  const featureErrors = yield* validFeaturesRequired(errors)
  if (featureErrors) errors.featureIds = featureErrors

  const brandFeatures = yield select(selectBrandFeatures)
  const brandFeaturesError = validateNotAllowedCategory(brandFeatures)
  const blValidBrandFeatures = brandFeaturesError.length == 0

  if (!blValidBrandFeatures) {
    errors.not_allowed_category = brandFeaturesError
  }

  const licenseErrors = yield validateLicense()
  if (licenseErrors.length > 0) {
    errors.license = licenseErrors
  }

  const blValidFeatures = validFeatures(features)

  const constraints = yield select(selectConstraints)
  const validConstraints = constraints.filter((constraint) => {
    const {
      publishing_not_allowed: publishingNotAllowed,
    } = constraint.restrictions
    return publishingNotAllowed.length > 0
  })

  const valid = !Object.keys(errors).length
    && blValidFeatures
    && blValidBrandFeatures
    && !validConstraints.length

  return {
    errors,
    valid
  }
}

const validateNotAllowedCategory = (brandFeatures) => {

  const notAllowedItemList = checkNotAllowedCategory(brandFeatures)
  let errors = []

  if (notAllowedItemList.length) {
    const term = notAllowedItemList.join(', ')
    errors = [
      {
        messageForNotif: I18n.t('prohibited', { scope: 'turbosquid.products.edit', term }),
        messageForInline: I18n.t('prohibited', { scope: 'turbosquid.products.edit', term }),
        items: notAllowedItemList
      }
    ]
  }
  return errors
}

function* validatePublishCategoryData() {
  const errors = yield validateSpecsForPublishCategory()

  const brandFeatures = yield select(selectBrandFeatures)
  const brandFeaturesError = validateNotAllowedCategory(brandFeatures)
  const blValidBrandFeatures = brandFeaturesError.length == 0

  if (!blValidBrandFeatures) {
    errors.not_allowed_category = brandFeaturesError
  }

  yield put(saveValidationErrors({ errors, action: 'publish_category' }))
  const valid = !Object.keys(errors).length
    && blValidBrandFeatures

  yield put(resetNotificationsOnPublishAttempt())

  return valid
}

function* validateBeforeSaveForm(draftSaving = true, isPreview = false) {
  if (draftSaving) {
    yield put(updateLastActionDraftSaving(true))
    const errors = yield validateSpecsForSaveDraft()

    if (Object.keys(errors).length) {
      yield put(saveValidationErrors({ errors, draftSaving, action: 'save_draft' }))
    }
    // for draft saving, record the errors but always consider the form valid.
    return true
  } else {
    yield put(updateLastActionDraftSaving(false))

    const {
      errors,
      valid,
    } = yield validatePublishData(draftSaving)
    yield put(saveValidationErrors({ errors, draftSaving, action: 'publish' }))

    let actionType = isPreview ? undefined : 'publish'
    actionType = draftSaving ? 'save_draft' : actionType
    yield put(publishProductInvalid({
      action: valid ? actionType : undefined,
    }))

    yield put(resetNotificationsOnPublishAttempt())

    return valid
  }
}

export function* saveStemcellSaga(stemcell) {
  try {
    const draftId = yield* checkDraftId()
    const saveCertificationParams = {
      draftId,
      body: { id: stemcell, draft_id: draftId },
    }
    const saveDraftCertificationParams = {
      certification: "stemcell_v2",
      draftId,
      csrfToken: CSRFToken(),
    }

    yield call(addAsyncQueueForCallback, asyncQueueAction.fetch, { callback: addCertification, args: [saveDraftCertificationParams] })
    yield call(addAsyncQueueForCallback, asyncQueueAction.fetch, { callback: saveCertificationQuery, args: [saveCertificationParams] })
    yield put(setStemcell({ stemcell }))
  } catch (e) {
    console.log(e)
  }
}

export const getDeletedAssociatedFile = (state, id, fileType) => {
  const { formState } = state
  const { deletedAssociatedFiles } = formState
  const associatedFileIndex = deletedAssociatedFiles.findIndex(
    (file) => file.id === id && file.type === fileType
  )
  return deletedAssociatedFiles[associatedFileIndex]
}

export function* saveDraftData({ name, value }, draftSaving = true, force = false) {
  yield put(updateFormActionInProgress('save_draft'))

  try {
    const productIsValid = yield validateBeforeSaveForm()
    if (productIsValid) {
      yield* saveForm({ name, value, draftSaving, force })
      yield put(saveDraftDone())
    } else {
      yield put(updateFormActionInProgress({}))
    }
  } catch (e) {
    yield put(requestFailed({ draftSaving: e.message }))
  }
  const prohibitedWordsError = yield select(selectProhibitedWordsError)
  if (!isEmpty(prohibitedWordsError)) {
    yield* saveForm({ name, value, draftSaving, force })
  }
  yield put(saveDraftDone())
}

export function* publishProductData({ payload }) {
  yield put(toggleOverlay(true))

  const { name, value, stemcell } = payload.event

  try {
    const productIsValid = yield validateBeforeSaveForm(false)
    let saved = false
    if (productIsValid) {
      if (stemcell) {
        yield* saveStemcellSaga(stemcell)
      }

      saved = yield* saveForm({name, value, draftSaving: false, force: true})
      if (saved) {
        yield put(setDraftSavePendingChanges({status: false}))
        yield put(setDisableLeavePagePrompt({disable: true}))
        
        window.location.href = gon.turbosquid_products_path
      }
    }
    if (!saved) {
      yield put(updateFormActionInProgress({}))
      yield put(toggleOverlay(false))
      yield put(setDisableLeavePagePrompt({disable: false}))
    }
  } catch (e) {
    console.log(e)
    yield put(requestFailed({ error: e.message }))
    yield put(toggleOverlay(false))
    yield put(setDisableLeavePagePrompt({disable: false}))
  }
}

function saveFeatureSyncBatch(response) {
  const { batchId, productIds } = response
  if (batchId !== undefined && batchId !== null) {
    const newBatch = { id: batchId, productIds, createdAt: Date.now() }
    const batches = JSON.parse(window.localStorage.getItem('batches') || '[]')
    batches.push(newBatch)
    window.localStorage.setItem('batches', JSON.stringify(batches))
    cookie.setCookie(BATCH_COOKIE_NAME, JSON.stringify(batches), 365)
  }
}

export function* saveFeatureGraph({ name, value }) {
  try {
    const categoryIsValid = yield validatePublishCategoryData()
    if (categoryIsValid) {
      yield put(startSubmit())
      const state = yield select()
      const params = { ...featureGraphParams(state), [name]: value, edit_category: 1 }
      const response = yield call(submitPublisherForm, params)
      if (response && response.error) {
        yield put(requestFailed({ error: response.error.message }))
      } else {
        saveFeatureSyncBatch(response)
        yield put(requestSucceeded({ response }))
        window.location.href = gon.turbosquid_products_path
      }
    }
  } catch (e) {
    yield put(requestFailed({ error: e.message }))
  }
}

// Preview validation is now handled by usePreviewSpecs hook
export function* previewValidate() {
  // TSQ-24099: just validate wireframe or uv image
  const validationType = 'preview'
  yield put(publishProductInvalid({
    action: undefined,
    validationType,
  }))
}

export function* previewDraftPage() {
  yield put(toggleOverlay(true))
  const draftId = yield* checkDraftId()
  const productIsValid = yield validateBeforeSaveForm(false, true)
  if (productIsValid && draftId) {
    const saved = yield* saveForm({ name: 'commit', value: '', draftSaving: false, force: true })
    if (saved) {
      yield put(setDraftSavePendingChanges({ status: false }))
      yield put(setDisableLeavePagePrompt({ disable: true }))
      const csrfToken = encodeURIComponent(CSRFToken())
      const featureIds = yield select(selectFeatureIds)
      const featuresIds = featureIds.join(',')
      window.location.href = `${gon.external_turbosquid_draft_preview_url}${draftId}&redirectFrom=opp&features=${encodeURIComponent(featuresIds)}&token=${csrfToken}`
    } else {
      yield put(toggleOverlay(false))
    }
  } else {
    yield put(toggleOverlay(false))
  }
}

function* cancelCertificationSubmission(certification) {
  try {
    const productId = gon.product.id
    const draftId = yield* checkDraftId()
    const params = {
      draftId,
      asset_id: productId,
      cancel: true,
    }

    yield call(addAsyncQueueForCallback, asyncQueueAction.fetch, { callback: deleteCertificationQuery, args: [params, certification] })
    yield all([
      put(cancelStemcellSubmission({ status: false, success: true })),
      put(updateCertification({ certifications: 'none' })),
    ])
  } catch {
    yield put(cancelStemcellSubmission({ status: false, success: false }))
    
  }
}

const discardDraftRemotely = (url) => {
  const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
  const options = {
    method: 'DELETE',
    body: JSON.stringify({ authenticity_token: authenticityToken }),
    credentials: 'same-origin',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
  return makeRequest(url, options)
}

export function* discardDraftStart({ payload: { url } }) {
  const response = yield call(discardDraftRemotely, url)
  yield put(setDisableLeavePagePrompt({ disable: true }))
  if (response && !response.error) {
    window.location.href = response.url
  } else {
    window.location.href = gon.turbosquid_products_path
  }
}

export function* cancelStemcellSubmissionStart() {
  try {
    yield put(cancelStemcellSubmission({ status: true, success: false }))

    yield* cancelCertificationSubmission('stemcell')
    yield* deleteStemcell()
  } catch {
    yield put(cancelStemcellSubmission({ status: false, success: false }))
  }
}

export function* deleteStemcell(stemcell) {
  try {
    const draftId = yield* checkDraftId()
    const params = {
      draftId,
      body: { draft_id: draftId },
    }

    yield call(addAsyncQueueForCallback, asyncQueueAction.fetch, { callback: deleteCertificationQuery, args: [params, 'stemcell_v2'] })
  } catch (e) {
    console.log(e)
  }
}
