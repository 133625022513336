import React from 'react'
import { panelChevronClass, panelCollapseClass } from './common/dashboard_client'
import News from './news'
import ForumPosts from './forum_posts'

class NewsAndForums extends React.Component {
  constructor(props) {
    super(props);
    this.newsAndForumsLink = React.createRef();
    this.newsAndForumsIcon = React.createRef();
  }

  componentDidMount() {
    $('a[data-toggle="tab"]').bind('shown.bs.tab', this.setTabLinkAndIcon)
  }

  setTabLinkAndIcon = (e) => {
    this.newsAndForumsIcon.current.setAttribute('class', (e.target.attributes.getNamedItem('data-icon-class') || {}).value)
    this.newsAndForumsLink.current.setAttribute('href', (e.target.attributes.getNamedItem('data-icon-url') || {}).value)
  }

  render() {
    return (
      <div className="panel-body text-left">
        <div className="panel-heading-text row">
          <div className="col-xs-12">
            <a className="pull-right toggle-collapse js-toggle-collapse" data-toggle="collapse" data-target=".news-and-forums-panel-collapse" href="#">
              <i className={panelChevronClass('news-and-forums-container')} />
            </a>
            <ul className="nav nav-tabs" id="news_and_forums_tabs">
              <li className="active">
                <a href="#news" data-toggle="tab" data-icon-url={gon.all_news_url} data-icon-class="fa fa-newspaper-o" className="panel-heading-label">{I18n.t('dashboard.news.news')}</a>
              </li>
              <li>
                <a href="#forums" data-toggle="tab" data-icon-url={gon.discourse_url} data-icon-class="fa fa-comments" className="panel-heading-label">{I18n.t('dashboard.forums.forums')}</a>
              </li>
              <li className="panel-info-link">
                <a href={gon.all_news_url} ref={this.newsAndForumsLink}><i ref={this.newsAndForumsIcon} className="fa fa-newspaper-o" /></a>
              </li>
            </ul>
          </div>
        </div>
        <div className={`panel-divider news-and-forums-panel-collapse ${panelCollapseClass('news-and-forums-container')}`} />
        <div className={`news-and-forums-panel-collapse ${panelCollapseClass('news-and-forums-container')}`}>
          <div className="tab-content">
            <div id="news" className="tab-pane fade active in" role="tabpanel">
              <div className="panel-overflow">
                <News />
              </div>
            </div>
            <div id="forums" className="tab-pane fade" role="tabpanel">
              <div className="panel-overflow">
                <ForumPosts />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewsAndForums
