import { Text } from 'slate'

export const serialize = (node) => {
  if (Text.isText(node)) {
    let string = node.text

    if (node.bold) {
      string = `<strong>${string}</strong>`
    }

    if (node.italic) {
      string = `<em>${string}</em>`
    }

    if (node.underline) {
      string = `<u>${string}</u>`
    }

    if (node.code) {
      string = `<code>${string}</code>`
    }

    return string
  }

  const children = node.children.map((n) => serialize(n)).join('')

  switch (node.type) {
    case 'heading-one':
      return `<h1>${children}</h1>`
    case 'heading-two':
      return `<h2>${children}</h2>`
    case 'block-quote':
      return `<blockquote>${children}</blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'productLink':
      if (children.length === 0) {
        return
      }

      return `[productID]${children}[/productID]`
    default:
      return children
  }
}
