/* global gon, I18n */
import React from 'react'
import PropTypes from 'prop-types'

const checkmateIconClass = (isPro) => (isPro ? 'Pro' : 'Lite')

const checkmateCertificationLabel = (isPro) => (isPro ? 'Pro' : 'Lite')

const jobUrl = (jobId) => gon.freshwater_job_url + jobId

const Certified = ({ checkmate_pro: checkmatePro }) => (
  <div>
    <i className={`cm-status-icon ${checkmateIconClass(checkmatePro)}`} />
    <span className="cm-status">{checkmateCertificationLabel(checkmatePro)}</span>
  </div>
)

Certified.propTypes = {
  checkmate_pro: PropTypes.bool.isRequired,
}

const BeingInspected = ({ job_id: jobId }) => (
  <a className="being-inspected" href={jobUrl(jobId)}>
    <span className="cm-status">
      {I18n.t('dashboard.checkmate.status_code.inspection')}
    </span>
  </a>
)

BeingInspected.propTypes = {
  job_id: PropTypes.string.isRequired,
}

const RequiresAttention = ({ job_id: jobId }) => (
  <div>
    <i className="cm-status fa fa-exclamation-circle" />
    <a className="requires-attention" href={jobUrl(jobId)}>
      <span className="cm-status">
        {I18n.t('dashboard.checkmate.status_code.requires_action')}
      </span>
    </a>
  </div>
)

RequiresAttention.propTypes = {
  job_id: PropTypes.string.isRequired,
}

const Queued = () => (
  <span className="cm-status">Queued</span>
)

const CheckmateCell = (props) => {
  const { checkmate_status: checkmateStatus, checkmate_pro: checkmatePro } = props
  let result = null
  if (checkmateStatus) {
    const handlers = {
      'Requires Attention': RequiresAttention,
      'Being Inspected': BeingInspected,
      Certified,
      Queued,
    }
    const handler = handlers[checkmateStatus]
    if (handler) {
      result = handler(props)
    }
    return (
      <div className={`checkmate-queue cm-status-wrapper ${checkmateCertificationLabel(checkmatePro).toLowerCase()}`}>
        { result }
      </div>
    )
  }
  return ''
}

export default CheckmateCell
