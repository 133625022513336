/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'
import { productPageUrl, product3DPageUrl } from '../helpers'
import EditableCell from './EditableCell'
import { PENDING_ASSET_TYPE } from '../constants'
import { FORBIDDEN_WORDS, PRODUCT_TITLE_FORBIDDEN_WORDS } from '../../../products/react_components/helpers'
import ConstraintsService from '../../../services/ConstraintsService'

const i18nOptions = {
  scope: 'turbosquid.products.product_validation',
}

export default class ThumbnailCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      thumbError: false,
      seo_url: props.seo_url,
      name: props.name,
      originalName: props.name,
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({ name: nextProps.name, originalName: nextProps.name })
  }

  handleEditCellSubmit = (data) => {
    const product = data
    const { handleEditCellSubmit } = this.props
    const { value: name } = data
    const regex = /(<([^>]+)>)/ig
    const forbiddenWords = []
    const title = data.value.toLowerCase()
    FORBIDDEN_WORDS.concat(PRODUCT_TITLE_FORBIDDEN_WORDS).forEach((word) => {
      if (title.includes(word)) {
        forbiddenWords.push(word)
        product.alert = {
          type: 'danger',
          message: word,
        }
      }
    })
    if (forbiddenWords.length > 0) {
      const words = forbiddenWords.toString()
      product.alert = {
        type: 'danger',
        message: `${I18n.t('turbosquid_products.messages.product_name')} ${I18n.t('the_word_was_included', { ...i18nOptions, words })}`,
      }
    }

    if (name.length === 0 || name.length > 255 || name.match(regex)) {
      product.alert = {
        type: 'danger',
        message: I18n.t('turbosquid_products.messages.an_error_occured'),
      }
      const { originalName } = this.state
      this.setState({ name: originalName })
      handleEditCellSubmit(product)
    } else {
      const nameErrors = new Set()
      ConstraintsService.findConstraints(name)
        .then((response) => {
          const { terms } = response
          if (terms.length) {
            terms.forEach((term) => {
              const words = term.term
              if (term.license.editorial_uses_only_license.length) {
                nameErrors.add(I18n.t('allow_term_under_certain_license_only', { ...i18nOptions, words }))
              }
              if (term.restrictions.publishing_not_allowed.length) {
                nameErrors.add(I18n.t('turbosquid.products.edit.prohibited', { term: words }))
              }
            })

            if (nameErrors.size) {
              const alertMessage = Array.from(nameErrors).join(' ')
              product.alert = {
                type: 'danger',
                message: alertMessage,
              }
              const { originalName } = this.state
              this.setState({ name: originalName })
            }
          } else {
            this.setState({ name, originalName: name })
          }
          handleEditCellSubmit(product)
        })
        .catch((_) => {})
    }
  }

  render() {
    const {
      thumbnail_path: thumbnailPath,
      turbosquid_id: turbosquidId,
      pendingProductIds,
      type: productType,
    } = this.props
    const { thumbError, name, seo_url } = this.state
    const noThumbnailClass = !thumbnailPath || thumbError ? 'my-products__item-thumbnail-wrapper--no-thumbnail' : ''
    let titleNode
    if (productType === PENDING_ASSET_TYPE) {
      titleNode = <span className="name my-products__item-details">{ name }</span>
    } else {
      titleNode = (
        <EditableCell
          name="name"
          value={name}
          editValue={name}
          turbosquidId={turbosquidId}
          pendingProductIds={pendingProductIds}
          columnSize={20}
          maxLength={gon.name_length_max}
          tagName="h5"
          className="name my-products__item-details"
          handleEditCellSubmit={this.handleEditCellSubmit}
          restrictWidth
        />
      )
    }

    return (
      <div style={{ maxWidth: '100%' }}>
        <a href={seo_url && seo_url.length > 0 ? product3DPageUrl(seo_url) : productPageUrl(turbosquidId)} target="_blank" rel="noopener noreferrer" title={name}>
          <span className={`my-products__item-thumbnail-wrapper ${noThumbnailClass}`}>
            <img
              src={thumbnailPath}
              alt={name}
              className="my-products__item-thumbnail"
              onLoad={() => this.setState({ thumbError: false })}
              onError={() => this.setState({ thumbError: true })}
            />
          </span>
        </a>
        <div className="details my-products__item-details" style={{ width: '80%' }} data-testid="asset-name-gridcell">
          {titleNode}
        </div>
      </div>
    )
  }
}

ThumbnailCell.propTypes = {
  thumbnail_path: PropTypes.string,
  turbosquid_id: PropTypes.string.isRequired,
  pendingProductIds: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  handleEditCellSubmit: PropTypes.func.isRequired,
  type: PropTypes.string,
}

ThumbnailCell.defaultProps = {
  thumbnail_path: '',
  type: '',
}
