export const hasAlertsByTypes = (alerts, types) => (
  alerts.filter((a) => types.includes(a.type)).length > 0
)

export const hasEditorialUsesOnlyLicenseConstraints = (constraints = []) => (
  constraints.filter(({ license = {} }) => license.editorial_uses_only_license.length).length > 0
)

export const disableExtendedUsesLicense = (alerts) => (
  hasAlertsByTypes(alerts, ['royalty_free_editorial_uses_only'])
)

// eslint-disable-next-line no-unused-vars
export const prohibitExtendedUses = (alerts, constraints) => (
  disableExtendedUsesLicense(alerts)
)

export const disableEditorialLicense = (alerts) => (
  hasAlertsByTypes(alerts, ['publish_not_allowed'])
)

export const prohibitCustomUses = (alerts) => (
  hasAlertsByTypes(alerts, ['custom'])
)
