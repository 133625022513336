/* global I18n */
import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const i18nOptions = { scope: 'turbosquid_products' }

const pagesToDisplay = 3

const pageLinkOptions = (page, searchObject, pathName) => {
  const query = { ...searchObject }
  query.page = page
  const result = {
    pathname: pathName,
    search: `?${queryString.stringify(query)}`,
  }
  return result
}

const previousLink = (currentPage, pages, searchObject, pathName) => {
  if (pages && currentPage > 1) {
    return (
      <li>
        <Link to={pageLinkOptions(currentPage - 1, searchObject, pathName)}>
          <span aria-hidden="true">&laquo;</span>
          <span className="sr-only">{I18n.t('previous', { ...i18nOptions })}</span>
        </Link>
      </li>
    )
  }
  return ''
}

const previousPageNumbers = (currentPage) => {
  const pagesLeft = currentPage - pagesToDisplay
  const start = pagesLeft < 1 ? 1 : pagesLeft
  const numbers = _.range(start, currentPage, 1)
  return numbers
}

const nextLink = (currentPage, pages, searchObject, pathName) => {
  const number = currentPage + 1
  if (pages && number < pages) {
    return (
      <li key={number}>
        <Link to={pageLinkOptions(number, searchObject, pathName)}>
          <span aria-hidden="true">&raquo;</span>
          <span className="sr-only">{I18n.t('next', { ...i18nOptions })}</span>
        </Link>
      </li>
    )
  }
  return ''
}

const nextPageNumbers = (currentPage, pages) => {
  const pagesLeft = currentPage + pagesToDisplay + 1
  const end = pagesLeft > pages + 1 ? (pages + 1) : pagesLeft
  const numbers = _.range(currentPage + 1, end, 1)
  return numbers
}

const pageNumberLink = (number, searchObject, pathName) => (
  <li key={number}>
    <Link to={pageLinkOptions(number, searchObject, pathName)}>
      { number }
    </Link>
  </li>
)

const pageSizeLinkOptions = (size, searchObject, pathName) => {
  const query = { ...searchObject }
  query.size = size
  query.page = 1
  const result = {
    pathname: pathName,
    search: `?${queryString.stringify(query)}`,
  }
  return result
}

const pageSizeLink = (size, searchObject, pathName) => (
  <li key={size} role="presentation">
    <Link to={pageSizeLinkOptions(size, searchObject, pathName)} role="menuitem">
      {size}
    </Link>
  </li>
)

const Paginator = (props) => {
  // eslint-disable-next-line no-unused-vars
  let { maxLinks } = props
  const { pages, page, pageSize, search, total, pageSizes, pathName } = props
  maxLinks = maxLinks || 5
  const searchObject = queryString.parse(search)
  searchObject.size = pageSize
  return (
    <div className="row paginator">
      <div className="col-xs-6 paginator-info">
        <span className="current-page-label">
          {I18n.t('showing_page', { ...i18nOptions })}
          {` ${page || 1} ${I18n.t('of', { ...i18nOptions })}`}
          {` ${total} ${I18n.t('results', { ...i18nOptions })}.`}
        </span>
        <div className="results-per-page">
          <span className="results-per-page-label">{` ${I18n.t('results_per_page', { ...i18nOptions })}:`}</span>
          <div className="btn-group dropup" style={{ marginLeft: '10px' }}>
            <button className="btn btn-default dropdown-toggle" type="button" id="pageDropDown" data-toggle="dropdown" aria-expanded="false">
              { pageSize }
            </button>
            <ul className="dropdown-menu" role="menu" aria-labelledby="pageDropDown">
              {pageSizes.map((size) => pageSizeLink(size, searchObject, pathName))}
            </ul>
          </div>
        </div>
      </div>
      <div className="col-xs-6 pull-right paginator-pages">
        <ul className="pagination pull-right">
          {previousLink(page, pages, searchObject, pathName)}
          {previousPageNumbers(page).map(
            (number) => pageNumberLink(number, searchObject, pathName)
          )}
          <li className="active">
            <Link to={pageLinkOptions(page, searchObject, pathName)}>
              <span aria-hidden="true">{page}</span>
            </Link>
          </li>
          {nextPageNumbers(page, pages).map(
            (number) => pageNumberLink(number, searchObject, pathName)
          )}
          {nextLink(page, pages, searchObject, pathName)}
        </ul>
      </div>
    </div>
  )
}

export default Paginator

Paginator.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  search: PropTypes.string,
  total: PropTypes.number,
  pageSizes: PropTypes.array,
  maxLinks: PropTypes.number,
  pathName: PropTypes.string,
}

Paginator.defaultProps = {
  pages: 0,
  page: 1,
  pageSize: 50,
  search: '',
  total: 0,
  pageSizes: [],
  maxLinks: 5,
  pathName: '/turbosquid/products',
}
