import React from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import PropTypes from 'prop-types'

const FilterAdditionalData = (props) => {
  const {
    partners,
    button_title: buttonTitle,
    button_key: buttonKey,
    key,
    updateAdditionalData,
  } = props
  const partnerKeys = Object.keys(partners)
  return (
    <div className="in-page-action-button-group additional-syndication-data pull-left">
      <DropdownButton
        bsStyle="default"
        title={buttonTitle}
        key={buttonKey}
        id={`dropdown-basic-${key}`}
      >
        {partnerKeys.map((partner) => (
          <MenuItem
            key={partner}
            eventKey={partner}
            onSelect={updateAdditionalData}
            className={partners[partner].selected === true ? 'syndication-partner selected' : ' syndication-partner '}
          >
            {partners[partner].value}
          </MenuItem>
        ))}
      </DropdownButton>
    </div>
  )
}

export default FilterAdditionalData

FilterAdditionalData.propTypes = {
  partners: PropTypes.object.isRequired,
  button_title: PropTypes.string.isRequired,
  button_key: PropTypes.string.isRequired,
  key: PropTypes.string,
  updateAdditionalData: PropTypes.func.isRequired,
}

FilterAdditionalData.defaultProps = {
  key: undefined,
}
