import React from 'react'
import { PENDING_ASSET_TYPE } from '../constants'

const checkEmptyCell = (WrappedComponent) => {
  const Enhancer = (props) => {
    const { type } = props
    return type !== PENDING_ASSET_TYPE ? <WrappedComponent {...props} /> : <div />
  }
  return Enhancer
}

export default checkEmptyCell
