/* global gon */
import queryString from 'query-string'
import { FORBIDDEN_WORDS } from '../../products/react_components/helpers'

export const PENDING_ASSET_TYPE = 'pending_asset'
export const PENDING_ASSETS_POLL_INTERVAL = 10000
export const BATCH_TTL = 1800000
export const BATCH_RECHECK_DELAY = 10000
export const BETA_PUBLISHER_SWITCH_COOKIE_NAME = 'my_products_beta_publisher_checked'
export const BATCH_COOKIE_NAME = 'my_products_batches'
export const FORBIDDEN_WORDS_REGEX = new RegExp(`^(?:(?!${FORBIDDEN_WORDS.join('|')}).)*$`, 's')

export const statusList = ['online', 'offline', 'private']
export const productPageStatusList = [...statusList, 'await review', 'suspended', 'suspended awaiting review'];
export const checkmateStatusList = [
  'Requires Attention',
  'Queued',
  'Being Inspected',
  'Certified',
]

export const stemcellStatusList = {
  SUBMITTED: "submitted",
  IN_PROGRESS: "in_progress",
  QUEUED: "in_queue",
  ACTION_REQUIRED: "action_required",
  ERROR: "error",
  CANCELED: "canceled",
  CERTIFIED: "certified"
}
export const maxAllowedFileSize = 5 * 1024 * 1024 * 1024
export const fileFormats = ['3ds', 'max', 'c4d']
export const priceRanges = gon.price_ranges
export const indexI18nOptions = { scope: 'turbosquid.products.index' }

export const nameI18nOptions = {
  scope: 'turbosquid.products.product_name_panel',
}
export const filesI18nOptions = {
  scope: 'turbosquid.products.product_files_panel',
}
export const imagesI18nOptions = {
  scope: 'turbosquid.products.product_images_panel',
}
export const validationI18nOptions = {
  scope: 'turbosquid.products.product_validation',
}

export const currentSelection = (key, search) => {
  const filterState = queryString.parse(search)
  return filterState[key] || 'All'
}

export const filterToAggregation = {
  file_format: 'format_names',
  checkmate_status: 'checkmate_statuses',
  price_range: 'price_ranges',
  status: 'statuses',
}

export const asyncQueueAction = {
  fetch: 'fetch',
}

export const PRODUCT_FILES_PANEL = 'product-files-panel'

// Frontend uv options
export const UNKNOWN = 'unknown'
export const NO = 'no'

// Frontend validation inputs
export const FILES = 'files'
export const PREVIEWS = 'previews'
export const PREVIEW_COUNT = 'preview-count'
export const WIREFRAME_COUNT = 'wireframe-count'
export const UV_COUNT = 'uv-count'
export const TURNTABLE_COUNT = 'turntable-count'
export const NATIVE_COUNT = 'native-count'
export const PRIMARY_COUNT = 'primary-count'
export const SEARCH_PREVIEW_COUNT = 'search-image-count'
export const STEMCELL_COUNT= 'stemcell-count'

// Frontend validations

export const MAX_NAME_LENGTH = 255
export const MIN_THUMBNAIL_COUNT = 5
export const MIN_WIREFRAME_COUNT = 1
export const MIN_UV_COUNT = 1
export const MIN_TURNTABLE_COUNT = 1
export const MIN_NATIVE_COUNT = 1
export const MIN_SEARCH_PREVIEW_COUNT = 1
export const MIN_PREVIEW_WIDTH = 1920
export const MIN_PREVIEW_HEIGHT = 1080
export const MIN_WIREFRAME_WIDTH = 1920
export const MIN_WIREFRAME_HEIGHT = 1080
export const MIN_TURNTABLE_WIDTH = 1920
export const MIN_TURNTABLE_HEIGHT = 1080
export const MIN_UV_WIDTH = 800
export const MIN_UV_HEIGHT = 800
export const MAX_PREVIEW_RESOLUTION = 8000

// Frontend uploads
export const MAX_UPLOADABLE_FILES = 255
export const MAX_UPLOADABLE_TUNTABLE_FILES = 36

// Frontend sort facets
export const ASC = 'asc'
export const DESC = 'desc'

// Frontend notification types
export const ERROR = 'error'
export const WARNING = 'warning'
export const notificationTypes = [ERROR, WARNING]

// Frontend error types
export const UPLOAD_FAILED = 'upload failed'
export const INSUFFICIENT_FRAMES = 'insufficient frames'
export const TOO_MANY_FRAMES = 'too many frames'
export const MISSING_FRAMES = 'missing frames'

// Frontend certifications types
export const NONE = 'none'
export const CERTIFIED_PREVIEWS = 'certified-previews'
export const STEMCELL_CERTIFICATION = 'stemcell_v2'

// Frontend thumbnail types
export const REGULAR = 'regular'
export const WIREFRAME = 'wireframe'
export const UV = 'uv'

// Frontend file types
export const NATIVE = 'native'
export const PREVIEW = 'preview'
export const ASSOCIATED = 'associated'
export const TEXTURE = 'texture'
export const VIEWER = 'viewer'
export const PROMOTIONAL = 'promotional'
export const CUSTOMER = 'customer'
export const THUMBNAIL = 'thumbnail'
export const TURNTABLE = 'turntable'

// Frontend formats
export const OTHER = 'other'
export const RAR = 'rar'
export const ZIP = 'zip'
export const SEVENZ = '7z'
export const MARMOSET = 'mview'

// API file resources
export const PRODUCT_FILES = 'product_files'
export const ASSOCIATED_FILES = 'associated_files'
export const TEXTURE_FILES = 'texture_files'
export const THUMBNAIL_FILES = 'thumbnails'

// API & Frontend upload status
export const QUEUED = 'queued'
export const PENDING = 'pending'
export const PROCESSING = 'processing'
export const SUCCESS = 'success'
export const FAILED = 'failed'
export const CANCELED = 'canceled'
export const VIRUS = 'virus'
export const REJECTED = 'rejected'

export const pendingUploadStatus = [QUEUED, PENDING]
export const successfulUploadStatus = [SUCCESS]
export const terminalUploadStatus = [SUCCESS, FAILED, VIRUS]
export const failedUploadStatus = [CANCELED, REJECTED, FAILED]

export const stemcellCertifiableFileFormats = ['zip', 'rar']
export const stemcellCertifiable3DFormats = ['3ds_max', 'blender']
export const stemcellCcertifiablRenderers = ['arnold','vray','cycles_render']

export const bundleProductsI18Options = {
  scope: 'turbosquid.products.bundles_edit_panel',
}

export const BUNDLES_PANEL = 'bundles-panel'
export const MAX_BUNDLE_PREVIEW_COUNT = 5
export const MIN_BUNDLE_PRODUCTS_COUNT = 2
export const MAX_BUNDLE_PRODUCTS_COUNT = 100
export const COLLECTION = 'collection'
export const PRODUCT = 'product'
