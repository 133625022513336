/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { timeFormat } from 'd3-time-format'

import createReactClass from 'create-react-class';
import MoneyUtility from './common/money_utility';

const SaleDetail = createReactClass({
  displayName: 'SaleDetail',
  mixins: [MoneyUtility],
  date() {
    return new Date(Date.parse(this.props.sale.date))
  },
  dateDisplay() {
    const format = timeFormat('%Y-%m-%d')
    return format(this.date())
  },
  title() {
    return this.props.sale.name
  },
  country() {
    return this.props.sale.country
  },
  dollars(method) {
    const dollars = this.currencyParts(method).main_unit
    if (method === this.props.sale.price) {
      return dollars.replace('-', '')
    }
    return dollars
  },
  cents(method) {
    return this.currencyParts(method).fractional_unit
  },
  currencyParts(method) {
    return this.dollars_to_currency_parts(method)
  },
  productURL() {
    return gon.turbosquid_asset_full_preview + this.productID()
  },
  productID() {
    return this.props.sale.product_id
  },
  royaltyClass() {
    if (this.props.sale.order_type !== 'Sale') {
      return 'text-muted'
    }
  },
  noProductLink() {
    return this.props.sale.name.includes('(PixelSquid)') || this.props.sale.name.includes('(Data Deal)');
  },
  render() {
    return (
      <tr>
        <td className="text-nowrap"><span className="text-muted">{this.dateDisplay()}</span></td>
        <td>{this.noProductLink() ? <span className="text-muted" dangerouslySetInnerHTML={{ __html: this.title() }} /> : <a href={this.productURL()} target="_blank" dangerouslySetInnerHTML={{ __html: this.title() }} />}</td>
        <td><span className="text-muted">{this.country()}</span></td>
        <td className="text-right">
          <span className="text-muted">
            <strong className={this.royaltyClass()}>
              {this.dollars(this.props.sale.price)}
              <span className="cents">
                .
                {this.cents(this.props.sale.price)}
              </span>
            </strong>
          </span>
        </td>
        <td className="text-right">
          <strong className={this.royaltyClass()}>
            {this.dollars(this.props.sale.royalty)}
            <span className="cents">
              .
              {this.cents(this.props.sale.royalty)}
            </span>
          </strong>
        </td>
      </tr>
    )
  },
})

export default SaleDetail;
