/* global I18n */
import React, { useContext, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { ProductContext } from '../../../contexts/ProductContext'
import {
  filesI18nOptions,
  PREVIEW,
  PREVIEW_COUNT,
  PREVIEWS,
  SEARCH_PREVIEW_COUNT,
  TURNTABLE_COUNT,
  UV_COUNT,
  WIREFRAME_COUNT
} from '../../products/constants'
import { UIContext } from '../../../contexts/UIContext'

// Use this component to register preview validations
// that aren't tied to a specific user input.

const hidden = { display: 'none' }

const message = (translation, setCurrentTab) => {
  return (
    <>
      <a onClick={() => setCurrentTab(PREVIEW)} className="field-link">
        {`${I18n.t('images', filesI18nOptions)}: `}
      </a>
      <span className="error-messages">{I18n.t(translation, filesI18nOptions)}</span>
    </>
  )
}

export default function PreviewValidations({
  turntableRequired,
  searchPreviewRequired,
  wireframeRequired,
  uvRequired,
  previewCount,
  wireframeCount,
  uvCount,
  turntableCount,
  searchPreviewCount
}) {
  const { setCurrentTab } = useContext(UIContext)
  const { register, setValue } = useFormContext()
  const product = useContext(ProductContext)
  const {
    minPreviewCount,
    minWireframeCount,
    minUvCount,
    minTurntableCount,
    minSearchImageCount,
  } = product.validations

  const initialized = useRef(false)

  // Update value of inputs when previews change
  useEffect(() => {
    setValue(`${PREVIEWS}.${PREVIEW_COUNT}`, previewCount, { shouldValidate: initialized.current })
    setValue(`${PREVIEWS}.${WIREFRAME_COUNT}`, wireframeCount, { shouldValidate: initialized.current })
    setValue(`${PREVIEWS}.${UV_COUNT}`, uvCount, { shouldValidate: initialized.current })
    setValue(`${PREVIEWS}.${TURNTABLE_COUNT}`, turntableCount, { shouldValidate: initialized.current })
    setValue(`${PREVIEWS}.${SEARCH_PREVIEW_COUNT}`, searchPreviewCount, { shouldValidate: initialized.current })
    initialized.current = true
  }, [previewCount, wireframeCount, uvCount, turntableCount, searchPreviewCount, searchPreviewRequired, turntableRequired])

  return (
    <>
      <input
        style={hidden}
        value={previewCount}
        {...register(
          `${PREVIEWS}.${PREVIEW_COUNT}`, {
              required: true,
              min: {
                value: minPreviewCount,
                message: message('not_enough_previews', setCurrentTab)
              }
            }
          )
        }
      />
      <input
        style={hidden}
        value={wireframeCount}
        {...register(`${PREVIEWS}.${WIREFRAME_COUNT}`, {
          required: wireframeRequired,
          min: {
            value: wireframeRequired ? minWireframeCount : 0,
            message: message('not_enough_wireframes', setCurrentTab)
          }
        })}
      />
      <input
        style={hidden}
        value={uvCount}
        {...register(`${PREVIEWS}.${UV_COUNT}`, {
            required: uvRequired,
            min: {
              value: uvRequired ? minUvCount : 0,
              message: message('not_enough_uv_maps', setCurrentTab)
            }
          }
        )}
      />
      <input
        style={hidden}
        value={turntableCount}
        {...register(`${PREVIEWS}.${TURNTABLE_COUNT}`, {
          required: turntableRequired,
          min: {
            value: turntableRequired ? minTurntableCount : 0,
            message: message('not_enough_turntables', setCurrentTab)
          }
        })}
      />
      <input
        style={hidden}
        value={searchPreviewCount}
        {...register(`${PREVIEWS}.${SEARCH_PREVIEW_COUNT}`, {
          required: searchPreviewRequired,
          min: {
            value: searchPreviewRequired ? minSearchImageCount : 0,
            message: message('first_preview_validation_fail', setCurrentTab)
          }
        })}
      />
    </>
  )
}
