/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';

const CheckMateLeadersItem = createReactClass({
  displayName: 'CheckMateLeadersItem',
  render() {
    const search_url = gon.turbosquid_artist_url + this.props.leader.username
    if (this.props.leader.turbosquid_id.toString() === tsUserId) {
      return (
        <tr>
          <td><span className="text-primary"><strong>{this.props.leader.rank}</strong></span></td>
          <td><span className="text-primary"><strong>{I18n.t('dashboard.checkmate.you')}</strong></span></td>
          <td className="text-center"><span className="text-primary"><strong>{this.props.leader.pro}</strong></span></td>
          <td className="text-center"><span className="text-primary"><strong>{this.props.leader.lite}</strong></span></td>
          <td className="text-center"><span className="text-primary"><strong>{this.props.leader.total}</strong></span></td>
        </tr>
      )
    }
    return (
      <tr>
        <td>{this.props.leader.rank}</td>
        <td><a href={search_url}>{this.props.leader.username}</a></td>
        <td className="text-muted text-center">{this.props.leader.pro}</td>
        <td className="text-muted text-center">{this.props.leader.lite}</td>
        <td className="text-muted text-center">{this.props.leader.total}</td>
      </tr>
    )
  },
})

export default CheckMateLeadersItem;
