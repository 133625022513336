import React, { useContext } from 'react'
import { FileContext } from '../../../contexts/FileContext'
import NativeFile from './NativeFile'
import { useQuery } from 'react-query'
import { getFileFormats } from '../../../services/file-format-service'
import Dropzone from './Dropzone'
import { arrayFrom, count, filterStatusNot } from '../../../utils/ProductFiles'
import { validateMainFilesExtension } from '../../../utils/ImageValidations'
import { helpTextColumns } from './HelpTextColumns'
import imgPackageIcon from '../../../../../assets/images/PackageIcon-01.png'
import imgMoreFiles from '../../../../../assets/images/MoreFilesIcon-01.png'
import img3DModelIcon from '../../../../../assets/images/3DModelIcon-01.png'
import { ProductContext } from '../../../contexts/ProductContext'
import { mapNativeFileUpload } from '../../../services/upload-service'
import useFileUploads from '../../../hooks/useFileUploads'
import { FAILED, filesI18nOptions, MAX_UPLOADABLE_FILES, NATIVE } from '../../products/constants'
import Notification from './Notification'
import { NotificationContext } from '../../../contexts/NotificationContext'
import useNativeFiles from '../../../hooks/useNativeFiles'
import { formatAlert } from '../../../hooks/useNotifications'
import { fileName, fileSize } from './validators'
import NativeValidations from './NativeValidations'
import { useFormContext } from 'react-hook-form'
import { UploadContext } from '../../../contexts/UploadContext'
import { uniqueName } from './filters'

const nativeFileExtension = (file) => {
  return validateMainFilesExtension(file.name)
    ? [I18n.t('file_extension_error', filesI18nOptions)]
    : []
}

export default function NativeFiles() {
  const { product, draft, saveDraft } = useContext(ProductContext)
  const { files: { native } } = useContext(FileContext)
  const { uploads } = useContext(UploadContext)
  const { alert, clear, notifications } = useContext(NotificationContext)
  const { setValue } = useFormContext()
  const { data: formats } = useQuery(
    'fileFormats',
    () => getFileFormats(),
    { refetchOnWindowFocus: false }
  )
  const {
    primaryFile,
    nativeFiles,
    setPrimary,
    removePrimary
  } = useNativeFiles(native, {
    setValue,
    formats,
    saveDraft,
    product,
  })

  const { ready, inputRef, handleFileUploads } = useFileUploads({
    mapUpload: file => mapNativeFileUpload(file, draft, formats),
    maxFiles: MAX_UPLOADABLE_FILES,
    batchFilters: [uniqueName({
      ...native,
      ...filterStatusNot(uploads[NATIVE], FAILED)
    })],
    validators: [nativeFileExtension, fileSize, fileName],
    onError: errors => errors.forEach(message => alert(formatAlert(message))),
  })

  const disabled = !ready

  return (
    <div className={`panel panel-default files-panel native-files product-native-files-container files-panel-container ${!!count(native) ? 'has-files' : ''}`}>
      <div className="panel-body panel-collapse collapse in panel-files-wrapper panel-native-files">
        <div className="panel-body-container">
          <NativeValidations files={native} formats={(formats ?? [])} />
          <Dropzone className="dzu-dropzone" disabled={disabled} onChange={handleFileUploads}>
            <div className="upload-files">
              <div className="tab-alert-container">
                {notifications.map(n =>
                  <Notification key={n.message} notification={n} clear={clear} />
                )}
              </div>
              <div className="full-size-uploader-no-cloud single-upload">
                <div className="upload-files-label">
                  <div className="upload-files-label-title browse-button">
                    <span className="drag-files-label">{I18n.t('drag_files_here_or', filesI18nOptions)}</span>
                    <label className={`browse-files-link ${disabled ? 'disabled' : ''}`} htmlFor="file-input">
                      {I18n.t('browse', filesI18nOptions)}
                    </label>
                    <input
                      ref={inputRef}
                      type="file"
                      id="file-input"
                      multiple="multiple"
                      style={{ display: 'none' }}
                      onChange={handleFileUploads}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
              {!count(native) && (
                <div className="full-size-uploader">
                  <i data-testid="upload-icon" className="fa fa-cloud-upload" />
                  {
                    helpTextColumns([imgPackageIcon, imgMoreFiles, img3DModelIcon],
                      [
                        I18n.t('3D_model_files', filesI18nOptions),
                        I18n.t('more_product_files', filesI18nOptions),
                        I18n.t('package_and_separate_main_product', filesI18nOptions)
                      ],
                      [
                        I18n.t('3D_model_files_body',filesI18nOptions),
                        I18n.t('more_product_files_body', filesI18nOptions),
                        I18n.t('package_and_separate_main_product_body', filesI18nOptions)
                      ])
                  }
                </div>
              )}
              <div className="row file-list-wrapper native-files-wrapper">
                <ul className="file-list">
                  {primaryFile && (
                    <NativeFile
                      key={primaryFile.id}
                      file={primaryFile}
                      formats={formats}
                      onRemovePrimary={removePrimary}
                    />
                  )}
                  {arrayFrom(nativeFiles).map(file => (
                    <NativeFile
                      key={file.id}
                      file={file}
                      formats={formats}
                      onSetPrimary={setPrimary}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </Dropzone>
        </div>
      </div>
    </div>
  )
}
