/* global I18n, gon */
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Select from 'react-select'
import Icon from '../../../products/react_components/icon'
import {
  infoIconClasses,
  inputHasErrorsClass,
  stripHTMLFromString,
  IsActionPublish,
  getErrors,
  clearTitleAndTagsForbiddenWords,
} from '../../../products/react_components/helpers'
import FieldErrors from '../../../products/react_components/form_components/field_errors'
import { SaveDraftValidationShaper, PublishValidationShaper } from '../../../utils/SaveValidations'
import DisableableField from '../../../products/react_components/form_components/DisableableField'

const i18nOptions = {
  scope: 'turbosquid.products.product_name_panel',
}

const panelPopoverHTML = () => (
  I18n.t('panel_popover.html', {
    ...i18nOptions,
    product_names_url: gon.product_names_url,
    checkmate_price_levels_url: gon.checkmate_price_levels_url,
    product_pricing_guidelines_url: gon.product_pricing_guidelines_url,
  })
)

const validationSchema = yup.object().shape(SaveDraftValidationShaper())
const publishValidationSchema = yup.object().shape(PublishValidationShaper())

const SPECIAL_SEPARATORS = [',', ' ', '.', ';']

class ProductTitlePrice extends React.Component {
  constructor(props) {
    super(props)
    this.priceInput = null
    this.nameInput = null
    this.validationSchema = validationSchema
  }

  componentDidMount() {
    this.checkName(this.nameInput.value, { trim: true, updateWithConstraint: true, validate: true })
  }

  componentDidUpdate() {
    const { name } = this.props
    if (this.nameInput && this.nameInput.value.length === 0 && name.length > 0) {
      this.checkName(name, { trim: true, updateWithConstraint: true, validate: true })
    }
  }

  panelClasses = () => {
    const { highlightPanel } = this.props
    if (highlightPanel) {
      return 'panel panel-warning name-panel'
    }
    return 'panel panel-default name-panel'
  }

  formatPrice = (price) => {
    const priceValue = Math.floor(parseFloat(price))
    return priceValue >= 0 ? priceValue.toFixed(2) : ''
  }

  price = () => {
    const { price } = this.props
    return this.formatPrice(price)
  }

  onInput = (e) => {
    if (SPECIAL_SEPARATORS.includes(e.target.value.slice(-1))) {
      return this.updateName(e, { trim: false, updateWithConstraint: false, validate: false })
    }
    return this.updateName(e, { trim: true, updateWithConstraint: false, validate: false })
  }

  getUpdateNameFunc = (updateWithConstraint = true) => {
    // eslint-disable-next-line react/prop-types
    const { updateName, updateNameWithConstraints } = this.props
    if (updateWithConstraint) {
      return updateNameWithConstraints
    }

    return updateName
  }

  updateName = (e, options = { trim: false, updateWithConstraint: true, validate: false }) => {
    const elem = e.target
    const { trim, updateWithConstraint, validate } = options
    let { value: strippedValue } = elem
    const updateFunc = this.getUpdateNameFunc(updateWithConstraint)
    if (e.type === 'blur') {
      strippedValue = stripHTMLFromString(strippedValue)
      if (trim) {
        strippedValue = clearTitleAndTagsForbiddenWords(strippedValue).trim()
      }
      this.nameInput.value = strippedValue
    }

    if (validate) {
      yup.reach(this.validationSchema, 'name')
        .validate(strippedValue)
        .then((_) => updateFunc(strippedValue))
        .catch((exception) => updateFunc(strippedValue, exception.errors))
    }
  }

  onBlurName = (e) => (this.updateName(e, { trim: true, updateWithConstraint: true, validate: true }))

  updatePrice = (e) => {
    const elem = e.target
    const value = parseFloat(elem.value)
    const fixedValue = Math.floor(value).toFixed(2)
    const { updatePrice: updatePriceProp } = this.props
    // const displayedValue = (value >= 0) ? value.toFixed(2) : ''
  
    yup.reach(this.validationSchema, 'price')
      .validate(elem.value)
      .then((_) => updatePriceProp(fixedValue))
      .catch((exception) => updatePriceProp(fixedValue, exception.errors))
  }

  fixPrice = (e) => {
    const elem = e.target
    const value = parseFloat(elem.value)
    const fixedValue = Math.floor(value).toFixed(2)

    if (value >= 0){
      elem.value = fixedValue
    }
  }
  toggleNamePanel = () => {
    const { showInfo, toggleInfo } = this.props
    toggleInfo(!showInfo)
  }

  preventInvalidNumbers = (e) => {
    const { value } = e.target
    const dot = '.'
    // don't allow non-chars or number
    if (e.which < 46 || e.which > 57 || e.which === 47) {
      e.preventDefault()
    }
    // don't allow multiple dots
    if (value.indexOf(dot) !== -1 && e.key === dot) {
      e.preventDefault()
    }
  }

  checkName = (value, options = { trim: false, updateWithConstraint: true }) => {
    const { trim, updateWithConstraint } = options
    const updateFunc = this.getUpdateNameFunc(updateWithConstraint)
    let strippedValue
    if (trim) {
      strippedValue = value.trim().replace(/[[\]{}<>()_]/g, '')
    }
    this.nameInput.value = strippedValue

    if (strippedValue === value) {
      return
    }
    yup.reach(this.validationSchema, 'name')
      .validate(strippedValue)
      .then((_) => updateFunc(strippedValue))
      .catch((exception) => updateFunc(strippedValue, exception.errors))
  }

  render = () => {
    const {
      showInfo,
      name,
      errors,
      price,
      formActionInProgress,
    } = this.props

    return (
      <div className={this.panelClasses()}>
        <div className="panel-heading">
          <button type="button" data-toggle="collapse" data-target="#name-panel-instructions" onClick={this.toggleNamePanel}>
            {I18n.t('name_price', i18nOptions)}
            <Icon type={infoIconClasses(showInfo)} />
          </button>
        </div>

        <div className="panel-body panel-collapse collapse in" id="name-panel-body">
          <div className="panel-body-container">
            <div
              id="name-panel-instructions"
              className={showInfo ? 'panel-instructions collapse in' : 'panel-instructions collapse'}
              dangerouslySetInnerHTML={{ __html: panelPopoverHTML() }/* eslint-disable-line */}
            />

            <div className="form-groups-wrapper">
              <div className={`form-group turbosquid_product_form_name ${inputHasErrorsClass(getErrors(errors).name)}`}>
                <label className="control-label" htmlFor="turbosquid_product_form_name" tabIndex="-1">
                  {I18n.t('title', i18nOptions)}
                </label>
                <DisableableField
                  defaultDisabled={IsActionPublish(formActionInProgress)}
                >
                  {(disabled) => (
                    <input
                      className="form-control"
                      defaultValue={name}
                      disabled={disabled}
                      placeholder={I18n.t('name', i18nOptions)}
                      id="turbosquid_product_form_name"
                      name="turbosquid_product_form[name]"
                      ref={(input) => { this.nameInput = input }}
                      maxLength={gon.product_validations.name_length_max}
                      onBlur={this.onBlurName}
                      onInput={this.onInput}
                      onPaste={
                        (e) => this.updateName(
                          e,
                          { trim: true, updateWithConstraint: false }
                        )
                      }
                      type="text"
                    />
                  )}
                </DisableableField>
                <FieldErrors errors={getErrors(errors).name} />
              </div>
              <div className={`form-group turbosquid_product_form_price ${inputHasErrorsClass(getErrors(errors).price)}`}>
                <label className="control-label" htmlFor="turbosquid_product_form_price" tabIndex="-1">
                  {I18n.t('price', i18nOptions)}
                </label>
                <div className="price-holder">
                  <div className="currency-holder">
                    <span>
                      {I18n.t('price', i18nOptions)}
                    </span>
                    <span>
                      (
                      {I18n.t('dollar_sign', i18nOptions)}
                      )
                    </span>
                  </div>
                  <DisableableField
                    defaultDisabled={IsActionPublish(formActionInProgress)}
                  >
                    {(disabled) => (
                      <input
                        className="form-control"
                        id="turbosquid_product_form_price"
                        name="turbosquid_product_form[price]"
                        disabled={disabled}
                        onBlur={this.fixPrice}
                        onInput={this.updatePrice}
                        onKeyPress={this.preventInvalidNumbers}
                        defaultValue={price}
                        ref={(input) => { this.priceInput = input }}
                        type="text"
                      />
                    )}
                  </DisableableField>
                </div>
                <FieldErrors errors={getErrors(errors).price} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ProductTitlePrice.propTypes = {
  highlightPanel: PropTypes.object,
  price: PropTypes.string,
  name: PropTypes.string,
  notifications: PropTypes.array,
  showInfo: PropTypes.bool,
  errors: PropTypes.object,
  toggleInfo: PropTypes.func.isRequired,
  updateName: PropTypes.func.isRequired,
  updatePrice: PropTypes.func.isRequired,
  formActionInProgress: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  publishValidationTriggered: PropTypes.bool,
}

ProductTitlePrice.defaultProps = {
  highlightPanel: null,
  name: '',
  price: '',
  notifications: [],
  errors: {},
  showInfo: false,
  formActionInProgress: '',
  publishValidationTriggered: false,
}

export default ProductTitlePrice
