/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import DashboardClient from './common/dashboard_client';
import NumberUtility from './common/number_utility';
import ReportError from './report_error';
import PanelSpinner from './common/panel_spinner';
import PublishingChart from './publishing_chart';

const Publishing = createReactClass({
  displayName: 'Publishing',
  mixins: [DashboardClient, NumberUtility],
  endpoint() {
    return "/turbosquid/dashboard_widgets/published_products.json";
  },
  turbosquidCount() {
    return this.state.data.aggregations.turbosquid_count.value;
  },
  checkmateCount() {
    return this.state.data.aggregations.checkmate_count.value;
  },
  pixelsquidCount() {
    return this.state.data.aggregations.pixelsquid_count.value;
  },
  turbosquidArtistSearchUrl() {
    return gon.turbosquid_artist_search_url;
  },
  turbosquidPublisherUrl() {
    return gon.turbosquid_publish_url;
  },
  turbosquidArtistCheckmateSearchUrl() {
    return gon.turbosquid_artist_checkmate_search_url;
  },
  checkmateGettingStartedUrl() {
    return gon.checkmate_getting_started_url;
  },
  pixelsquidArtistSearchUrl() {
    return gon.pixelsquid_artist_search_url;
  },
  pixelsquidPublishUrl() {
    return gon.pixelsquid_publish_url;
  },
  render() {
    return (
      <div className="panel-body text-left">
        <div className="panel-heading-text row">
          <div className="col-xs-12">
            <a className="pull-right toggle-collapse js-toggle-collapse" data-toggle="collapse" data-target=".publishing-panel-collapse" href="#"><i className={this.panelChevronClass('publishing-container')} /></a>
            <ul className="nav nav-tabs">
              <li className="active"><a href="#publishing-tab" data-toggle="tab" className="panel-heading-label">{I18n.t('dashboard.publishing.my_products')}</a></li>
              <li className="panel-info-link"><a href="#publishing-chart-tab" data-toggle="tab"><i className="fa fa-line-chart" /></a></li>
            </ul>
          </div>
        </div>
        <div className={`panel-divider publishing-panel-collapse ${this.panelCollapseClass('publishing-container')}`} />
        <div className={`publishing-panel-collapse ${this.panelCollapseClass('publishing-container')}`}>
          <div className="row text-center">
            {this.reportsDisabled() || __guard__(this.state.data != null ? this.state.data.aggregations : undefined, ({error}) => error) ? <ReportError /> : this.state.data != null ? (
              <div className="tab-content">
                <div id="publishing-tab" role="tabpanel" className="tab-pane fade active in">
                  <div className="col-xs-4 panel-vertical-divider">
                    <h5 className="text-muted">{I18n.t('dashboard.publishing.turbosquid')}</h5>
                    {this.turbosquidCount() > 0 ? <h3><a href={this.turbosquidArtistSearchUrl()} target="_blank">{this.formatInteger(this.turbosquidCount())}</a></h3> : <h3><a href={this.turbosquidPublisherUrl()} target="_blank">{I18n.t('dashboard.publishing.none')}</a></h3>}
                  </div>
                  <div className="col-xs-4 panel-vertical-divider">
                    <h5 className="text-muted">{I18n.t('dashboard.publishing.checkmate')}</h5>
                    {this.checkmateCount() > 0 ? <h3><a href={this.turbosquidArtistCheckmateSearchUrl()} target="_blank">{this.formatInteger(this.checkmateCount())}</a></h3> : <h3><a href={this.checkmateGettingStartedUrl()} target="_blank">{I18n.t('dashboard.publishing.none')}</a></h3>}
                  </div>
                  <div className="col-xs-4">
                    <h5 className="text-muted">{I18n.t('dashboard.publishing.pixelsquid')}</h5>
                    {this.pixelsquidCount() > 0 ? <h3><a href={this.pixelsquidArtistSearchUrl()} target="_blank">{this.formatInteger(this.pixelsquidCount())}</a></h3> : <h3><a href={this.pixelsquidPublishUrl()} target="_blank">{I18n.t('dashboard.publishing.none')}</a></h3>}
                  </div>
                </div>
                <div id="publishing-chart-tab" role="tabpanel" className="tab-pane fade in">{(this.state.data.aggregations.error ? <div className="row text-center"><ReportError /></div> : undefined, <PublishingChart aggregations={this.state.data.aggregations} />)}</div>
              </div>
            ) : <PanelSpinner />}
          </div>
        </div>
      </div>
    );
  },
})

export default Publishing;

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined
}
