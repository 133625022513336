import { createSlice } from '@reduxjs/toolkit'
import { formSlice } from './form_slice'

export const flashInitialState = {
  flash: {},
}

export const flashSlice = createSlice({
  name: 'flash',
  initialState: {},
  reducers: {},
  extraReducers: {
    [formSlice.actions.requestFailed]: (state, action) => {
      state.flash = action.payload.flash
    }
  },
})
