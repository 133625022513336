import React from 'react'
import PropTypes from 'prop-types'
import CustomDropdownMenu from '../CustomDropdownMenu/CustomDropdownMenu'
import {
  productPageStatusList,
  checkmateStatusList,
  fileFormats,
  priceRanges,
} from '../constants'

const FiltersMyProducts = (props) => {
  const { location, aggregations } = props
  return (
    <ul className="nav nav-pills pull-right main-filters">
      {CustomDropdownMenu('status', productPageStatusList, location.search, aggregations)}
      {CustomDropdownMenu(
        'price_range',
        priceRanges,
        location.search,
        aggregations
      )}
      {CustomDropdownMenu(
        'file_format',
        fileFormats,
        location.search,
        aggregations
      )}
      {gon.checkmate_enabled &&
      CustomDropdownMenu(
       'checkmate_status',
        checkmateStatusList,
        location.search,
        aggregations
      )}
    </ul>
  )
}

export default FiltersMyProducts

FiltersMyProducts.propTypes = {
  location: PropTypes.object,
  aggregations: PropTypes.object,
}

FiltersMyProducts.defaultProps = {
  location: {},
  aggregations: {},
}
