import { jsx } from 'slate-hyperscript'

const NUMBER_REGEX = /^\d+$/
const TEXT_NODES = ['B', 'CODE', 'EM', 'I', 'STRONG', 'U']
const TURBOSQUID_LINK_REGEX = new RegExp(/(?:\[productID\](\d+)\[\/productID\])/, 'gi')

export const deserializeLinks = (text) => (
  text.replace(TURBOSQUID_LINK_REGEX, '<a href="#product-id">$1</a>')
)

export const deserialize = (el) => {
  if (el.nodeType === Node.TEXT_NODE) {
    // Transform links from Turbosquid format into a real link
    if (el.textContent.match(TURBOSQUID_LINK_REGEX)) {
      const html = deserializeLinks(el.textContent)
      const fragment = new DOMParser().parseFromString(html, 'text/html')
      return deserialize(fragment.body)
    }

    return el.textContent
  }

  if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  let children = Array.from(el.childNodes).map(deserialize).flat()

  if (children.length === 0) {
    children = [{ text: '' }]
  }

  if (TEXT_NODES.includes(el.nodeName)) {
    // Google Docs (and others) uses `<b>` (or other text nodes) in weird ways.
    // Just return the children if a <b> tag (or other tags) has children of other type than text
    if (children.filter((child) => child.type && child.type !== 'text').length > 0) {
      return children
    }
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return '\n'
    case 'A':
      if (!el.textContent.match(NUMBER_REGEX)) {
        return children.map((child) => jsx('text', child))
      }

      return jsx('element', { type: 'productLink' }, children)
    case 'H1':
      return jsx('element', { type: 'heading-one' }, children)
    case 'H2':
    case 'H3':
    case 'H4':
    case 'H5':
    case 'H6':
      return jsx('element', { type: 'heading-two' }, children)
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'block-quote' }, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children)
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children)
    case 'LI':
      return jsx('element', { type: 'list-item' }, children)
    case 'B':
    case 'STRONG':
      return children.map((child) => jsx('text', { bold: true }, child))
    case 'CODE':
      return children.map((child) => jsx('text', { code: true }, child))
    case 'EM':
    case 'I':
      return children.map((child) => jsx('text', { italic: true }, child))
    case 'U':
      return children.map((child) => jsx('text', { underline: true }, child))
    default:
      return children
  }
}
