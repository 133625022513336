import React, {useContext, useEffect, useState} from 'react'
import GenericModal from '../../../shared/GenericModal';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import bundleModalLeftImage from '../../../../../assets/images/bundles/bundles-left.svg'
import bundleModalCenterImage from '../../../../../assets/images/bundles/bundles-center.svg'
import bundleModalRightImage from '../../../../../assets/images/bundles/bundles-right.svg'

const i18nScope = {
  scope: 'turbosquid.products.bundles_edit_panel',
}

const MODAL_SHOWN_KEY = 'bundles-modal-shown'

export function BundlesModal() {
  const [showModal, setShowModal] = useState(false)
  const localStorage = useLocalStorage()

  useEffect(() => {
    localStorage.getItem(MODAL_SHOWN_KEY)
      .then(modalShown => {
        setShowModal(!modalShown)
        return localStorage.setItem(MODAL_SHOWN_KEY, 'true')
      })
      .catch(e => console.log(e))
  }, []);

  return showModal
  ? (
    <GenericModal
      title={''}
      className="bulk stem-cell-modal"
      show={true}
      hideModal={() => setShowModal(false)}
    >
      <h1>{I18n.t('title', i18nScope)}</h1>
      <h2>{I18n.t('subtitle', i18nScope)}</h2>
      <div className="triptic">
        <div>
          <h6>{I18n.t('upload_models', i18nScope)}</h6>
          <img src={bundleModalLeftImage} style={{margin: '0', maxHeight: '200px'}} />
          <p>{I18n.t('upload_description', i18nScope)}</p>
        </div>
        <div style={{justifyContent: 'unset'}}>
          <h6>{I18n.t('create_bundles', i18nScope)}</h6>
          <img src={bundleModalCenterImage} style={{margin: '0', maxHeight: '200px'}} />
          <p>{I18n.t('create_description', i18nScope)}</p>
        </div>
        <div>
          <h6>{I18n.t('discount', i18nScope)}</h6>
          <img src={bundleModalRightImage} style={{margin: '0', maxHeight: '175px'}}/>
          <p>{I18n.t('discount_description', i18nScope)}</p>
        </div>
      </div>
      <div className="center">
        <h2>{I18n.t('tagline', i18nScope)}</h2>
        <p>{I18n.t('detail', i18nScope)}</p>
      </div>
      <div className="center buttons">
        <a onClick={() => setShowModal(false)} className="active">{I18n.t('continue', i18nScope)}</a>
      </div>
      <div className="gradient"></div>
    </GenericModal>
  ) : (
    <></>
    )
}
