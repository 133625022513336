/* global I18n, gon */
import { createSlice } from '@reduxjs/toolkit'
import { arrayToList } from './publisher_reducer_methods'
import { formSlice } from './form_slice'
import { intersection } from 'lodash'

const requiresEditorialLicense = (feature) => (
  feature.constraints && feature.constraints.license === 'royalty_free_editorial_uses_only'
)

const publishingNotAllowed = (feature) => (
  feature.constraints && feature.constraints.restrictions
  && feature.constraints.restrictions.length
  && feature.constraints.restrictions.includes('publish_not_allowed')
)

const licenseNotification = (features, i18nKey) => {
  const terms = arrayToList(features.map((f) => f.text))
  return I18n.t(i18nKey, { term: terms })
}

const processStateChanges = (state) => {
  let newState = state
  let features = []
  const alerts = []
  let prohibitedWords = []

  features = newState.features.filter(publishingNotAllowed)
  if (features.length) {
    prohibitedWords = features.map((f) => f.text)
    newState.license = null
  } else {
    features = newState.features.filter(requiresEditorialLicense)
    if (features.length) {
      if (newState.license !== 'royalty_free_editorial_uses_only') {
        alerts.push(
          {
            notifications: [{
              panel: 'categories',
              message: licenseNotification(features, 'turbosquid.products.edit.editorial_required_term_unbranded'),
            }],
            alert_panels: ['license'],
            type: 'royalty_free_editorial_uses_only',
            level: 'warning',
          }
        )
        newState.license = null
      } else {
        alerts.push(
          {
            notifications: [],
            alert_panels: [],
            type: 'royalty_free_editorial_uses_only',
            level: null,
          }
        )
      }
    } else if (newState.license === 'royalty_free_editorial_uses_only'
    && !(newState.features.find(requiresEditorialLicense))) {
      alerts.push(
        {
          notifications: [{
            panel: 'license',
            message: licenseNotification(features, 'turbosquid.products.edit.editorial'),
          }],
          alert_panels: ['categories'],
          type: 'suggest_brand_assignment',
          level: 'info',
        }
      )
    }
  }

  newState.prohibitedWords = prohibitedWords
  newState.alerts = alerts

  return newState
}

export const getFeatureInitialState = () => {
  const alerts = []
  if (gon.product && gon.product.license === 'custom') {
    alerts.push(
      {
        notifications: [{
          panel: 'categories',
          message: 'test',
        }],
        alert_panels: ['license'],
        type: 'custom',
        level: 'warning',
      }
    )
  }
  const objectFeaturePathIds = (gon.feature_graph_object_path_id || '').split('/')
  const brandFeaturePathIds = (gon.feature_graph_brand_path_id || '').split('/')

  const objectFeatures = gon.features.filter((f) => (
    intersection(f.ancestry_ids, objectFeaturePathIds).length === objectFeaturePathIds.length))
  let brandFeatures = gon.features.filter((f) => (
    intersection(f.ancestry_ids, brandFeaturePathIds).length === brandFeaturePathIds.length))
  let licenseFeature = null
  if (gon.product.license === 'royalty_free_all_extended_uses') {
    brandFeatures = []
    licenseFeature = gon.unidentified_origin_taxonomy_id
  } else if (!gon.product.license) {
    brandFeatures = []
  }
  const collection = gon.features.some(
    (f) => Number(f.id) === Number(gon.collection_taxonomy_id)
  )

  const state = {
    license: gon.product.license,
    features: objectFeatures,
    brandFeatures,
    licenseFeature,
    alerts,
    collectionFeature: collection ? gon.collection_taxonomy_id : 0,
    missingBrand: { name: '', website: '' },
  }
  return processStateChanges(state)
}

export const publisherFeatureSlice = createSlice({
  name: 'feature',
  initialState: {},
  reducers: {},
  extraReducers: {
    [formSlice.actions.updateLicense]: (state, { payload: { license } }) => {
      state.license = license
      if (license === 'royalty_free_all_extended_uses') {
        state.licenseFeature = gon.unidentified_origin_taxonomy_id
        state.brandFeatures = []
        state.missingBrand = { name: '', website: '' }
      } else {
        state.licenseFeature = null
      }
      processStateChanges(state)
    },
    [formSlice.actions.updateFeatures]: (state, { payload: { features } }) => {
      state.features = features
      if (features.length <= 0) {
        state.collectionFeature = 0
      }
      processStateChanges(state)
    },
    [formSlice.actions.setCollectionFeature]: (state, { payload }) => {
      state.collectionFeature = payload ? gon.collection_taxonomy_id : 0
    },
    [formSlice.actions.updateBrandFeatures]: (state, { payload: { features }}) => {
      state.brandFeatures = features
    },
  },
})
