import React, {useContext, useEffect, useState} from 'react'
import GenericModal from "../../shared/GenericModal";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {ProductContext} from "../../contexts/ProductContext";
import stemModalLeftImage from '../../../../assets/images/stem/sc-left.svg'
import stemModalCenterImage from '../../../../assets/images/stem/sc-center.svg'
import stemModalRightImage from '../../../../assets/images/stem/sc-right.svg'

const i18nScope = {
  scope: 'turbosquid.products.stemcell_certification_modal',
}

const MODAL_SHOWN_KEY = 'stemcell-modal-shown'

export function StemCellModal() {
  const [showModal, setShowModal] = useState(false)
  const localStorage = useLocalStorage()
  const { endpoints: { stemcellResourcesUrl } } = useContext(ProductContext)

  useEffect(() => {
    localStorage.getItem(MODAL_SHOWN_KEY)
      .then(modalShown => {
        setShowModal(!modalShown)
        return localStorage.setItem(MODAL_SHOWN_KEY, 'true')
      })
      .catch(e => console.log(e))
  }, []);

  return showModal
  ? (
    <GenericModal
      title={''}
      className="bulk stem-cell-modal"
      show={true}
      hideModal={() => setShowModal(false)}
    >
      <h1>{I18n.t('title', i18nScope)}</h1>
      <h2>{I18n.t('subtitle', i18nScope)}</h2>
      <div className="triptic">
        <div>
          <h6>{I18n.t('climb_search_rankings', i18nScope)}</h6>
          <img src={stemModalLeftImage} />
          <p id="boost">{I18n.t('boost', i18nScope)}</p>
          <p>{I18n.t('visibility', i18nScope)}</p>
        </div>
        <div>
          <h6>{I18n.t('reach_broader_audience', i18nScope)}</h6>
          <img src={stemModalCenterImage} />
          <p>{I18n.t('auto_convert', i18nScope)}</p>
        </div>
        <div>
          <h6>{I18n.t('be_the_star', i18nScope)}</h6>
          <img src={stemModalRightImage}/>
          <p>{I18n.t('priority_placement', i18nScope)}</p>
        </div>
      </div>
      <div className="center">
        <h2>{I18n.t('tagline', i18nScope)}</h2>
        <p>{I18n.t('detail', i18nScope)}</p>
      </div>
      <div className="center buttons">
        <a href={stemcellResourcesUrl}>{I18n.t('learn_more', i18nScope)}</a>
        <a onClick={() => setShowModal(false)} className="active">{I18n.t('continue', i18nScope)}</a>
      </div>
      <div className="gradient"></div>
    </GenericModal>
  ) : (
    <></>
    )
}
