/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import FeatureToken from './FeatureToken'
import FeatureEllipse from './FeatureEllipse'
import FeatureSelectInput from './FeatureSelectInput'
import { getErrors } from '../../../products/react_components/helpers'

const FeatureRow = (props) => {
  const {
    feature,
    ancestorFeatureIds,
    removeFeatureCallback,
    assignedFeatureIds,
    addFeatureCallback,
    categoryPage,
    errors,
  } = props
  const {
    assignment_type: assignmentType,
    id,
    visible_ancestor_tags: visibleAncestorTags,
    visible_ancestor_ids: visibleAncestorIds,
    text,
    ancestry_ids: ancestryIds,
    has_children: hasChildren,
    added,
  } = feature
  const isLocked = assignmentType.toUpperCase() === 'A'
  const isDuplicate = ancestorFeatureIds.indexOf(id.toString()) > -1

  const tags = [...visibleAncestorTags, text]
  const visibleTags = tags.length > 4 ? tags.slice(0, 1).concat(tags.slice(-2)) : tags

  const ids = [...visibleAncestorIds, id]
  const visibleIds = ids.length > 4 ? ids.slice(0, 1).concat(ids.slice(-2)) : ids

  const newAncestryIds = [...ancestryIds, id]
  const ancestry = newAncestryIds.join('/')

  const getKey = (index) => `feature-token-${id}-${index}`
  const showEllipse = (index) => index === 1 && visibleAncestorIds.length > 3

  const removeFeature = () => removeFeatureCallback(id)

  const hasError = () => Object.keys(getErrors(errors)).includes('not_allowed_category')
                          && getErrors(errors).not_allowed_category.length
                          && getErrors(errors).not_allowed_category[0].items.includes(text)

  return (
    <li className={`feature-assignment-row ${hasError() ? 'has-errors' : ''}`}>
      <button
        type="button"
        className="btn-delete-feature-assignment disabled"
        title={I18n.t('feature_assignment_widget.locked')}
        tabIndex="-1"
      >
        <span className="sr-only">{I18n.t('feature_assignment_widget.locked')}</span>
      </button>

      <button
        type="button"
        className="btn-delete-feature-assignment"
        title={I18n.t('feature_assignment_widget.delete')}
        onClick={removeFeature}
      >
        <span aria-hidden="true"><i className="fa fa-times" /></span>
        <span className="sr-only">{I18n.t('feature_assignment_widget.delete')}</span>
      </button>

      <div className="feature-assignment-text">
        <ol className="feature-assignment-text-list">
          {
            visibleTags.map((token, index) => {
              const key = getKey(index)
              const rows = [<FeatureToken
                key={key}
                token={token}
                id={visibleIds[index]}
                isLast={visibleTags.length === index + 1}
              />]
              if (showEllipse(index)) {
                rows.push(<FeatureEllipse key={`${key}-ellipse`} />)
              }
              return rows
            })
          }
          { hasChildren
          && (
            <li>
              <FeatureSelectInput
                key={`feature-react-select-${id}`}
                inline
                assignedFeatureIds={assignedFeatureIds}
                ancestry={ancestry}
                minimumInputLength={0}
                addFeatureCallback={addFeatureCallback}
                closeMenuOnSelect={false}
                menuIsOpen={added}
                categoryPage={categoryPage}
              />
            </li>
          )}
        </ol>
      </div>

      { isDuplicate
        && (
        <p className="duplicate">
          {I18n.t('feature_assignment_widget.duplicate_category')}
          <button
            type="button"
            className="inline-btn-delete-feature-assignment"
            onClick={removeFeature}
          >
            {I18n.t('feature_assignment_widget.remove')}
          </button>
          .
        </p>
        )}
    </li>
  )
}

export default FeatureRow

FeatureRow.propTypes = {
  feature: PropTypes.object.isRequired,
  ancestorFeatureIds: PropTypes.array,
  removeFeatureCallback: PropTypes.func.isRequired,
  assignedFeatureIds: PropTypes.array,
  addFeatureCallback: PropTypes.func.isRequired,
  errors: PropTypes.object,
}

FeatureRow.defaultProps = {
  ancestorFeatureIds: [],
  assignedFeatureIds: [],
  error: {},
}
