import {
  count,
  recognizePrimary,
  removeExtension,
  resolvePrimary
} from '../utils/ProductFiles'
import { useEffect, useMemo, useRef, useState } from 'react'

// Native Files
// - Enforce only one file is primary (has property isNative set to true)
// - Automatically assign primacy to a file if it's the first one recognized as native
// - Provide the ability to change the primary file
// - Provide the ability to remove primacy

export default function useNativeFiles(files, {
  formats,
  setValue,
  saveDraft,
  product
}) {
  const [primaryFile, nativeFiles] = resolvePrimary(files)
  const [previousFileCount, setPreviousFileCount] = useState(count(files))
  const nameLocked = useRef(false)

  // Automatically recognize and save a primary native file
  // when the number of files increases (from new files being uploaded).
  // If the draft doesn't have a name yet, use the primary file's name.
  useEffect(() => {
    const fileCount = count(files)
    if (fileCount > previousFileCount && formats) {
      if (!primaryFile) {
        const [primary] = recognizePrimary(files, formats)
        if (primary) {
          setPrimary(primary)
          if (!product.name && !nameLocked.current) {
            nameLocked.current = true
            saveDraft({
              name: removeExtension(primary.attributes.name)
            })
          }
        }
      }
    }
    setPreviousFileCount(fileCount)
  }, [files, formats])

  const setPrimary = (file) => {
    if (primaryFile) {
      changePrimary(file)
    } else {
      setValue(`${file.id}.isNative`, true)
    }
  }

  const changePrimary = (file) => {
    setValue(`${primaryFile.id}.isNative`, false)
    setValue(`${file.id}.isNative`, true)
  }

  const removePrimary = async () => {
    setValue(`${primaryFile.id}.isNative`, false)
  }

  return {
    primaryFile: useMemo(() => primaryFile, [files]),
    nativeFiles: useMemo(() => nativeFiles, [files]),
    setPrimary,
    changePrimary,
    removePrimary
  }
}
