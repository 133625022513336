/* global gon, I18n */
import React, { useEffect, useContext } from 'react'

import Providers from '../../components/publisher/Providers'
import { QueryClient, QueryClientProvider } from 'react-query'
import BundlesPanel from './BundlesPanel'
import PublisherHeader from './PublisherHeader'
import DraftAlert from './DraftAlert'
import PublisherAlerts from './PublisherAlerts'
import BundlesProvider from './BundlesProvider'
import MetadataPanel from "./MetadataPanel";
import  { BundlesModal } from '../../components/products/Bundles/BundlesModal'
import { BundlesContext } from './BundlesProvider'

const queryClient = new QueryClient()

export default function BundlesEdit() {
	return (
    <QueryClientProvider client={queryClient}>
      <Providers gon={gon}>
				<BundlesProvider>
					<div className="row main-form-wrapper new-opp">
						<div className="product-header-wrapper">
							<div id="js-turbosquid-product-header">
								<PublisherHeader />
								<div className="row edit-header">
									<div className="col-sm-12 PublisherAlerts">
										<DraftAlert />
										<div className="row publisher-header-notifications">
											<PublisherAlerts />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xs-12 panes-wrapper">
							<div className="col-xs-12 col-sm-8 opp-left-pane">
								<BundlesPanel />
							</div>
							<div className="col-xs-12 col-sm-4 opp-right-pane">
								<MetadataPanel />
							</div>
						</div>
					</div>
					<BundlesModal/>
				</BundlesProvider>
			</Providers>
  	</QueryClientProvider>
	)
}
