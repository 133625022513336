/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string'
import {
  statusList,
  checkmateStatusList,
  fileFormats,
  priceRanges,
} from '../constants'
import AlertBox from '../../../shared/AlertBox'
import { reactSelectStyle } from '../../../utils/Styles'

const i18nOptions = {
  scope: 'turbosquid_products.filter_titles',
}

const selectorsData = () => ([
  {
    id: 'status',
    data: statusList,
    title: I18n.t('availability', i18nOptions),
  },
  {
    id: 'price_range',
    data: priceRanges,
    title: I18n.t('price_range', i18nOptions),
  },
  {
    id: 'file_format',
    data: fileFormats,
    title: I18n.t('file_formats', i18nOptions),
  },
  ...gon.checkmate_enabled ? [{
    id: 'checkmate_status',
    data: checkmateStatusList,
    title: I18n.t('turbosquid_products.checkmate'),
  }] : [],
])

class FilterModalContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedValues: {
        display_name: '',
        term: '',
        status: '',
        price_range: '',
        file_format: '',
        checkmate_status: '',
      },
      inProgress: false,
      uniqueFilterName: true,
      errorMessage: '',
      deleteInProgress: false,
    }
  }

  componentDidMount() {
    this.prefillFiltersFromUrl()
    const { selectedFilter } = this.props
    if (selectedFilter) {
      this.prefillFilters(selectedFilter)
    }
    this.folderNameInput.focus()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    const { crudError } = this.props
    if (prevProps.crudError !== crudError) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        inProgress: false,
        deleteInProgress: false,
      })
    }
  }

  formatData = (data, id) => {
    const newData = [{ value: 'all', label: I18n.t('all', i18nOptions), id }]
    data.map(
      (el) => newData.push({ value: el, label: el.charAt(0).toUpperCase() + el.slice(1), id })
    )
    return newData
  }

  handleInputChange = (event) => {
    const { value } = event.target
    const key = event.target.name
    this.setState((prevState) => ({
      ...prevState,
      selectedValues: {
        ...prevState.selectedValues,
        [key]: value,
      },
    }
    ))
  }

  handleSelectChange = (selectedValues) => {
    const selectedValue = selectedValues.id
    this.setState((prevState) => ({
      ...prevState,
      selectedValues: {
        ...prevState.selectedValues,
        [selectedValue]: selectedValues.value,
      },
    }
    ))
  }

  handleSelectedValues = (selectedValues) => {
    const quickFilters = {}
    let displayName
    Object.keys(selectedValues).forEach((key) => {
      switch (key) {
        case 'display_name':
          displayName = selectedValues[key].trim()
          break
        default:
          quickFilters[key] = selectedValues[key]
      }
    })
    const filterData = { quick_filter: { ...quickFilters }, display_name: displayName }
    return filterData
  }

  // eslint-disable-next-line consistent-return
  handleSubmit = (event) => {
    event.preventDefault()

    const { selectedFilter, filtersData } = this.props
    const { selectedValues } = this.state

    this.setState({
      errorMessage: '',
    })
    const trimmedDisplayName = selectedValues.display_name.trim()
    if (!trimmedDisplayName || trimmedDisplayName.length === 0) {
      const empty = I18n.t('filter_name_required', i18nOptions)
      this.setState({
        uniqueFilterName: false,
        errorMessage: empty,
      })
      return false
    }
    const FILTER_NAME_MAX_LENGTH = 100
    if (trimmedDisplayName.length > FILTER_NAME_MAX_LENGTH) {
      const tooLong = I18n.t('filter_name_too_long', { name_length: FILTER_NAME_MAX_LENGTH, ...i18nOptions })
      this.setState({
        uniqueFilterName: false,
        errorMessage: tooLong,
      })
      return false
    }

    this.setState({
      inProgress: true,
    })

    const newFilterData = this.handleSelectedValues(selectedValues)

    if (!selectedFilter) {
      this.handleSubmitNewFilter(filtersData, newFilterData)
      return false
    }

    this.handleSubmitUpdateFilter(filtersData, newFilterData, selectedFilter)
  }

  handleSubmitNewFilter = (filtersData, newFilterData) => {
    const checkName = this.matchFilterName(filtersData, newFilterData)
    const { addFilter } = this.props
    if (checkName) {
      this.resetAlertBox()
      addFilter(newFilterData)
    }
  }

  // eslint-disable-next-line consistent-return
  handleSubmitUpdateFilter = (filtersData, newFilterData, selectedFilter) => {
    const { updateFilter } = this.props
    if (newFilterData.display_name === selectedFilter.key) {
      this.resetAlertBox()
      updateFilter(newFilterData, selectedFilter.id, 'PATCH')
      return false
    }
    const checkName = this.matchFilterName(filtersData, newFilterData)
    if (checkName) {
      this.resetAlertBox()
      updateFilter(newFilterData, selectedFilter.id, 'PATCH')
    }
  }

  prefillFiltersFromUrl = () => {
    const { selectedValues } = this.state
    const filterParams = queryString.parse(window.location.search)
    const obj = {
      display_name: selectedValues.display_name,
      term: '',
      status: '',
      price_range: '',
      file_format: '',
      checkmate_status: '',
    }
    Object.entries(filterParams).forEach(([key, value]) => {
      const term = key
      if (term !== 'page') {
        obj[term] = value
      }
    })

    this.setState((prevState) => ({
      ...prevState,
      selectedValues: obj,
    }))
  }

  prefillFilters = (selectedFilter) => {
    const newObj = {}
    Object.entries(selectedFilter).forEach(([key, value]) => {
      if (key === 'key') {
        newObj.display_name = value
      } else if (key === 'data') {
        Object.entries(value).forEach(([k, v]) => {
          newObj[k] = v
        })
      }
      this.setState((prevState) => ({
        ...prevState,
        selectedValues: newObj,
      }))
    })
  }

  clearFilters = () => {
    this.setState((prevState) => ({
      ...prevState,
      selectedValues: {
        ...prevState.selectedValues,
        display_name: '',
        term: '',
        status: '',
        price_range: '',
        file_format: '',
        checkmate_status: '',
      },
    }
    ))
  }

  deleteConfirm = (message, yesCallback, noCallback = () => {}) => {
    if (window.confirm(message)) { // eslint-disable-line
      yesCallback()
    } else {
      noCallback()
    }
  }

  handleDelete = (selectedFilter) => {
    const { deleteFilter, filtersData } = this.props
    this.deleteConfirm(
      I18n.t('confirm_delete', i18nOptions),
      () => {
        this.setState({ deleteInProgress: true })
        deleteFilter(filtersData, selectedFilter, 'DELETE')
      }
    )
  }

  resetAlertBox = () => {
    this.setState({ uniqueFilterName: true })
  }

  matchFilterName(filtersData, newFilterData) {
    let check = true
    Object.entries(filtersData).forEach(([_, value]) => {
      if (value.key === newFilterData.display_name) {
        const exists = I18n.t('filter_name_exists', i18nOptions)
        this.setState({
          uniqueFilterName: false,
          errorMessage: exists,
          inProgress: false,
        })
        check = false
      }
    })
    return check
  }

  render() {
    const {
      selectedValues,
      inProgress,
      uniqueFilterName,
      errorMessage,
      deleteInProgress,
    } = this.state
    const { selectedFilter, crudError, activeFolderId } = this.props

    const selectors = selectorsData().map((el) => (
      <div className="form-group" key={el.id}>
        <p>{el.title}</p>
        <Select
          name={el.id}
          className={el.id}
          searchable={false}
          clearable={false}
          value={this.formatData(el.data, el.id).filter(({ value }) => value === selectedValues[el.id])} //eslint-disable-line
          placeholder={I18n.t('all', i18nOptions)}
          onChange={this.handleSelectChange}
          options={this.formatData(el.data, el.id)}
          styles={reactSelectStyle}
        />
      </div>
    ))

    let submitButtonName = I18n.t('turbosquid_products.filter_titles.modal_save_filter')
    let filtersAction = (
      <button type="button" className="prefill-button" onClick={this.clearFilters}>
        <i className="fa fa-copy" />
        {I18n.t('turbosquid_products.filter_titles.clear_filters')}
      </button>
    )

    if (selectedFilter) {
      submitButtonName = I18n.t('turbosquid_products.filter_titles.modal_update_filter')
      filtersAction = (
        <button type="button" className="prefill-button" onClick={this.prefillFiltersFromUrl}>
          <i className="fa fa-copy" />
          {I18n.t('turbosquid_products.filter_titles.prefill_with_current_selection')}
        </button>
      )
    }

    let updateFilterNameError = ''
    if (uniqueFilterName.length === 0 || !uniqueFilterName || crudError !== null) {
      updateFilterNameError = (
        <AlertBox
          type="danger"
          message={crudError !== null ? crudError : errorMessage}
          resetAlertBox={this.resetAlertBox}
        />
      )
    }

    return (
      <form className="filter-content" method="post" onSubmit={this.handleSubmit}>

        {updateFilterNameError}

        <div className="form-group bulk-unit folder-name">
          <p>{I18n.t('turbosquid_products.price_preview.name')}</p>
          <input
            type="text"
            name="display_name"
            value={selectedValues.display_name || ''}
            placeholder={I18n.t('turbosquid_products.filter_titles.folder_name')}
            className="form-control"
            ref={(input) => { this.folderNameInput = input }}
            onChange={(event) => this.handleInputChange(event)}
          />
        </div>

        <div className="form-group bulk-unit">
          <p>{I18n.t('turbosquid_products.filter_titles.public_tags')}</p>
          <input
            type="text"
            name="term"
            placeholder={I18n.t('turbosquid_products.filter_titles.tags')}
            value={selectedValues.term || ''}
            className="form-control"
            onChange={(event) => this.handleInputChange(event)}
          />
        </div>
        <div className="form-group bulk-unit" />

        {selectors}

        <div className="btn-wrapper">
          <div className="wrapper">
            <button type="submit" disabled={(inProgress || deleteInProgress)} className="btn btn-info btn-bulk-submit">
              {submitButtonName}
            </button>
            <div className="loader" style={{ display: (inProgress && !deleteInProgress) ? 'inline-block' : 'none' }} />
          </div>
          <div className="wrapper">
            {filtersAction}
            {selectedFilter
              && (
                <button type="button" className="delete-button" onClick={() => this.handleDelete(selectedFilter.id, activeFolderId)}>
                  <i className="fa fa-trash-o" />
                  {I18n.t('turbosquid_products.filter_titles.delete')}
                  <div className="loader" style={{ display: deleteInProgress ? 'inline-block' : 'none' }} />
                </button>
              )}
          </div>
        </div>
      </form>
    )
  }
}

export default FilterModalContent

FilterModalContent.propTypes = {
  selectedFilter: PropTypes.object,
  updateFilter: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  activeFolderId: PropTypes.string,
  deleteFilter: PropTypes.func.isRequired,
  filtersData: PropTypes.array.isRequired,
  crudError: PropTypes.string,
}

FilterModalContent.defaultProps = {
  selectedFilter: {},
  activeFolderId: '',
  crudError: '',
}
