/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import EditableCell from './EditableCell'
import { PENDING_ASSET_TYPE } from '../constants'
import { LOWER_PRICE_BOUND, HIGHER_PRICE_BOUND } from '../../../products/react_components/helpers'

export default class PriceCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      price: props.price,
      originalPrice: props.price,
    }
  }

  componentDidUpdate(prevProps, _nextProps) {
    const { price, originalPrice } = this.props
    const oldPrice = prevProps.price

    if (price !== oldPrice) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ price, originalPrice })
    }
  }

  handleEditCellSubmit = (data) => {
    const product = data;
    const { handleEditCellSubmit } = this.props
    
    if (!this.validPrice(data.value)) {
      product.alert = {
        type: 'danger',
        message: I18n.t('turbosquid_products.messages.an_error_occured'),
      };
      const { originalPrice } = this.state
      this.setState({ price: originalPrice })
    } else {
      const price = Math.floor(parseFloat(data.value))
      const newPrice = `$${price.toFixed(2)}`
      this.setState({ price: newPrice, originalPrice: newPrice })
      
      product.value = price;
    }

    handleEditCellSubmit(product)
  }

  handleEditCellChange = (data) => {
    const product = data
    
    if (!this.validPrice(data.value)) {
      product.alert = {
        type: 'danger',
        message: I18n.t('turbosquid_products.messages.an_error_occured'),
      };
      const { originalPrice } = this.state
      this.setState({ price: originalPrice })
    } else {
      const priceInput = Math.floor(parseFloat(data.value))
      const newPrice = `$${priceInput.toFixed(2)}`
      this.setState({ price: newPrice, originalPrice: newPrice })
    }
  }

  validPrice = (value) => {
    const price = parseFloat(value)
    // eslint-disable-next-line no-restricted-globals
    if (value === '' || isNaN(value)) { return false }
    if (price !== 0 && (price < LOWER_PRICE_BOUND || price > HIGHER_PRICE_BOUND)) {
      return false
    }

    return true
  }

  render() {
    const {
      turbosquid_id: turbosquidId,
      pendingProductIds,
      type: productType,
    } = this.props
    const { price } = this.state
    const editPrice = price.replace(/[$,]+/g, '')
    let priceNode
    if (productType === PENDING_ASSET_TYPE) {
      priceNode = <span className="price_cell">{price}</span>
    } else {
      priceNode = (
        <EditableCell
          name="price"
          value={price}
          editValue={editPrice}
          turbosquidId={turbosquidId}
          pendingProductIds={pendingProductIds}
          columnSize={7}
          tagName="span"
          className="price_cell"
          handleEditCellSubmit={this.handleEditCellSubmit}
          handleEditCellChange={this.handleEditCellChange}
        />
      )
    }

    return (
      <>
        {priceNode}
      </>
    )
  }
}

PriceCell.propTypes = {
  turbosquid_id: PropTypes.string.isRequired,
  pendingProductIds: PropTypes.array.isRequired,
  price: PropTypes.string,
  originalPrice: PropTypes.string,
  handleEditCellSubmit: PropTypes.func.isRequired,
  handleEditCellChange: PropTypes.func,
  type: PropTypes.string,
}

PriceCell.defaultProps = {
  price: '',
  originalPrice: '',
  type: '',
}
