window.onload = function() {
  function addEventListenerList(nodeList, event, fn) {
    for (var i = 0, len = nodeList.length; i < len; i++) {
      nodeList[i].addEventListener(event, fn, false);
    }
  }
  let domList = document.querySelectorAll(".btn-brand-expand, .btn-sub-brand-expand");
  addEventListenerList(domList, "click", (e) => {
    let collapse = e.currentTarget.getAttribute("data-collapse");
    let icon = e.currentTarget.querySelector(".fa");
    let collapseDom = e.currentTarget.parentElement.querySelector(".collapse");
    if (!collapseDom) return;

    if (collapse === 'show') {
      collapse = 'hide';
      e.currentTarget.classList.remove("expanded");
    } else {
      collapse = 'show';
      e.currentTarget.classList.add("expanded");
    }

    if (icon) {
      if (icon && collapse === 'hide') {
        icon.classList.remove("fa-minus");
        icon.classList.add("fa-plus");
      } else {
        icon.classList.remove("fa-plus");
        icon.classList.add("fa-minus");
      }
    }
    e.currentTarget.setAttribute("data-collapse", collapse);
    if (collapseDom) {
      if (collapse === 'show') {
        collapseDom.classList.add("in");
      } else {
        collapseDom.classList.remove("in");
      }
    }

    if (collapse === 'hide') {
      let nestedExpandDoms = collapseDom.querySelectorAll(".btn-sub-brand-expand");
      for (var i = 0, len = nestedExpandDoms.length; i < len; i++) {
        nestedExpandDoms[i].setAttribute("data-collapse", "show");
        nestedExpandDoms[i].click();
      }
    }
  });

  let expandAllDom = document.querySelector(".btn-expand-all-boxes");
  if (expandAllDom) {
    expandAllDom.addEventListener("click", (e) => {
      for (var i = 0, len = domList.length; i < len; i++) {
        domList[i].setAttribute("data-collapse", "hide");
        domList[i].click();
      }
    }, false);
  }

  let prohibitedFilterTermDom = document.querySelector("#prohibited-filter-term");
  if (prohibitedFilterTermDom && prohibitedFilterTermDom.value.length) {
    for (var i = 0, len = domList.length; i < len; i++) {
      domList[i].setAttribute("data-collapse", "hide");
      domList[i].click();
    }
  }
}
