import React from 'react'
import { ThumbnailCell, CheckmateCell, StatusCell, FeatureCell, IdCell, DateCell } from './index'

const baseOptions = {
  style: {
    flexDirection: 'row-reverse',
  },
  headerStyle: {
    flexDirection: 'row-reverse',
  },
}

// /AssetManager/Index.cfm?stgAction=getProduct&intProductId=

export const defaultColumns = [
  {
    Header: 'ID',
    maxWidth: 80,
    id: 'turbosquid_id',
    accessor: 'turbosquid_id',
    headerClassName: 'turbosquid_id',
    className: 'turbosquid_id',
    Cell: (row) => IdCell(row.original),
    ...baseOptions,
  },
  {
    Header: 'Name',
    id: 'product_name',
    minWidth: 280,
    Cell: (row) => <ThumbnailCell {...row.original} />, // eslint-disable-line
  },
  {
    Header: 'Categories',
    accessor: 'feature_assignments',
    sortable: false,
    minWidth: 175,
    Cell: (row) => FeatureCell(row.original),
    style: { textAlign: 'left' },
  },
  {
    Header: 'Price',
    id: 'price_value',
    accessor: 'price',
    headerClassName: 'price',
    className: 'price',
    Cell: ({ value }) => <span className="price_cell">{ value }</span>, // eslint-disable-line
    ...baseOptions,
  },
  {
    Header: 'Date Added',
    id: 'classic_created_at',
    minWidth: 125,
    accessor: 'date_added',
    headerClassName: 'date_added',
    className: 'date_added',
    Cell: (props) => DateCell({ ...props.original, value: props.value, displayOptions: { classes: 'classic_created_at_cell' } }),
    ...baseOptions,
  },
  {
    Header: 'Date Modified',
    id: 'classic_updated_at',
    minWidth: 125,
    accessor: 'date_modified',
    headerClassName: 'date_modified',
    className: 'date_modified',
    Cell: (props) => DateCell({ ...props.original, value: props.value, displayOptions: { classes: 'classic_updated_at_cell' } }),
    ...baseOptions,
  },
  {
    Header: 'Sales',
    accessor: 'sale_count',
    headerClassName: 'sale_count',
    className: 'sale_count',
    maxWidth: 70,
    Cell: ({ value }) => <span className="sale_count_cell">{ value }</span>, // eslint-disable-line
    ...baseOptions,
  },
  {
    Header: 'Total Sales',
    accessor: 'sale_total',
    headerClassName: 'sale_total',
    className: 'sale_total',
    minWidth: 105,
    Cell: ({ value }) => <span className="sale_total_cell">{ value }</span>, // eslint-disable-line
    ...baseOptions,
  },
  {
    Header: 'CheckMate',
    minWidth: 125,
    accessor: 'checkmate_status',
    headerClassName: 'checkmate_status',
    className: 'checkmate_status',
    id: 'metadata.turbosquid.checkmate_status.pipeline_status',
    Cell: (row) => CheckmateCell(row.original),
  },
  {
    Header: 'Status',
    accessor: 'status',
    headerClassName: 'status',
    className: 'status',
    id: 'status',
    minWidth: 110,
    Cell: (row) => StatusCell(row.original),
  },
]

export const partnerColumns = (partner, key) => [{
  Header: `${partner.value} link`,
  id: `${key}_link`,
  accessor: (data) => {
    const hasData = data.syndication_data != null && typeof data.syndication_data[key] !== 'undefined'
    return hasData ? data.syndication_data[key].link : ''
  },
  // eslint-disable-next-line react/prop-types
  Cell: ({ value }) => (value
    && (
      <a className="partner_link" href={value}>
        { value }
        {' '}
        product
      </a>
    )
  ),
},
{
  Header: `${partner.value} sales`,
  id: `${key}_sales`,
  accessor: (data) => {
    const hasData = data.syndication_data !== null && typeof data.syndication_data[key] !== 'undefined'
    return hasData ? data.syndication_data[key].sales : ''
  },
  maxWidth: 70,
  Cell: ({ value }) => value >= 0 && <span className="sale_count_cell">{ value }</span>, // eslint-disable-line
  ...baseOptions,
},
{
  Header: `${partner.value} total sales`,
  id: `${key}_sales_total`,
  accessor: (data) => {
    const hasData = data.syndication_data !== null && typeof data.syndication_data[key] !== 'undefined'
    return hasData ? data.syndication_data[key].sales_total : ''
  },
  maxWidth: 95,
  Cell: ({ value }) => <span className="sale_total_cell">{ value }</span>, // eslint-disable-line
  ...baseOptions,
}]
