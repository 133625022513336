export const defaultFields = [
  'tags',
  'price',
  'mediaType',
  'description',
  'name',
]

// the numeric keys are the type ids
export const specificFields = {
  2: [
    'geometry',
    'vertices',
    'polygons',
    'unwrappedUVs',
  ],
  3: [
    'width',
    'height',
    'colorDepth',
  ],
  5: [
    'frameRate',
    'length',
  ],
}
