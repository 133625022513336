/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';
import DashboardClient from './common/dashboard_client';
import CheckMateLeaders from './checkmate_leaders';
import CheckmateHeader from './checkmate_header';
import PanelSpinner from './common/panel_spinner';
import CheckMateNotSquidGuild from './checkmate_not_squidguild';
import CheckMateQueue from './checkmate_queue';

const CheckMate = createReactClass({
  displayName: 'CheckMate',
  mixins: [DashboardClient],
  endpoint() {
    return '/turbosquid/dashboard_widgets/tier_settings.json'
  },
  render() {
    return (
      <div className="panel-body">
        {(() => {
          if (this.state.data != null) {
            const tier_setting = _.head(this.state.data.tier_settings)
            if (tier_setting != null && tier_setting.is_squid_guild) {
              return (
                <div>
                  <CheckmateHeader is_squid_guild checkmate_published_count={this.state.data.tier_settings[0].checkmate_published_count} />
                  <div className={`panel-divider checkmate-panel-collapse ${this.panelCollapseClass('checkmate-container')}`} />
                  <div id="checkmateTab" className={`tab-content checkmate-panel-collapse ${this.panelCollapseClass('checkmate-container')}`}>
                    <div className="tab-pane fade active in" id="queue"><CheckMateQueue tier_setting={tier_setting} /></div>
                    <div className="tab-pane fade" id="leaderboard"><CheckMateLeaders /></div>
                  </div>
                </div>
              )
            }
            return (
              <div>
                <CheckmateHeader is_squid_guild={false} />
                <div className={`panel-divider checkmate-panel-collapse ${this.panelCollapseClass('checkmate-container')}`} />
                <div className={`checkmate-panel-collapse ${this.panelCollapseClass('checkmate-container')}`}><CheckMateNotSquidGuild /></div>
              </div>
            )
          }
          return (
            <div>
              <CheckmateHeader is_squid_guild={false} />
              <div className={`panel-divider checkmate-panel-collapse ${this.panelCollapseClass('checkmate-container')}`} />
              <div className={`checkmate-panel-collapse ${this.panelCollapseClass('checkmate-container')}`}><PanelSpinner /></div>
            </div>
          )
        })()}
      </div>
    )
  },
})
export default CheckMate;
