/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const i18nOptions = {
  scope: 'turbosquid.products.product_alerts.draft_warning',
}

const BundlesPublishModal = ({ show, hideModal }) => (
  <Modal show={show} className="discard-modal-container" onHide={hideModal}>
    <Modal.Dialog>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('bundles_publish', i18nOptions)}</Modal.Title>
      </Modal.Header>
      <div className="separator" />
      <Modal.Footer>
        <a variant="primary" id="discard" className="delete" type="button" onClick={hideModal}>
          {' '}
          {I18n.t('ok', i18nOptions)}
        </a>
      </Modal.Footer>
    </Modal.Dialog>
  </Modal>
)

BundlesPublishModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired
}

export default BundlesPublishModal
