import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import ReactSpinner from '../react-spinner'

const SpinnerOverlay = ({ children, renderSpinner, bodyStyle }) => (
  createPortal(
    <div className="overlay">
      <div className="overlay-body" style={bodyStyle}>
        {
          !renderSpinner
          && (
            <div className="spinner-container">
              <ReactSpinner color="#fff" />
            </div>
          )
        }
        {renderSpinner}
        <div className="message">
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('wrap')
  )
)

SpinnerOverlay.propTypes = {
  children: PropTypes.node,
  renderSpinner: PropTypes.node,
  bodyStyle: PropTypes.object,
}

SpinnerOverlay.defaultProps = {
  children: null,
  renderSpinner: null,
  bodyStyle: {},
}

export default SpinnerOverlay
