import React, { useContext } from 'react'
import {
  ASSOCIATED_FILE_DESCRIPTION_LENGTH,
  FORBIDDEN_WORDS,
  roundedSizeInMBFromB
} from '../../../products/react_components/helpers'
import { Controller } from 'react-hook-form'
import { FileContext } from '../../../contexts/FileContext'
import ReactSpinner from '../../../products/react-spinner'
import { reactSelectStyle } from '../../../utils/Styles'
import Select from 'react-select'
import useFileForm from '../../../hooks/useFileForm'
import { FORBIDDEN_WORDS_REGEX } from '../../products/constants'
import { associatedErrorMessage as message } from '../../../utils/ProductFiles'
import { UIContext } from '../../../contexts/UIContext'
import { ProductContext } from '../../../contexts/ProductContext'

const i18nOptions = {
  scope: 'turbosquid.products.product_specifications_panel',
}

const fileTypeOptions = [
  {
    value: 'promotional',
    label: 'Promotional Download',
  },
  {
    value: 'customer',
    label: 'Customer Only Download',
  },
  {
    value: 'texture',
    label: 'Textures',
  },
  {
    value: 'viewer',
    label: 'Marmoset Viewer Files',
  },
]

export default function AssociatedFile({ file }) {
  const { id, type, attributes } = file
  const { name, size } = attributes
  const { setCurrentTab } = useContext(UIContext)
  const { clearFile, deleteFiles, pendingDelete } = useContext(FileContext)
  const { register, control, fileFormValues, formState: { errors }, isReady } = useFileForm(file, {
    fileType: type,
    description: attributes.description,
  })
  const fileType = fileFormValues?.fileType
  const isPendingDelete = pendingDelete.includes(id)
  const disabled = !isReady || isPendingDelete

  const handleDeleteFile = () => deleteFiles(
    { files: [file] },
    {
      onSuccess: () => clearFile(id),
      onError: e => console.error(e)
    }
  )

  const forbiddenWordsError = I18n.t('the_word_was_included', {
    scope: 'turbosquid.products.product_validation',
    words: FORBIDDEN_WORDS.join(', ')
  })

  const descriptionError = I18n.t('associated_files_description_error', {
    scope: 'turbosquid.products.product_files_panel',
    upper_bound: ASSOCIATED_FILE_DESCRIPTION_LENGTH
  })

  return (
    <li>
      <div className="row file-header">
        <span className="file-name">{name}</span>
        {
          isPendingDelete
            ? <span className="delete-file-spinner-container pull-right"><ReactSpinner /></span>
            : <button disabled={disabled} onClick={handleDeleteFile} className="fa fa-times pull-right delete-file" />
        }
      </div>
      <div className="row file-specs">
        <div className="col-md-4">
          <div className="form-group select optional">
            <label className="select optional control-label" >{I18n.t('file_type', i18nOptions)}</label>
            <Controller
              name={`${id}.fileType`}
              control={control}
              render={({ field: { onChange }}) => {
                return (
                  <Select
                    id={`${id}.fileType`}
                    aria-label={I18n.t('file_type', i18nOptions)}
                    className="select optional"
                    value={fileTypeOptions.find(f => f.value === fileType)}
                    searchable={true}
                    onChange={({ value }) => onChange(value)}
                    styles={reactSelectStyle}
                    options={fileTypeOptions}
                    isDisabled={disabled}
                  />
                )
              }}
            />
          </div>
        </div>
        <div className="col-md-6 file-description">
          <div className="form-group">
            <label className="control-label" >{I18n.t('description', i18nOptions)}</label>
            <textarea
              maxLength={ASSOCIATED_FILE_DESCRIPTION_LENGTH}
              className="form-control"
              disabled={disabled}
              {...register(`${id}.description`, {
                maxLength: {
                  value: ASSOCIATED_FILE_DESCRIPTION_LENGTH,
                  message: message('associated_files_description_error', {
                    setCurrentTab,
                    params: {
                      scope: 'turbosquid.products.product_files_panel',
                      upper_bound: ASSOCIATED_FILE_DESCRIPTION_LENGTH
                    }
                  })
                },
                pattern: {
                  value: FORBIDDEN_WORDS_REGEX,
                  message: message('the_word_was_included', {
                    setCurrentTab,
                    params: { words: FORBIDDEN_WORDS }
                  })
                }
              })}
            />
            {errors[id]?.description?.type ==='maxLength' &&
              <div className="errors form-field-error text-error">{descriptionError}</div>
            }
            {errors[id]?.description?.type === 'pattern' &&
              <div className="errors form-field-error text-error">{forbiddenWordsError}</div>
            }
          </div>
        </div>
        <div className="col-md-2">
          <span className="file-size">{`${roundedSizeInMBFromB(size)} MB`}</span>
        </div>
      </div>
    </li>
  )
}
