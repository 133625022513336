import React from 'react'
import DefaultContentLoader from 'react-content-loader'

export default function ContentLoader() {
  return (
    <DefaultContentLoader
      viewBox="0 0 280 150"
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        height: '100%',
        width: '100%'
      }}
    >
      <rect x="0" y="0" width="100%" height="100%" />
    </DefaultContentLoader>
  )
}
