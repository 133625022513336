/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from 'jquery';
import QuickPreviewPopup from './quick-preview-popup';
import QuickPreviewOverlay from './quick-preview-overlay';

const DEFAUlT_OPTIONS = {
  hoverTimeout: 300,
  spinnerFramerate: 10,
  quickPreviewPopup: {
    width: 400,
    gutter: 10
  },
  itemContainerSelector: '.js-quick-preview-items-container',
  itemSelector: '.js-quick-preview-item',
  hideSelector: '.header, .nav, .footer',
  itemOverlayContentSelector: '.quick-preview-overlay-content',
  itemPopupContentSelector: '.quick-preview-popup-content'
};

class QuickPreview {
  constructor(userOptions = null){
    this.options = DEFAUlT_OPTIONS
    if (userOptions != null) {
      for (let key in DEFAUlT_OPTIONS) {
        if (userOptions[key] != null) { this.options[key] = userOptions[key]; }
      }
    }

    this.quickPreviewPopup = new QuickPreviewPopup({width: 400, gutter: 10});
    this.quickPreviewOverlay = new QuickPreviewOverlay();

    $(this.options.hideSelector).on('mouseenter', this.hideQuickPreview);
    $(this.options.itemContainerSelector).on('mouseenter', this.options.itemSelector, (e) => {
      this.handleHover(e)
    });
  }

  handleHover = (e) => {
    this.hideQuickPreview();
    this.setCurrentItem(e.currentTarget);
    this.handleDisplayQuickPreview();
  }

  handleDisplayQuickPreview = () => {
    $(document).off('quick-preview-overlay.mouse.leave.quickPreview');
    const options = {
      offset: this.$currentItem.offset(),
      width: this.$currentItem.outerWidth(),
      height: this.$currentItem.outerHeight(),
      documentWidth: $(document).width(),
      documentHeight: $(document).height(),
      $itemOverlayContent: $(this.$currentItem.find(this.options.itemOverlayContentSelector)),
      $itemPopupContent: $(this.$currentItem.find(this.options.itemPopupContentSelector))
    };

    this.quickPreviewOverlay.show(options);

    options.height = this.quickPreviewOverlay.height() || options.height;
    this.quickPreviewPopup.show(options);

    $(document).on('quick-preview-overlay.mouse.leave.quickPreview', this.hideQuickPreview);
  }

  setCurrentItem = (element) => {
    this.$currentItem = $(element);
  }

  hideQuickPreview = () => {
    this.quickPreviewOverlay.hide();
    this.quickPreviewPopup.hide();
  }
}
export default QuickPreview;
