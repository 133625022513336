import React, { useState } from 'react'
import { imagesI18nOptions, filesI18nOptions } from '../../products/constants'

export default function PreviewSpec({
  title,
  translationKey,
  count,
  required,
  className,
  learnMoreUrl
}) {
  const [hovering, setHovering] = useState(false)
  const completed = count > 0 && count >= required
  const showAmount = (required || count > 0) && !completed
  const learnMoreLabel = I18n.t('learn_more', filesI18nOptions)
  const message = I18n.t(`cm_image_icon_tips.${translationKey}`, imagesI18nOptions)
  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={`image_container ${required ? '' : 'certified-previews'}`}
    >
      <div className={`
          image-icon-hint ${className}
          ${completed ? 'complete fa fa-check-circle' : ''}`
      }>
        {showAmount && (
          <div className="amount">
            {count} {required > 0 && `/ ${required}`}
          </div>
        )}
        {hovering && (
          <div className="previews-image-info-container">
            <div className="message">
              {message}
              <a
                className="learn-more-url"
                href={learnMoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                title={learnMoreLabel}
              >
                {learnMoreLabel}
              </a>
            </div>
          </div>
        )}
      </div>
      <div>{title}</div>
    </div>
  )
}
