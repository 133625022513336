/* global I18n */
import React from 'react'
import { filesI18nOptions, MAX_NAME_LENGTH, nameI18nOptions } from '../../products/constants'

export default function FormError({ error }) {
  const text = (() => {
    switch (error.type) {
      case 'required':
        return I18n.t('required', filesI18nOptions)
      case 'version':
        return I18n.t('version', filesI18nOptions)
      case 'validate':
        return I18n.t('invalid', filesI18nOptions)
      case 'maxLength':
        return I18n.t('name_validation_max_length_notif', { ...nameI18nOptions, upper_bound: MAX_NAME_LENGTH })
      default:
        return 'Error'
    }
  })()

  return (
    <div className="errors form-field-error text-error">
      {text}
    </div>
  )
}
