/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import DismissableNotificationContainer from '../../../containers/publisher/EditHeader/DismissableNotificationContainer'

const PublisherInfo = ({
  turntableCapReached
}) => {
  return (
    <div className="col-xs-12">
      {
        turntableCapReached
        && (
          <DismissableNotificationContainer notificationLevel="info" notificationId="turntableCapErrorNotifications">
            <div>
              {I18n.t('turbosquid.products.product_images_panel.turntable_cap')}
            </div>
          </DismissableNotificationContainer>
        )
      }
    </div>
  )
}

PublisherInfo.propTypes = {
  turntableCapReached: PropTypes.bool,
}

PublisherInfo.defaultProps = {
  turntableCapReached: false,
}

export default PublisherInfo
