/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import createReactClass from 'create-react-class';

const CheckMateNotSquidGuild = createReactClass({
  displayName: 'CheckMateNotSquidGuild',
  render() {
    return (
      <div className="text-center">
        <div className="panel-empty">
          <h3>{I18n.t('dashboard.checkmate.squidguild_title')}</h3>
          <div className="text-left row">
            <div className="col-xs-10 col-xs-offset-1">
              <ul>
                <li className="text-muted">{I18n.t('dashboard.checkmate.squidguild_hint_1')}</li>
                <li className="text-muted">{I18n.t('dashboard.checkmate.squidguild_hint_2')}</li>
                <li
                  className="text-muted"
dangerouslySetInnerHTML={{
          __html: I18n.t('dashboard.checkmate.squidguild_hint_3', { url: gon.checkmate_home_url }),
        }}
                />
<li className="text-muted">{I18n.t('dashboard.checkmate.squidguild_hint_4')}</li>
                <li className="text-muted">{I18n.t('dashboard.checkmate.squidguild_hint_5')}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row publish-product-action"><div className="col-xs-12 text-center"><a className="btn btn-warning" href={gon.squidguild_info_url}>{I18n.t('dashboard.checkmate.see_all_benefits')}</a></div></div>
      </div>
    )
  },
})

export default CheckMateNotSquidGuild;
