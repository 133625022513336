import { connect } from 'react-redux'
import ProductDescription from '../../../components/publisher/ProductSpecs/ProductDescription'
import { filterNotifications } from '../../../products/react_components/helpers'
import { updateDescription,
  fetchConstraints } from '../../../products/react_components/actions/form_slice_actions'
import { toggleInfo } from '../../../products/react_components/actions/publisher_ui_slice_actions'
import { selectPublishValidationTriggered } from '../../../products/react_components/selectors/formSelectors'

const highlightPanel = (alerts) => (
  alerts.find((a) => a.alert_panels.find((p) => p === 'description'))
)

const mapStateToProps = (state) => {
  const { formState } = state
  const { formValidationErrors, values } = formState.turbosquid_product_form

  return {
    errors: formValidationErrors,
    description: values.description,
    highlightPanel: highlightPanel(state.formState.alerts),
    showInfo: state.uiState.descriptionPanel.showInfo,
    formActionInProgress: formState.formActionInProgress,
    publishValidationTriggered: selectPublishValidationTriggered(state),
    collectionFeature: state.featureState.collectionFeature,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateDescriptionWithConstraints: (description, errors, draftSavePendingChanges = true) => dispatch(fetchConstraints({ description, errors, draftSavePendingChanges })),
  updateDescription: (description, errors, draftSavePendingChanges = true) => dispatch(updateDescription({ description, errors, constraintsPending: true, draftSavePendingChanges })),
  toggleInfo: (value) => dispatch(toggleInfo({ panel: 'descriptionPanel', value })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDescription)
