import React, { useContext } from 'react'
import { UIContext } from '../../contexts/UIContext'

const DismissableNotification = ({
  children,
  notificationLevel,
  extraClasses,
  dismissed,
}) => {
  const { setDisplayAlerts } = useContext(UIContext)

  return (
    <div className={`dismissable-notification notification-top alert alert-${notificationLevel}
      ${extraClasses} ${dismissed ? 'dismiss-notifications' : ''}`}
    >
      {children}
      <button
        className="btn-close"
        type="button"
        onClick={() => setDisplayAlerts(false)}
        tabIndex="0"
      />
    </div>
  )
}

export default DismissableNotification