/* global gon, I18n */
import React from 'react'
import PropTypes from 'prop-types'

const i18nOptions = { scope: 'turbosquid_products.price_preview' }

function priceClass(price) {
  return (price === 0) ? 'text-danger' : ''
}

function ProductsPricePreview(props) {
  const { products } = props
  const display = products.length ? 'block' : 'none'

  return (
    <>
      <div id="bulk-update-price-caption" className="col-sm-12 bulk-update-price-caption" style={{ display }}>
        <span>{I18n.t('title', { ...i18nOptions, count: products.length })}</span>
      </div>
      <table id="bulk-update-price-previewer" className="table table-striped table-fixed" style={{ display }}>
        <thead>
          <tr>
            <th className="col-sm-6 left-column name">{I18n.t('name', i18nOptions)}</th>
            <th className="col-sm-2 text-right old-price">{I18n.t('price', i18nOptions)}</th>
            <th className="col-sm-2 right-column text-right new-price">{I18n.t('new_price', i18nOptions)}</th>
          </tr>
        </thead>
        <tbody>
          {
            products.map((product) => (
              <tr id={`preview-${product.turbosquid_id}`} key={product.turbosquid_id}>
                <td className="col-sm-6 left-column name">{product.name}</td>
                <td className="col-sm-2 text-right old-price">{product.price}</td>
                <td className="col-sm-2 right-column text-right new-price">
                  <span
                    className={priceClass(product.priceInCents)}
                  >
                    {product.newPrice}
                  </span>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  )
}

export default ProductsPricePreview

ProductsPricePreview.propTypes = {
  products: PropTypes.array.isRequired,
}
