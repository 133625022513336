import { wireframeHeight, wireframeWidth } from '../../../utils/ImageValidations'

export const imageDimension = (width, height) => {
  const defaultRatio = 16 / 9
  const w = parseInt(width, 10)
  const h = parseInt(height, 10)
  const newRatio = w / h

  if (newRatio >= defaultRatio) {
    return { width: '100%' }
  }

  return { height: '100%' }
}

export const compareFilenameWithNumberSorting = (a, b) => {
  const ax = []
  const bx = []
  if (typeof (a) === 'object' && a.filename) {
    a.filename.replace(/(\d+)|(\D+)/g, (_, $1, $2) => { ax.push([$1 || Infinity, $2 || '']) })
  } else ax.push(['z', 'Z'])
  if (typeof (b) === 'object' && b.filename) {
    b.filename.replace(/(\d+)|(\D+)/g, (_, $1, $2) => { bx.push([$1 || Infinity, $2 || '']) })
  } else bx.push(['z', 'Z'])
  while (ax.length && bx.length) {
    const an = ax.shift()
    const bn = bx.shift()
    const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1])
    if (nn) return nn
  }
  return ax.length - bx.length
}

export const validForWireframe = (width, height) => {
  const w = parseFloat(width)
  const h = parseFloat(height)

  return (w >= wireframeWidth && h >= wireframeHeight)
}
