import { arrayFrom, filterTurntableCandidate, objectFrom } from '../utils/ProductFiles'
import {
  FAILED,
  INSUFFICIENT_FRAMES,
  MISSING_FRAMES, QUEUED,
  SUCCESS,
  TOO_MANY_FRAMES,
  TURNTABLE
} from '../components/products/constants'
import * as TurntableService from '../services/turntable-service'
import { consecutiveCheck, getFileInfo } from '../utils/TurntableUtils'
import { useEffect, useMemo, useRef, useState } from 'react'
import { pollTurntablesReady } from '../services/turntable-service'
import useInterval from './useInterval'
import useMutations from './useMutations'

export const MIN_TURNTABLE_FRAMES = 12
export const MAX_TURNTABLE_FRAMES = 36
const POLL_TURNTABLE_INTERVAL = 5000

const validate = (candidates) => {
  const frames = candidates.map(c => getFileInfo(c.attributes.name))
  const missingFrames = consecutiveCheck(frames.map(f => f.position))
  if (candidates.length < MIN_TURNTABLE_FRAMES) {
    return [{
      type: INSUFFICIENT_FRAMES,
      data: missingFrames
    }]
  } else if (candidates.length > MAX_TURNTABLE_FRAMES) {
    return [{
      type: TOO_MANY_FRAMES,
      data: candidates.length
    }]
  } else if (missingFrames.length) {
    return [{
      type: MISSING_FRAMES,
      data: missingFrames
    }]
  } else {
    return []
  }
}

export const getTurntablesFromCandidates = (candidates) => {
  const turntables = candidates.reduce((acc, candidate) => {
    const id = candidate.attributes.turntableId
    const turntable = acc[id] ?? {}
    const attributes = turntable.attributes ?? {}
    const files = attributes.files ?? []
    return {
      ...acc,
      [id]: {
        attributes: {
          files: [
            ...files,
            candidate
          ]
        },
      }
    }
  }, {})
  return arrayFrom(turntables).map(turntable => {
    const completed = turntable.attributes.files.filter(f => f.status === SUCCESS)
    const { name } = getFileInfo(turntable.attributes.files[0].attributes.name)
    return {
      ...turntable,
      attributes: {
        ...turntable.attributes,
        frameCount: turntable.attributes.files.length,
        completedCount: completed.length,
        name,
      }
    }
  })
}

const useTurntable = (turntable) => {
  const files = turntable.attributes.files
  return {
    total: files.length,
    completed: files.filter(c => c.status === SUCCESS),
    failed: files.filter(c => c.status === FAILED)
  }
}

export default function useTurntableCandidates({
  previews,
  cancelUploads,
  ackUploads,
  updateFiles,
  clearFile,
  draftId,
  csrfToken
}) {
  const [turntables, setTurntables] = useState({})
  const [errors, setErrors] = useState({})
  const candidates = useMemo(() => arrayFrom(
    filterTurntableCandidate(previews)),
    [previews]
  )

  const {
    mutateAsync: createTurntable,
    mutations,
    reset
  } = useMutations(
    'createTurntable',
    ({ fileIds }) => TurntableService.create({
      fileIds,
      draftId,
      csrfToken
    })
  )

  const turntableCandidates = getTurntablesFromCandidates(candidates)

  useEffect(() => {
    turntableCandidates.forEach(turntable => {
      const { completed, failed, total } = useTurntable(turntable)
      const { isLoading, isSuccess } = mutations.current[turntable.id] ?? {}
      const isFailed = !!failed.length && completed.length + failed.length === total
      const isReady =
        !isFailed
        && !isLoading
        && !isSuccess
        && completed.length > 0
        && completed.length === total

      if (isFailed) {
        // Show missing frames
        setErrors({
          ...errors,
          [turntable.id]: validate(completed)
        })
      } else if (isReady) {
        const validationErrors = validate(completed)
        setErrors({
          ...errors,
          [turntable.id]: validationErrors
        })
        if (!validationErrors.length) {
          const fileIds = completed.map(c => c.attributes.fileId)
          createTurntable(turntable.id, { fileIds })
            .then(({ id }) => {
              // Queue new turntable
              setTurntables(prevTurntables => ({
                ...prevTurntables,
                [id]: {
                  attributes: turntable,
                  status: QUEUED
                }
              }))
              // Add turntable placeholder
              updateFiles([{
                id,
                type: TURNTABLE,
                attributes: {
                  name: completed[0].name
                }
              }])
              // Clear uploads from the completed turntable
              const uploadIds = completed.map(c => c.id)
              ackUploads(uploadIds)
              // Reset mutations state
              reset(turntable.id)
            })
            .catch(setErrors)
        }
      }
    })
  }, [candidates])

  const pollTurntableJobs = async (ids) => {
    const data = await pollTurntablesReady({ ids, draftId })
    data.forEach(result => {
      if (result?.status === SUCCESS) {
        // Remove turntable placeholder
        clearFile(result.jobId)
        setTurntables(prevTurntables => {
          const { [result.jobId]: _, ...rest } = prevTurntables
          return rest
        })
        updateFiles([result])
      }
    })
  }

  const jobs = Object.keys(turntables).filter(t => turntables[t].status === QUEUED)
  useInterval(() => pollTurntableJobs(jobs), jobs.length ? POLL_TURNTABLE_INTERVAL : null)


  const clearTurntableCandidates = (turntableId) => {
    const uploadIds = candidates
      .filter(c => c.attributes.turntableId === turntableId)
      .map(c => c.id)
    cancelUploads(uploadIds)
    setErrors({})
  }

  return {
    turntables,
    turntableCandidates,
    errors,
    mutations,
    clearCandidates: clearTurntableCandidates,
  }
}
