/* global I18n, gon */
import React, { useContext, useState, useEffect, useLayoutEffect, useRef } from 'react'
import { productStatusLink } from '../../components/publisher/EditHeader/StatusDescription'
import { formattedActionTime } from '../../utils/time_helpers'
import PublisherControls from './BundlesPublisherControls'
import { ProductContext } from '../../contexts/ProductContext'
import { BundlesContext } from './BundlesProvider'
import AlertBox from '../../shared/AlertBox'

const i18nOptions = {
  scope: 'turbosquid.products.product_header',
}

const getDraftUpdates = (draftSaving, updatedAt) => {
  if (draftSaving) {
    return I18n.t('draft_saving', i18nOptions)
  } if (updatedAt) {
    return `${I18n.t('draft_saved_at', i18nOptions)} ${formattedActionTime(new Date(updatedAt))}`
  } return ''
}

export default function PublisherHeader() {
	const { draftId, product, isLoading, updatedAt } = useContext(ProductContext)

	const {
		metadataForm,
		searchSelect
	} = useContext(BundlesContext)

	const {
		bundleTitle,
	} = metadataForm

	const {
    selectedSearchResults,
    selected,
    removed,
  } = searchSelect

	const resetAlertBox = () => {
    alert = {}
		setAlertPresent(null)
  }
	
	const [alertPresent, setAlertPresent] = useState(null)

  useLayoutEffect(() => {
    setAlertPresent(renderAlertBox(selected, true))
  }, [selectedSearchResults])

  useEffect(() => {
    setAlertPresent(renderAlertBox(removed, false))
  }, [removed])

  const renderAlertBox = (item, action) => {
		if (Object.keys(item).length > 0 ) {
      let message = ''
      if (action) {
			  message = `Product ${item.name} has been added to your bundle.`
      } else {
        message = `Product ${item.name} has been removed from your bundle.`
      }
      return (
        <AlertBox
          type={action ? 'success' : 'danger'}
          message={message}
          timeout={100000}
          resetAlertBox={resetAlertBox}
          isBundleAlert
        />
      )
		}
    return null
  }

  return (
    <div className="product-header-action-wrapper" style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex'}}>
        <div className="product-info-wrapper">
          <h1 className="product-title">{bundleTitle || I18n.t('new_bundle', i18nOptions)}</h1>
          <div className="product-status">
            {productStatusLink(product.status)}
          </div>
          <div className="product-info">
          {/* <span className="product-info-type">
            {`${I18n.t('type', i18nOptions)}:`}
          </span> */}
            {draftId
              ? (
                <div>
                {/* <span className="product-info-draft">
                  {I18n.t('draft', i18nOptions)}
                </span> */}
                  <span className="product-info-draft-info">
                  {/* {getDraftUpdates(isLoading, updatedAt)} */}
                </span>
                </div>
              )
              : <span className="product-info-draft">{I18n.t('published_product', i18nOptions)}</span>}
          </div>
        </div>
        <PublisherControls />
      </div>
      <div>
			  {alertPresent}  
      </div>
    </div>
  )
}