/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { timeFormat } from 'd3-time-format'

import createReactClass from 'create-react-class';
import MoneyUtility from './common/money_utility';
import NumberUtility from './common/number_utility';

const SalesRoyaltiesItem = createReactClass({
  displayName: 'SalesRoyaltiesItem',
  mixins: [MoneyUtility],
  getDefaultProps() {
    return { type: 'royalties' }
  },
  itemClass() {
    const xsClass = this.props.index === 0 ? 'hidden-xs' : 'col-xs-6'
    return `col-sm-4 panel-vertical-divider ${xsClass}`
  },
  monthName() {
    const formatMonth = timeFormat("%B")
    return formatMonth(this.date())
  },
  date() {
    return new Date(Date.parse(this.props.sale.from_as_string) + new Date(this.props.sale.from_as_string).getTimezoneOffset() * 60000)
  },
  month() {
    return this.date().getMonth() + 1
  },
  year() {
    return this.date().getFullYear()
  },
  reportURL() {
    return `${gon.monthly_sale_report_url}&theYear=${this.year()}&theMonth=${this.month()}`
  },
  salesRoyaltiesValue() {
    return this.props.sale[this.props.type] != null ? this.props.sale[this.props.type].value : undefined
  },
  currencyParts() {
    return this.cents_to_currency_parts(this.salesRoyaltiesValue())
  },
  render() {
    const parts = this.currencyParts()
    return (
      <div key={this.props.sale.key} className={this.itemClass()}>
        <h5 className="text-muted">{this.monthName()}</h5>
        <h3>
          <a href={this.reportURL()} target="_blank">
            {parts.main_unit}
            <span className="cents text-muted">
              .
              {parts.fractional_unit}
            </span>
          </a>
        </h3>
      </div>
    )
  },
})

export default SalesRoyaltiesItem;
