import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const GenericModal = ({ show, hideModal, title, children, className }) => (
  <Modal className={className} show={show} onHide={hideModal}>
    <Modal.Header closeButton>
      <Modal.Title>{ title }</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      { children }
    </Modal.Body>
  </Modal>
)

GenericModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
}

GenericModal.defaultProps = {
  children: null,
  className: '',
}

export default GenericModal
