import React from 'react'
import PropTypes from 'prop-types'

const FeatureToken = (props) => {
  const { token, id, isLast } = props
  const turbosquidSearchFeatureUrl = `/features/${id}/turbosquid_search`
  const style = isLast ? {} : { background: 'none' }

  return (
    <li>
      <a href={turbosquidSearchFeatureUrl} target="_blank" rel="noopener noreferrer" tabIndex="-1" style={style}>
        {token}
      </a>
    </li>
  )
}

export default FeatureToken

FeatureToken.propTypes = {
  id: PropTypes.number.isRequired,
  token: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  isLast: PropTypes.bool,
}

FeatureToken.defaultProps = {
  token: undefined,
  isLast: false,
}
