/* global I18n, gon */
import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import ProductsPricePreview from './ProductsPricePreview'
import { bulkPriceUpdate } from './helpers'
import PendingAssetsService from '../../../services/PendingAssetsService'
import { reactSelectStyle } from '../../../utils/Styles'

const i18nOptions = {
  scope: 'turbosquid_products.bulk_price_update',
}

const getPriceOperators = () => ([
  { value: '+', label: I18n.t('operations.increase_by', i18nOptions) },
  { value: '-', label: I18n.t('operations.decrease_by', i18nOptions) },
  { value: '=', label: I18n.t('operations.set_to', i18nOptions) },
])

const priceChangeUnits = [
  { value: '$', label: '$' },
  { value: '%', label: '%' },
]

const productCanBeUpdated = (product) => (Number.isInteger(product.priceInCents) && product.priceInCents >= 0)

const formatProductParams = (product) => ({
  id: product.turbosquid_id,
  type: 'product',
  attributes: {
    price: product.priceInCents,
  },
})

const buildUpdateParams = (products) => (
  products.filter(productCanBeUpdated).map(formatProductParams)
)

class PriceUpdateForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      previewableProducts: [],
      operator: '+',
      amount: '',
      unit: '$',
      inProgress: false,
    }
  }

  handleOperatorChange = (newOption) => {
    const operator = newOption.value
    let newState = { operator, amount: '', previewableProducts: [] }

    if (operator === '=') {
      newState = { ...newState, unit: '$' }
    }

    this.setState(newState)
  }

  handleAmountChange = (e) => {
    const intAmount = parseInt(e.target.value, 10)
    const amount = Number.isNaN(intAmount) ? '' : intAmount
    const { operator, unit } = this.state
    const previewableProducts = this.previewProductsChanges(operator, amount, unit)

    this.setState({ amount, previewableProducts })
  }

  handleUnitChange = (newOption) => {
    const unit = newOption.value
    const { amount, operator } = this.state
    const previewableProducts = this.previewProductsChanges(operator, amount, unit)

    this.setState({ unit, previewableProducts })
  }

  previewProductsChanges = (operator, amount, unit) => {
    const { products } = this.props
    const strAmount = amount.toString()
    if (operator.length > 0 && strAmount.length > 0 && unit.length > 0) {
      return bulkPriceUpdate(products, { operator, amount, unit })
    }
    return []
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { handleBulkUpdateSubmit, handlePendingSubmitsChanges } = this.props
    const { previewableProducts } = this.state
    this.setState({ inProgress: true })

    const submitId = new Date().getTime()
    handlePendingSubmitsChanges(submitId)

    const params = {
      authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
      products: buildUpdateParams(previewableProducts) }

    PendingAssetsService.saveAssetChanges(e.target.action, params)
      .then((response) => {
        const productIds = previewableProducts.map((product) => product.turbosquid_id)
        const data = { batchID: response.id, productIds }
        this.setState({ inProgress: false })
        handlePendingSubmitsChanges(submitId)
        handleBulkUpdateSubmit(data)
      })
      .catch((error) => {
        this.setState({ inProgress: false })
        handlePendingSubmitsChanges(submitId)
        handleBulkUpdateSubmit({ error })
      })
  }

  render() {
    const { operator, amount, unit, previewableProducts, inProgress } = this.state
    const updateableProducts = previewableProducts.filter(productCanBeUpdated)
    return (
      <form className="form-inline" method="post" action={gon.turbosquid_product_batches_path} onSubmit={this.handleSubmit}>
        <div className="bulk-price-form-fields">
          <p>{I18n.t('curent_price', i18nOptions)}</p>

          <div className="form-group bulk-operator">
            <Select
              name="operator"
              className="select-operators"
              placeholder={I18n.t('operator', i18nOptions)}
              searchable={false}
              clearable={false}
              value={getPriceOperators().filter(({ value }) => value === operator)}
              onChange={this.handleOperatorChange}
              options={getPriceOperators()}
              styles={reactSelectStyle}
            />
          </div>

          <div className="form-group bulk-amount">
            <input
              name="amount"
              pattern="\d+"
              maxLength="7"
              required
              placeholder={I18n.t('value', i18nOptions)}
              className="form-control"
              value={amount}
              onChange={this.handleAmountChange}
            />
          </div>

          <div className="form-group bulk-unit">
            <Select
              name="unit"
              className="select-units"
              placeholder={I18n.t('value', i18nOptions)}
              searchable={false}
              clearable={false}
              isDisabled={operator === '='}
              value={priceChangeUnits.filter(({ value }) => value === unit)}
              onChange={this.handleUnitChange}
              options={priceChangeUnits}
              styles={reactSelectStyle}
            />
          </div>
        </div>
        <ProductsPricePreview products={previewableProducts} />

        <div className="btn-wrapper">
          <button type="submit" disabled={inProgress || !updateableProducts.length} className="btn btn-info btn-bulk-submit">
            {I18n.t('turbosquid_products.save_bulk_changes')}
          </button>

          <div className="loader" style={{ display: inProgress ? 'inline-block' : 'none' }} />
        </div>
      </form>
    )
  }
}

export default PriceUpdateForm

PriceUpdateForm.propTypes = {
  products: PropTypes.array.isRequired,
  handleBulkUpdateSubmit: PropTypes.func.isRequired,
  handlePendingSubmitsChanges: PropTypes.func.isRequired,
}
