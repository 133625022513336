import React from 'react'
import { useSlate } from 'slate-react'
import PropTypes from 'prop-types'
import { isBlockActive, isMarkActive, toggleBlock, toggleMark, toggleLink } from '../utils/RichTextUtils'
import Icon from '../products/react_components/icon'

const renderToolButton = (data, disabled) => (
  <span
    title={data.title}
    key={data.key}
    className={(disabled && 'disabled') || (data.active && 'active') || ''}
    style={data.style || {}}
    onMouseDown={data.onMouseDown}
  >
    {data.icon}
  </span>
)

const RichTextToolbar = (props) => {
  const { disabled, editorSelection, removeMarkup } = props
  const editor = useSlate()
  const toolbarItems = [
    {
      key: 'bold',
      title: 'Bold',
      active: isMarkActive(editor, 'bold'),
      icon: <Icon type="bold" data-placement="top" />,
      onMouseDown: (event) => {
        event.preventDefault()
        toggleMark(editor, 'bold', editorSelection)
      },
    },
    {
      key: 'italic',
      title: 'Italic',
      active: isMarkActive(editor, 'italic'),
      icon: <Icon type="italic" />,
      onMouseDown: (event) => {
        event.preventDefault()
        toggleMark(editor, 'italic', editorSelection)
      },
    },
    {
      key: 'heading-one',
      title: 'Heading 1',
      active: isBlockActive(editor, 'heading-one'),
      icon: <span>H1</span>,
      onMouseDown: (event) => {
        event.preventDefault()
        toggleBlock(editor, 'heading-one', editorSelection)
      },
    },
    {
      key: 'heading-two',
      title: 'Heading 2',
      active: isBlockActive(editor, 'heading-two'),
      icon: <span>H2</span>,
      onMouseDown: (event) => {
        event.preventDefault()
        toggleBlock(editor, 'heading-two', editorSelection)
      },
    },
    {
      key: 'block-quote',
      title: 'Block-quote',
      active: isBlockActive(editor, 'block-quote'),
      icon: <Icon type="quote-right" />,
      onMouseDown: (event) => {
        event.preventDefault()
        toggleBlock(editor, 'block-quote', editorSelection)
      },
    },
    {
      key: 'numbered-list',
      title: 'Numbered list',
      active: isBlockActive(editor, 'numbered-list'),
      icon: <Icon type="list-ol" />,
      onMouseDown: (event) => {
        event.preventDefault()
        toggleBlock(editor, 'numbered-list', editorSelection)
      },
    },
    {
      key: 'bulleted-list',
      title: 'Bulleted list',
      active: isBlockActive(editor, 'bulleted-list'),
      icon: <Icon type="list-ul" />,
      onMouseDown: (event) => {
        event.preventDefault()
        toggleBlock(editor, 'bulleted-list', editorSelection)
      },
    },
    {
      key: 'productLink',
      title: 'Product link',
      active: isBlockActive(editor, 'productLink'),
      icon: <Icon type="external-link" />,
      onMouseDown: (event) => {
        event.preventDefault()
        toggleLink(editor, 'productLink')
      },
      style: { position: 'relative', top: '1px' },
    },
    {
      key: 'remove-markup',
      title: 'Remove format',
      active: false,
      icon: <Icon type="remove-format" />,
      onMouseDown: (event) => {
        event.preventDefault()
        removeMarkup()
      },
    },
  ]
  return (
    <div className="rich-text-toolbar">
      {toolbarItems.map((item) => renderToolButton(item, disabled))}
    </div>
  )
}

RichTextToolbar.propTypes = {
  disabled: PropTypes.bool,
  editorSelection: PropTypes.object,
  removeMarkup: PropTypes.func,
}

RichTextToolbar.defaultProps = {
  disabled: false,
  editorSelection: {},
}

export default RichTextToolbar
