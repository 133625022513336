// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string'
import { get } from './service'
import { encodeQuery } from '../components/products/helpers'

class ProductsService {
  sortKey = (field) => {
    const parts = [field.id]
    if (field.desc) {
      parts.push('desc')
    }
    return parts.join(',')
  }

  // eslint-disable-next-line no-unused-vars
  dataUrl = (pageNumber, ignoredSize, sortColumn, searchLocation) => {
    const search = queryString.parse(window.location.search)
    const page = search.page || pageNumber + 1
    let sortName = null
    const path = '/turbosquid/products.json'
    const {
      term,
      status,
      price_range: priceRange,
      file_format: fileFormat,
      checkmate_status: checkmateStatus,
      size,
    } = search

    if (sortColumn) {
      sortName = this.sortKey(sortColumn)
    }

    const query = {
      sort: sortName,
      page,
      size,
      file_format: fileFormat,
      status,
      checkmate_status: checkmateStatus,
      price_range: priceRange,
      term,
    }

    Object.keys(query).forEach((key) => (
      query[key] === undefined || query[key] === null) && delete query[key])

    return path + encodeQuery(query)
  }

  pageChangeSearchLocation = (pageNumber, searchLocation) => {
    const search = queryString.parse(searchLocation)
    search.page = pageNumber
    return encodeQuery(search)
  }

  pageSizeChangeSearchLocation = (pageSize, searchLocation) => {
    const search = queryString.parse(searchLocation)
    search.size = pageSize
    search.page = 1
    return encodeQuery(search)
  }

  getProducts = (state, searchLocation) => {
    const { size: pageSize, page } = state
    const url = this.dataUrl(page, pageSize, state.sorted[0], searchLocation)
    return get(url)
  }
}

export default new ProductsService()
