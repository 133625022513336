export const reactSelectStyle = {
  control: (provided) => ({
    ...provided,
    // background: '#e8e8e8',
    // border: '1px solid #ccc',
    background: '#f6f6f6',
    border: '1px solid #d7d7d7',
    borderRadius: 2,
    color: 'gray',
    height: 32,
    outline: 'none',
    minHeight: 0,
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    borderRadius: '0 0 3px 3px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingTop: 1,
    paddingBottom: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    background: 'none',
    color: state.isSelected || state.isFocused ? '#ff8135' : 'gray',

    '&:hover': {
      background: 'none',
      color: '#ff8135',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'gray',
    // top: 2,
    // transform: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 32,
  }),
}
