import { imagesAllowedExtensions, maxAllowedFileSize, validFileExtensionImages } from '../../../utils/ImageValidations'
import {
  ERROR,
  filesI18nOptions,
  validationI18nOptions,
  imagesI18nOptions,
  MAX_PREVIEW_RESOLUTION,
  MAX_UPLOADABLE_TUNTABLE_FILES
} from '../../products/constants'
import { arrayFrom, imageResolution, stripName, count } from '../../../utils/ProductFiles'
import { getTurntablesFromCandidates } from '../../../hooks/useTurntableCandidates'
import { getFileInfo } from '../../../utils/TurntableUtils'
import { uniq } from 'lodash'

export const fileSize = (file) => {
  return file.size >= maxAllowedFileSize
    ? [{ type: ERROR, message: I18n.t('invalid_file_size', filesI18nOptions) }]
    : []
}

export const fileName = (file) => {
  const name = stripName(file.name)
  const characters = name.match(/[^0-9a-zA-Z_\-.\s]/g)
  return characters?.length
    ? [{
      type: ERROR,
      message: I18n.t('the_character_was_included', { ...validationI18nOptions, characters: uniq(characters).join(', ') }),
    }]
    : []
}

// TODO: do we have to add maxPreviews validator? 
export const maxPreviews = (previews, isCollection) => {
  return (files) => {
      const previewCount = isCollection
        ? arrayFrom(previews).filter(p => p.attributes.thumbnailType === COLLECTION).length
        : count(previews)
      const maxPreviews = isCollection ? 5 : 100
      return previewCount + files.length > maxPreviews
        ? [{ type: ERROR, message: I18n.t('max_previews_error', filesI18nOptions) }]
        : []
    }
}

export const turntableImageName = (url) => {
  return (file) => {
    const turntableNameRe = new RegExp(`^(.*)[-_]\\d{2,}.(${imagesAllowedExtensions.join('|')})$`)
    return (turntableNameRe).test(file.name.toLowerCase())
      ? []
      : [{
        type: ERROR,
        message: I18n.t('invalid_turntable_name', filesI18nOptions),
        link: {text: I18n.t('naming_turntables', filesI18nOptions), url}
      }]
  }
}

const mapCandidate = (candidate) => {
  const info = getFileInfo(candidate.name)
  return {
    attributes: {
      name: candidate.name,
      turntableId: info.id
    }
  }
}

export const turntableFileLimit = (turntableCandidates, uploads) => {
  return (files) => {
    const candidates = files.map(mapCandidate)
    const turntables = getTurntablesFromCandidates([...candidates, ...arrayFrom(uploads)])

    for (let i = 0; i < turntables.length; i++) {
      const prevTurntable = turntableCandidates.find(({ id }) => id === turntables[i].id)
      const prevTurntableFileCount = prevTurntable?.attributes?.files?.length ?? 0
      const newTurntableFileCount = candidates.filter(c => c.attributes.turntableId === turntables[i].id).length
      if (prevTurntableFileCount + newTurntableFileCount > MAX_UPLOADABLE_TUNTABLE_FILES) {
        return [{ type: ERROR, message: `Maximum number of turntable frames is ${MAX_UPLOADABLE_TUNTABLE_FILES}` }]
      }
    }

    return []
  }
}

export const hasUniqueName = (file, files) => {
  return arrayFrom(files).every(f => f.attributes.name !== file.name)
}

export const previewFileExtension = (file) => {
  return !validFileExtensionImages(file.name)
    ? [I18n.t('file_extension_error', imagesI18nOptions)]
    : []
}

export const previewMaxDimensions = async (file) => {
  const { width, height} = await imageResolution(file)
  return width > MAX_PREVIEW_RESOLUTION || height > MAX_PREVIEW_RESOLUTION
    ? [I18n.t('max_dimensions_error', imagesI18nOptions)]
    : []
}
