import React from 'react'
import { panelCollapseClass } from './common/dashboard_client'
import PanelSpinner from './common/panel_spinner'
import { get } from '../../services/service'

const endpoint = '/turbosquid/dashboard_widgets/forum_posts.json'

class ForumPosts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: {},
    }
  }

  componentDidMount() {
    this.fetchPosts()
  }

  fetchPosts = () => {
    const url = endpoint
    get(url)
      .then((response) => {
        this.setState({
          data: response ?? {},
        })
      })
      .catch(() => {
        this.setState({
          data: {},
        })
      })
  }

  render() {
    const { data } = this.state
    const { forum_posts: posts = [] } = data
    let children

    if (posts.length > 0) {
      children = (
        <div className="panel-overflow">
          <ul className="feeds">
            {
              posts.map(post => (
                <li key={post.id}>
                  <div className="col1">
                    <div className="cont">
                      <div className="cont-col1">
                        <div className="desc">
                          <a href={post.post_uri}>{post.title}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col2">
                    <div className="date">
                      {I18n.t('dashboard.forums.post', { count: post.posts_count })}
                      &nbsp;
                      &nbsp;
                      {I18n.t('dashboard.forums.view', { count: post.views })}
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      )
    } else {
      children = <PanelSpinner />
    }

    return (
      <div className={`forums-panel-collapse ${panelCollapseClass('forums-container')}`}>
        {children}
      </div>
    )
  }
}

export default ForumPosts
