/* global I18n */
import React from 'react'
import { cookie, flattenArray } from './helpers'
import PendingAssetsService from '../../services/PendingAssetsService'
import { BATCH_TTL, BATCH_RECHECK_DELAY, BATCH_COOKIE_NAME } from './constants'

const validBatch = (batch) => Date.now() - batch.createdAt <= BATCH_TTL

export default function withPendingUpdates(WrappedComponent) {
  return class MyProductsWithPendingUpdates extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        alert: {},
        pendingProductIds: [],
        updatedProducts: {},
        pendingSubmits: [],
      }
    }

    componentDidMount() {
      this.fetchBatchesFromLocalStorage()
      window.addEventListener('beforeunload', this.checkForPendingSubmits)
    }

    componentWillUnmount() {
      window.removeEventListener('beforeunload', this.checkForPendingSubmits)
    }

    resetAlert = () => {
      this.setState({ alert: {} })
      return null
    }

    checkForPendingUpdates = (event) => {
      const { pendingProductIds } = this.state
      if (pendingProductIds.length > 0) {
        const message = I18n.t('turbosquid_products.messages.pending_updates')
        // eslint-disable-next-line no-param-reassign
        event.returnValue = message
        return message
      }
      return null
    }

    // eslint-disable-next-line consistent-return
    checkForPendingSubmits = (event) => {
      const { pendingSubmits } = this.state
      if (pendingSubmits.length > 0) {
        const message = I18n.t('turbosquid_products.messages.pending_updates')
        // eslint-disable-next-line no-param-reassign
        event.returnValue = message
        return message
      }
    }

    fetchBatchesFromLocalStorage = () => {
      const batchesString = window.localStorage.getItem('batches') || '[]'
      const pendingBatches = JSON.parse(batchesString).filter(validBatch)
      const newBatchesString = JSON.stringify(pendingBatches)
      if (batchesString !== newBatchesString) {
        window.localStorage.setItem('batches', newBatchesString)
        cookie.setCookie(BATCH_COOKIE_NAME, newBatchesString, 365)
      }

      pendingBatches.forEach((batch) => {
        setTimeout(() => { this.getBulkUpdateStatus(batch.id) }, BATCH_RECHECK_DELAY)
      })

      const pendingProductIds = flattenArray(pendingBatches.map((batch) => batch.productIds))
      this.setState({ pendingProductIds })
    }

    handleBulkUpdateSubmit = (data) => {
      const { batchID, productIds, error } = data

      if (error) {
        this.setState({ alert: { type: 'danger', message: error.message, timestamp: Date.now(), timeout: BATCH_TTL } })
      } else {
        const newBatch = { id: batchID, productIds, createdAt: Date.now() }
        const batches = JSON.parse(window.localStorage.getItem('batches') || '[]')
        const pendingBatches = batches.filter(validBatch)
        pendingBatches.push(newBatch)

        const pendingProductIds = flattenArray(pendingBatches.map((batch) => batch.productIds))
        const message = I18n.t('turbosquid_products.bulk_price_update.update_in_progress')
        this.setState({ pendingProductIds, alert: { message, type: 'warning', timestamp: Date.now(), timeout: BATCH_TTL } })
        window.scrollTo(0, 0)

        window.localStorage.setItem('batches', JSON.stringify(pendingBatches))
        cookie.setCookie(BATCH_COOKIE_NAME, JSON.stringify(pendingBatches), 365)
        setTimeout(() => { this.getBulkUpdateStatus(batchID) }, BATCH_RECHECK_DELAY)
      }
    }

    getBulkUpdateStatus = (batchID) => {
      let message = ''
      PendingAssetsService.getBulkUpdateStatus(batchID)
        .then((response) => {
          const { status } = response.attributes
          switch (status) {
            case 'completed':
            case 'failed':
              this.handleFinishedBulkUpdate(response)
              break
            case 'pending':
              this.handlePendingBulkUpdate(response)
              break
            default:
              message = I18n.t('turbosquid_products.messages.update_failed')
              this.setError(message)
          }
        })
        .catch((error) => this.setError(error.message))
    }

    setError = (message) => {
      this.setState({ alert: { type: 'danger', message, timestamp: Date.now(), timeout: BATCH_TTL } })
    }

    handleFinishedBulkUpdate = (response) => {
      const batchID = response.id
      const batches = JSON.parse(window.localStorage.getItem('batches') || '[]')
      const pendingBatches = batches.filter((batch) => batch.id !== batchID)
      window.localStorage.setItem('batches', JSON.stringify(pendingBatches))
      cookie.setCookie(BATCH_COOKIE_NAME, JSON.stringify(pendingBatches), 365)

      this.setState({
        updatedProducts: response,
        pendingProductIds: flattenArray(pendingBatches.map((batch) => batch.productIds)),
      })
    }

    handlePendingBulkUpdate = (response) => {
      const batchesString = window.localStorage.getItem('batches') || '[]'
      const pendingBatches = JSON.parse(batchesString).filter(validBatch)
      const newBatchesString = JSON.stringify(pendingBatches)

      if (batchesString !== newBatchesString) {
        window.localStorage.setItem('batches', newBatchesString)
        cookie.setCookie(BATCH_COOKIE_NAME, newBatchesString, 365)

        const pendingProductIds = flattenArray(pendingBatches.map((batch) => batch.productIds))
        this.setState({ pendingProductIds })
      }

      const batchID = response.id
      const currentBatch = pendingBatches.filter((batch) => batch.id === batchID)[0]

      if (currentBatch) {
        setTimeout(() => { this.getBulkUpdateStatus(batchID) }, BATCH_RECHECK_DELAY)
      }
    }

    handlePendingSubmitsChanges = (submitId) => {
      const { pendingSubmits } = this.state
      const submitIdIdx = pendingSubmits.indexOf(submitId)
      if (submitIdIdx !== -1) {
        pendingSubmits.splice(submitIdIdx, 1)
      } else {
        pendingSubmits.push(submitId)
      }

      this.setState({ pendingSubmits })
    }

    render() {
      const { pendingProductIds, updatedProducts, alert } = this.state
      return (
        <WrappedComponent
          {...this.props} // eslint-disable-line
          alert={alert}
          pendingProductIds={pendingProductIds}
          updatedProducts={updatedProducts}
          handleBulkUpdateSubmit={this.handleBulkUpdateSubmit}
          handlePendingSubmitsChanges={this.handlePendingSubmitsChanges}
          resetParentAlert={this.resetAlert}
        />
      )
    }
  }
}
