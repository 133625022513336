import React from 'react'
import { randomKey } from './common/number_utility'
import { panelCollapseClass } from './common/dashboard_client'
import PanelSpinner from './common/panel_spinner'
import NewsItem from './news_item'
import { get } from '../../services/service'

const endpoint = '/turbosquid/dashboard_widgets/blog_posts.json'

class News extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    this.fetchNews()
  }

  fetchNews = () => {
    const url = endpoint
    get(url)
      .then((response) => {
        this.setState({
          data: response,
        })
      })
      .catch(() => {
        this.setState({
          data: [],
        })
      })
  }

  render() {
    const { data } = this.state
    const news = data || []
    let children

    if (news.length > 0) {
      children = (
        <div className="panel-overflow">
          <ul className="chats">
            {
              news.map(post => (
                <NewsItem key={randomKey(post.created_at)} post={post} />
              ))
            }
          </ul>
        </div>
      )
    } else {
      children = <PanelSpinner />
    }

    return (
      <div className={`news-panel-collapse ${panelCollapseClass('news-container')}`}>
        {children}
      </div>
    )
  }
}

export default News
