import { connect } from 'react-redux'
import DraftAlert from '../../../components/publisher/EditHeader/DraftAlert'
import { selectNotifications } from '../../../products/react_components/selectors/uiSelectors'
import { discardDraft } from '../../../products/react_components/actions/form_slice_actions'

const mapDispatchToProps = (dispatch) => ({
  discardDraft: (url) => dispatch(discardDraft({ url })),
})

const mapStateToProps = (state) => ({
  draftId: state.formState.draftId,
  createdAt: state.formState.createdAt,
  updatedAt: state.formState.updatedAt,
  hadDraft: state.formState.hadDraft,
  notifications: selectNotifications(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftAlert)
