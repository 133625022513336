/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { timeFormat } from 'd3-time-format'
import { format } from 'd3-format'

import createReactClass from 'create-react-class';

const Chart = createReactClass({
  plot(element) {
    let chart
    const formatters = {
      axis_x_tick_format: '%b',
      tooltip_title: timeFormat('%Y-%m-%d'),
      tooltip_value: '',
      two_decimal: format('.2f'),
    }

    if (this.props.tooltip_value) {
      formatters.tooltip_value = this.props.tooltip_value
    }

    return chart = c3.generate({
      bindto: element,
      legend: {
        show: !!this.props.show_legend,
      },
      point: {
        show: false,
      },
      data: {
        x: 'x',
        columns: this.props.data,
        colors: this.props.colors,
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: formatters.axis_x_tick_format,
          },
        },
        y: {
          tick: {
            format: (value) => {
              if (this.props.y_ticks_integers) {
                if (value === Math.floor(value)) {
                  return value
                }
                return ''
              }
              return formatters.two_decimal(value)
            },
          },
        },
      },
      tooltip: {
        format: {
          title(data) {
            return formatters.tooltip_title(data)
          },
          value(value, ratio, id) {
            const tooltipFormat = format(formatters.tooltip_value)
            return tooltipFormat(value)
          },
        },
      },
    })
  },
  render() {
    return <div ref={this.plot} />
  },
})

export default Chart;
